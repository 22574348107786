import { RETRIEVED_PART, RETRIEVED_PARTS } from "../../common/consts";
import isEmpty from "lodash/isEmpty";
//import findIndex from "lodash/findIndex";
import { checkInvalidSessionID, debug } from "../../common/helpers";
import ReactGA from "react-ga4";

const partsData = (state = {}, action = {}) => {
  //console.log("Parts Reducers", state, action);
  //let _return = {};

  if (checkInvalidSessionID({ action })) {
    return {
      ...state,
    };
  }

  switch (action.type) {
    case RETRIEVED_PARTS:
      debug.log("retrieved parts", action);
      debug.log("state parts", state.parts);

      return {
        ...state,
        // if we don't already have parts OR if the current page is 1 then reset the state parts
        // otherwise, concat the state parts with the newly loaded parts because we're using load more
        parts:
          action.criteria.response.load_more && state.parts
            ? state.parts.concat(action.criteria.response.parts)
            : action.criteria.response.parts,
        //parts: (state.parts === undefined || action.partCriteria.response.current_page === 1) ? action.partCriteria.response.parts : state.parts.concat(action.partCriteria.response.parts),
        filters:
          action.criteria.response.filters !== null
            ? action.criteria.response.filters
            : state.filters,
        selectedFilters:
          action.criteria.response.selected_filters !== null
            ? action.criteria.response.selected_filters
            : [],
        totalPages: action.criteria.response.total_pages,
        totalParts: action.criteria.response.total_parts,
        time: action.criteria.response.time,
      };

    case RETRIEVED_PART:
      console.log("retrieved part", action);

      if (!isEmpty(action.criteria.response.parts)) {
        if (process.env.NODE_ENV !== "development") {
          ReactGA.event({
            category: "Part",
            action: `View: ${action.criteria.response.parts[0].id}`,
          });
        }
      }
      return {
        ...state,
        part: !isEmpty(action.criteria.response.parts)
          ? action.criteria.response.parts[0]
          : [],
        time: action.criteria.response.time,
        //filters: (action.partCriteria.response.filters !== null) ? action.partCriteria.response.filters : state.filters,
        //totalPages: action.partCriteria.response.total_pages,
        //totalParts: action.partCriteria.response.total_parts
      };

    default:
      return state;
  }
};
export default partsData;
