import classNames from 'classnames/bind';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Grid, Header, Icon } from 'semantic-ui-react';
import {
  DRILLING_CUT_TYPE_ID,
  TAPPING_CUT_TYPE_ID,
  REAMING_CUT_TYPE_ID,
  NO_FIELD_VALUE
} from '../../common/consts';
import {
  debug,
  hasValue,
  isAuthenticated,
  isTurningCutType, isTurningFinishingCutType,
  isTurningRoughingCutType,
  userTierAccess
} from '../../common/helpers';
import { updateSettings } from '../../redux/actions/settings';
import { createUserTool } from '../../redux/actions/userTools';
import RecipeField from './RecipeField';
import UserRecipeButton from './UserRecipeButton';
import RecipeVoteButtons from './RecipeVoteButtons';
import RecipeMedia from './RecipeMedia';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import ReactHtmlParser from 'react-html-parser';

// ---------------------------------------------- RECIPE PREVIEW CARD COMPONENT ---------------------------------------------- //
const RecipePreviewCard = props => {
  //console.log("Recipe Preview Card", props);

  const { recipe, settings, userTools, createUserTool } = props;
  const [playing, setPlaying] = useState(false);
  const [showMore, setShowMore] = useState(false);

  function addToMyTools(e) {
    e.preventDefault();
    // eventually we'll move this to a Redux action
    createUserTool({ data: { tool_id: recipe.cutting_tools[0].id } });
  }

  const onPlay = () => {
    setPlaying(true);
  };

  const onPause = () => {
    setPlaying(false);
  };

  const playerRef = React.createRef();

  const handleOpenFusionFile = (e) => {
    //debug.log(recipe.files);
    window.open(`fusion360://command=open&file=${encodeURIComponent(recipe.files[0].file)}`, '_blank');
  };

  const handleOpenRecipePage = (e, data, section = '') => {
    debug.log(section);
    setPlaying(false);
    window.open(`/recipe/${recipe.id}${(section !== '') ? `#${section}` : ''}`, '_blank');
  };

  const handleToggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleOpenRecipePageComments = (e, data) => {
    handleOpenRecipePage(e, data, 'comments');
  };

  const handleOpenJoin = (e) => {
    props.history.push('/join');
  };

  const handleOpenUpgrade = (e) => {
    props.history.push('/account');
  };

  // look at https://react.semantic-ui.com/views/card/#content-header-card
  return (<Card
    id="recipe-card-preview"
    fluid
    className={classNames('recipe-card', 'recipe', 'recipe-card--preview', `recipe-${recipe.id}`)}>
    <Card.Content className="recipe-card-preview__content">

      <Grid
        className="recipe-media__wrapper"
        columns={3}>
        <Grid.Row>
          <RecipeMedia
            recipe={recipe}
            minTier="1"
            checkProWithTier={true}
            playerRef={playerRef}
            playing={playing}
            onPlay={onPlay}
            onPause={onPause}/>
        </Grid.Row>
      </Grid>

      <Grid
        className="recipe-bar"
        columns={3}>
        <Grid.Row>
          {isAuthenticated() ?
            <Grid.Column
              width={2}
              className="recipe-bar__buttons">

              {/*<Grid.Column width={1} className="recipe-bar__favorite">*/}
              {/*{isAuthenticated() ? (<div*/}
              {/*className={classNames("recipe-bar__favorite-icon", `recipe-bar__favorite-icon--${recipe.is_user_recipe ? "on" : "off"}`)}*/}
              {/*onClick={toggleFavoriteRecipe}></div>) : ""}*/}
              {userTierAccess({ minTier: '1.0.0', maxTier: '1.9.9' }) && recipe.pro_tier ?
                <a
                  className="recipe-bar__lock"
                  href="/account"><Icon
                  name="lock"
                  size="small"/> PRO</a> : ''}
              {/*{(userTierAccess({minTier: "1"}) && !recipe.pro_tier) || userTierAccess({minTier: "2"}) ?*/}
              {/*  <UserRecipeButton recipe={recipe}/> : ""}*/}
              <UserRecipeButton recipe={recipe}/>
            </Grid.Column> : ''}
          <Grid.Column
            mobile={7}
            computer={3}
            className="recipe-bar__id">
            Recipe ID: {recipe.id}
          </Grid.Column>
          <Grid.Column
            mobile={7}
            computer={11}
            className="recipe-bar__voting">
            <RecipeVoteButtons recipe={recipe}/>
          </Grid.Column>
          {/*<Grid.Column width={12} floated='right' textAlign='right' className="recipe-bar__voting">{isAuthenticated() && userTierAccess({*/}
          {/*  minTier: "1.0.0",*/}
          {/*  maxTier: "1.9.9"*/}
          {/*}) && recipe.pro_tier ?*/}
          {/*  <div>UP / DOWN</div> : ""}*/}
          {/*<Icon name='lock' size='small'/> : ""}*/}

          {/*<UserRecipeButton recipe={recipe}/>*/}
          {/*<Icon link name='external' size='small' onClick={handleOpenRecipePage}*/}
          {/*      className="recipe-bar__open-recipe" title="open recipe page"/>*/}

          {/*</Grid.Column>*/}


          {/*<Grid.Column width={12} className="recipe-bar__details-btn">*/}
          {/*  <a href={`/recipe/${recipe.id}`} target="_blank" rel="noopener noreferrer" onClick={openRecipePage}>See More*/}
          {/*    Videos & Photos On Full*/}
          {/*    Recipe Page &#9658;</a>*/}
          {/*</Grid.Column>*/}
        </Grid.Row>
      </Grid>

      <Grid
        columns={3}
        divided
        className={`recipe__info-columns ${showMore ? 'show-desktop-only' : ''} `}>
        <Grid.Row>
          {/* ------------------------------------- COLUMN 1 ------------------------------------- */}
          <Grid.Column className="recipe__info-column">
            <div className="recipe__info-column-title">RECIPE INFO</div>
            <Grid
              columns={2}
              className="recipe__info-details">
              <RecipeField
                id={recipe.id}
                name="machine"
                label="Machine"
                value={!isEmpty(recipe.cnc_machine) ? `${recipe.cnc_machine.brand.title} ${recipe.cnc_machine.part_number.name}` : NO_FIELD_VALUE}
                isText={true}
              />

              <RecipeField
                id={recipe.id}
                name="material"
                label="Material"
                value={!isEmpty(recipe.material) ? `${recipe.material.title} ${recipe.material.class.name}` : NO_FIELD_VALUE}
              />

              <RecipeField
                id={recipe.id}
                name="cut-style"
                label="Cut Style"
                value={!isEmpty(recipe.cut_type) ? recipe.cut_type.name : NO_FIELD_VALUE}
              />

              <RecipeField
                id={recipe.id}
                name="mrr"
                label="MRR"
                value={recipe.mrr}
                convertible
                hideOnMobile={true}
              />

              <RecipeField
                id={recipe.id}
                name="gauge-length"
                label="Gauge Length"
                value={recipe.gage_length}
                convertOptions={{ type: 'inches' }}
                convertible
                minTier="2"
                recipe={recipe}
                isPro={recipe.pro_tier}
                checkProWithTier={true}
              />

              <RecipeField
                id={recipe.id}
                name="coolant-style"
                label="Coolant Style"
                value={!isEmpty(recipe.coolant_style.name) ? recipe.coolant_style.name : NO_FIELD_VALUE}
              />
            </Grid>

          </Grid.Column>

          {/* ------------------------------------- COLUMN 2 ------------------------------------- */}
          <Grid.Column className="recipe__info-column">
            <Grid
              columns={2}
              className="recipe__info-header">
              <Grid.Row>
                <Grid.Column className="recipe__info-column-title">TOOL INFO</Grid.Column>
                <Grid.Column className="recipe__info-column-title recipe__tool-add-my-tools">
                  {/*{console.log("recipe", recipe, "user tools", userTools)}*/}

                  {/*{console.log("find tool", (!isEmpty(userTools.user_tools) && !find(userTools.user_tools.tools, {tool_id: recipe.cutting_tools[0].id})) || isEmpty(userTools.user_tools))}*/}
                  {(!isEmpty(recipe.cutting_tools[0]) && !recipe.cutting_tools[0].is_user_tool) && ((!isEmpty(userTools.user_tools) && !find(userTools.user_tools.tools, { tool_id: recipe.cutting_tools[0].id })) || isEmpty(userTools.user_tools)) &&
                  ((userTierAccess({ minTier: '1' }) && !recipe.pro_tier) || userTierAccess({ minTier: '2' })) ?
                    (<div>
                        {/*<a href="" onClick={addToMyTools}>+ Add to my tools</a>*/}
                        <Button
                          onClick={addToMyTools}
                          className="link">+ Add to my tools</Button></div>
                    ) : (!isEmpty(userTools.user_tools) && find(userTools.user_tools.tools, { tool_id: recipe.cutting_tools[0].id })) ?
                      <div>Added to <a
                        href="/account"
                        rel="noopener noreferrer">My Tools</a></div> : <React.Fragment/>}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid
              columns={2}
              className="recipe__info-details">
              <RecipeField
                id={recipe.id}
                name="tool-name"
                label="Tool Name"
                value={!isEmpty(recipe.cutting_tools[0]) ? recipe.cutting_tools[0].aka : NO_FIELD_VALUE}
              />

              <RecipeField
                id={recipe.id}
                name="tool-brand"
                label="Tool Brand"
                value={!isEmpty(recipe.cutting_tools[0]) ? recipe.cutting_tools[0].brand.title : NO_FIELD_VALUE}
              />

              <RecipeField
                id={recipe.id}
                name="tool-type"
                label="Tool Type"
                value={!isEmpty(recipe.cutting_tools[0]) ? recipe.cutting_tools[0].type.name : NO_FIELD_VALUE}
              />

              <RecipeField
                id={recipe.id}
                name="tool-diameter"
                label="Tool Diameter"
                value={!isEmpty(recipe.cutting_tools[0]) ? recipe.cutting_tools[0].cutting_diameter : NO_FIELD_VALUE}
                convertOptions={{ type: 'inches', precision: 4 }}
                convertible
              />

              <RecipeField
                id={recipe.id}
                name="flutes"
                label="Flutes"
                value={!isEmpty(recipe.cutting_tools[0]) ? recipe.cutting_tools[0].number_of_flutes : NO_FIELD_VALUE}
                minTier="2"
                recipe={recipe}
                isPro={recipe.pro_tier}
                checkProWithTier={true}
              />

              <RecipeField
                id={recipe.id}
                name="tool-url"
                label="URL"
                value={(!isEmpty(recipe.cutting_tools[0]) && !isEmpty(recipe.cutting_tools[0].url)) ? 'Click here to view' : ''}
                url={(!isEmpty(recipe.cutting_tools[0]) && !isEmpty(recipe.cutting_tools[0].url)) ? recipe.cutting_tools[0].url : ''}
                urlOptions={{ target: '_blank', rel: 'noopener noreferrer' }}
                isURL={(!isEmpty(recipe.cutting_tools[0]) && !isEmpty(recipe.cutting_tools[0].url))}
                minTier="2"
                recipe={recipe}
                isPro={recipe.pro_tier}
                checkProWithTier={true}
              />
            </Grid>
          </Grid.Column>

          {/* ------------------------------------- COLUMN 3 ------------------------------------- */}
          <Grid.Column className="recipe__info-column">
            <div className="recipe__info-column-title">CUT INFO</div>
            <Grid
              columns={2}
              className="recipe__info-details">

              {/*{isAuthenticated() && !userTierAccess({minTier: "1"}) ?*/}
              {isAuthenticated() && userTierAccess({ minTier: recipe.tier }) ?
                <React.Fragment>
                  {/* Only if Cut Type is Drilling */}
                  {(recipe.cut_type.id === DRILLING_CUT_TYPE_ID || isTurningCutType(recipe.cut_type.id)) ?
                    <RecipeField
                      id={recipe.id}
                      name="feed-per-revolution"
                      label="Feed per Rev"
                      value={hasValue(recipe.inches_per_revolution) ? recipe.inches_per_revolution : NO_FIELD_VALUE}
                      minTier="2"
                      recipe={recipe}
                      isPro={recipe.pro_tier}
                      checkProWithTier={true}
                      convertOptions={{ type: 'inches', precision: 4 }}
                      convertible
                    /> :
                    <RecipeField
                      id={recipe.id}
                      name="feed-per-tooth"
                      label="Feed per Tooth"
                      value={hasValue(recipe.programmed_ipt) ? recipe.programmed_ipt : NO_FIELD_VALUE}
                      minTier="2"
                      recipe={recipe}
                      isPro={recipe.pro_tier}
                      checkProWithTier={true}
                      convertOptions={{ type: 'inches', precision: 4 }}
                      convertible
                    />}

                  <RecipeField
                    id={recipe.id}
                    name="feed-per-minute"
                    label="Feed per Minute"
                    value={hasValue(recipe.ipm) ? recipe.ipm : NO_FIELD_VALUE}
                    minTier="2"
                    recipe={recipe}
                    isPro={recipe.pro_tier}
                    checkProWithTier={true}
                    convertOptions={{ type: 'inches', precision: 1 }}
                    convertible
                  />

                  <RecipeField
                    id={recipe.id}
                    name="surface-speed"
                    label="Surface Speed"
                    value={hasValue(recipe.surface_feet_per_minute) ? recipe.surface_feet_per_minute : NO_FIELD_VALUE}
                    minTier="2"
                    recipe={recipe}
                    isPro={recipe.pro_tier}
                    checkProWithTier={true}
                    convertOptions={{ type: 'feet/min' }}
                    convertible
                  />

                  {(!isTurningCutType(recipe.cut_type.id) || (isTurningCutType(recipe.cut_type.id) && !recipe.programmed_with_const_surface_speed)) ?
                    <RecipeField
                      id={recipe.id}
                      name="rpm"
                      label="RPM"
                      value={hasValue(recipe.rpm) ? recipe.rpm : NO_FIELD_VALUE}
                      minTier="2"
                      recipe={recipe}
                      isPro={recipe.pro_tier}
                      checkProWithTier={true}
                    /> : ''}

                  {/* Only if Cut Type is not Drilling, Tapping, Reaming, Turning Profile Roughing, or Turning Profile Finishing */}
                  {

                    /*
                     (recipe.cut_type.id !== DRILLING_CUT_TYPE_ID &&
                   recipe.cut_type.id !== TURNING_PROFILE_ROUGHING_CUT_TYPE_ID &&
                  recipe.cut_type.id !== TURNING_PROFILE_FINISHING_CUT_TYPE_ID) ? (

                     */
                  }
                  {![DRILLING_CUT_TYPE_ID, TAPPING_CUT_TYPE_ID, REAMING_CUT_TYPE_ID].includes(recipe.cut_type.id) && !isTurningCutType(recipe.cut_type.id) ? (
                    <React.Fragment>
                      <RecipeField
                        id={recipe.id}
                        name="radial-doc-value"
                        label="RADIAL D.O.C"
                        value={hasValue(recipe.radial_depth_of_cut_value) ? recipe.radial_depth_of_cut_value : NO_FIELD_VALUE}
                        minTier="2"
                        recipe={recipe}
                        isPro={recipe.pro_tier}
                        checkProWithTier={true}
                        convertOptions={{ type: 'inches', precision: 3 }}
                        convertible
                      />

                      <RecipeField
                        id={recipe.id}
                        name="axial-doc-value"
                        label="AXIAL D.O.C"
                        value={hasValue(recipe.axial_depth_of_cut_value) ? recipe.axial_depth_of_cut_value : NO_FIELD_VALUE}
                        minTier="2"
                        recipe={recipe}
                        isPro={recipe.pro_tier}
                        checkProWithTier={true}
                        convertOptions={{ type: 'inches', precision: 3 }}
                        convertible
                      />
                    </React.Fragment>
                  ) : ''}

                  {/* Only if Cut Type is Turning Profile Roughing */}
                  {/*{(recipe.cut_type.id === TURNING_PROFILE_ROUGHING_CUT_TYPE_ID) ? (*/}
                  {(isTurningRoughingCutType(recipe.cut_type.id)) ? (
                    <React.Fragment>
                      <RecipeField
                        id={recipe.id}
                        name="depth-of-cut"
                        label="D.O.C"
                        value={hasValue(recipe.depth_of_cut) ? recipe.depth_of_cut : NO_FIELD_VALUE}
                        minTier="2"
                        recipe={recipe}
                        isPro={recipe.pro_tier}
                        checkProWithTier={true}
                        convertOptions={{ type: 'inches', precision: 3 }}
                        convertible
                      />
                    </React.Fragment>
                  ) : ''}

                  {/* Only if Cut Type is Turning Profile Finishing */}
                  {/*{(recipe.cut_type.id === TURNING_PROFILE_FINISHING_CUT_TYPE_ID) ? (*/}
                  {(isTurningFinishingCutType(recipe.cut_type.id)) ? (
                    <React.Fragment>
                      <RecipeField
                        id={recipe.id}
                        name="depth-of-cut-x"
                        label="D.O.C X"
                        value={hasValue(recipe.depth_of_cut_x) ? recipe.depth_of_cut_x : NO_FIELD_VALUE}
                        minTier="2"
                        recipe={recipe}
                        isPro={recipe.pro_tier}
                        checkProWithTier={true}
                        convertOptions={{ type: 'inches', precision: 3 }}
                        convertible
                      />
                      <RecipeField
                        id={recipe.id}
                        name="depth-of-cut-z"
                        label="D.O.C Z"
                        value={hasValue(recipe.depth_of_cut_z) ? recipe.depth_of_cut_z : NO_FIELD_VALUE}
                        minTier="2"
                        recipe={recipe}
                        isPro={recipe.pro_tier}
                        checkProWithTier={true}
                        convertOptions={{ type: 'inches', precision: 3 }}
                        convertible
                      />
                    </React.Fragment>
                  ) : ''}

                  <Button
                    textAlign="center"
                    className="show-more-btn"
                    onClick={handleToggleShowMore}>Show More</Button>
                </React.Fragment> :
                // show free ad
                <Grid
                  className="recipe-preview-banner-ad test-drive-recipe-preview-banner-ad"
                  verticalAlign="middle"
                  centered>
                  <Grid.Row>
                    <Grid.Column>
                      <Header
                        as="h1"
                        textAlign="center">{get(settings, 'config.htmls.test_drive_recipe_preview_banner.title')}</Header>
                      {ReactHtmlParser(get(settings, 'config.htmls.test_drive_recipe_preview_banner.html'))}
                      <Button
                        animated
                        secondary
                        className="test-drive-recipe-preview-banner-ad__button"
                        onClick={userTierAccess({ minTier: '1.0.0' }) ? handleOpenUpgrade : handleOpenJoin}
                        textAlign="center">
                        <Button.Content visible>Upgrade</Button.Content>
                        <Button.Content hidden><Icon name="arrow right"/></Button.Content>
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid className="recipe-footer">
        {/*{isAuthenticated() && !userTierAccess({minTier: "1"}) ?*/}
        {isAuthenticated() && !userTierAccess({ minTier: recipe.tier }) ?
          // free tier (when recipe isn't free)
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button
                onClick={userTierAccess({ minTier: '1.0.0' }) ? handleOpenUpgrade : handleOpenJoin}
                className="black"
                icon="right caret"
                content="Upgrade to view recipe information"
                labelPosition="right"
              />
            </Grid.Column>
          </Grid.Row>
          :
          // paid tiers
          <Grid.Row columns={2}>
            <Grid.Column textAlign="left">
              {/*{!isEmpty(recipe.files) && isAuthenticated() && ((userTierAccess({minTier: "1"}) && !recipe.pro_tier) || userTierAccess({minTier: "2"})) ?*/}
              {!isEmpty(recipe.files) && isAuthenticated() && userTierAccess({ minTier: recipe.tier }) ?
                <React.Fragment>
                  <Button
                    onClick={handleOpenFusionFile}
                    className="black open-in-fusion-btn"
                    icon="right caret"
                    content="Open in Fusion 360"
                    labelPosition="right"
                  />
                </React.Fragment> : ''}
            </Grid.Column>
            <Grid.Column textAlign="right">
              {/*<a href={`/recipe/${recipe.id}`} target="_blank" rel="noopener noreferrer" onClick={openRecipePage}>See More*/}
              {/*  Videos & Photos On Full*/}
              {/*  Recipe Page &#9658;</a>*/}

              {isAuthenticated() && ((userTierAccess({ minTier: '1' }) && !recipe.pro_tier) || userTierAccess({ minTier: '2' })) ?
                <Button
                  onClick={handleOpenRecipePageComments}
                  className="black comments-btn"
                  icon="right caret"
                  content="Comments"
                  labelPosition="right"
                /> : <React.Fragment/>}


              <Button
                onClick={handleOpenRecipePage}
                className="black details-btn"
                icon="right caret"
                content="Detailed Speeds & Feeds"
                labelPosition="right"
              />
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
    </Card.Content>
  </Card>);
};

// ---------------------------------------------- //RECIPE PREVIEW CARD COMPONENT ---------------------------------------------- //

function mapStateToProps(state) {
  //console.log("Recipes: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    settings: state.settings,
    userTools: state.userToolsData,
  };
}

export default React.memo(connect(mapStateToProps, { updateSettings, createUserTool })(withRouter(RecipePreviewCard)));