//import queryString from "query-string";
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
import { Grid, Header } from 'semantic-ui-react';
// import { DEFAULT_AUTHENTICATED_ROUTE } from "../../common/consts";
// import { isAuthenticated } from "../../common/helpers";
import { updateSettings } from '../../redux/actions/settings';
import Layout from '../global/Layout';

function ExitSurvey(props) {
  const { settings, updateSettings } = props;

  //const parsedURLParams = queryString.parse(props.location.search);
  // console.log("params", parsedURLParams);

  useEffect(() => {
    //console.log("useEffect: SignUp", props);

    if (settings.activeMenuItem !== 'exit-survey') {
      updateSettings({ activeMenuItem: 'exit-survey' });

      // setTimeout(function () {
      //   store.set("visited_user_survey", true);
      // }, 60000);
    }

  }, [settings]);

  let formURL = `https://form.jotform.com/210055844173148`;
  // let formURL = `https://form.jotform.com/${jotFormID}${!isEmpty(user.email) ? `?email=${user.email}&fullName=${user.fullname}` : ""}`;

  return (
    <Layout>
      <Grid className="exit-survey-content content">
        <Grid.Row className="exit-survey-content__heading">
          <Grid.Column
            className="exit-survey-content__header"
            width={5}><Header as="h1">Exit Survey</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid
            className="exit-survey-content__form-columns"
            columns={1}>
            <Grid.Row>
              <Grid.Column width={16}>
                <iframe
                  title="Exit Survey Form"
                  src={formURL}
                  width="100%"
                  height="1000"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0">Loading...
                </iframe>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Row>
      </Grid>
    </Layout>);
}

function mapStateToProps(state) {
  //console.log("SignUp: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    //order: state.orderData,
  };
}

export default connect(mapStateToProps, { updateSettings })(ExitSurvey);
