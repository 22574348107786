import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "semantic-ui-react";
import { updateSettings } from "../../redux/actions/settings";
import { debug, isAuthenticated, userTierAccess } from "../../common/helpers";
import store from "store";
import ReactPlayer from "react-player";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Mousetrap from "mousetrap";

function RecipeRoadblock (props) {
  const {settings, updateSettings} = props;
  const [showRoadblock, setShowRoadblock] = useState(isAuthenticated() && !userTierAccess({minTier: "1"}) && store.get("show_free_tier_roadblock") && !isEmpty(get(settings, "config.free_tier_roadblock_video_url")));

  const hideRoadblock = () => {
    debug.log("hiding free tier roadblock");
    store.remove("show_free_tier_roadblock");
    setShowRoadblock(false);
    updateSettings({
      hideRoadblock: true
    });

  };

  const openedModal = () => {
  };
  const closedModal = () => {
  };
  const onPlay = () => {
  };

  const onProgress = (progress) => {
    //debug.log("progress", progress);

    if (progress.played > .99) {
      hideRoadblock();
    }
  };

  const playerRef = React.createRef();

  Mousetrap.bind("alt+right", function () {
    hideRoadblock();
    Mousetrap.unbind("alt+right");
  });

  return (
    <React.Fragment>{get(settings, "config") && showRoadblock ?
      <Modal
        dimmer="blurring"
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={closedModal}
        onOpen={openedModal}
        open={showRoadblock}
        //close={!showRoadblock}
      >
        {/*<Modal.Header>Test Drive Intro Video</Modal.Header>*/}
        <Modal.Content>
          <ReactPlayer
            className='recipe-roadblock__react-player'
            width="100%"
            height="100%"
            url={settings.config.free_tier_roadblock_video_url}
            ref={playerRef}
            onPlay={onPlay}
            playing={true}
            //onPause={onPause}
            onProgress={onProgress}
            controls={false}
            config={{
              vimeo: {
                preload: true,
                playerOptions: {responsive: true}
              }
            }}
          />

        </Modal.Content>
        {/*<Modal.Actions>*/}
        {/*<Button onClick={closeModal} positive disabled={shouldShowRoadblock()}>Continue</Button>*/}
        {/*</Modal.Actions>*/}
      </Modal> : ""}
    </React.Fragment>);
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, {updateSettings})(RecipeRoadblock);