import React from "react";
import packageJson from "../../package.json";
import { debug, sessionLogout } from "./helpers";
import store from "store";
import compareVersions from "compare-versions";

export const FORCE_LOGOUT_ON_NEWER_VERSION = true;

global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log("Clearing cache and hard reloading...");
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }

        if (process.env.NODE_ENV === "production") {
          // delete browser cache and hard reload
          window.location.reload(true);
        }
      }
    };
  }

  componentDidMount () {
    fetch("/meta.json")
      .then((response) => {
        //console.log("meta json", response);
        return response.json();
      })
      .then((meta) => {
        //console.log("meta", meta);
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        console.log(`meta version: ${latestVersion} current version: ${currentVersion}`);

        // check the stored current version. If the stored version is < the current version AND
        // we're forcing a log out on new version, log the user out of the session forcing them to log back in
        const storedCurrentVersion = store.get("current_version") || "0";
        debug.log("stored current version", storedCurrentVersion, "current version", currentVersion);
        debug.log("compare to min app version", compareVersions(storedCurrentVersion, currentVersion));
        //
        if (compareVersions(storedCurrentVersion, currentVersion) < 0 && FORCE_LOGOUT_ON_NEWER_VERSION) {
          debug.log("logging out");
          //   // we don't have the minimum version so log out
          sessionLogout();
        }
        store.set("current_version", currentVersion);

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          sessionLogout();
          this.setState({loading: false, isLatestVersion: false});
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({loading: false, isLatestVersion: true});
        }

      });
  }

  render () {
    const {loading, isLatestVersion, refreshCacheAndReload} = this.state;
    return this.props.children({loading, isLatestVersion, refreshCacheAndReload});
  }
}

export default CacheBuster;