import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Input, List, Table } from 'semantic-ui-react';
import { convertUnits, debug, isNumber, userTierAccess, isMobileDevice } from '../../common/helpers';
import { retrieveRecipes } from '../../redux/actions/recipes';
import { updateSettings } from '../../redux/actions/settings';
import { HashLink as Link } from 'react-router-hash-link';

function RecipeListFilter(props) {
  const {
    path,
    simple,
    property = 'title',
    defaultToTextField,
    showSearch = true,
    showUncheckAll = false,
    title,
    className,
    filters,
    selectedFilters,
    retrieveRecipes,
    updateSettings,
    settings,
    recipesTime,
    minTier = '0.0.0',
    suffix = '',
    range = false,
    increment = 1,
    incrementByRange = false,
    presorted = false,
    minFilterOptionsScroll = 12,
    small = false,
    initOpen = true,
    initOpenOnMobile = false,
    multiSelect = true,
    overrideActiveFilters = false,
    faq = '',
    onRemove = null,
    convertible,
    convertOptions = {}
  } = props;

  //console.log(`path ${filter path}`);
  //debug.log(`path ${path}`);
  const [open, setOpen] = useState((initOpen && !isMobileDevice()) || initOpenOnMobile);
  const [searchString, setSearchString] = useState('');
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);
  const [allFilterOptions, setAllFilterOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [madeFirstSelection, setMadeFirstSelection] = useState(false);

  function resetFilter() {
    setMadeFirstSelection(false);
    setSearchString('');
    setSelectedFilterOptions([]);

    if (initOpen && !isMobileDevice()) {
      openList();
    }
  }

  function changeTextField(e) {
    setSearchString(e.currentTarget.value);
  }

  function searchTextField() {
    // console.log('search text field', path, searchString);
    // updateSettings({
    //   activeFilters: set(settings.activeFilters, path, searchString)
    // });

    updateRecipes({ value: searchString });
  }

  function changeSearch(e) {
    setSearchString(e.currentTarget.value);
    searchFilterOptions(e.currentTarget.value);
  }

  function searchFilterOptions(_searchString) {
    //console.log("searching for", searchString, "all filter options", allFilterOptions);

    let _allFilterOptions = allFilterOptions;

    const _filterOptions = _allFilterOptions.filter(function (item) {
      //console.log("item", item);
      const _value = (simple ? item.toString() : item[property].toString());
      //console.log("_value", _value);
      _searchString = _searchString.replace(/\\/g, '');
      const regex = new RegExp(_searchString, 'gi');
      return _value.match(regex);
    });
    // debug.error('_filterOptions', _filterOptions);
    setFilterOptions(_filterOptions);
  }

  function toggleSelectedFilterOption(e) {
    e.preventDefault();

    if (settings.fetchingRecipes) {
      return;
    }

    //console.log("toggle selected filter option", e.currentTarget.dataset.id);
    const _id = e.currentTarget.dataset.id;

    // open first
    if (selectedFilterOptions.length === 1) {
      openList();
    }

    // then remove so it appears unchecked in the list
    removeSelectedFilterOption(_id.toString());

    if (selectedFilterOptions.length > 1) {
    }
  }

  function selectFilterOption(e) {
    e.preventDefault();
    //console.log("selected option value", e.currentTarget.dataset.id);

    if (settings.fetchingRecipes) {
      return;
    }

    if (!userTierAccess({ minTier })) {
      return;
    }

    const _id = e.currentTarget.dataset.id;

    if (selectedFilterOptions.includes(_id.toString())) {
      //console.log("calling removing", _id.toString());
      removeSelectedFilterOption(_id.toString());
    } else {

      //console.log("does not exist, adding", _id.toString());
      addSelectedFilterOption(_id.toString());
    }

    // console.log("selectedFilterOptions", selectedFilterOptions);
    // if (selectedFilterOptions.length < 1) {
    //   closeList();
    // }
  }

  function addSelectedFilterOption(id) {

    let _selectedFilterOptions = [id];

    if (multiSelect) {
      _selectedFilterOptions = [id, ...selectedFilterOptions];
    }

    // console.log("adding item to filter", [..._selectedFilterOptions], path);
    setSelectedFilterOptions([..._selectedFilterOptions]);
    //console.log("(add) updated _selectedFilterOptions", _selectedFilterOptions);
    changeSelectedFilterOptions(_selectedFilterOptions);
  }

  function removeSelectedFilterOption(id) {
    let _selectedFilterOptions = selectedFilterOptions;
    //console.log("exists, removing", id.toString());
    const _idIndex = _selectedFilterOptions.indexOf(id.toString());
    _selectedFilterOptions.splice(_idIndex, 1);
    //console.log("(remove) updated _selectedFilterOptions", _selectedFilterOptions);
    setSelectedFilterOptions([..._selectedFilterOptions]);
    changeSelectedFilterOptions(_selectedFilterOptions);
  }

  function uncheckAll(e) {
    e.preventDefault();

    const _selectedFilterOptions = [];
    setSelectedFilterOptions([..._selectedFilterOptions]);
    changeSelectedFilterOptions(_selectedFilterOptions);
  }

  function changeSelectedFilterOptions(selectedFilterOptionIDs) {
    //console.log("change selected filter options", selectedFilterOptionIDs);
    //console.log("selectedFilterOptionIDs", selectedFilterOptionIDs, "selectedFilterOptions", selectedFilterOptions);
    if (selectedFilterOptionIDs.length === 1 && !madeFirstSelection) {
      setMadeFirstSelection(true);
      closeList();
    }

    updateRecipes({ value: selectedFilterOptionIDs });
    // updateSettings({
    //   activeFilters: set(settings.activeFilters, path, selectedFilterOptionIDs)
    // });
    //
    // retrieveRecipes();
    //
    // // go to the top
    // window.scrollTo(0, 0);
  }

  function updateRecipes(options) {
    //console.log("filter retrieve recipes", options);
    // console.log('updateRecipes', overrideActiveFilters, settings.activeRecipeFilters);

    if (settings.activeRecipeFilters.user_filters !== null && !isEmpty(get(settings.activeRecipeFilters, 'user_filters'))) {
      // console.log('applied user filter');
      updateSettings({
        activeRecipeFilters: set(settings.activeRecipeFilters, 'append_to_user_filters', true)
      });
    }

    if (overrideActiveFilters) {
      updateSettings({
        activeRecipeFilters: set({}, path, options.value)
      });
    } else {
      updateSettings({
        activeRecipeFilters: set(settings.activeRecipeFilters, path, options.value)
      });
    }

    retrieveRecipes();

    // go to the top
    window.scrollTo(0, 0);
  }

  function toggleOpenList(e) {
    e.preventDefault();
    openList();
  }

  function toggleCloseList(e) {
    e.preventDefault();
    closeList();
  }

  function openList() {
    setOpen(true);
  }

  function closeList() {
    setOpen(false);
    setSearchString('');
    if (allFilterOptions.length) {
      setFilterOptions(allFilterOptions);
    }
  }

  function sortSelectedFilterOptions() {
    let sortedSelectedFilterOptions = [];

    if (simple) {
      //sortedSelectedFilterOptions = selectedFilterOptions.sort();
      sortedSelectedFilterOptions = selectedFilterOptions.sort((a, b) => a - b);

    } else {
      //console.log("all filter options", allFilterOptions);
      sortedSelectedFilterOptions = sortBy(selectedFilterOptions.map((id, index) => {
        //console.log("id", id);
        let filterOption = find(allFilterOptions, { id: parseInt(id) });
        if (filterOption === undefined) {
          filterOption = id;
        }
        //console.log("filter option", filterOption);
        return filterOption;
      }), property);
    }

    //console.log("sorted filter options", sortedSelectedFilterOptions);
    return sortedSelectedFilterOptions;
  }

  function sortFilterOptions() {
    // debug.log(path, 'SORT FILTER OPTIONS');
    // if (path === 'tools.cutting_diameters') {
    //   debug.error('sortFilterOptions()', path, filterOptions);
    // }
    let sortedFilterOptions = [];

    //debug.log("path", path, "presorted", presorted, filterOptions);
    if (presorted) {
      sortedFilterOptions = filterOptions;

    } else if (simple) {
      //sortedFilterOptions = filterOptions.sort();
      sortedFilterOptions = filterOptions.sort((a, b) => a - b);
    } else {
      //console.log("property", property);
      sortedFilterOptions = sortBy(filterOptions.map((id, index) => {
        //console.log("id", id);
        let filterOption = find(allFilterOptions, { id: parseInt(id) });
        if (filterOption === undefined) {
          filterOption = id;
        }
        //console.log("filter option", filterOption);
        return filterOption;
      }), property);
    }
    // console.log("sort filter options", path, simple, sortedFilterOptions);

    // debug.log(path, 'sorted filter options', sortedFilterOptions);
    return sortedFilterOptions;
  }

  let _filter = {};

  useEffect(() => {

    // console.log("selected filter options", "filters", filters, "all filter options", allFilterOptions);

    // if (path === "materials.types") {
    //   console.log("material types filters", filters);
    //   console.log("settings recipes time", settings.recipesTime, "recipes time", recipesTime);
    // }

    //if (!settings.fetchingRecipes && !isEmpty(recipe) && !isEqual(recipe, _recipe)) {
    _filter = {};

    //console.log(path, "filters", filters, "settings", settings, "recipes time", recipesTime);
    if (!isEmpty(filters)) {
      //console.log("filters", filters);
      //console.log("all filters", allFilterOptions);

      if (isEmpty(allFilterOptions) || settings.recipesTime !== recipesTime) {
        //debug.log(`updating filter ${path}`, "filters", filters);
        //debug.log("_f", _f);

        _filter = get(filters, path);
        //_filter = stringToObjectPath({string: path, obj: filters});
        //debug.log("_filter", _filter);

        if (!_filter) {
          debug.error(`cannot find ${path} in filters`, filters);
          setFilterOptions({});
          setAllFilterOptions({});

        } else {
          // if (path === "materials.types") {
          // console.log("material types", _filter);
          // console.log("settings recipes time", settings.recipesTime, "recipes time", recipesTime);
          // }
          if (path === 'tools.gage_length_mins') {
            // debug.error(path, _filter);
          }
          setFilterOptions(_filter);
          setAllFilterOptions(_filter);
        }
        //console.log("Filter: filter", _filter);
        //if (isEmpty(settings.activeFilters) && initOpen) {

        //if (isEmpty(settings.activeFilters) || isEmpty(settings.activeFilters.user_filters) || (!isEmpty(settings.activeFilters.user_filters) && path !== "user_filters")) {
        if (isEmpty(settings.activeRecipeFilters) || !isEmpty(settings.activeRecipeFilters.user_filters) || isEmpty(get(settings.activeRecipeFilters, path))) {
          resetFilter();
        }
        //console.log(`updated filter ${path}`);
      }
    } else {

    }

    const _selectedFilters = get(selectedFilters, path);

    if (!isEmpty(_selectedFilters)) {
      //console.log("selected filters from backend", _selectedFilters);

      if (Array.isArray(_selectedFilters)) {
        setSelectedFilterOptions(_selectedFilters);
        closeList();
      } else {
        setSearchString(_selectedFilters);
      }
    }

    // if (path === 'tools.cutting_diameters') {
    //   debug.error('final', path, allFilterOptions);
    // }
  }, [filters, selectedFilters]);

  // useEffect(() => {
  //   if (path === 'tools.cutting_diameters') {
  //     debug.error('ALL FILTER OPTIONS UPDATED', path, allFilterOptions);
  //   }
  // }, [allFilterOptions]);

  //debug.log(`checking ${path} all filter options`, allFilterOptions);
  // we want to make sure we don't skip this filter if it's for the ID field which is a special case
  // since ID won't have any filter options
  if ((isEmpty(filters) || isEmpty(allFilterOptions)) && path !== 'id') {
    //debug.log(`filters is empty or all filter options is empty for ${path}`);
    return (<React.Fragment></React.Fragment>);
  }

  if (isEmpty(allFilterOptions) && defaultToTextField) {
    return (<React.Fragment>
      <div className={classNames('text-filter', className)}>
        <div className="title">{title}</div>
        <Input
          placeholder="Search..."
          value={searchString}
          onChange={changeTextField}
          icon={{ name: 'search', circular: true, link: true, onClick: searchTextField }}/>
      </div>
    </React.Fragment>);
  }

  return (<React.Fragment>

    <div className={classNames('list-filter', className)}>
      <div className="title">{title} {!isEmpty(faq) ?

        <Link to={`/faq#${faq}`}><Icon
          name="question circle outline"
          size="small"/></Link> : ''}
        {/*{!isEmpty(selectedFilterOptions) ?*/}

        <span
          className={`list-filter__toggle list-filter--${open ? 'hide' : 'show'}`}>{open ?
          <Button
            onClick={toggleCloseList}
            className="link">Hide</Button> :
          <Button
            onClick={toggleOpenList}
            className="link">Show</Button>}</span>
        {/*: ""}*/}
      </div>

      {/*{path === 'tools.cutting_diameters' && debug.error(path, allFilterOptions)}*/}

      {open && !isEmpty(allFilterOptions) ?
        <div className="list-filter--opened">
          {showSearch ?
            <Input
              placeholder="Search..."
              value={searchString}
              onChange={changeSearch}
              disabled={!userTierAccess({ minTier })}
              icon={{ name: 'search', circular: true, link: false }}/> : ''}
          <div
            className={classNames('list-filter__table', {
              scrollable: allFilterOptions.length > minFilterOptionsScroll,
              small
            })}>
            <Table>
              <Table.Body>
                {sortFilterOptions().map((value, key) => {
                  // console.log("value", value, "key", key);
                  // console.log("value", value, "new selectedFilterOptions", selectedFilterOptions);

                  //const _index = key;
                  const _nextIndex = key + 1;

                  const id = value.id !== undefined ? value.id.toString() : (simple ? value.toString() : value[property].toString());
                  //console.log("id", id, "selectedFilterOptions", selectedFilterOptions);

                  //let _value = simple ? `${value}${suffix}` : `${value[property]}${suffix}`;

                  let _value = '';
                  let _incrementalValue = 0;
                  let _increment = increment;

                  if (range) {
                    //_value = simple ? `${value}${suffix} - ${parseInt(value) + increment}${suffix}` : `${value[property]}${suffix} - ${parseInt(value[property]) + increment}${suffix}`;
                    debug.log('range: convertible', convertible, 'path', path);

                    _value = simple ? parseFloat(value) : parseFloat(value[property]);
                    //debug.log("path", path, _value, "range init value", "convertible", convertible);

                    if (incrementByRange) {
                      _increment = sortFilterOptions().length === _nextIndex ? 0 : sortFilterOptions()[_nextIndex] - _value - .01;
                      // debug.log('path', path, _increment, 'increment');
                    }
                    // && isNumber(_value)
                    if (convertible) {
                      //console.log("is number ", value, "converted", convertUnits({...convertOptions, value}));

                      // if (incrementByRange) {
                      //   _increment = sortFilterOptions().length == _nextIndex ? 0 : sortFilterOptions()[_nextIndex];
                      //   debug.log("path", path, _increment, "increment");
                      //
                      // }

                      // convert incremental first since _value is still a number
                      _incrementalValue = convertUnits({
                        value: (_value + _increment),
                        precision: 2,
                        ...convertOptions
                      });

                      _value = convertUnits({ value: _value, precision: 2, ...convertOptions });
                      // debug.log("converted values", _value, _incrementalValue);

                    } else {

                      _incrementalValue = _value + _increment;
                    }

                    if (_increment === 0) {
                      _incrementalValue = '+';
                    }

                    if (!isEmpty(suffix)) {
                      _value = `${_value}${suffix} - ${_incrementalValue}${suffix}`;
                    } else {
                      _value = <React.Fragment>{_value} <span> - </span>{_incrementalValue}</React.Fragment>;
                    }
                  } else {

                    //debug.log("reg: convertible", convertible, "path", path);
                    _value = simple ? value : value[property];
                    //debug.log(_value, "init value", "convertible", convertible);

                    if (convertible && isNumber(_value)) {
                      // debug.log('is number ', value, 'converted', convertUnits({ ...convertOptions, value }));
                      //debug.log('precision units', { value: _value, precision: 2, ...convertOptions });
                      _value = convertUnits({ value: _value, precision: 2, ...convertOptions });
                      // debug.log(_value, 'converted value');
                    }
                    // debug.log('suffix', suffix);
                    if (!isEmpty(suffix)) {
                      _value = `${_value}${suffix}`;
                    }
                  }

                  // debug.log('CONVERT', convertUnits({
                  //   ...convertOptions,
                  //   returnAs: 'object',
                  //   // value: 0.2361811,
                  //   value: 0.1875,
                  //   type: 'inches',
                  //   precision: 2
                  // }));

                  return (
                    <Table.Row
                      className="option-row"
                      key={key}>
                      <Table.Cell className="option-column">{selectedFilterOptions.includes(id) ? <span
                        className="checkmark">&#10003;</span> : ''}
                        {onRemove ? <Button
                          onClick={onRemove}
                          color="red"
                          circular
                          size="mini"
                          icon="close"
                          className="remove"
                          data-id={id}
                          data-title={_value}/> : ''}
                        <Button
                          onClick={selectFilterOption}
                          className={classNames('link', { 'no-access': !userTierAccess({ minTier }) })}
                          data-id={id}>{_value}</Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
          {showUncheckAll ? <div className="uncheck-all"><Button
            onClick={uncheckAll}
            className="link">Uncheck
            All</Button></div> : ''}
        </div>
        :
        <div className="list-filter--closed">
          <List>
            {/*{console.log("sort selected filter options", sortSelectedFilterOptions())}*/}
            {sortSelectedFilterOptions().map((selectedFilterOption, index) => {
              // debug.info('path', path);
              // debug.info('selected filter option', selectedFilterOption);
              const id = selectedFilterOption.id !== undefined ? selectedFilterOption.id.toString() : selectedFilterOption;

              let title = selectedFilterOption[property] !== undefined ? selectedFilterOption[property] : selectedFilterOption;
              if (convertible && isNumber(title)) {
                // debug.log('label is number ', title, 'converted', convertUnits({ ...convertOptions, title }));
                //title = convertUnits({ ...convertOptions, returnAs: 'valueOnly', value: title });

                title = convertUnits({ returnAs: 'object', value: title, precision: 2,...convertOptions });
                // debug.log(title, 'converted value');
              }

              if (range) {
                // let _increment = increment;
                //
                // if (incrementByRange) {
                //   _increment = sortFilterOptions().length == _nextIndex ? 0 : sortFilterOptions()[_nextIndex] - _value - .01;
                //   debug.log("path", path, _increment, "increment");
                // }

                //title = selectedFilterOption[property] !== undefined ? `${selectedFilterOption[property]}${suffix} - ${parseInt(selectedFilterOption[property]) + increment}${suffix}` : `${selectedFilterOption}${suffix} - ${parseInt(selectedFilterOption) + increment}${suffix}`;

                if (!title.value) {
                  title = `${title}${suffix} - ${parseInt(title) + increment}${suffix}`;
                } else {
                  title = `${title.prefix}${title.value}${title.suffix} - ${title.prefix}${title.value + increment}${title.suffix}`;
                }
              } else {
                if (!title.value) {
                  title = `${title}${suffix}`;
                } else {
                  title = `${title.prefix}${title.value}${title.suffix}`;
                }
              }

              // debug.info('title', title);

              return (<List.Item
                key={index}
                as="a"
                data-id={id}
                onClick={toggleSelectedFilterOption}><span
                className="checkmark">&#10003;</span>{title}</List.Item>);
            })}
          </List>
        </div>}
    </div>
  </React.Fragment>);
}

function mapStateToProps(state) {
  //console.log("Toolbar: Map State to Props", state);

  return {
    recipesTime: state.recipesData.time,
    recipes: state.recipesData.recipes,
    filters: state.recipesData.filters,
    selectedFilters: state.recipesData.selectedFilters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    //user: state.userData,
  };
}

export default connect(mapStateToProps, { retrieveRecipes, updateSettings })(RecipeListFilter);