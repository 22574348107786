import classNames from "classnames/bind";
import isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Slider from "react-slick";
import { Button, Grid, Header, Icon, Image } from "semantic-ui-react";
import { cdnURL, DEFAULT_AUTHENTICATED_ROUTE } from "../../common/consts";
import { arrayChunks, isAuthenticated, showSessionMessages, userTierAccess } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
import Footer from "../global/Footer";
import { default as HeaderMenu } from "../global/PageHeader";
import has from "lodash/has";

function Home (props) {

  const {settings, updateSettings} = props;
  //const handleMenuItemClick = (e, {name}) => updateSettings({activeMenuItem: name});
  const parsedURLParams = queryString.parse(props.location.search);
  //console.log("params", parsedURLParams);

  if (isAuthenticated() && userTierAccess({minTier: "1"}) && parsedURLParams.stay !== "true") {
    //console.log("reg redirect");
    return <Redirect to={DEFAULT_AUTHENTICATED_ROUTE}/>;
  }

  const [productPrices, setProductPrices] = useState({
    init: true,
    1: {price: "", interval: ""},
    2: {price: "", interval: ""},
  });
  const [partnerSlides, setPartnerSlides] = useState([]);

  useEffect(() => {
    //console.log("useEffect: Home", props);

    if (settings.activeMenuItem !== "home") {
      updateSettings({activeMenuItem: "home"});
    }

    if (productPrices.init && settings.config) {
      //console.log("all products", settings.config.products);
      let products = {};
      settings.config.products.forEach(product => {
        if (has(product.pricing, "default")) {
          products[product.id] = {price: product.pricing["default"].price, interval: product.interval};
        }
      });

      setProductPrices({init: false, ...products});
    }

    if (settings.config && !isEmpty(settings.config.home_partner_logos)) {
      let partnerLogos = [];

      settings.config.home_partner_logos.forEach(partner_logo => {
        if (!isEmpty(partner_logo.large)) {
          partnerLogos.push(partner_logo.large);
        }
        //debug.log("partner logo", partner_logo);
      });

      //debug.log("partner logos", partnerLogos);
      setPartnerSlides(arrayChunks(partnerLogos, 9));
    }

  }, [settings]);

  // https://react-slick.neostack.com/docs/example/multiple-items
  const testimonialSliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    //adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000
  };

  const testimonialSlides = [
    {
      quote: "ProvenCut helps us select the rights tools and feeds & speeds for our R&D projects",
      name: "Justin Wenning",
      company: "Fabrisonic",
      logo: "Fabrisonic.png"
    },

    {
      quote: "Being a one-off shop working with a large variety of materials, ProvenCut gives us the confidence to program the right feeds and speeds the FIRST time.",
      name: "Andy Thurlow",
      title: "Manager",
      company: "Kansas State University Physics Machine Shop",
      logo: "Kansas-State-University-logo.png"
    },

    {
      quote: "ProvenCut has eliminated the learning curve when new materials arrive. No more \"made up\" numbers from suppliers or guesswork before making good parts.",
      name: "Morgan Oliff",
      company: "National High Magnetic Field Laboratory",
      logo: "NHMFL-Logo.png"
    },

    {
      quote: "The cutting data, coupled with the video, make ProvenCut a necessity for anyone running a CNC Machine",
      name: "Jake Yates",
      company: "Yates Precision Manufacturing",
      logo: "Yates_Logo.png"
    },

    {
      quote: "ProvenCut is a fantastic resource. The filters are intuitive and and purchase links are a huge time saver.",
      name: "Luke Colby",
      company: "Triton Space Technologies, LLC",
      logo: "Triton.png"
    }
  ];

  const partnerSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    //autoplay: true,
    //autoplaySpeed: 5000
  };

  /*
  let partnerLogos = [
    "Logo_SandvikCoromant.png",
    "Tormach.png",
    "Fusion_360_2017_lockup_OL_1line_no_year.png",
    "ABTools.png",
    "DIJET-logo_1000.png",
    "helical_notag_4c.png",
//Lake
    //"Logo_Haas_F1.png",
    "LSC_Logo-Bumpersticker-4color.png",
    "HaasF1TeamLogoLockup_ColorTopPadding.png",
    "Guhring.png",
    "Korloy_Logo.png",
    "Maritool_Logo.png",
    "NiagaraCutterLogo.png",
    "allied-machine---engineering_owler_20180504_001219_original.png",
    "Mitsubishi_Materials_USA.png",
    "Emuge.png",
    "10B8777-3BCF-4403-B5C1-E39E05EB27F6-logo.png",
    "Nine9.png",
    "SECO-LOGO.png",
    "UltraLogo.png",
    "YG-1_Transparent.png",
    "DAtron.png",
    "Logo_HarveyPerformance_webhead-1-e1490131205316.png",
  ];
  */

  // const partnerSlides = arrayChunks(partnerLogos, 9);
  //debug.log("partner slides",partnerSlides);

  return (<React.Fragment>
    <Grid className="home-content content">

      <Grid.Row className="home-content__hero-row">
        <Grid.Column className="home-content__hero-column">
          <Grid>
            <HeaderMenu reversed={true} hideSessionMessages={true}/>
            {/*<Grid.Row className="home-content__menu-row">*/}
            {/*<Grid.Column className="home-content__menu-column">*/}
            {/*<Menu stackable text className="pc-menu--top">*/}
            {/*<Menu.Menu position="left">*/}
            {/*<Menu.Item className="pc-menu__item" name="home" onClick={handleMenuItemClick} href="/">*/}
            {/*<Image className="pc-menu__logo"*/}
            {/*src={`${cdnURL}logos/provencut_logo_280x57.png`}/>*/}
            {/*</Menu.Item>*/}
            {/*</Menu.Menu>*/}

            {/*<Menu.Menu position="right">*/}
            {/*{!isAuthenticated() ? <Menu.Item className="pc-menu__item" name="join" href="/join">NEW*/}
            {/*ACCOUNT</Menu.Item> : ""}*/}
            {/*{!isAuthenticated() ? <Menu.Item className="pc-menu__item" name="login" href="/login">LOG*/}
            {/*IN</Menu.Item> : <Menu.Item className="pc-menu__item" name="logout" href="/logout">LOG*/}
            {/*OUT</Menu.Item>}*/}
            {/*</Menu.Menu>*/}
            {/*</Menu>*/}
            {/*</Grid.Column>*/}
            {/*</Grid.Row>*/}

            {showSessionMessages()}

            <Grid.Row className="home-content__hero-image-row">
              <Grid.Column className="home-content__hero-image-column">
                <Image className="home-content__hero"
                       src={`${cdnURL}misc/home_hero.jpg`} alt="Hero"/>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="home-content__hero-overlay-row" columns={1}>
              <Grid.Column className="home-content__hero-overlay-column" computer={5} tablet={16} mobile={16}>
                <h1>Better Feeds and Speeds</h1>
                <p>Sign up for access to Proven Speed & Feed recipes.</p>

                <Button animated secondary href="/join">
                  <Button.Content visible>Sign Up</Button.Content>
                  <Button.Content hidden><Icon name='arrow right'/></Button.Content>
                </Button>
              </Grid.Column>
              {/*<Grid.Column></Grid.Column>*/}
              {/*<Grid.Column></Grid.Column>*/}
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row centered className="home-content__headline-row">
        <Grid.Column>
          <Header as="h1" textAlign='center' className="home-content__headline">Tap into our Proven Speeds &amp; Feeds
            Library</Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={3} centered className="home-content__highlight-row">
        <Grid.Column computer={4} mobile={16} tablet={16} className="home-content__highlight">
          <Header size='large'>Proven Cuts</Header>
          <p>Proven speeds &amp; feeds recipes with video, photos, tooling, machine and cutting condition
            information</p>
        </Grid.Column>
        <Grid.Column computer={4} mobile={16} tablet={16} className="home-content__highlight">
          <Header size='large'>Intelligent Filters</Header>
          <p>Search Speeds &amp; feeds recipes by machine, operation, tool, material, gage length, and more</p>
        </Grid.Column>
        <Grid.Column computer={4} mobile={16} tablet={16} className="home-content__highlight">
          <Header size='large'>No More<br/>Breaking Tools</Header>
          <p>Our recipes give YOU the confidence to machine parts with proven success</p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row centered className="home-content__testimonials-row">
        <Grid.Column width={12} className="home-content__testimonials-column">
          <Slider {...testimonialSliderSettings}>
            {testimonialSlides.map((testimonialSlide, index) => {
              // console.log("slide", testimonialSlide);
              return <div key={index}
                          className={classNames("testimonial-slide", `testimonial-slide-${index}`)}>
                <h2 className="testimonial-quote"><span
                  className="testimonial-quote-symbol">&ldquo;</span>{testimonialSlide.quote}<span
                  className="testimonial-quote-symbol">&rdquo;</span></h2>
                <Grid className="testimonial-slide-bottom">
                  <Grid.Row columns={2} className="testimonial-slide-bottom-row">
                    <Grid.Column className="testimonial-attribute" computer={4} mobile={16} tablet={6}>
                      <div className="testimonial-name">{testimonialSlide.name}</div>
                      {!isEmpty(testimonialSlide.name) ? <div
                        className="testimonial-title">{testimonialSlide.title}</div> : ""}
                      <div className="testimonial-company">{testimonialSlide.company}</div>
                    </Grid.Column>
                    <Grid.Column className="testimonial-logo" computer={4} mobile={16} tablet={6}>
                      <Image
                        src={`${cdnURL}logos/testimonial/${testimonialSlide.logo}`}
                        alt="Testimonial Logo"/></Grid.Column></Grid.Row></Grid>
              </div>;
            })
            }
          </Slider>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2} centered className="home-content__sample-row">
        <Grid.Column computer={4} mobile={16} tablet={8} textAlign='center'><Image
          className="home-content__sample-card"
          src={`${cdnURL}misc/sample_card_v2.png`} alt="Sample Card"/></Grid.Column>
        <Grid.Column computer={6} mobile={16} tablet={16} className="home-content__card-info">
          <Header as="h1">All the Proven Cutting<br/>Information you need!</Header>
          <p>Each ProvenCut Speeds &amp; Feeds recipe is backed by video footage of the cut, photographs of the cutting
            tool, chips, tool holder, machine, workholding setup and more.</p>
          <p>ProvenCut gives you the information you need to succeed, including direct links to the tools used.</p>

          <Button animated secondary href="/login">
            <Button.Content visible>Log In</Button.Content>
            <Button.Content hidden><Icon name='arrow right'/></Button.Content>
          </Button>

        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2} centered className="home-content__feature-row">
        <Grid.Column computer={4} mobile={16} tablet={16} className="home-content__feature-description">
          <Header size='large'>Complete Details</Header>
          <p>We give you the information to replicate with consistent machining results. Each speed and feed
            recipe includes photo and video documentation of the cutting conditions. Subscribers get downloadable Fusion
            360&trade; CAM, direct links to purchase tooling, holders, and more.</p>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16} tablet={16} className="home-content__feature-image">
          <Image src={`${cdnURL}misc/complete_details.png`} alt="Details"/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2} centered className="home-content__feature-row feature_white">
        <Grid.Column computer={6} mobile={16} tablet={16} className="home-content__feature-image">
          <Image src={`${cdnURL}misc/easy_access.png`} alt="Easy Access"/>
        </Grid.Column>
        <Grid.Column computer={4} mobile={16} tablet={16} className="home-content__feature-description">
          <Header size='large'>Easy Recipes</Header>
          <p>ProvenCut recipes are easy to find with the intuitive, dynamic filter system. Unlike CNC machining
            calculators, there's nothing to guess or fill in. Just search for the material, CNC Machine Tool, CAM
            operation, tool type or more. Build out your "My Tools" library to filter search results by tools you
            already own!</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2} centered className="home-content__feature-row">
        <Grid.Column computer={4} mobile={16} tablet={16} className="home-content__feature-description">
          <Header size='large'>Thoroughly Tested</Header>
          <p>Our speed and feed recipes are tested and documented by CNC machinists. ProvenCut video footage, photos,
            and comprehensive cutting condition information mean you know exactly how to use that cutting tool and what
            to expect. Materials include aluminum, steel, titanium, exotics, composites, plastics and more.</p>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16} tablet={16} className="home-content__feature-image partner-slider">

          <Slider {...partnerSliderSettings}>
            {partnerSlides.map((partnerSlide, index) => {
              //debug.log("partner slide",partnerSlide);

              const partnerSlideRows = arrayChunks(partnerSlide, 3);
              //debug.log("slide rows", partnerSlideRows);

              return <div key={index}
                          className={classNames("partner-slide", `partner-slide-${index}`)}>
                {partnerSlideRows.map((partnerSlideLogo, rowIndex) => {
                  return <Grid key={rowIndex} verticalAlign='middle' stackable centered><Grid.Row columns={3}>
                    <Grid.Column textAlign="center">
                      {/*<div className="logo-container"><Image src={`${cdnURL}logos/partner/${partnerSlideLogo[0]}`}/></div>*/}
                      <div className="logo-container"><Image src={partnerSlideLogo[0]} alt="Partner Logo"/></div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="logo-container"><Image src={partnerSlideLogo[1]} alt="Partner Logo"/></div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="logo-container"><Image src={partnerSlideLogo[2]} alt="Partner Logo"/></div>
                    </Grid.Column>
                  </Grid.Row></Grid>;
                })}
              </div>;
            })}
          </Slider>

        </Grid.Column>
      </Grid.Row>

      {!productPrices.init ?
        <Grid.Row columns={2} centered className="home-content__plans-row">
          <Grid.Column className="home-content__plan" computer={5} mobile={16} tablet={16} textAlign='center'>
            <Header size='huge'>{`LITE/$${productPrices[1].price} ${productPrices[1].interval}`}</Header>
            <Header size='medium'><strong>Limited</strong> to Hobby CNC Machines.</Header>
            <Button animated secondary href="/join?product_id=1">
              <Button.Content visible>Subscribe To Lite</Button.Content>
              <Button.Content hidden><Icon name='arrow right'/></Button.Content>
            </Button>
          </Grid.Column>
          <Grid.Column className="home-content__plan" computer={5} mobile={16} tablet={16} textAlign='center'>
            <Header size='huge'>{`PRO/$${productPrices[2].price} ${productPrices[2].interval}`}</Header>
            <Header size='medium'><strong>Full Access</strong> including Professional Grade Machines.</Header>
            <Button animated secondary href="/join?product_id=2">
              <Button.Content visible>Subscribe To Pro</Button.Content>
              <Button.Content hidden><Icon name='arrow right'/></Button.Content>
            </Button>
          </Grid.Column>
          <Grid.Column className="home-content__plan" computer={5} mobile={16} tablet={16} textAlign='center'>
            <Header size='huge'>TEST DRIVE</Header>
            <Header size='medium'><strong>Limited</strong> access to all of our Recipes
              for <strong>free</strong>.</Header>
            <Button animated secondary href="/test-drive">
              <Button.Content visible>Try ProvenCut</Button.Content>
              <Button.Content hidden><Icon name='arrow right'/></Button.Content>
            </Button>
          </Grid.Column>
        </Grid.Row> : ""}

    </Grid>
    <Footer/>
  </React.Fragment>);
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, {updateSettings})(Home);