import isEmpty from 'lodash/isEmpty';
import React, { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dimmer, Grid, Icon, Label, Loader, Table } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';
import { updateSettings } from '../../redux/actions/settings';
import {
  createUserRecipeModifiers,
  deleteUserRecipeModifiers,
  findUserRecipeModifiers,
  updateUserRecipeModifiers
} from '../../redux/actions/userRecipeModifiers';
import { debug } from '../../common/helpers';
import get from 'lodash/get';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function UserRecipeModifiers(props) {
  const {
    findUserRecipeModifiers,
    deleteUserRecipeModifiers,
    createUserRecipeModifiers,
    updateUserRecipeModifiers,
    settings,
    user,
    userRecipeModifiers
  } = props;

  const [initUserRecipeModifiers, setInitUserRecipeModifiers] = useState(true);
  const [myUserRecipeModifiers, setMyUserRecipeModifiers] = useState([]);
  const [currentUserRecipeModifier, setCurrentUserRecipeModifier] = useState(null);
  //const [modifiedFields, setModifiedFields] = useState({});
  const [updateFormValid, setUpdateFormValid] = useState(true);
  const [createFormValid, setCreateFormValid] = useState(true);

  const createUserRecipeModifierFormRef = createRef();

  useEffect(() => {
    //debug.log("useEffect: User Recipe Modifiers", props);

    if (initUserRecipeModifiers) {
      setInitUserRecipeModifiers(false);
      findUserRecipeModifiers({ uid: user.uid });
    }

    if (!isEmpty(userRecipeModifiers.user_recipe_modifiers)) {
      buildUserRecipeModifiersList();
    }

    if (!settings.updatingUserRecipeModifiers) {
      setCurrentUserRecipeModifier(null);
    }

    if (!settings.creatingUserRecipeModifiers) {
      createUserRecipeModifierFormRef.current.reset();
    }

    /*


    if (groups.createGroupMemberMessage && !isEmpty(groups.result) && !isEmpty(groups.message) && hasValue(_group.id)) {
      debug.log("reset form");
      createGroupMemberFormRef.current.reset();
    }
    */

  }, [settings, userRecipeModifiers]);

  const buildUserRecipeModifiersList = () => {
    setMyUserRecipeModifiers([]);

    if (!isEmpty(userRecipeModifiers.user_recipe_modifiers)) {
      setMyUserRecipeModifiers(userRecipeModifiers.user_recipe_modifiers.map(user_recipe_modifier => ({
        key: user_recipe_modifier.id,
        value: user_recipe_modifier.id,
        ...user_recipe_modifier,
      })));
    }
  };

  const editUserRecipeModifier = (options) => {
    debug.log('edit user recipe modifier', options);
    setCurrentUserRecipeModifier(options.user_recipe_modifier.id);
  };

  const onValidUpdate = options => {
    //debug.log("valid", options);
    setUpdateFormValid(true);
  };

  const onInvalidUpdate = options => {
    // debug.log("invalid");
    setUpdateFormValid(false);
  };

  const updateUserRecipeModifier = (options) => {
    //debug.log("update user recipe modifier", options);

    // we add this here since both forms will be submitted and we only
    // want to perform an update when we have a User Recipe Modifier
    if (currentUserRecipeModifier == null) {
      return;
    }

    let data = {
      ...options,
      id: currentUserRecipeModifier
    };
    updateUserRecipeModifiers({ data });
  };

  const cancelUserRecipeModifier = options => {
    setCurrentUserRecipeModifier(null);
  };

  const onValidCreate = options => {
    //debug.log("valid", options);
    setCreateFormValid(true);
  };

  const onInvalidCreate = options => {
    // debug.log("invalid");
    setCreateFormValid(false);
  };

  const createUserRecipeModifier = (options) => {
    //debug.log("update user recipe modifier", options);

    let data = {
      ...options,
    };
    createUserRecipeModifiers({ data });
  };

  const removeUserRecipeModifier = (user_recipe_modifier_id) => {
    deleteUserRecipeModifiers({ id: user_recipe_modifier_id });
  };

  const changeUserRecipeModifier = (e, data) => {
    //debug.log(`change input ${data.name}`, e, data);
    //setModifiedFields({...modifiedFields, [data.name]: data.value});

    // it isn't necessary to track the changes in myUserRecipeModifiers, but we will in case we need them later...
    // let _myUserRecipeModifiers = myUserRecipeModifiers;
    // let currentRecipeModifierIndex = findIndex(myUserRecipeModifiers, {id: currentUserRecipeModifier});
    // _myUserRecipeModifiers[currentRecipeModifierIndex][data.name] = data.value;
    //debug.log("my recipe modifiers after update", _myUserRecipeModifiers);
    // setMyUserRecipeModifiers(_myUserRecipeModifiers);
  };

  const errorLabel = <Label
    color="red"
    pointing/>;

  return (<Grid.Column className="account__info-column">
    <div className="account__info-column-title">MY RECIPE MODIFIERS</div>
    <Dimmer
      active={settings.fetchingUserRecipeModifiers}
      inverted>
      <Loader size='large'>Loading</Loader>
    </Dimmer>
    <div className="my-user-recipe-modifiers__table">
      <Form
        size="large"
        className="user-recipe-modifier-content__form"
        onValidSubmit={updateUserRecipeModifier}
        onValid={onValidUpdate}
        onInvalid={onInvalidUpdate}
        loading={settings.updatingUserRecipeModifiers}>
        <Table
          celled
          compact
          striped>
          <Table.Header fullWidth><Table.Row>
            <Table.HeaderCell/>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>RPM</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
          </Table.Header>
          <Table.Body>
            {!isEmpty(myUserRecipeModifiers) ? myUserRecipeModifiers.map((user_recipe_modifier, index) => {
              return (
                <Table.Row key={user_recipe_modifier.key}>
                  <Table.Cell collapsing>{index + 1}</Table.Cell>
                  {currentUserRecipeModifier === user_recipe_modifier.id ?
                    <React.Fragment>
                      <Table.Cell>
                        <Form.Field>
                          <Input
                            placeholder='Title'
                            name="title"
                            defaultValue={user_recipe_modifier.title}
                            required
                            fluid
                            onChange={changeUserRecipeModifier}
                            validationErrors={{
                              isDefaultRequiredValue: 'Title is Required'
                            }}
                            errorLabel={errorLabel}
                          /></Form.Field>
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <Form.Field>
                          <Input
                            placeholder='RPM'
                            name="rpm"
                            defaultValue={user_recipe_modifier.rpm.toString()}
                            required
                            fluid
                            onChange={changeUserRecipeModifier}
                            validations={{
                              isNumeric: true,
                            }}
                            validationErrors={{
                              isDefaultRequiredValue: 'RPM is Required',
                              isNumeric: 'RPM must be numeric'
                            }}
                            errorLabel={errorLabel}
                          /></Form.Field>
                      </Table.Cell></React.Fragment> : <React.Fragment>
                      <Table.Cell
                        onClick={() => editUserRecipeModifier({
                          user_recipe_modifier,
                          index
                        })}>{user_recipe_modifier.title}</Table.Cell>
                      <Table.Cell
                        width={2}
                        onClick={() => editUserRecipeModifier({
                          user_recipe_modifier,
                          index
                        })}>{user_recipe_modifier.rpm}</Table.Cell>
                    </React.Fragment>}
                  <Table.Cell
                    collapsing
                    className="my-user-recipe-modifiers__buttons">{currentUserRecipeModifier === user_recipe_modifier.id ?
                    <React.Fragment>
                      <Form.Button
                        disabled={!updateFormValid}
                        content="Update"
                        className="user-recipe-modifiers-form__update-button"/>
                      <Button
                        onClick={() => cancelUserRecipeModifier(user_recipe_modifier.value)}
                        className="user-recipe-modifiers-form__cancel-button">Cancel</Button>
                    </React.Fragment> : <React.Fragment>
                      <Button onClick={() => editUserRecipeModifier({ user_recipe_modifier, index })}>Edit</Button>
                      <Button
                        negative
                        onClick={() => removeUserRecipeModifier(user_recipe_modifier.value)}>Remove</Button>
                    </React.Fragment>} </Table.Cell>
                </Table.Row>);
            }) : <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell colSpan={4}>There are no recipe modifiers associated with your account.</Table.Cell>
            </Table.Row>}
          </Table.Body>

          {/*<Table.Footer fullWidth>*/}
          {/*  <Table.Row><Table.HeaderCell colSpan='7'>*/}
          {/*  </Table.HeaderCell>*/}
          {/*  </Table.Row>*/}
          {/*</Table.Footer>*/}
        </Table>
      </Form>


      <Form
        size="large"
        className="add-user-recipe-modifier-content__form"
        onValidSubmit={createUserRecipeModifier}
        onValid={onValidCreate}
        onInvalid={onInvalidCreate}
        loading={settings.creatingUserRecipeModifiers}
        ref={createUserRecipeModifierFormRef}>
        {/*{tokenize({*/}
        {/*  messageType: "group_member_add_new_members_notice",*/}
        {/*  data: {*/}
        {/*    seats: `${group.seats}`,*/}
        {/*    active_members: `${group.active_group_members_count}`,*/}
        {/*    inactive_members: `${group.inactive_group_members_count}`*/}
        {/*  }*/}
        {/*})}*/}

        <p>Add a new Recipe Modifier.</p>
        <Form.Group>
          <Form.Field width={5}>
            <Input
              placeholder='Title'
              name="title"
              required
              validationErrors={{
                isDefaultRequiredValue: 'Title is Required'
              }}
              instantValidation
              errorLabel={errorLabel}/>
          </Form.Field>
          <Form.Field width={2}>
            <Input
              placeholder='RPM'
              name="rpm"
              required
              validations={{
                isNumeric: true,
              }}
              validationErrors={{
                isDefaultRequiredValue: 'RPM is Required',
                isNumeric: 'RPM must be numeric'
              }}
              instantValidation
              errorLabel={errorLabel}/>
          </Form.Field>
          {get(settings, 'config.recaptcha_key') !== undefined ?
            <GoogleReCaptchaProvider reCaptchaKey={settings.config.recaptcha_key}>
              <GoogleReCaptcha onVerify={token => debug.log(token)}/>
            </GoogleReCaptchaProvider> : ''}
          <Form.Button
            width={3}
            floated='left'
            disabled={!createFormValid || settings.creatingUserRecipeModifiers}
            icon
            labelPosition='left'
            size='small'
          ><Icon name='sliders horizontal'/> Add Modifier</Form.Button>
        </Form.Group>
      </Form>


    </div>
  </Grid.Column>);
}

function mapStateToProps(state) {
  //console.log("User Tools: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    userRecipeModifiers: state.userRecipeModifiersData,
  };
}

export default connect(mapStateToProps, {
  updateSettings,
  findUserRecipeModifiers,
  createUserRecipeModifiers,
  updateUserRecipeModifiers,
  deleteUserRecipeModifiers
})(withRouter(UserRecipeModifiers));