import { CREATED_USER_RECIPE, DELETED_USER_RECIPE } from "../../common/consts";
import { checkInvalidSessionID } from "../../common/helpers";

const userRecipesData = (state = {}, action = {}) => {
  //console.log("User Recipes Reducers", state, action);
  if (checkInvalidSessionID({action})) {
    return {
      ...state
    };
  }

  switch (action.type) {
    case CREATED_USER_RECIPE:
      //console.log("reducer: user recipes - created", action);
      return {
        ...state,
        //is_user_recipe: action.userRecipeResponse.result === "success"
        user_recipes: action.criteria.response.user_recipes,
      };

    case DELETED_USER_RECIPE:
      //console.log("reducer: user recipes - deleted", action);
      return {
        ...state,
        //is_user_recipe: !(action.userRecipeResponse.result === "success")
        user_recipes: action.criteria.response.user_recipes,
      };

    /*
  case TOGGLE_USER_RECIPE:
    //console.log("reducer: user recipes - toggle", action);
    return {
      ...state,
      is_user_recipe: action.toggle,
    };
*/

    default:
      //console.log("default reducer: stored user", user, state);
      return {
        ...state,
        //is_user_recipe: (state.is_user_recipe === null) ? null : state.is_user_recipe,
      };
  }
};

export default userRecipesData;