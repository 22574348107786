import isEmpty from 'lodash/isEmpty';
import { RETRIEVED_RECIPE, RETRIEVED_RECIPES, TOGGLE_IS_USER_RECIPE, TOGGLE_VOTE } from '../../common/consts';
import { debug } from '../../common/helpers';
import api from '../api';
import { updateSettings } from './settings';

// export const retrieveRecipes = recipeCriteria => dispatch =>
//   api.recipes.retrieve(recipeCriteria).then(recipesResponse => {
//     console.log("Retrieve Recipes: response", recipesResponse);
//     dispatch(retrievedRecipes(recipesResponse));
//   });
export const retrieveRecipes = recipeCriteria => dispatch => {
  const globalState = window.store.getState();

  window.store.dispatch(updateSettings({ fetchingRecipes: true }));

  api.recipes.retrieve(recipeCriteria).then(recipesResponse => {
      //console.log("Retrieve Recipes: response", recipesResponse);
      debug.log('Retrieve Recipes: response', recipesResponse);
      window.store.dispatch(updateSettings({ fetchingRecipes: false }));
      if (!isEmpty(globalState.settings.activeRecipeFilters)) {
        window.store.dispatch(updateSettings({ userInteractionEnableUserSurveyCTA: true }));
      }
      dispatch(retrievedRecipes(recipesResponse));
      window.store.dispatch(updateSettings({ recipesTime: recipesResponse.response.time }));
    }
  );
};

export const retrievedRecipes = recipeCriteria => ({
  type: RETRIEVED_RECIPES,
  criteria: recipeCriteria
});

export const retrieveRecipe = recipeCriteria => dispatch => {
  window.store.dispatch(updateSettings({ fetchingRecipe: true }));
  api.recipe.retrieve(recipeCriteria).then(recipeResponse => {
      //console.log("Retrieve Recipe: response", recipeResponse);
      debug.log('Retrieve Recipe: response', recipeResponse);
      window.store.dispatch(updateSettings({ fetchingRecipe: false }));
      dispatch(retrievedRecipe(recipeResponse));
      window.store.dispatch(updateSettings({ recipeTime: recipeResponse.response.time }));
    }
  );
};

export const retrievedRecipe = recipeCriteria => ({
  type: RETRIEVED_RECIPE,
  criteria: recipeCriteria
});

export const toggleIsUserRecipe = options => ({
  type: TOGGLE_IS_USER_RECIPE,
  criteria: options,
});

export const toggleVote = options => ({
  type: TOGGLE_VOTE,
  criteria: options,
});