import { RETRIEVED_FAQS } from "../../common/consts";
import { checkInvalidSessionID } from "../../common/helpers";

const faqsData = (state = {}, action = {}) => {
  //console.log("FAQs Reducers", state, action);
  if (checkInvalidSessionID({action})) {
    return {
      ...state
    };
  }

  switch (action.type) {
    case RETRIEVED_FAQS:
      return {
        ...state,
        faqs: action.criteria.response.faqs,
      };

    default:
      return state;
  }
};
export default faqsData;