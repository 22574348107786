import classNames from "classnames/bind";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { scroller } from "react-scroll";
import Slider from "react-slick";
import { Image } from "semantic-ui-react";
import { hasValue, userTierAccess } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";

//import ReactPlayer from "react-player";

function RecipeMedia (props) {
  const {recipe, minTier = "0.0.0", checkProWithTier = false, scrollOnClick = false, playerRef = React.createRef(), onPlay, onPause, playing} = props;
  const [sliderMedia, setSliderMedia] = useState([]);
  const [heroMedia, setHeroMedia] = useState({type: "", url: "", index: -1});
  let restrictMedia = false;

  // debug.log("min tier", minTier, "user tier min 1", userTierAccess({minTier: "1"}), "recipe pro tier", recipe.pro_tier, "user tier min tier", !userTierAccess({minTier}));
  // debug.log("tier check a", userTierAccess({
  //   minTier: "1.0.0",
  //   maxTier: "1.9.9"
  // }) && recipe.pro_tier, "b", !userTierAccess({minTier}));
  if (checkProWithTier) {
    //debug.log("check pro media", recipe.pro_tier, minTier);
    // if the user tier is 1-1.9.9 and this is a Pro recipe OR the user tier isn't the minTier
    // if ((userTierAccess({minTier: "2"}) && recipe.pro_tier) || !userTierAccess({minTier})) {
    if ((userTierAccess({minTier: "1.0.0", maxTier: "1.9.9"}) && recipe.pro_tier) || !userTierAccess({minTier})) {
      restrictMedia = true;
    }
  } else {

    //console.log("check tier", userTierAccess({minTier}));
    // if the user is logged in we should have the no access token in each of the limited fields
    // or we can tell by the user's tier and the field's tier.
    // either way, if they don't have access to this field, show them a message
    if (!userTierAccess({minTier})) {
      restrictMedia = true;
    }
  }

  // 2/26/20 - this may be redundant, but now we're using the recipe's tier to do the final check
  // if the user's tier is < the recipe tier, the media is restricted
  if (!isEmpty(recipe.tier)) {
    if (userTierAccess({minTier: recipe.tier})) {
      restrictMedia = false;
    }
  }

  //debug.log("Restrict Media", restrictMedia);

  // https://react-slick.neostack.com/docs/example/multiple-items
  const [sliderSettings, setSliderSettings] = useState({
    dots: false,
    infinite: false,
    speed: 500,
  });

  if (!isEmpty(recipe)) {

    //console.log("RECIPE!!!!!");
  }

  function showMedia (options) {
    //console.log("show media", options);

    if (restrictMedia) {

    } else {
      setHeroMedia({
        type: options.type,
        url: options.type === "video" ? options.data.url : options.data.large,
        index: options.index
      });

      if (scrollOnClick) {
        scroller.scrollTo("recipe-media-hero", {
          duration: 500,
          delay: 0,
          smooth: true,
          //containerId: "ContainerElementID",
          offset: -5, // Scrolls to element + 50 pixels down the page
        });
      }
    }
  }

  // debug.log("recipe from media", recipe);
  if (!isEmpty(recipe) && isEmpty(sliderMedia)) {
    let media = [];
    // there should always be a video, but check how many
    media = [...media, ...recipe.videos];
    // are there one or more images?
    media = [...media, ...recipe.images];
    // check cutting tools, inserts, holders, machines and material
    if (!isEmpty(recipe.cutting_tools)) {
      recipe.cutting_tools.forEach(tool => {
        if (!isEmpty(tool.images)) {
          media = [...media, ...tool.images];
        }
        return;
      });
    }

    if (!isEmpty(recipe.indexable_inserts)) {
      recipe.indexable_inserts.forEach(insert => {
        if (!isEmpty(insert.images)) {
          media = [...media, ...insert.images];
        }
        return;
      });
    }

    if (!isEmpty(recipe.milling_tool_holders)) {
      recipe.milling_tool_holders.forEach(holder => {
        if (!isEmpty(holder.images)) {
          media = [...media, ...holder.images];
        }
        return;
      });
    }

    //    media = [...media, ...recipe.material.images, ...recipe.cnc_machine.images];
    if (!isEmpty(recipe.material) && hasValue(recipe.material.images)) {
      media = [...media, ...recipe.material.images];
    }

    if (!isEmpty(recipe.cnc_machine) && hasValue(recipe.cnc_machine.images)) {
      media = [...media, ...recipe.cnc_machine.images];
    }

    //temp to test more than 8 slides
    //media = [...media, ...media];

    //console.log("media count", media.length, media);
    setSliderMedia(media);

    setSliderSettings({
      ...sliderSettings,
      slidesToShow: Math.min(8, media.length),
      slidesToScroll: media.length > 8 ? 8 : 3
    });
    //console.log("slider settings", sliderSettings);

    setHeroMedia(!isEmpty(recipe.videos[0]) ? {type: "video", url: recipe.videos[0].url, index: 0} : {
      type: "image",
      url: media[0],
      index: 0
    });

    //updateSettings({playerRef: React.createRef()});
  }

  useEffect(() => {
  }, [recipe]);

  if (isEmpty(sliderMedia)) {
    return (<div>Loading Recipe</div>);
  }

  return (
    <div className="recipe-media">
      {/*{console.log("SLIDER SETTINGS", sliderSettings)}*/}
      {/*(sliderMedia.length > 1) ? console.log("media > 1", sliderMedia) : console.log("media <= 1", sliderMedia)*/}
      {!isEmpty(heroMedia) ? (<div className="recipe-media-hero" id="recipe-media-hero">{
        heroMedia.type === "video" ?
          <div className="recipe-media-hero__react-player-wrapper">
            <ReactPlayer
              className='recipe-media-hero__react-player'
              width="100%"
              height="100%"
              url={heroMedia.url}
              ref={playerRef}
              playing={playing}
              onPlay={onPlay}
              onPause={onPause}
              controls={true}
              config={{
                vimeo: {
                  preload: true,
                  playerOptions: {responsive: true}
                }
              }}
            />
          </div>

          :

          <div className="recipe-media-hero__thumbnail">
            <Image verticalAlign='middle'
                   src={heroMedia.url}
                   as='div'/>
          </div>
      }</div>) : ""}


      {/*{console.log("Hero", heroMedia)}*/}
      {((sliderMedia.length > 1) ? <div className="recipe-media-slider">
        {!userTierAccess({minTier}) ? <div className="slider-cta"><a href="/join">Click Here to Join ProvenCut
          Today
          and Unlock Your CNC
          Potential</a></div> : ""}

        <Slider {...sliderSettings}>
          {sliderMedia.map((mediaObj, index) => {
            //debug.log("media obj", mediaObj);
            return <div key={index}
                        className={classNames("slide", `slide-${index}`, {"slide--selected": index === heroMedia.index}, {
                          "slide--video": mediaObj.id !== undefined,
                          "slide-restrict": restrictMedia
                        })}>

              {mediaObj.id !== undefined ? <span className="play-btn"></span> : ""}

              <Image
                src={mediaObj.id !== undefined ? mediaObj.thumbnail_url : mediaObj.thumb}
                onClick={(e) => showMedia({
                  type: (mediaObj.id !== undefined ? "video" : "image"),
                  data: mediaObj,
                  index
                })}/>
            </div>
          })
          }
        </Slider>

      </div> : "")}
    </div>);
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, {updateSettings})(RecipeMedia);