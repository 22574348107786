import platform from "platform";
import store from "store";

export const WEB_VERSION = "1.0.0";
export const PUBLIC_API_KEY = "]umZJCnXmDdVjqkjCZV9bd7Ntfx7dc";
export const PRODUCTION_DEBUG_KEY = "true";
export const RETRIEVED_RECIPES = "RETRIEVED RECIPES";
export const RETRIEVED_RECIPE = "RETRIEVED RECIPE";
export const CREATED_USER_RECIPE = "CREATED USER RECIPE";
export const DELETED_USER_RECIPE = "DELETED USER RECIPE";
export const TOGGLE_USER_RECIPE = "TOGGLE USER RECIPE";
export const TOGGLE_VOTE = "TOGGLE VOTE";
export const TOGGLE_IS_USER_RECIPE = "TOGGLE IS USER RECIPE";
export const FOUND_USER_TOOLS = "FOUND USER TOOLS";
export const CREATED_USER_TOOL = "CREATED USER TOOL";
export const DELETED_USER_TOOL = "DELETED USER TOOL";
export const TOGGLE_USER_TOOL = "TOGGLE USER TOOL";
export const FOUND_USER_FILTERS = "FOUND USER FILTERS";
export const CREATED_USER_FILTERS = "CREATED USER FILTERS";
export const DELETED_USER_FILTERS = "DELETED USER FILTERS";
export const UPDATED_USER_FILTERS = "UPDATED USER FILTERS";
export const TOGGLE_USER_FILTERS = "TOGGLE USER FILTERS";
export const FOUND_USER_RECIPE_MODIFIERS = "FOUND USER RECIPE MODIFIERS";
export const CREATED_USER_RECIPE_MODIFIERS = "CREATED USER RECIPE MODIFIERS";
export const UPDATED_USER_RECIPE_MODIFIERS = "UPDATED USER RECIPE MODIFIERS";
export const DELETED_USER_RECIPE_MODIFIERS = "DELETED USER RECIPE MODIFIERS";
export const RETRIEVED_PARTS = "RETRIEVED PARTS";
export const RETRIEVED_PART = "RETRIEVED PART";
export const CREATED_USER = "CREATED USER";
export const UPDATED_USER = "UPDATED USER";
export const CANCELED_USER = "CANCELED USER";
export const STATUS_USER = "STATUS USER";
export const REACTIVATED_USER = "REACTIVATED USER";
export const MIGRATED_USER = "MIGRATED USER";
export const LOGGEDIN_USER = "LOGIN USER";
export const LOGGEDOUT_USER = "LOGOUT USER";
export const RETRIEVE_USER = "RETRIEVE USER";
export const PASSWORD_RESET = "PASSWORD RESET";
export const PASSWORD_RESET_REQUEST = "PASSWORD RESET REQUEST";
export const CONVERT_UNITS = "CONVERT UNITS";
export const RETRIEVED_CONFIG = "RETRIEVED CONFIG";
export const UPDATE_SETTINGS = "UPDATE SETTINGS";
export const FOUND_GROUP_MEMBERS = "FOUND GROUP MEMBERS";
export const UPDATED_GROUP_MEMBER = "UPDATED GROUP MEMBER";
export const CREATED_GROUP_MEMBER = "CREATED GROUP MEMBER";
export const RESENT_GROUP_MEMBER_INVITE = "RESENT GROUP MEMBER INVITE";
export const RETRIEVED_FAQS = "RETRIEVED FAQS";
export const STRIPE_PRODUCTION_PUBLIC_API_KEY =
  "pk_live_zAHe6vLBKOqJm4sQuux1yuRM";
export const STRIPE_DEVELOPMENT_PUBLIC_API_KEY =
  "pk_test_kO2xMwvsDKb0esGDRRY0EP8U";
// set the DEVELOPMENT KEY TO THE PRODUCTION KEY BELOW TO USE UI TO RENDER PRODUCITON STRIPE TOKEN
// in dev enviroment

//export const STRIPE_DEVELOPMENT_PUBLIC_API_KEY = STRIPE_PRODUCTION_PUBLIC_API_KEY;
//export const STRIPE_PRODUCTION_PUBLIC_API_KEY = STRIPE_DEVELOPMENT_PUBLIC_API_KEY;
export const STRIPE_USE_DEV_KEY_IN_DEV_MODE = true;
export const RETRIEVED_ORDER_REVIEW = "RETRIEVED ORDER REVIEW";
export const ORDER_CREATED = "ORDER CREATED";
export const RESTRICTED_ACCESS_TOKEN = "**noaccess**";
export const NO_FIELD_VALUE = "N/A";
export const DRILLING_CUT_TYPE_ID = 31;
export const SLOTTING_CUT_TYPE_ID = 32;
export const TAPPING_CUT_TYPE_ID = 100;
export const REAMING_CUT_TYPE_ID = 140;
export const CHAMFER_TOOL_TYPE_ID = 57;
export const ADAPTIVE_CUT_TYPE_ID = 28;
export const ADAPTIVE_BOTH_WAYS_CUT_TYPE_ID = 53;
// LATHE ROUGHING
export const TURNING_PROFILE_ROUGHING_CUT_TYPE_ID = 177;
export const TURNING_ADAPTIVE_ROUGHING_CUT_TYPE_ID = 213;
export const TURNING_PARTING_CUT_TYPE_ID = 206;
// LATHE FINISHING
export const TURNING_PROFILE_FINISHING_CUT_TYPE_ID = 181;
export const TURNING_FACING_CUT_TYPE_ID = 210;
export const TURNING_BORE_CUT_TYPE_ID = 214;
// LATHE BOTH
export const TURNING_OD_THREAD_CUT_TYPE_ID = 207;
export const TURNING_ID_THREAD_CUT_TYPE_ID = 217;
export const FREE_PRODUCT_ID = 4;
export const USER_FILTERS_TITLE_LENGTH = 50;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 100;
export const MIN_PASSWORD_NUMBERS = 1;
export const DEFAULT_AUTHENTICATED_ROUTE = "/recipes";
export const DEFAULT_ANONYMOUS_ROUTE = "/";
export const DEFAULT_DISQUS_SHORTNAME = "ProvenCut";
export const HEARTBEAT_TIMER_INTERVAL = 600;

export const initialSettings = {
  ready: false,
  fetchingRecipes: false,
  userInteractionEnableUserSurveyCTA: false,
  allowUserSurveyCTA: true,
  fetchingConfig: false,
  fetchingOrder: false,
  creatingUser: false,
  updatingUser: false,
  cancelingUser: false,
  retrivingUserStatus: false,
  updatingUserBilling: false,
  updatingUserPayment: false,
  updatingUserSubscription: false,
  loggingInUser: false,
  fetchingUserTools: false,
  creatingUserRecipe: false,
  deletingUserRecipe: false,
  creatingUserTool: false,
  deletingUserTool: false,
  fetchingUserFilters: false,
  creatingUserFilters: false,
  deletingUserFilters: false,
  updatingUserFilters: false,
  fetchingUserRecipeModifiers: false,
  creatingUserRecipeModifiers: false,
  updatingUserRecipeModifiers: false,
  deletingUserRecipeModifiers: false,
  resettingPasswordRequest: false,
  resettingPassword: false,
  currentPage: 1,
  imperial: true,
  activeMenuItem: "home",
  activeRecipeFilters: {},
  activeRecipeSort: "rid",
  activeRecipeSortDirection: "desc",
  activeRecipeThumbnail: "video",
  previewRecipeID: 0,
  playerRef: null,
  hideRoadblock: false,
  activePartFilters: {},
  activePartSort: "pid",
  activePartSortDirection: "desc",
  activePartThumbnail: "",
  previewPartID: 0,
  calculatorCutType: "milling",
  openFilters: false,
  heartbeatTimer: null,
  // total_pages: 1,
  // total_recipes: 0,
  // recipes: [],
  // filters: {
  //   machines: {models: [], brands: []},
  //   materials: {classes: [], types: []},
  //   tools: {types: [], materials: [], brands: [], models: []},
  //   cuts: {types: []},
  //   coolants: {styles: []},
  // },
};

export const cdnURL = "https://d3827p95tv2r1a.cloudfront.net/cdn/";
export const apiURL = "https://api.provencut.com/api/provencut/";

export const baseParams = (options = {}) => {
  //const state = window.store.getState();
  // console.log("state: ", state);
  const globalState = window.store.getState();
  // console.log("global state", globalState);

  //debug.log("platform", platform.description);

  // browser detection: https://github.com/bestiejs/platform.js#readme
  let params = {
    "context[version]": global.appVersion, //WEB_VERSION,
    "context[app_id]": "com.provencut",
    "context[browser_name]": platform.name,
    "context[browser_version]": platform.version,
    "context[browser_description]": platform.description,
    "context[referrer]": document.referrer,
    provencut_public_api_key: PUBLIC_API_KEY,
  };

  if (globalState.userData.session_id && !options.skip_session_id) {
    params.session_id = globalState.userData.session_id;
    // test
    //params.session_id = 'invalid session id';
  }

  const utm = store.get("utm");
  // console.log("utm:", utm);

  if (utm) {
    Object.keys(utm).forEach((key) => {
      console.log(`${key}: ${utm[key]}`);
      params[`context[utm][${key}]`] = utm[key];
      // params.context.utm[key] = utm[key];
    });

    console.log("params", params);
  }

  return params;
};

export const sortOptions = [
  //{text: "Most Viewed", value: "views"},
  //{text: "Date Added", value: "age"},
  { text: "MRR", value: "mrr" },
  { text: "Stickout", value: "stickout" },
  { text: "Gauge Length", value: "gage_length" },
  { text: "Recipe Number", value: "rid" },
  { text: "Diameter", value: "cutting_diameter" },
  { text: "User Rating", value: "user_rating" },
  //{text: "Machine Model", value: "machine_model"},
];

export const thumbnailOptions = [
  { text: "Video", value: "video" },
  { text: "Part", value: "part" },
  { text: "Chips", value: "chips" },
  { text: "Setup", value: "setup" },
  { text: "Cam", value: "cam" },
  { text: "Tool + Assembly", value: "tool" },
  { text: "Tool", value: "cutting_tool" },
  { text: "Insert", value: "insert" },
  { text: "Holder", value: "holder" },
];

export const calculatorCutTypes = [
  { text: "Milling", value: "milling" },
  { text: "Drilling", value: "drilling" },
];

export const US_STATES = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];
