import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Dimmer, Grid, Header, Icon, Input, Loader, Modal, Table } from "semantic-ui-react";
import { debug } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
import { deleteUserFilters, findUserFilters, updateUserFilters } from "../../redux/actions/userFilters";
import { USER_FILTERS_TITLE_LENGTH } from "../../common/consts";
import find from "lodash/find";
import sortBy from "lodash/sortBy";

function UserFilters (props) {
  const {findUserFilters, updateUserFilters, deleteUserFilters, userFilters, settings, updateSettings, user} = props;

  const [initUserFilters, setInitUserFilters] = useState(true);
  const [myFilters, setMyFilters] = useState([]);
  const [editFilter, setEditFilter] = useState();
  const [userFilterTitle, setUserFilterTitle] = useState();
  const [removeFiltersOption, setRemoveFiltersOption] = useState({id: null, title: ""});
  const [removeFiltersModalOpen, setRemoveFiltersModalOpen] = useState(false);

  //const [myMachines, setMyMachines] = useState([]);

  useEffect(() => {
    //debug.log("useEffect: User Filters", props);

    // if (settings.activeMenuItem !== "account") {
    //   updateSettings({activeMenuItem: "account"});
    // }

    //debug.log(`is authenticated: ${isAuthenticated()}`, "user filters", userFilters.user_filters, "fetching", settings.fetchingUserFilters);
    //if (isAuthenticated() && isEmpty(userFilters.user_filters) && !settings.fetchingUserFilters) {
    if (initUserFilters) {
      setInitUserFilters(false);
      debug.log("going to fetch user filters");
      findUserFilters({uid: user.uid});
    }

    if (!isEmpty(userFilters.user_filters)) {
      buildUserFiltersList();
    } else {
      setMyFilters([]);
    }

  }, [settings, userFilters]);

  const buildUserFiltersList = () => {
    setMyFilters([]);

    if (!isEmpty(userFilters.user_filters)) {

      const _userFilters = sortBy(userFilters.user_filters, [user_filters => user_filters.title.toLocaleLowerCase()]);

      setMyFilters(_userFilters.map(user_filters => ({
        key: user_filters.id,
        value: user_filters.id,
        label: user_filters.title,
      })));
    }
  };

  const editUserFilter = (filter_id) => {
    setEditFilter(filter_id);
  };

  const changeUserFilter = (e) => {
    //debug.log("change user filter", e.currentTarget.value);
    setUserFilterTitle(e.currentTarget.value);
  };

  const finishEditUserFilter = (e) => {
    debug.log("finished", userFilterTitle);

    updateUserFilters({
      data: {
        id: editFilter,
        title: userFilterTitle,
      }
    });
    setEditFilter();
    setUserFilterTitle();
  };

  const viewRecipes = (filter_id) => {
    debug.log("view recipes for", filter_id);

    updateSettings({
      activeRecipeFilters: {user_filters: [filter_id]}
    });
    // props.history.push(`/recipes?tool_id=${filter_id}`);
    props.history.push(`/recipes`);
  };

  function removeMyFilters () {
    debug.log("removing", removeFiltersOption);
    deleteUserFilters({id: removeFiltersOption.id});
    hideRemoveFiltersModal();
  }

  function confirmRemoveMyFilters (filter_id) {
    debug.log("remove my filters", find(userFilters.user_filters, {id: filter_id}));

    const selectedFilter = find(userFilters.user_filters, {id: filter_id});

    showRemoveMyFiltersModal({id: selectedFilter.id, title: selectedFilter.title});
  }

  function showRemoveMyFiltersModal (myFiltersOption) {
    setRemoveFiltersOption(myFiltersOption);
    setRemoveFiltersModalOpen(true);
  }

  function hideRemoveFiltersModal () {
    setRemoveFiltersModalOpen(false);
  }

  // can add && !isEmpty(myMachines) if we're ever using machines
  return (<Grid.Column className="account__info-column">
    <div className="account__info-column-title">MY FILTERS</div>
    <Dimmer
      active={settings.fetchingUserFilters} inverted>
      <Loader size='large'>Loading</Loader>
    </Dimmer>
    <div className="my-filters__table">
      <Table celled compact striped>
        <Table.Header fullWidth><Table.Row>
          <Table.HeaderCell/>
          <Table.HeaderCell>Filter</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>{!isEmpty(myFilters) ? myFilters.map((filter, index) => {
          //debug.log([tool]);
          return (<Table.Row key={filter.key}>
            <Table.Cell collapsing>{index + 1}</Table.Cell>
            <Table.Cell>{(filter.value === editFilter) ? <Input type="text" placeholder={filter.label}
                                                                defaultValue={filter.label}
                                                                maxLength={USER_FILTERS_TITLE_LENGTH}
                                                                minLength="5"
                                                                fluid
                                                                onBlur={finishEditUserFilter}
                                                                onChange={changeUserFilter}
                                                                action={{
                                                                  onClick: finishEditUserFilter,
                                                                  content: "Save"
                                                                }}/> :
              <div onClick={() => editUserFilter(filter.value)}>{filter.label}</div>}
            </Table.Cell>
            <Table.Cell collapsing className="my-filters__buttons"><Button onClick={() => viewRecipes(filter.key)}>View
              Filter</Button> <Button negative onClick={() => confirmRemoveMyFilters(filter.value)}>Remove
              Filter</Button></Table.Cell>
          </Table.Row>);
        }) : <Table.Row>
          <Table.Cell></Table.Cell>
          <Table.Cell>There are no filters associated with your account.</Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>}</Table.Body>
      </Table>

      <Modal
        open={removeFiltersModalOpen}
        onClose={hideRemoveFiltersModal}
        basic size='small'>
        <Header icon='archive' content='Delete Filter'/>
        <Modal.Content>
          <p>
            Are you sure you want to remove "<strong>{removeFiltersOption.title}</strong>"?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={hideRemoveFiltersModal}>
            <Icon name='remove'/> No
          </Button>
          <Button color='green' inverted onClick={removeMyFilters}>
            <Icon name='checkmark'/> Yes
          </Button>
        </Modal.Actions>
      </Modal>


    </div>
  </Grid.Column>);

}

function mapStateToProps (state) {
  //console.log("User Tools: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    userFilters: state.userFiltersData,
    //filters: state.recipesData.filters,
  };
}

export default connect(mapStateToProps, {
  updateSettings,
  findUserFilters,
  updateUserFilters,
  deleteUserFilters
})(withRouter(UserFilters));