//import Formsy from 'formsy-react-es6';
import { Form } from "formsy-semantic-ui-react";
import has from "lodash/has";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import { debug } from "../../common/helpers";
import { resetOrderReview, retrieveOrderReview } from "../../redux/actions/order";
import { updateSettings } from "../../redux/actions/settings";
import { updateUserPayment } from "../../redux/actions/user";

function PaymentInfoForm (props) {
  const {settings, stripe, user, updateUserPayment} = props;
  //console.log("payment form", props);

  const [editPayment, setEditPayment] = useState(false);
  const [allowUpdatePayment, setAllowUpdatePayment] = useState(false);

  const [stripeError, setStripeError] = useState({});

  //const [modifiedFields, setModifiedFields] = useState({});

  //const query = Object.assign({product_id}, qs.parse(props.location.search));
  //console.log("query", query);

  /*
  const orderReview = (options) => {
    // submit product_id, address, code for order data
    retrieveOrderReview({
      product_id: productID,
      address,
    });

  };
*/

  /*
  const changePaymentDropdown = (e, data) => {
    //console.log(`change dropdown ${data.name}`, e, data);
    setModifiedFields({...modifiedFields, [data.name]: data.value});
    setAllowUpdatePayment(true);
  };
*/

  /*
  const changePaymentInput = (e, data) => {
    //console.log(`change input ${data.name}`, e, data);
    //setModifiedFields({...modifiedFields, [data.name]: data.value});
    setAllowUpdatePayment(true);
  };
*/

  const changeStripe = (e) => {
    //console.log("Change Stripe", e);
    debug.log(e, "Change Stripe");
    setAllowUpdatePayment(!e.empty && e.complete);

    // if there's any error in the field then update the stripeError so we can check it throughout the form
    setStripeError(e.error);
  };

  const clickEditPayment = (e) => {
    e.preventDefault();
    setEditPayment(true);
  };

  useEffect(() => {
    //console.log("useEffect: Payment Info Form. Order State", orderState, "Props", props);

  }, [settings, user]);

  const updateAccount = (formData) => {
    debug.log({"formData": formData, "Stripe": stripe, "Stripe Error": stripeError});

    stripe.createToken({name: `${user.firstname} ${user.lastname}`}).then(({token, error}) => {
      if (error) {
        // show error
      } else {
        if (editPayment) {
          // only update payment
          setEditPayment(false);
        }
        debug.log(token, "Received Stripe token:");
        let userData = {
          stripe_token: token.id,

          // the backend with automatically know which api key to use based on the api mode in the user settings
          // so we don't need to pass it here
          //stripe_public_api_key: stripePublicAPIKey(),
        };

        updateUserPayment({data: userData});
      }
    });

    if (has(stripeError, "code")) {
      // if we have an error code we should be showing it to the user anyway,
      // but we don't want to be able to continue until it's resolved so just return
      return;
    }

    // check that formData.accept === 'accept'
    // pass info to Stripe
    // send to server
    // how do we tell them it was success
    // then what happens? do we redirect?
    //loginUser({email: formData.email, password: formData.password});
  };

  //const errorLabel = <Label color="red" pointing/>;

  return (

    <Grid.Column className="account__info-column"><Form size="large" className="account-content__form"
                                                        onValidSubmit={updateAccount}
                                                        loading={settings.fetchingOrder}>
      <div className="account__info-column-title">PAYMENT INFO</div>
      {editPayment ?
        (<div>
          <Form.Field>
            <label>Card Number <span className="red">*</span></label>
            <CardElement
              onChange={changeStripe}
              hidePostalCode
              style={{
                base: {
                  fontFamily: "\"Open Sans\", Helvetica, sans-serif",
                  fontSize: "15px",
                  // lineHeight: "1.6",
                  "::placeholder": {
                    color: "#DEDEDE",
                  },
                },
              }}
              elementRef={StripeElement => {
                props.Stripe.StripeElement = StripeElement;
              }}
            />
          </Form.Field>
          <div className="account__info-payment_button">
            <Form.Button
              content="Update Payment"
              disabled={!allowUpdatePayment || has(stripeError, "code")}
              className="account-form__update-button"/>
          </div>
        </div>) :
        (<div>
          <Dimmer
            active={settings.updatingUserPayment} inverted>
            <Loader size='large'>Updating</Loader>
          </Dimmer>
          <div className="account__info-payment_card">
            <div className="account__info-payment_card_brand">{user.user_subscriptions[0].card_brand}</div>
            <div
              className="account__info-payment_card_last4">{`Ending in ${user.user_subscriptions[0].card_last4}`}</div>
          </div>
          <div className="account__info-payment_button">
            <Button onClick={clickEditPayment}>Edit Payment</Button>
          </div>
        </div>)}
    </Form></Grid.Column>
  );
}

function mapStateToProps (state) {
  //console.log("Payment Info Form: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    order: state.orderData,
  };
}

export default injectStripe(connect(mapStateToProps, {
  updateSettings,
  retrieveOrderReview,
  resetOrderReview,
  updateUserPayment
})(withRouter(PaymentInfoForm)));
