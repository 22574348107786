import axios from "axios";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiURL, baseParams } from "../../common/consts";
import { isAuthenticated, userTierAccess } from "../../common/helpers";
import { toggleVote } from "../../redux/actions/recipes";
import classNames from "classnames";
//import { updateSettings } from "../../redux/actions/settings";
//import { createUserRecipe, deleteUserRecipe } from "../../redux/actions/userRecipes";

function RecipeVoteButtons (props) {
  const {recipe, settings, toggleVote} = props;
  const [togglingVote, setTogglingVote] = useState(false);
  const [votes, setVotes] = useState(recipe.votes);

  const createRecipeVote = (options) => {
    //console.log("API: Create Recipe Vote", options);

    if (!isAuthenticated()) {
      return false;
    }

    const globalState = window.store.getState();
    //console.log("Global State", globalState);

    const params = {
      ...baseParams(),
      auth_token: globalState.userData.auth_token,
    };
    //debug.log("Create Recipe Vote: Params", params);
    const data = {
      //uid: globalState.userData.uid,
      recipe_id: options.recipe_id,
      direction: options.direction,
      //...options.data
    };
    //debug.log("Recipe Vote: Data", data);

    setTogglingVote(true);
    return axios.post(`${apiURL}recipe/vote?${queryString.stringify(params)}`, {...data}).then(response => response.data);
  };

  const deleteRecipeVote = (options) => {
    if (!isAuthenticated()) {
      return false;
    }

    const globalState = window.store.getState();
    //console.log("Global State", globalState);

    const params = {
      ...baseParams(),
      auth_token: globalState.userData.auth_token,
    };

    //console.log("Delete User Recipe: Params", params);
    const data = {
      recipe_id: options.recipe_id,
    };
    //console.log("User Recipe: Data", data);
    setTogglingVote(true);
    return axios.post(`${apiURL}recipe/delete_vote?${queryString.stringify(params)}`, {...data}).then(response => response.data);
  };

  useEffect(() => {
    //debug.log("votes", votes);
  },);

  const userCanVote = () => {

    //return false;
    //if ((userTierAccess({minTier: "1"}) && !recipe.pro_tier) || userTierAccess({minTier: "2"}))
    // only users with a minimum tier of 1 can vote
    return userTierAccess({minTier: "1"});
  };

  const toggleUserVoteHandler = (direction) => {
    //debug.log("votes", votes);

    //if ((userTierAccess({minTier: "1"}) && !recipe.pro_tier) || userTierAccess({minTier: "2"}))
    // only users with a minimum tier of 1 can vote
    if (!userCanVote()) {
      return;
    }

    // make sure we're not already talking to the server
    if (!settings.votingRecipe) {

      // check if we've already voted and are not trying to "unvote"
      if ((direction === "up" && votes.voted_up) || (direction === "down" && votes.voted_down)) {
        // we're deleting a vote
        deleteRecipeVote({recipe_id: recipe.id}).then(response => {
          console.log("done deleting", response);

          if (response.result === "success") {
            // setIsUserRecipe(false);
            toggleVote({id: recipe.id, votes: response.response.votes});
            //updateUserVoted(response.response.votes);
            setVotes(response.response.votes);
            setTogglingVote(false);
          }
        });

      } else {

        createRecipeVote({recipe_id: recipe.id, direction}).then(response => {
          // console.log("done creating", response);
          if (response.result === "success") {
            toggleVote({id: recipe.id, votes: response.response.votes});
            setVotes(response.response.votes);
            setTogglingVote(false);
          }
        });
      }
    }
  };

  return (<React.Fragment>
    <div
      className={classNames("recipe-bar__vote-icon", "recipe-bar__vote-up-icon", `recipe-bar__vote-up-icon--${votes.voted_up ? "on" : "off"}`, {"disabled": !userCanVote()}, {"recipe-bar__vote-up-icon--dim": togglingVote})}
      onClick={() => toggleUserVoteHandler("up")} title="vote up recipe"></div>
    <span
      className={classNames("votes-label votes-up-label", {"disabled": !userCanVote()})}>{` ${votes.positives}`}</span>
    <div
      className={classNames("recipe-bar__vote-icon", "recipe-bar__vote-down-icon", `recipe-bar__vote-down-icon--${votes.voted_down ? "on" : "off"}`, {"disabled": !userCanVote()}, {"recipe-bar__vote-down-icon--dim": togglingVote})}
      onClick={() => toggleUserVoteHandler("down")} title="vote down recipe"></div>
    <span
      className={classNames("votes-label votes-down-label", {"disabled": !userCanVote()})}>{` ${Math.abs(votes.negatives)}`}</span></React.Fragment>);
}

function mapStateToProps (state) {
  //console.log("User Recipe Button: Map State to Props", state);
  return {
    //recipe: state.recipesData.recipe,
    recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    settings: state.settings,
    //user: state.userData,
    //userRecipes: state.userRecipesData,
  };
}

export default connect(mapStateToProps, {
  // retrieveRecipe,
  // createUserRecipe,
  // deleteUserRecipe,
  toggleVote,
})(RecipeVoteButtons);