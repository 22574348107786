import React from "react";
import { connect } from "react-redux";
import { Dropdown, Grid, Header, Radio } from "semantic-ui-react";
import { updateSettings } from "../../redux/actions/settings";
import { updateUser } from "../../redux/actions/user";
import { imperialUnits } from "../../common/helpers";

function CalculatorToolbar (props) {
  const {updateUser, settings, updateSettings} = props;

  // When we toggle the Imperial / Metric values we update the settings which will automatically rerender the cards
  const handleUnitChange = (e, {value}) => {
    // updateSettings({imperial: value === "true"});

    updateUser({data: {default_units: value === "true" ? "imperial" : "metric"}});
  };

  const handleCutTypeChange = (e, {value}) => {
    updateSettings({
      calculatorCutType: value
    });

    //console.log("cut type", value);
  };

  return (<Grid className="recipe-toolbar toolbar" columns="equal">
    <Grid.Row>
      <React.Fragment>
        <Grid.Column className="toolbar__header"
                     width={7}><Header as="h1">Speeds and Feeds Calculator</Header></Grid.Column>

        <Grid.Column className="toolbar__cut-type" width={4}>
          <span className="label">CUT TYPE:{""}</span> <Dropdown inline options={settings.calculatorCutTypes}
                                                                 value={settings.calculatorCutType}
                                                                 onChange={handleCutTypeChange}/>
        </Grid.Column>

        <Grid.Column className="toolbar__units" width={5} floated='right'><span className="label">UNITS:</span>
          <Radio label="Imperial" name="units" value="true" checked={imperialUnits()}
                 onChange={handleUnitChange}/>
          <Radio label="Metric" name="units" value="false" checked={!imperialUnits()} onChange={handleUnitChange}/>
        </Grid.Column>
      </React.Fragment>
    </Grid.Row>
  </Grid>);
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    settings: state.settings,
    user: state.userData,
  };
}

export default connect(mapStateToProps, {updateSettings, updateUser})(CalculatorToolbar);
