import { Form, Input } from "formsy-semantic-ui-react";
import get from "lodash/get";
import React, { useEffect } from "react";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import { Button, Grid, Header, Label } from "semantic-ui-react";
import { debug, showMessage } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
import { resetPasswordRequest } from "../../redux/actions/user";
import Layout from "../global/Layout";
import isEmpty from "lodash/isEmpty";

function ForgotPassword (props) {
  const {settings, user, updateSettings, resetPasswordRequest} = props;

  useEffect(() => {
    //console.log("useEffect: FAQ", props);

    if (settings.activeMenuItem !== "forgot-password") {
      updateSettings({activeMenuItem: "forgot-password"});
    }

  }, [settings]);

  const requestPasswordReset = (formData) => {
    debug.log("form data", formData);
    let passwordResetData = {
      email: formData.email,
    };

    //console.log("password reset data", passwordResetData);
    debug.log("password reset data", passwordResetData);
    resetPasswordRequest({email: formData.email});
  };

  const errorLabel = <Label color="red" pointing/>;

  return (<Layout>
    <Grid className="forgot-password-content content">
      <Grid.Row className="forgot-password-content__heading" centered>
        <Grid.Column className="forgot-password-content__header" width={5}><Header as="h1">Forgot Password</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="forgot-password-content__form" centered>
        <Grid.Column width={5}>
          {user.reset_password_request ? showMessage({messageType: "password_reset_request"}) : ""}
          {!isEmpty(user.reset_password_request_failed_code) ? showMessage({
            messageType: user.reset_password_request_failed_code,
            isError: true
          }) : ""}
          <Form size="large" className="forgot-password-form" onValidSubmit={requestPasswordReset}
                loading={settings.resettingPasswordRequest}>
            <Form.Field>
              <label>Email <span className="red">*</span></label>
              <Input
                placeholder='Email'
                name="email"
                required
                validationErrors={{
                  isDefaultRequiredValue: "Email is Required"
                }}
                errorLabel={errorLabel}/>
            </Form.Field>
            {get(settings, "config.recaptcha_key") !== undefined ?
              <GoogleReCaptchaProvider reCaptchaKey={settings.config.recaptcha_key}>
                <GoogleReCaptcha onVerify={token => console.log(token)}/>
              </GoogleReCaptchaProvider> : ""}
            <Button type='submit' className="forgot-password-form__submit-button">Reset Password</Button>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Layout>);
}

function mapStateToProps (state) {
  //console.log("FAQs: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    user: state.userData,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, {updateSettings, resetPasswordRequest})(ForgotPassword);