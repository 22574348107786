import React, { useEffect } from "react";
import { connect } from "react-redux";
//import { updateSettings } from "../../redux/actions/settings";
import { logoutUser } from "../../redux/actions/user";

function LogOut (props) {
  const {settings, logoutUser} = props;

  useEffect(() => {
    //console.log("useEffect: Logout", props);

    if (settings.activeMenuItem !== "logout") {
      //console.log("calling log out user");
      logoutUser();
      props.history.push("/");
      //updateSettings({activeMenuItem: "logout"});
    }
  }, [settings]);

  return (<div></div>);
  // return (<Redirect to="/"/>);
}

function mapStateToProps (state) {
  //console.log("LogOut: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    //user: state.userData,
  };
}

export default connect(mapStateToProps, {logoutUser})(LogOut);