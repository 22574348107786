import {
  initialSettings,
  RETRIEVED_CONFIG,
  sortOptions,
  thumbnailOptions,
  calculatorCutTypes,
  UPDATE_SETTINGS
} from "../../common/consts";
import { debug } from "../../common/helpers";
import api from "../api";

export const retrieveSettings = settingsCriteria => dispatch => {
  window.store.dispatch(updateSettings({fetchingConfig: true}));
  api.config.retrieve(settingsCriteria).then(configResponse => {
      //console.log("Retrieve Config: response", configResponse);
      debug.log("Retrieve Config: response", configResponse);
      window.store.dispatch(updateSettings({fetchingConfig: false}));
      dispatch(retrievedConfig(configResponse));
    }
  );
};

export const retrievedConfig = configResponse => ({
  type: RETRIEVED_CONFIG,
  configResponse,
  initialSettings,
  sortOptions,
  thumbnailOptions,
  calculatorCutTypes
});

export const updateSettings = settingsCriteria => ({
  type: UPDATE_SETTINGS,
  criteria: settingsCriteria
});