//import Formsy from 'formsy-react-es6';
import { Dropdown, Form, Input } from "formsy-semantic-ui-react";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectStripe } from "react-stripe-elements";
import { Button, Dimmer, Grid, Label, Loader } from "semantic-ui-react";
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, MIN_PASSWORD_NUMBERS, US_STATES } from "../../common/consts";
import { debug, isCustomer, isValidPassword } from "../../common/helpers";
import { resetOrderReview, retrieveOrderReview } from "../../redux/actions/order";
import { updateSettings } from "../../redux/actions/settings";
import { updateUserBilling } from "../../redux/actions/user";
import * as Formsy from "formsy-react";
import isEmpty from "lodash/isEmpty";

function BillingInfoForm (props) {
  const {settings, user, updateUserBilling} = props;
  //console.log("billing form", props);

  const [editBilling, setEditBilling] = useState(false);
  const [allowUpdateBilling, setAllowUpdateBilling] = useState(false);

  const [modifiedFields, setModifiedFields] = useState({});

  //const query = Object.assign({product_id}, qs.parse(props.location.search));
  //console.log("query", query);

  const USStatesOptions = [{key: null, value: null, text: "Select State"}, ...US_STATES.map(USState => ({
    key: USState.abbreviation,
    value: USState.abbreviation,
    text: USState.name,
  }))];

  const [countriesOptions, setCountriesOptions] = useState([{
    key: null,
    value: null,
    text: "Select Country"
  }]);

  const changeBillingDropdown = (e, data) => {
    //console.log(`change dropdown ${data.name}`, e, data);
    setModifiedFields({...modifiedFields, [data.name]: data.value});
    setAllowUpdateBilling(true);
  };

  const changeBillingInput = (e, data) => {
    //console.log(`change input ${data.name}`, e, data);
    setModifiedFields({...modifiedFields, [data.name]: data.value});
    setAllowUpdateBilling(true);
  };

  const clickEditBilling = (e) => {
    e.preventDefault();
    setEditBilling(true);
  };

  useEffect(() => {
    //console.log("useEffect: Billing Info Form. Order State", orderState, "Props", props);

    // we only want to do this once
    if (get(settings, "config.countries") && countriesOptions.length === 1) {
      setCountriesOptions([...countriesOptions, ...settings.config.countries.map(Country => ({
        key: Country.cid,
        value: Country.iso2,
        text: Country.name,
      }))]);
      //console.log("Country Options", countriesOptions);
    }
  }, [settings, user]);

  const cancelEditBilling = (e) => {
    e.preventDefault();
    setEditBilling(false);
  };

  const updateAccount = (formData) => {
    debug.log({"formData": formData, "modified fields": modifiedFields});

    if (editBilling) {
      // only update billing
      setEditBilling(false);
    }

    let userData = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      phone: formData.phone,
      email: formData.email,
      password: formData.password,
    };

    if (!isEmpty(user.addresses[0])) {
      userData = {
        ...userData, address: {
          id: user.addresses[0].id,
          firstname: formData.firstname,
          lastname: formData.lastname,
          street_1: formData.street_1,
          street_2: formData.street_2,
          city: formData.city,
          state_province_region: formData.state_province_region,
          country: formData.country,
          zip: formData.zip,
        }
      };
    }

    debug.log("update user data", userData);
    updateUserBilling({data: userData});

    // check that formData.accept === 'accept'
    // pass info to Stripe
    // send to server
    // how do we tell them it was success
    // then what happens? do we redirect?
    //loginUser({email: formData.email, password: formData.password});
  };

  const errorLabel = <Label color="red" pointing/>;

  Formsy.addValidationRule("isValidPassword", function (values, value) {
    return isValidPassword(value);
  });

  return (
    <Grid.Column stretched className="account__info-column">

      <Form size="large" className="account-content__form" onValidSubmit={updateAccount}
            loading={settings.fetchingOrder}>
        <div className="account__info-column-title">BILLING ADDRESS & PASSWORD</div>
        {editBilling ?
          (<div>
            <Form.Field>
              <label>First Name <span className="red">*</span></label>
              <Input
                placeholder='First Name'
                name="firstname"
                value={user.firstname}
                onChange={changeBillingInput}
                required
                validationErrors={{
                  isDefaultRequiredValue: "First Name is Required"
                }}
                errorLabel={errorLabel}/>
            </Form.Field>
            <Form.Field>
              <label>Last Name <span className="red">*</span></label>
              <Input
                placeholder='Last Name'
                name="lastname"
                value={user.lastname}
                onChange={changeBillingInput}
                required
                validationErrors={{
                  isDefaultRequiredValue: "Last Name is Required"
                }}
                errorLabel={errorLabel}/>
            </Form.Field>

            {isCustomer() ? <React.Fragment>
              <Form.Field>
                <label>Street <span className="red">*</span></label>
                <Input
                  placeholder='Street'
                  name="street_1"
                  value={user.addresses[0].street_1}
                  onChange={changeBillingInput}
                  required
                  validationErrors={{
                    isDefaultRequiredValue: "Street is Required"
                  }}
                  errorLabel={errorLabel}/>
              </Form.Field>
              <Form.Field>
                <label>Apt. / Suite #</label>
                <Input
                  placeholder='Apt. / Suite #'
                  name="street_2"
                  value={user.addresses[0].street_2}
                  onChange={changeBillingInput}
                  validations="maxLength:40"
                />
              </Form.Field>
              <Form.Field>
                <label>City <span className="red">*</span></label>
                <Input
                  placeholder='City'
                  name="city"
                  value={user.addresses[0].city}
                  onChange={changeBillingInput}
                  validations="maxLength:30"
                  required
                  validationErrors={{
                    isDefaultRequiredValue: "You must enter a City"
                  }}
                  errorLabel={errorLabel}/>
              </Form.Field>

              <Form.Field>
                <label>State <span className="red">*</span></label>
                {user.addresses[0].country.toUpperCase() === "US" ?
                  (<Dropdown
                    name="state_province_region"
                    value={user.addresses[0].state_province_region.toUpperCase()}
                    placeholder="Select a State"
                    search
                    required
                    validationErrors={{
                      isDefaultRequiredValue: "You need to select a State",
                    }}
                    errorLabel={<Label color="red" pointing="left"/>}
                    options={USStatesOptions}
                    onChange={changeBillingDropdown}
                  />)
                  :
                  (<Input
                    placeholder='State / Province / Region'
                    name="state_province_region"
                    value={user.addresses[0].state_province_region}
                    onChange={changeBillingInput}
                    required
                    validations="maxLength:30"
                    validationErrors={{
                      isDefaultRequiredValue: "You must enter a State / Province / Region"
                    }}
                    errorLabel={errorLabel}/>)}
              </Form.Field>

              <Form.Field>
                <label>Zip / Postal Code <span className="red">*</span></label>
                <Input
                  placeholder='Zip / Postal Code'
                  name="zip"
                  value={user.addresses[0].zip.toString()}
                  onChange={changeBillingInput}
                  validations="maxLength:20"
                  required
                  validationErrors={{
                    isDefaultRequiredValue: "You must enter a Zip / Postal Code"
                  }}
                  errorLabel={errorLabel}
                />
              </Form.Field>

              <Form.Field>
                <label>Country <span className="red">*</span></label>
                <Dropdown
                  name="country"
                  placeholder="Select Country"
                  search
                  value={user.addresses[0].country.toUpperCase()}
                  required
                  validationErrors={{
                    isDefaultRequiredValue: "You need to select a Country",
                  }}
                  errorLabel={<Label color="red" pointing="left"/>}
                  options={countriesOptions}
                  onChange={changeBillingDropdown}
                />
              </Form.Field>
              <Form.Field>
                <label>Phone (with area code) <span className="red">*</span></label>
                <Input
                  placeholder='XXX XXX-XXXX'
                  name="phone"
                  value={user.addresses[0].phone}
                  onChange={changeBillingInput}
                  required
                  validationErrors={{
                    isDefaultRequiredValue: "Phone is Required"
                  }}
                  errorLabel={errorLabel}/>
              </Form.Field>
            </React.Fragment> : <React.Fragment/>}

            <Form.Field>
              <label>Email <span className="red">*</span></label>
              <Input
                placeholder='Email'
                name="email"
                value={user.email}
                onChange={changeBillingInput}
                required
                validations="isEmail"
                validationErrors={{
                  isEmail: "Email not valid",
                  isDefaultRequiredValue: "Email is Required"
                }}
                errorLabel={errorLabel}/>
            </Form.Field>

            <Form.Field>
              <label>Password <span className="red">*</span></label>
              <Input
                type="password"
                placeholder='New Password'
                name="password"
                onChange={changeBillingInput}
                validations="isValidPassword"
                validationErrors={{
                  //minLength: "Must be at least 8 characters long",
                  isValidPassword: `Password must be between ${MIN_PASSWORD_LENGTH} and ${MAX_PASSWORD_LENGTH} characters long and contain ${MIN_PASSWORD_NUMBERS} number`,
                  isDefaultRequiredValue: "Password is Required"
                }}

                errorLabel={errorLabel}/>
            </Form.Field>

            <Form.Field>
              <label>Confirm Password <span className="red">*</span></label>
              <Input
                type="password"
                placeholder='******'
                name="confirm_password"
                onChange={changeBillingInput}
                validations="equalsField:password"
                validationErrors={{
                  equalsField: "Must match the Password",
                  isDefaultRequiredValue: "Confirm Password is Required",
                }}
                errorLabel={errorLabel}/>
            </Form.Field>

            <Form.Button disabled={!allowUpdateBilling}
                         content="Update"
                         className="account-form__update-button"/>
            <div className="account__info-billing_button">
              <Form.Button negative onClick={cancelEditBilling}>Cancel</Form.Button>
            </div>
          </div>) :

          (<div className="account__info-address_contact">
            <Dimmer
              active={settings.updatingUserBilling} inverted>
              <Loader size='large'>Updating</Loader>
            </Dimmer>
            <div className="account__info-address_contact_fullname">{user.fullname}</div>
            {isCustomer() ? <React.Fragment>
              <div className="account__info-address_contact_address_street_1">{user.addresses[0].street_1}</div>
              <div className="account__info-address_contact_address_street_2">{user.addresses[0].street_2}</div>
              <div
                className="account__info-address_contact_address_city_state_zip">{`${user.addresses[0].city}, ${user.addresses[0].country.toUpperCase() === "US" ? user.addresses[0].state_province_region.toUpperCase() : user.addresses[0].state_province_region} ${user.addresses[0].zip} ${user.addresses[0].country}`}
              </div>
              <div className="account__info-address_contact_address_phone">{user.addresses[0].phone}</div>
            </React.Fragment> : <React.Fragment/>}
            <div className="account__info-address_contact_address_email">{user.email}</div>
            <div className="account__info-billing_button">
              <Button onClick={clickEditBilling}>Edit Billing / Password</Button>
            </div>
          </div>)}
      </Form></Grid.Column>
  );
}

function mapStateToProps (state) {
  //console.log("Billing Info Form: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    order: state.orderData,
  };
}

export default injectStripe(connect(mapStateToProps, {
  updateSettings,
  retrieveOrderReview,
  resetOrderReview,
  updateUserBilling,
})(withRouter(BillingInfoForm)));
