import Layout from '../global/Layout';
import { Form, Input } from 'formsy-semantic-ui-react';
import { Button, Grid, Label, Segment } from 'semantic-ui-react';
import React, { useState, useEffect } from 'react';
//import convert from "convert-units";
import isEmpty from 'lodash/isEmpty';
//import RecipesToolbar from "./RecipesToolbar";
import { convertUnits, imperialUnits, debug, userTierAccess, hasValue, isNumber } from '../../common/helpers';
//import store from "store";
import CalculatorToolbar from './CalculatorToolbar';
import { connect } from 'react-redux';
import { retrieveRecipe } from '../../redux/actions/recipes';
import round from 'lodash/round';
import { DRILLING_CUT_TYPE_ID } from '../../common/consts';
import ReactHtmlParser from 'react-html-parser';
//import queryString from "query-string";
import isEqual from 'lodash/isEqual';
import RecipeCardPreview from './RecipeCardPreview';
import { updateUser } from '../../redux/actions/user';
import { updateSettings } from '../../redux/actions/settings';

//import classNames from "classnames/bind";

function Calculator(props) {
  const { retrieveRecipe, updateUser, updateSettings, settings, recipe, user } = props;

  const defaultFieldValues = {
    recalculate: false,
    changing: null,
    toolDiameter: '',
    flutes: '',
    rpm: '',
    surfaceSpeed: '',
    feedPerTooth: '',
    feedPerMinute: '',
    feedPerRevolution: '',
    radialDOCValue: '',
    radialDOCPercent: '',
    axialDOCValue: '',
    axialDOCPercent: '',
    effectiveFeedPerTooth: '',
    mrr: '',
  };

  const [modifiedFields, setModifiedFields] = useState(defaultFieldValues);

  const [imperialActive, setImperialActive] = useState(imperialUnits());

  const precision = 4;

  const [recipeID, setRecipeID] = useState(props.match.params.recipeID);
  const [_recipe, setRecipe] = useState({});

  //const parsedURLParams = queryString.parse(window.location.search);
  // console.log("params", parsedURLParams);

  // const formRef = createRef();

  //  const parsedURLParams = queryString.parse(props.location.search);
  const errorLabel = <Label
    color="red"
    pointing/>;

  init();

  function init() {
    useEffect(() => {
      _retrieveRecipe({ recipeID });
    }, []);
  }

  // listen for changes to modified fields object
  /*
  useEffect(() => {
    debug.log("use effect", modifiedFields);
    debug.log("imperial units", imperialUnits(), "imperial active", imperialActive);
    if (imperialUnits() !== imperialActive) {
      console.log("units changed ", imperialActive);
      setImperialActive(!imperialActive);
      convertAllFieldUnits();
    }

    if (modifiedFields.recalculate) {
      calculateResults();
    }

    if (!settings.fetchingRecipes && !isEmpty(recipe) && !isEqual(recipe, _recipe)) {
      setRecipe(recipe);
      debug.log("SETTING RECIPE");

      updateFieldsWithRecipe(recipe);
    }

  }, [modifiedFields, settings, recipe]);  // on change recalculate all values
   */

  useEffect(() => {
    debug.log('use effect - modified fields', modifiedFields);

    if (modifiedFields.recalculate) {
      calculateResults();
    }

  }, [modifiedFields]);

  // useEffect(() => {
  //   debug.log("use effect - settings", settings);
  //
  //   debug.log("imperial units", imperialUnits(), "imperial active", imperialActive);
  //   if (imperialUnits() !== imperialActive && !forceUnitConversion) {
  //     //   console.log("units changed ", imperialActive);
  //     setImperialActive(!imperialActive);
  //     convertAllFieldUnits();
  //   }
  //
  // }, [settings]);

  useEffect(() => {
    debug.log('use effect - user', user);

    debug.log('imperial units', imperialUnits(), 'imperial active', imperialActive);
    if (imperialUnits() !== imperialActive) {
      //console.log('units changed ', imperialActive);
      setImperialActive(!imperialActive);
      convertAllFieldUnits();
    }

  }, [user]);

  useEffect(() => {
    debug.log('use effect - recipe', recipe);

    if (!settings.fetchingRecipes && !isEmpty(recipe) && !isEqual(recipe, _recipe)) {
      setRecipe(recipe);
      debug.log('SETTING RECIPE');

      updateFieldsWithRecipe(recipe);
    }

  }, [recipe]);

  const handleKeyPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e, data) => {
    //debug.log(e, e.target, data);

    let newModifiedFieldsData = { ...modifiedFields, [data.name]: data.value, recalculate: true, changing: data.name };

    // check if the current modified field is a priority dependency field
    if (data.name === 'feedPerMinute') {
      newModifiedFieldsData.priorityFeed = 'feedPerMinute';
    }

    if (data.name === 'feedPerTooth') {
      newModifiedFieldsData.priorityFeed = 'feedPerTooth';
    }

    setModifiedFields(newModifiedFieldsData);

    //formRef.current.submit();
    // debug.log("form", formRef);
    // debug.log("form", formRef.current.submit(e));
  };

  const handleInputFocus = (e) => {};

  const handleLoadRecipeIDChange = (e, data) => {
    e.preventDefault();

    setRecipeID(data.value);
  };

  const resetCalculator = (e) => {
    e.preventDefault();
    debug.log('reset');

    setModifiedFields({ ...defaultFieldValues, recalculate: false });
  };

  const loadRecipe = (formData) => {
    debug.log('search recipe', recipeID);
    _retrieveRecipe({ recipeID: recipeID });
  };

  const _retrieveRecipe = (options) => {
    if (options.recipeID) {
      debug.log('retrieving recipe id', options.recipeID);
      let retrieveOptions = { id: options.recipeID, data: { view: true } };

      if (userTierAccess({ minTier: '1.0.0', maxTier: '1.9.9' })) {
        retrieveOptions.user = { tier: '2.0.0' };
      }

      // console.log("useEffect: Recipe Init", props);
      retrieveRecipe(retrieveOptions);
    }
  };

  const updateFieldsWithRecipe = recipe => {

    const currentImperialUnits = imperialUnits();
    setImperialActive(true);

    if (!currentImperialUnits) {
      // change units to imperial
      updateUser({ data: { default_units: 'imperial' } });
    }

    updateSettings({
      calculatorCutType: recipe.cut_type.id === DRILLING_CUT_TYPE_ID ? 'drilling' : 'milling'
    });

    let recipeFields = {};

    if (!isEmpty(recipe.cutting_tools[0])) {
      recipeFields.toolDiameter = recipe.cutting_tools[0].cutting_diameter;
    }

    if (!isEmpty(recipe.cutting_tools[0])) {
      recipeFields.flutes = recipe.cutting_tools[0].number_of_flutes;
    }

    if (hasValue(recipe.rpm)) {
      recipeFields.rpm = recipe.rpm;
    }

    if (hasValue(recipe.surface_feet_per_minute)) {
      recipeFields.surfaceSpeed = recipe.surface_feet_per_minute;
    }

    if (hasValue(recipe.programmed_ipt)) {
      recipeFields.feedPerTooth = recipe.programmed_ipt;
    }

    if (hasValue(recipe.ipm)) {
      recipeFields.feedPerMinute = recipe.ipm;
    }

    if (hasValue(recipe.inches_per_revolution)) {
      recipeFields.feedPerRevolution = recipe.inches_per_revolution;
    }

    if (hasValue(recipe.radial_depth_of_cut_value)) {
      recipeFields.radialDOCValue = recipe.radial_depth_of_cut_value;
    }

    if (hasValue(recipe.radial_depth_of_cut_percent)) {
      recipeFields.radialDOCPercent = recipe.radial_depth_of_cut_percent;
    }

    if (hasValue(recipe.axial_depth_of_cut_value)) {
      recipeFields.axialDOCValue = recipe.axial_depth_of_cut_value;
    }

    if (hasValue(recipe.axial_depth_of_cut_percent)) {
      recipeFields.axialDOCPercent = recipe.axial_depth_of_cut_percent;
    }

    if (hasValue(recipe.effective_ipt)) {
      recipeFields.effectiveFeedPerTooth = recipe.effective_ipt;
    }

    if (hasValue(recipe.mrr)) {
      recipeFields.mrr = recipe.mrr;
    }

    debug.log('recipe fields', recipeFields);

    // update field
    setModifiedFields({
      ...defaultFieldValues, ...recipeFields,
      recalculate: false,
    });
  };

  const calculateResults = () => {
    //debug.log(modifiedFields);
    //debug.log("calc results: previous input:", previousInputField, "current input field:", currentInputField);

    let calculatedFieldValues = {};

    if (modifiedFields.changing !== 'rpm') {
      const rpm = calculateRPM(modifiedFields);
      if (rpm) {
        calculatedFieldValues.rpm = rpm;
      }
    }

    if (modifiedFields.changing !== 'surfaceSpeed') {
      const surfaceSpeed = calculateSurfaceSpeed({ ...modifiedFields, ...calculatedFieldValues });

      if (surfaceSpeed) {
        calculatedFieldValues.surfaceSpeed = surfaceSpeed;
      }
    }

    if (modifiedFields.changing !== 'feedPerMinute') {
      const feedPerMinute = calculateFeedPerMinute({ ...modifiedFields, ...calculatedFieldValues });
      if (feedPerMinute) {
        calculatedFieldValues.feedPerMinute = feedPerMinute;
      }
    }

    if (modifiedFields.changing !== 'feedPerRevolution') {
      const feedPerRevolution = calculateFeedPerRevolution({ ...modifiedFields, ...calculatedFieldValues });
      if (feedPerRevolution) {
        calculatedFieldValues.feedPerRevolution = feedPerRevolution;
      }
    }

    if (modifiedFields.changing !== 'feedPerTooth') {
      const feedPerTooth = calculateFeedPerTooth({ ...modifiedFields, ...calculatedFieldValues });
      if (feedPerTooth) {
        calculatedFieldValues.feedPerTooth = feedPerTooth;
      }
    }

    if (modifiedFields.changing !== 'radialDOCValue') {
      const radialDOCValue = calculateRadialDOCValue({ ...modifiedFields, ...calculatedFieldValues });
      if (radialDOCValue) {
        calculatedFieldValues.radialDOCValue = radialDOCValue;
      }
    }

    if (modifiedFields.changing !== 'radialDOCPercent') {
      const radialDOCPercent = calculateRadialDOCPercent({ ...modifiedFields, ...calculatedFieldValues });
      if (radialDOCPercent) {
        calculatedFieldValues.radialDOCPercent = radialDOCPercent;
      }
    }

    if (modifiedFields.changing !== 'axialDOCValue') {
      const axialDOCValue = calculateAxialDOCValue({ ...modifiedFields, ...calculatedFieldValues });
      if (axialDOCValue) {
        calculatedFieldValues.axialDOCValue = axialDOCValue;
      }
    }

    if (modifiedFields.changing !== 'axialDOCPercent') {
      const axialDOCPercent = calculateAxialDOCPercent({ ...modifiedFields, ...calculatedFieldValues });
      if (axialDOCPercent) {
        calculatedFieldValues.axialDOCPercent = axialDOCPercent;
      }
    }

    calculatedFieldValues.effectiveFeedPerTooth = calculateEffectiveFeedPerTooth({ ...modifiedFields, ...calculatedFieldValues });

    const mrr = calculateMRR();
    if (mrr) {
      calculatedFieldValues.mrr = mrr;
    }

    //debug.log("modified fields", modifiedFields);
    //debug.log("calculated fields", calculatedFieldValues);

    setModifiedFields({
      ...defaultFieldValues,
      ...modifiedFields,
      ...calculatedFieldValues,
      recalculate: false,
      changing: null
    });
  };

  // convert all fields to the opposite unit
  const convertAllFieldUnits = () => {
    debug.log('converting field units to ', imperialUnits() ? 'inches' : 'mm', modifiedFields);

    let convertedFields = {};

    if (modifiedFields.toolDiameter) {
      convertedFields.toolDiameter = convertUnits({
        value: parseFloat(modifiedFields.toolDiameter),
        from: imperialUnits() ? 'mm' : 'in',
        to: imperialUnits() ? 'in' : 'mm',
        precision: precision,
        returnAs: 'valueOnly',
        //imperial: !imperialUnits(),
      });
      debug.log('tool diameter', modifiedFields.toolDiameter, convertedFields.toolDiameter);
    }

    if (modifiedFields.surfaceSpeed) {
      convertedFields.surfaceSpeed = convertUnits({
        value: parseFloat(modifiedFields.surfaceSpeed),
        from: imperialUnits() ? 'm' : 'ft',
        to: imperialUnits() ? 'ft' : 'm',
        precision: precision,
        returnAs: 'valueOnly',
      });
      debug.log('surfaceSpeed', modifiedFields.surfaceSpeed, convertedFields.surfaceSpeed);
    }

    if (modifiedFields.feedPerTooth) {
      convertedFields.feedPerTooth = convertUnits({
        value: parseFloat(modifiedFields.feedPerTooth),
        from: imperialUnits() ? 'mm' : 'in',
        to: imperialUnits() ? 'in' : 'mm',
        precision: precision,
        returnAs: 'valueOnly',
      });
      debug.log('feedPerTooth', modifiedFields.feedPerTooth, convertedFields.feedPerTooth);
    }

    if (modifiedFields.feedPerMinute) {
      convertedFields.feedPerMinute = convertUnits({
        value: parseFloat(modifiedFields.feedPerMinute),
        from: imperialUnits() ? 'mm' : 'in',
        to: imperialUnits() ? 'in' : 'mm',
        precision: precision,
        returnAs: 'valueOnly',
      });
      debug.log('feedPerMinute', modifiedFields.feedPerMinute, convertedFields.feedPerMinute);
    }

    if (modifiedFields.feedPerRevolution) {
      convertedFields.feedPerRevolution = convertUnits({
        value: parseFloat(modifiedFields.feedPerRevolution),
        from: imperialUnits() ? 'mm' : 'in',
        to: imperialUnits() ? 'in' : 'mm',
        precision: precision,
        returnAs: 'valueOnly',
      });
      debug.log('feedPerRevolution', modifiedFields.feedPerRevolution, convertedFields.feedPerRevolution);
    }

    if (modifiedFields.radialDOCValue) {
      convertedFields.radialDOCValue = convertUnits({
        value: parseFloat(modifiedFields.radialDOCValue),
        from: imperialUnits() ? 'mm' : 'in',
        to: imperialUnits() ? 'in' : 'mm',
        precision: precision,
        returnAs: 'valueOnly',
      });
      debug.log('radialDOCValue', modifiedFields.radialDOCValue, convertedFields.radialDOCValue);
    }

    if (modifiedFields.axialDOCValue) {
      convertedFields.axialDOCValue = convertUnits({
        value: parseFloat(modifiedFields.axialDOCValue),
        from: imperialUnits() ? 'mm' : 'in',
        to: imperialUnits() ? 'in' : 'mm',
        precision: precision,
        returnAs: 'valueOnly',
      });
      debug.log('axialDOCValue', modifiedFields.axialDOCValue, convertedFields.axialDOCValue);
    }

    if (modifiedFields.effectiveFeedPerTooth) {
      convertedFields.effectiveFeedPerTooth = convertUnits({
        value: parseFloat(modifiedFields.effectiveFeedPerTooth),
        from: imperialUnits() ? 'mm' : 'in',
        to: imperialUnits() ? 'in' : 'mm',
        precision: precision,
        returnAs: 'valueOnly',
      });
      debug.log('effectiveFeedPerTooth', modifiedFields.effectiveFeedPerTooth, convertedFields.effectiveFeedPerTooth);
    }

    if (modifiedFields.mrr) {
      convertedFields.mrr = convertUnits({
        value: parseFloat(modifiedFields.mrr),
        from: imperialUnits() ? 'mm3' : 'in3',
        to: imperialUnits() ? 'in3' : 'mm3',
        precision: precision,
        returnAs: 'valueOnly',
      });
      debug.log('mrr', modifiedFields.mrr, convertedFields.mrr);
    }

    debug.log('modified fields', modifiedFields, 'converted fields', convertedFields);
    setModifiedFields({ ...modifiedFields, ...convertedFields, recalculate: false, forceUnitConversion: false });
  };

  // const calculateRPM = () => {
  //   if (!isNumber(modifiedFields.surfaceSpeed) || !isNumber(modifiedFields.toolDiameter)) {
  //     return;
  //   }
  //
  //   let rpm = (12 * parseFloat(modifiedFields.surfaceSpeed)) / (Math.PI * parseFloat(modifiedFields.toolDiameter));
  //   // debug.log('s',parseFloat(modifiedFields.surfaceSpeed), 'd', parseFloat(modifiedFields.toolDiameter))
  //   rpm = round(rpm, precision);
  //   return rpm;
  // };

  const calculateRPM = (fields) => {
    if (!isNumber(fields.surfaceSpeed) || !isNumber(fields.toolDiameter)) {
      return;
    }

    const toolDiameter = convertUnits({
      value: parseFloat(fields.toolDiameter),
      from: imperialUnits() ? 'in' : 'mm',
      to: 'in',
      precision: precision,
      returnAs: 'valueOnly',
    });
    //console.log('converted tool diameter', toolDiameter);
    const surfaceSpeed = convertUnits({
      value: parseFloat(fields.surfaceSpeed),
      from: imperialUnits() ? 'ft' : 'm',
      to: 'ft',
      precision: precision,
      returnAs: 'valueOnly',
    });

    let rpm = (12 * surfaceSpeed) / (Math.PI * toolDiameter);
    // debug.log('s',surfaceSpeed, 'd', toolDiameter)
    rpm = round(rpm, precision);
    debug.log('rpm', rpm);
    return rpm;
  };

  const calculateSurfaceSpeed = (fields) => {
    if (!isNumber(fields.rpm) || !isNumber(fields.toolDiameter)) {
      return;
    }

    debug.log('fields', fields);
    //let surfaceSpeed = (Math.PI * parseFloat(fields.rpm) * parseFloat(fields.toolDiameter)) / 12;

    debug.log('original tool diameter', fields.toolDiameter);
    const toolDiameter = convertUnits({
      value: parseFloat(fields.toolDiameter),
      from: imperialUnits() ? 'in' : 'mm',
      to: 'in',
      precision: precision,
      returnAs: 'valueOnly',
    });
    //console.log('converted tool diameter', toolDiameter);
    let surfaceSpeed = (Math.PI * parseFloat(fields.rpm) * toolDiameter) / 12;
    debug.log('surface speed raw', surfaceSpeed);
    surfaceSpeed = convertUnits({
      value: surfaceSpeed,
      from: 'ft',
      to: imperialUnits() ? 'ft' : 'm',
      precision: precision,
      returnAs: 'valueOnly',
    });

    surfaceSpeed = round(surfaceSpeed, precision);
    debug.log('surface speed', surfaceSpeed);
    return surfaceSpeed;
  };

  const calculateRadialDOCValue = (fields) => {
    if (!isNumber(fields.radialDOCPercent) || !isNumber(fields.toolDiameter)) {
      return;
    }

    let radialDOCValue = (parseFloat(fields.toolDiameter) * parseFloat(fields.radialDOCPercent)) / 100;
    radialDOCValue = round(radialDOCValue, precision);

    return radialDOCValue;
  };

  const calculateRadialDOCPercent = (fields) => {
    if (!isNumber(fields.radialDOCValue) || !isNumber(fields.toolDiameter)) {
      return;
    }

    let radialDOCPercent = (parseFloat(fields.radialDOCValue) / parseFloat(fields.toolDiameter)) * 100;
    radialDOCPercent = round(radialDOCPercent, precision);

    return radialDOCPercent;
  };

  const calculateAxialDOCValue = (fields) => {
    if (!isNumber(fields.axialDOCPercent) || !isNumber(fields.toolDiameter)) {
      return;
    }

    let axialDOCValue = (parseFloat(modifiedFields.toolDiameter) * parseFloat(modifiedFields.axialDOCPercent)) / 100;
    axialDOCValue = round(axialDOCValue, precision);

    return axialDOCValue;
  };

  const calculateAxialDOCPercent = (fields) => {
    if (!isNumber(fields.axialDOCValue) || !isNumber(fields.toolDiameter)) {
      return;
    }

    let axialDOCPercent = (parseFloat(modifiedFields.axialDOCValue) / parseFloat(modifiedFields.toolDiameter)) * 100;
    axialDOCPercent = round(axialDOCPercent, precision);

    return axialDOCPercent;
  };

  const calculateFeedPerMinute = (fields) => {
    let feedPerMinute = '';
    // debug.log("feed per minute fields", fields);

    if (settings.calculatorCutType === 'drilling') {
      // debug.log("validating fpr:", fields.feedPerRevolution, "is number:", isNumber(fields.feedPerRevolution));
      // debug.log("validating rpm:", fields.rpm, "is number", isNumber(fields.rpm));
      if (!isNumber(fields.feedPerRevolution) || !isNumber(fields.rpm)) {
        return;
      }

      feedPerMinute = parseFloat(fields.rpm) * parseFloat(fields.feedPerRevolution);

    } else if (settings.calculatorCutType === 'milling') {
      if (!isNumber(fields.feedPerTooth) || !isNumber(fields.rpm) ||
        !isNumber(fields.flutes) || fields.priorityFeed === 'feedPerMinute') {
        return;
      }

      feedPerMinute = parseFloat(fields.feedPerTooth) * parseFloat(fields.rpm) * parseFloat(fields.flutes);
    }

    feedPerMinute = round(feedPerMinute, precision);
    //debug.log("fpm", feedPerMinute, "imperial", imperialUnits());

    return feedPerMinute;
  };

  const calculateFeedPerRevolution = (fields) => {
    //debug.log("feed per revolution fields", fields);
    if (!isNumber(fields.feedPerMinute) || !isNumber(fields.rpm)) {
      return;
    }

    let feedPerRevolution = parseFloat(fields.feedPerMinute) / parseFloat(fields.rpm);
    feedPerRevolution = round(feedPerRevolution, precision);
    // debug.log("fpr", feedPerRevolution);

    return feedPerRevolution;
  };

  // (aka Programmed IPT)
  const calculateFeedPerTooth = (fields) => {
    //debug.log("feed per tooth fields", fields);
    if (!isNumber(fields.feedPerMinute) || !isNumber(fields.rpm) || !isNumber(fields.flutes) || fields.priorityFeed === 'feedPerTooth') {
      return;
    }

    let feedPerTooth = parseFloat(fields.feedPerMinute) / parseFloat(fields.rpm) / parseFloat(fields.flutes);
    feedPerTooth = round(feedPerTooth, precision);
    //debug.log("fpt", feedPerTooth);

    return feedPerTooth;
  };

  // aka (feed per tooth in results)
  const calculateEffectiveFeedPerTooth = (fields) => {
    const EffectiveFeedPerToothConstant = .5;
    let effectiveFeedPerTooth = '';
    let updateEffectiveFeedPerTooth = false;

    if (isNumber(modifiedFields.feedPerTooth) && parseFloat(modifiedFields.radialDOCPercent) >= 50) {
      effectiveFeedPerTooth = round(parseFloat(modifiedFields.feedPerTooth), precision);
      updateEffectiveFeedPerTooth = true;
    } else if (isNumber(modifiedFields.toolDiameter) && isNumber(modifiedFields.radialDOCValue) && isNumber(modifiedFields.feedPerTooth)) {
      let dia_rdoc = parseFloat(modifiedFields.toolDiameter) / parseFloat(modifiedFields.radialDOCValue);
      // make sure dai rdoc is not infinite
      if (isFinite(dia_rdoc) && !isNaN(dia_rdoc)) {
        effectiveFeedPerTooth = parseFloat(modifiedFields.feedPerTooth) / ((EffectiveFeedPerToothConstant * dia_rdoc) / Math.sqrt(dia_rdoc - 1));
        updateEffectiveFeedPerTooth = true;
        //debug.log("rdoc", dia_rdoc, "eff", effectiveFeedPerTooth);
      }
    }

    if (updateEffectiveFeedPerTooth) {
      if (isNaN(effectiveFeedPerTooth)) {
        effectiveFeedPerTooth = '';
      }
    }

    return effectiveFeedPerTooth;
  };
  /*
   const calculateHorsepower = () => {
    
      if (empty($options['recipe']['mrr']) || empty($options['recipe']['material']['k_factor']))
     {
       return;
     }
     $horsepower = (float) $options['recipe']['mrr'] / $options['recipe']['material']['k_factor'];
     $horsepower = (float) number_format($horsepower, 2, '.', '');
     return $horsepower;
     
  };
 */

  const calculateMRR = () => {
    let mrr = '';
    let updateMRR = false;

    if (settings.calculatorCutType === 'drilling') {
      if (!isNumber(modifiedFields.feedPerRevolution) || !isNumber(modifiedFields.rpm) || !isNumber(modifiedFields.toolDiameter)) {
        return;
      }
      mrr = parseFloat(modifiedFields.feedPerRevolution) * parseFloat(modifiedFields.rpm) * Math.PI * Math.pow((parseFloat(modifiedFields.toolDiameter) / 2), 2);
      updateMRR = true;
    } else if (settings.calculatorCutType === 'milling') {
      //debug.log("checking mrr dependents", modifiedFields);
      if (!isNumber(modifiedFields.radialDOCValue) || !isNumber(modifiedFields.axialDOCValue) || !isNumber(modifiedFields.feedPerMinute)) {
        return;
      }

      mrr = parseFloat(modifiedFields.feedPerMinute) * parseFloat(modifiedFields.radialDOCValue) * parseFloat(modifiedFields.axialDOCValue);
      updateMRR = true;
    }

    //debug.log("mrr", mrr);
    if (updateMRR) {
      mrr = round(mrr, precision);
    }

    return mrr;
  };

  return (
    <Layout>
      <Grid className="calculator-content content">
        <Grid.Row>
          <Grid.Column className="calculator-container">
            <CalculatorToolbar/>
            <Segment>
              {/*ref={formRef}*/}
              <Form
                loading={settings.fetchingRecipe}
                onKeyPress={handleKeyPress}>
                <Grid
                  className="calculator-fields"
                  columns={2}>
                  {/* ------------------------------------- ROW 1 ------------------------------------- */}
                  <Grid.Row>
                    <Grid.Column>
                      <Grid
                        columns={2}
                        className="calculator-fields__field">
                        <Grid.Row>
                          <Grid.Column
                            className="calculator-fields__field-title"
                            width={6}>{`TOOL DIAMETER ${imperialUnits() ? '(inches)' : '(mm)'}`}</Grid.Column>
                          <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                            placeholder={`Tool Diameter ${imperialUnits() ? '(inches)' : '(mm)'}`}
                            name="toolDiameter"
                            type="number"
                            value={modifiedFields.toolDiameter}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            validations="isNumeric"
                            validationErrors={{ isNumeric: 'Value must be numeric' }}
                            errorLabel={errorLabel}/></Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid
                        columns={2}
                        className="calculator-fields__field">
                        <Grid.Row>
                          <Grid.Column
                            className="calculator-fields__field-title"
                            width={6}>FLUTES</Grid.Column>
                          <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                            placeholder="Flutes"
                            name="flutes"
                            type="number"
                            value={modifiedFields.flutes}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            validations="isNumeric"
                            validationErrors={{ isNumeric: 'Value must be numeric' }}
                            errorLabel={errorLabel}/></Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>

                  {/* ------------------------------------- ROW 2 ------------------------------------- */}
                  <Grid.Row>
                    <Grid.Column>
                      <Grid
                        columns={2}
                        className="calculator-fields__field">
                        <Grid.Row>
                          <Grid.Column
                            className="calculator-fields__field-title"
                            width={6}>RPM</Grid.Column>
                          <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                            placeholder="RPM"
                            name="rpm"
                            type="number"
                            value={modifiedFields.rpm}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            validations="isNumeric"
                            validationErrors={{ isNumeric: 'Value must be numeric' }}
                            errorLabel={errorLabel}/></Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid
                        columns={2}
                        className="calculator-fields__field">
                        <Grid.Row>
                          <Grid.Column
                            className="calculator-fields__field-title"
                            width={6}>{`SURFACE SPEED ${imperialUnits() ? '(Ft/min)' : '(M/min)'}`}</Grid.Column>
                          <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                            placeholder={`Surface Speed ${imperialUnits() ? '(Ft/min)' : '(M/min)'}`}
                            name="surfaceSpeed"
                            type="number"
                            value={modifiedFields.surfaceSpeed}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            validations="isNumeric"
                            validationErrors={{ isNumeric: 'Value must be numeric' }}
                            errorLabel={errorLabel}/></Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>

                  {/* ------------------------------------- ROW 3 ------------------------------------- */}
                  <Grid.Row>
                    <Grid.Column>
                      <Grid
                        columns={2}
                        className="calculator-fields__field">
                        <Grid.Row>
                          <Grid.Column
                            className="calculator-fields__field-title"
                            width={6}>{`FEED PER TOOTH ${imperialUnits() ? '(inches)' : '(mm)'}`}</Grid.Column>
                          <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                            placeholder={`Feed Per Tooth ${imperialUnits() ? '(inches)' : '(mm)'}`}
                            name="feedPerTooth"
                            type="number"
                            value={modifiedFields.feedPerTooth}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            validations="isNumeric"
                            validationErrors={{ isNumeric: 'Value must be numeric' }}
                            errorLabel={errorLabel}/></Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid
                        columns={2}
                        className="calculator-fields__field">
                        <Grid.Row>
                          <Grid.Column
                            className="calculator-fields__field-title"
                            width={6}>{`FEED PER MINUTE ${imperialUnits() ? '(inches)' : '(mm)'}`}</Grid.Column>
                          <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                            placeholder={`Feed Per Minute ${imperialUnits() ? '(inches)' : '(mm)'}`}
                            type="number"
                            value={modifiedFields.feedPerMinute}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            name="feedPerMinute"
                            validations="isNumeric"
                            validationErrors={{ isNumeric: 'Value must be numeric' }}
                            errorLabel={errorLabel}/></Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>

                  {(settings.calculatorCutType === 'milling') ?
                    <React.Fragment>
                      {/* ------------------------------------- ROW 4 ------------------------------------- */}
                      <Grid.Row>
                        <Grid.Column>
                          <Grid
                            columns={2}
                            className="calculator-fields__field">
                            <Grid.Row>
                              <Grid.Column
                                className="calculator-fields__field-title"
                                width={6}>{`RADIAL D.O.C. ${imperialUnits() ? '(inches)' : '(mm)'}`}</Grid.Column>
                              <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                                placeholder={`Radial D.O.C. ${imperialUnits() ? '(inches)' : '(mm)'}`}
                                name="radialDOCValue"
                                type="number"
                                value={modifiedFields.radialDOCValue}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                validations="isNumeric"
                                validationErrors={{ isNumeric: 'Value must be numeric' }}
                                errorLabel={errorLabel}/></Form.Field>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column>
                          <Grid
                            columns={2}
                            className="calculator-fields__field">
                            <Grid.Row>
                              <Grid.Column
                                className="calculator-fields__field-title"
                                width={6}>RADIAL
                                D.O.C.%</Grid.Column>
                              <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                                placeholder="Radial D.O.C. %"
                                name="radialDOCPercent"
                                type="number"
                                value={modifiedFields.radialDOCPercent}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                validations="isNumeric"
                                validationErrors={{ isNumeric: 'Value must be numeric' }}
                                errorLabel={errorLabel}/></Form.Field>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>

                      {/* ------------------------------------- ROW 5 ------------------------------------- */}
                      <Grid.Row>
                        <Grid.Column>
                          <Grid
                            columns={2}
                            className="calculator-fields__field">
                            <Grid.Row>
                              <Grid.Column
                                className="calculator-fields__field-title"
                                width={6}>{`AXIAL D.O.C. ${imperialUnits() ? '(inches)' : '(mm)'}`}</Grid.Column>
                              <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                                placeholder={`Axial D.O.C. ${imperialUnits() ? '(inches)' : '(mm)'}`}
                                name="axialDOCValue"
                                type="number"
                                value={modifiedFields.axialDOCValue}
                                onChange={handleInputChange}
                                validations="isNumeric"
                                validationErrors={{ isNumeric: 'Value must be numeric' }}
                                errorLabel={errorLabel}/></Form.Field>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column>
                          <Grid
                            columns={2}
                            className="calculator-fields__field">
                            <Grid.Row>
                              <Grid.Column
                                className="calculator-fields__field-title"
                                width={6}>AXIAL
                                D.O.C.%</Grid.Column>
                              <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                                placeholder="Axial D.O.C. %"
                                name="axialDOCPercent"
                                type="number"
                                value={modifiedFields.axialDOCPercent}
                                onChange={handleInputChange}
                                validations="isNumeric"
                                validationErrors={{ isNumeric: 'Value must be numeric' }}
                                errorLabel={errorLabel}/></Form.Field>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </React.Fragment> :

                    <React.Fragment>
                      {/* ------------------------------------- ROW 4 ------------------------------------- */}
                      <Grid.Row>
                        <Grid.Column>
                          <Grid
                            columns={2}
                            className="calculator-fields__field">
                            <Grid.Row>
                              <Grid.Column
                                className="calculator-fields__field-title"
                                width={6}>{`FEED PER
                              REVOLUTION ${imperialUnits() ? '(inches)' : '(mm)'}`}</Grid.Column>
                              <Grid.Column className="calculator-fields__field-input"> <Form.Field><Input
                                placeholder={`Feed Per Revolution ${imperialUnits() ? '(inches)' : '(mm)'}`}
                                name="feedPerRevolution"
                                type="number"
                                value={modifiedFields.feedPerRevolution}
                                onChange={handleInputChange}
                                validations="isNumeric"
                                validationErrors={{ isNumeric: 'Value must be numeric' }}
                                errorLabel={errorLabel}/></Form.Field>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </React.Fragment>
                  }

                  {/* ------------------------------------- RESULTS / RECIPE SEARCH ------------------------------------- */}
                  <Grid.Row>
                    <Grid.Column>

                      {(settings.calculatorCutType === 'milling') ?
                        <Grid className="calculator-fields__field">
                          <Grid.Row>
                            <Grid.Column
                              className="calculator-fields__field-title"
                              width={6}>{`EFFECTIVE
                          FEED/TOOTH ${imperialUnits() ? '(inches)' : '(mm)'}`}</Grid.Column>
                            <Grid.Column
                              className="calculator-fields__field-output"
                              width={5}>
                              <Label size="large">{modifiedFields.effectiveFeedPerTooth}</Label>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid> : ''}

                      <Grid className="calculator-fields__field">
                        <Grid.Row>
                          <Grid.Column
                            className="calculator-fields__field-title"
                            width={6}>
                            <div
                              className="title">{ReactHtmlParser(`Material Removal Rate<br>${imperialUnits() ? '(inches<sup>3</sup>)' : '(mm<sup>3</sup>)'}`)}</div>
                          </Grid.Column>
                          <Grid.Column className="calculator-fields__field-output">
                            <Label size="large">{modifiedFields.mrr}</Label>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>

                      <Grid className="calculator-fields__field">
                        <Grid.Row>
                          <Grid.Column width={12}><Button onClick={resetCalculator}>Reset Calculator</Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>

                    <Grid.Column floated="right">

                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Segment>

            <Grid className="calculator-fields__field">
              <Grid.Row>
                <Grid.Column className="auto-calc-recipe-container">
                  {/*{debug.log("recipe", _recipe)}*/}
                  <Form
                    onValidSubmit={loadRecipe}
                    loading={false}>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <div className="auto-calc-recipe-title">AUTOFILL CALCULATOR WITH RECIPE</div>
                      </Form.Field>
                      <Form.Field>
                        <Input
                          placeholder="Recipe ID"
                          name="recipeID"
                          value={recipeID}
                          onChange={handleLoadRecipeIDChange}
                          type="number"
                          validations="isNumeric"
                          validationErrors={{ isNumeric: 'Value must be numeric' }}
                          errorLabel={errorLabel}/></Form.Field>
                      <Form.Button
                        onClick={loadRecipe}
                        type="button">Search</Form.Button>
                    </Form.Group>
                  </Form>

                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>

        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="recipe-container">
            <Grid.Row>
              <Grid.Column>
                {!isEmpty(_recipe) && !settings.fetchingRecipe ?
                  <div className="auto-calc-recipe-preview-wrapper">
                    <RecipeCardPreview recipe={_recipe}/>
                  </div> : ''}
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
}

function mapStateToProps(state) {
  //console.log("Calculator: Map State to Props", state);
  return {
    // totalRecipes: state.recipesData.totalRecipes,
    //recipes: state.recipesData.recipes,
    recipe: state.recipesData.recipe,
    // filters: state.recipesData.filters,
    settings: state.settings,
    // we need to bring in user because it is updated when the units are changed
    // which will cause the UI here to be updated
    user: state.userData,
  };
}

export default connect(mapStateToProps, { retrieveRecipe, updateUser, updateSettings })(Calculator);