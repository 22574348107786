//import Formsy from 'formsy-react-es6';
import { card, cvc, expiration } from 'creditcards';
import * as Formsy from 'formsy-react';
import { Checkbox, Dropdown, Form, Input, RadioGroup } from 'formsy-semantic-ui-react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import has from 'lodash/has';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Dimmer, Grid, Label, List, Loader, Radio } from 'semantic-ui-react';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, MIN_PASSWORD_NUMBERS, US_STATES } from '../../common/consts';
import { convertMoney, debug, hasValue, isValidPassword, showMessage, stripePublicAPIKey } from '../../common/helpers';
import { resetOrderReview, retrieveOrderReview } from '../../redux/actions/order';
import { updateSettings } from '../../redux/actions/settings';
import { createUser } from '../../redux/actions/user';
import ReactHtmlParser from 'react-html-parser';

function SignUpForm(props) {
  const { settings, order, stripe, retrieveOrderReview, resetOrderReview, createUser, user, product_id = '2' } = props;
  //console.log("sign up", props);

  const [stripeError, setStripeError] = useState({});
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [selectedProductID, setSelectedProductID] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState('');
  const [products, setProducts] = useState([]);

  const [orderState, setOrderState] = useState('review');

  const query = Object.assign({ product_id }, qs.parse(props.location.search));
  //console.log("query", query);

  const [address, setAddress] = useState({
    country: 'US'
  });

  const USStatesOptions = [{ key: null, value: null, text: 'Select State' }, ...US_STATES.map(USState => ({
    key: USState.abbreviation,
    value: USState.abbreviation,
    text: USState.name,
  }))];

  const [countriesOptions, setCountriesOptions] = useState([{
    key: null,
    value: null,
    text: 'Select Country'
  }]);

  const initReceipt = {
    subtotal: 0,
    tax: 0,
    discount: 0,
    credit: 0,
    total: 0,
    init: true,
  };

  const [receipt, setReceipt] = useState(initReceipt);
  // set the price for id 2 to nothing until we have config from server
  // (we can set additional ids here too)
  const [productPrices, setProductPrices] = useState({
    init: true,
    1: { price: '', interval: '' },
    2: { price: '', interval: '' },
    4: { price: '', interval: '' }
  });
  const [productID, setProductID] = useState(query.product_id);

  const submitOrder = (formData) => {
    // console.log("formData", formData);
    // console.log("Stripe", stripe);
    // console.log("Stripe Error", stripeError);
    debug.log({ 'formData': formData, 'Stripe': stripe, 'Stripe Error': stripeError });

    if (has(stripeError, 'code')) {
      // if we have an error code we should be showing it to the user anyway,
      // but we don't want to be able to continue until it's resolved so just return
      return;
    }

    let orderData = {
      product_id: formData.product_id,
      discount_code: formData.discount_code,
      firstname: formData.firstname,
      lastname: formData.lastname,
      phone: formData.phone,
      email: formData.email,
      company: formData.company,
      password: formData.password,
      address: {
        firstname: formData.firstname,
        lastname: formData.lastname,
        street_1: formData.street_1,
        street_2: formData.street_2,
        city: formData.city,
        state_province_region: formData.state_province_region,
        country: formData.country,
        zip: formData.zip,
      }
    };

    // if it's a free subscription...
    if (selectedProductID === 4) {
      delete orderData.discount_code;

      // call user create right away

    } else {

      switch (orderState) {
        default:
        case 'review':
          debug.log('call retrieve order review', orderData);
          setLoading(true);
          retrieveOrderReview({ data: orderData });
          break;

        case 'create':

          stripe.createToken({
            name: `${formData.firstname} ${formData.lastname}`,
            address_line1: orderData.address.street_1,
            address_city: orderData.address.city,
            address_state: orderData.address.state_province_region,
            address_zip: orderData.address.zip,
            address_county: orderData.address.country
          }).then(({ token, error }) => {
            if (error) {
              // show error
              debug.log('Stripe Error', error);
            } else {
              debug.log('Received Stripe token:', token);

              // add token.id to orderData
              orderData.stripe_token = token.id;
              // add the Stripe public api key
              orderData.stripe_public_api_key = stripePublicAPIKey();
              // call user create
              debug.log('Order Data', orderData);
              setLoading(true);
              createUser({ data: orderData });
            }
          });

          break;
      }
    }

    // Stripe.current.state.stripe.createToken({name: `${formData.firstname} ${formData.lastname}`}).then(({token, error}) => {
    // if (error)
    // {
    // do something
    // } else {
    //   console.log("Received Stripe token:", token);
    // }
    // });

    // check that formData.accept === 'accept'
    // pass info to Stripe
    // send to server
    // how do we tell them it was success
    // then what happens? do we redirect?
    //loginUser({email: formData.email, password: formData.password});
  };

  const handleSubscriptionChange = (e, { value }) => {
    //console.log("Subscription change", value);
    setProductID(value);
    orderReview();
  };

  const orderReview = (options) => {
    // submit product_id, address, code for order data
    retrieveOrderReview({
      product_id: productID,
      address,
    });

  };

  const updateProductPrices = (options = { product_ids: [query.product_id] }) => {
    // this can be changed to accommodate multiple products by passing an array of objects or something similar

    //console.log("**** updateProductPrices - query", query);
    const { product_ids } = options;

    //console.log("**** updateProductPrices - product_id", product_id);
    const products = get(settings, 'config.products', []);
    setProducts(products);

    if (products.length) {

      if (product_ids !== null) {
        let _productPrices = {};

        product_ids.forEach(product_id => {
          //console.log("product id", product_id);

          const product = find(settings.config.products, { id: parseInt(product_id) });
          //console.log("**** updateProductPrices - product", product);
          //console.log("**** updateProductPrices - address", address);
          if (product) {
            const price = product.pricing[has(product.pricing, address.country.toLowerCase()) ? address.country.toLowerCase() : 'default'].price;

            if (price) {
              //const productPrice = {};
              //productPrice[product_id] = {price: convertMoney({amount: price})};
              // setProductPrices({...productPrices, init: false, ...productPrice});
              // changeOrderState("review");
              _productPrices[product_id] = { price: convertMoney({ amount: price }), interval: product.interval };
            }
          }
        });

        if (!isEmpty(_productPrices)) {
          setProductPrices({ ...productPrices, init: false, ..._productPrices });
          changeOrderState('review');
        }
      }

    }
  };

  const changeDropdown = (e, data) => {
    //console.log(`change dropdown ${data.name}`, e, data);
    setAddress({ ...address, [data.name]: data.value });
    // after we update the address useEffect will automatically be called which will update all the address dependencies
    changeOrderState('review');
    setProductPrices({ ...productPrices, init: true });
  };

  const changeInput = (e, data) => {
    //console.log(`change input ${data.name}`, e, data);
    setAddress({ ...address, [data.name]: data.value });
    // after we update the address useEffect will automatically be called which will update all the address dependencies
    changeOrderState('review');
  };

  const changeStripe = (e) => {
    //console.log("Change Stripe", e);

    // if there's any error in the field then update the stripeError so we can check it throughout the form
    if (e.error) {

      console.log('setting stripe error', e.error);
      setStripeError(e.error);
    } else {
      console.log('no stripe error');
      setStripeError({});
    }
  };

  const changeAcceptTerms = (e, data) => {
    //console.log("changeAcceptTerms", data);
    setAcceptTerms(data.checked);
  };

  const changeValid = () => {
    //console.log("valid");
    setFormErrors('');
    setFormValid(true);
    changeOrderState('review');
  };

  const changeInvalid = () => {
    //console.log("invalid");

    const errorElements = document.getElementsByClassName('form-error');
    //console.log("errors", errorElements);

    let formErrorsText = [];

    if (errorElements.length) {
      for (let errorElement of errorElements) {
        formErrorsText = [...formErrorsText, errorElement.textContent];
      }

      setFormErrors(<List as="ul">{formErrorsText.map((formErrorText, index) => {
        //console.log("error text", formErrorText);
        return <List.Item
          as="li"
          key={index}>{formErrorText}</List.Item>;
      })}</List>);
    } else {
      setFormErrors('');
    }

    setFormValid(false);
    changeOrderState('review');
  };

  useEffect(() => {
    //console.log("useEffect: SignUp Form. Order State", orderState, "Props", props);

    // we only want to do this once
    if (get(settings, 'config.countries') && countriesOptions.length === 1) {
      setCountriesOptions([...countriesOptions, ...settings.config.countries.map(Country => ({
        key: Country.cid,
        value: Country.iso2,
        text: Country.name,
      }))]);
      //console.log("Country Options", countriesOptions);
    }

    if (productPrices.init) {
      //console.log("product id from init", query.product_id);
      // updateProductPrices({product_id: 1});
      //  updateProductPrices({product_id: query.product_id});
      updateProductPrices({ product_ids: [1, 2] });
    }

    // check order fields
    //if (order.valid) {
    if (hasValue(order.price) && hasValue(order.total)) {
      // console.log("order data", order);
      debug.log('order data', order);
      setReceipt({
        // using the price for the subtotal instead of subtotal for subtotal because price is before discount
        subtotal: order.price,
        tax: order.tax,
        discount: order.discount,
        credit: order.credit,
        total: order.total,
        init: false
      });

      changeOrderState('create');
    } else {
      setReceipt(initReceipt);
    }
    setLoading(false);

  }, [settings, order, address]);

  const changeOrderState = (newOrderState) => {
    // maybe we need to do something else here...
    if (newOrderState === 'review') {
      resetOrderReview();
    }
    setOrderState(newOrderState);
  };

  const changeSelectedProduct = (e, { value }) => {
    //  console.log("Product change", value);
    debug.log('product change', value);
    setSelectedProductID(value);
  };

  Formsy.addValidationRule('isValidCardNumber', function (values, value) {
    if (value && value.length > 12) {
      //https://github.com/bendrucker/creditcards
      const isValidCardNumber = card.isValid(value);
      //console.log("checking card number", values, value, isValidCardNumber);
      setStripeError({});
      return isValidCardNumber;
    }
    return false;
  });

  Formsy.addValidationRule('isValidCardExpiration', function (values, value) {
    if (value && value.length === 5) {
      //https://github.com/bendrucker/creditcards
      const expirationMonth = expiration.month.parse(value.substring(0, 2));
      const expirationYear = expiration.year.parse(value.substring(3, 5), true);
      const isValidCardExpiration = !expiration.isPast(expirationMonth, expirationYear);
      //console.log("expiration", expirationMonth, expirationYear, value, isValidCardExpiration);
      setStripeError({});
      return isValidCardExpiration;
    }
    return false;
  });

  Formsy.addValidationRule('isValidCardCVC', function (values, value) {
    if (value && value.length >= 3) {
      //https://github.com/bendrucker/creditcards
      const isValidCardCVC = cvc.isValid(value);
      //console.log("checking card CVC", values, value, isValidCardCVC);
      setStripeError({});
      return isValidCardCVC;
    }
    return false;
  });

  Formsy.addValidationRule('isValidPassword', function (values, value) {
    //console.log("password validation", values, value);
    // const hasNumber = /\d/;
    // const hasLetter = /[a-z]/i;
    // if (value && value.length >= MIN_PASSWORD_LENGTH && value.length <= MAX_PASSWORD_LENGTH && hasNumber.test(value) && hasLetter.test(value)) {
    //   return true;
    // }
    //return false;

    return isValidPassword(value);

  });

  const errorLabel = <Label
    className="form-error"
    color="red"
    pointing/>;

  return (
    <React.Fragment>{!isEmpty(formErrors) ? showMessage({
      message: formErrors,
      messageHeader: 'Form Errors',
      isError: true
    }) : ''}
      <Form
        size="large"
        className="signup-content__form"
        onValid={changeValid}
        onInvalid={changeInvalid}
        onValidSubmit={submitOrder}
        loading={settings.fetchingOrder}>
        <Grid.Row>
          <Dimmer
            active={settings.fetchingOrder || settings.creatingUser}
            inverted>
            <Loader size="large">Loading</Loader>
          </Dimmer>
          <Grid
            className="signup-content__form-columns"
            columns={3}
            stackable
            divided>
            <Grid.Row>
              {/* ------------------------------------- COLUMN 1 ------------------------------------- */}
              <Grid.Column className="signup__info-column">
                <div className="signup__info-column-title">BILLING ADDRESS & CONTENT</div>
                <Form.Field>
                  <label>First Name <span className="red">*</span></label>
                  <Input
                    placeholder="First Name"
                    name="firstname"
                    value={user.firstname || ''}
                    required
                    validationErrors={{
                      isDefaultRequiredValue: 'First Name is Required'
                    }}
                    instantValidation
                    errorLabel={errorLabel}/>
                </Form.Field>
                <Form.Field>
                  <label>Last Name <span className="red">*</span></label>
                  <Input
                    placeholder="Last Name"
                    name="lastname"
                    value={user.lastname || ''}
                    required
                    validationErrors={{
                      isDefaultRequiredValue: 'Last Name is Required'
                    }}
                    instantValidation
                    errorLabel={errorLabel}/>
                </Form.Field>
                <Form.Field>
                  <label>Company</label>
                  <Input
                    placeholder="Company"
                    name="company"
                    value={user.company || ''}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Street <span className="red">*</span></label>
                  <Input
                    placeholder="Street"
                    name="street_1"
                    required
                    validationErrors={{
                      isDefaultRequiredValue: 'Street is Required'
                    }}
                    instantValidation
                    value={user.addresses && user.addresses[0] && user.addresses[0].street_1 || ''}
                    errorLabel={errorLabel}/>
                </Form.Field>

                <Form.Field>
                  <label>Apt. / Suite #</label>
                  <Input
                    placeholder="Apt. / Suite #"
                    name="street_2"
                    value={user.addresses && user.addresses[0] && user.addresses[0].street_2 || ''}
                    validations="maxLength:40"
                    instantValidation
                  />
                </Form.Field>

                <Form.Field>
                  <label>Country <span className="red">*</span></label>
                  <Dropdown
                    name="country"
                    placeholder="Select Country"
                    search
                    defaultValue={address.country}
                    required
                    validationErrors={{
                      isDefaultRequiredValue: 'You need to select a Country',
                    }}
                    instantValidation
                    errorLabel={errorLabel}
                    options={countriesOptions}
                    onChange={changeDropdown}
                    value={user.addresses && user.addresses[0] &&  user.addresses[0].country || ''}
                  />
                </Form.Field>

                <Form.Field>
                  <label>City <span className="red">*</span></label>
                  <Input
                    placeholder="City"
                    name="city"
                    validations="maxLength:30"
                    instantValidation
                    required
                    validationErrors={{
                      isDefaultRequiredValue: 'You must enter a City'
                    }}
                    value={user.addresses && user.addresses[0] && user.addresses[0].city || ''}
                    errorLabel={errorLabel}/>
                </Form.Field>

                <Form.Field>
                  <label>State <span className="red">*</span></label>
                  {address.country === 'US' ?
                    (<Dropdown
                      name="state_province_region"
                      placeholder="Select a State"
                      search
                      required
                      validationErrors={{
                        isDefaultRequiredValue: 'You need to select a State',
                      }}
                      instantValidation
                      errorLabel={errorLabel}
                      options={USStatesOptions}
                      onChange={changeDropdown}
                      value={user.addresses && user.addresses[0] && user.addresses[0].state_province_region || ''}
                    />)
                    :
                    (<Input
                      placeholder="State / Province / Region"
                      name="state_province_region"
                      required
                      validations="maxLength:30"
                      validationErrors={{
                        isDefaultRequiredValue: 'You must enter a State / Province / Region'
                      }}
                      instantValidation
                      value={user.addresses && user.addresses[0] && user.addresses[0].state_province_region || ''}
                      errorLabel={errorLabel}/>)}
                </Form.Field>

                <Form.Field>
                  <label>Zip / Postal Code <span className="red">*</span></label>
                  <Input
                    placeholder="Zip / Postal Code"
                    name="zip"
                    validations="maxLength:50"
                    required
                    validationErrors={{
                      isDefaultRequiredValue: 'You must enter a Zip / Postal Code'
                    }}
                    instantValidation
                    errorLabel={errorLabel}
                    onChange={changeInput}
                    value={user.addresses && user.addresses[0] && user.addresses[0].zip || ''}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Phone (with area code) <span className="red">*</span></label>
                  <Input
                    placeholder="XXX XXX-XXXX"
                    name="phone"
                    required
                    validationErrors={{
                      isDefaultRequiredValue: 'Phone is Required'
                    }}
                    instantValidation
                    value={user.addresses && user.addresses[0] && user.addresses[0].phone || ''}
                    errorLabel={errorLabel}/>
                </Form.Field>
                <Form.Field>
                  <label>Email <span className="red">*</span></label>
                  <Input
                    placeholder="Email"
                    name="email"
                    value={user.email || ''}
                    required
                    validations="isEmail"
                    validationErrors={{
                      isEmail: 'Email not valid',
                      isDefaultRequiredValue: 'Email is Required'
                    }}
                    instantValidation
                    errorLabel={errorLabel}/>
                </Form.Field>

                <Form.Field>
                  <label>Password <span className="red">*</span></label>
                  <Input
                    type="password"
                    placeholder="******"
                    name="password"
                    required
                    validations="isValidPassword"
                    validationErrors={{
                      //minLength: "Must be at least 8 characters long",
                      isValidPassword: `Password must be between ${MIN_PASSWORD_LENGTH} and ${MAX_PASSWORD_LENGTH} characters long and contain ${MIN_PASSWORD_NUMBERS} number`,
                      isDefaultRequiredValue: 'Password is Required'
                    }}
                    instantValidation
                    errorLabel={errorLabel}/>
                </Form.Field>

                <Form.Field>
                  <label>Confirm Password <span className="red">*</span></label>
                  <Input
                    type="password"
                    placeholder="******"
                    name="confirm_password"
                    required
                    validations="equalsField:password"
                    validationErrors={{
                      equalsField: 'Must match the Password',
                      isDefaultRequiredValue: 'Confirm Password is Required'
                    }}
                    instantValidation
                    errorLabel={errorLabel}/>
                </Form.Field>

              </Grid.Column>
              {/* ------------------------------------- COLUMN 2 ------------------------------------- */}
              <Grid.Column className="signup__info-column">
                <div className="signup__info-column-title">PAYMENT INFO</div>
                <Form.Field>
                  <label>Card Number <span className="red">*</span></label>
                  <CardElement
                    onChange={changeStripe}
                    hidePostalCode
                    style={{
                      base: {
                        fontFamily: '"Open Sans", Helvetica, sans-serif',
                        fontSize: '15px',
                        // lineHeight: "1.6",
                        '::placeholder': {
                          color: '#DEDEDE',
                        },
                      },
                    }}
                    elementRef={StripeElement => {
                      props.Stripe.StripeElement = StripeElement;
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              {/* ------------------------------------- COLUMN 3 ------------------------------------- */}
              <Grid.Column className="signup__info-column">
                <div className="signup__info-column-title">SUBSCRIPTION INFO</div>
                <Form.Field>
                  <label>Choose a Subscription <span className="red">*</span></label>
                  <RadioGroup
                    name="product_id"
                    className="products"
                    required
                    defaultSelected={query.product_id}
                    validationErrors={{
                      isDefaultRequiredValue: 'Subscription is Required'
                    }}
                    instantValidation
                    onChange={handleSubscriptionChange}
                  >
                    {/*<Radio label="Lite" value="individual_lite"/>*/}
                    {/*<Radio label={`Test Drive ${productPrices[4].price}`} value="4" onChange={changeSelectedProduct}/>*/}
                    <Radio
                      label={`Lite ${productPrices[1].price} ${productPrices[1].interval}`}
                      value="1"
                      onChange={changeSelectedProduct}/>
                    {/*<div className="description">For enthusiasts with machines <strong>up to 3hp</strong>.</div>*/}
                    <div
                      className="description">{!isEmpty(products) && !isEmpty(find(products, { id: 1 }).description) ? ReactHtmlParser(find(products, { id: 1 }).description) : ''}</div>
                    <Radio
                      label={`Pro ${productPrices[2].price} ${productPrices[2].interval}`}
                      value="2"
                      onChange={changeSelectedProduct}/>
                    {/*<div className="description">For professionals who need <strong>Unlimited Access</strong>.</div>*/}
                    <div
                      className="description">{!isEmpty(products) && !isEmpty(find(products, { id: 2 }).description) ? ReactHtmlParser(find(products, { id: 2 }).description) : ''}</div>
                  </RadioGroup>
                </Form.Field>

                <div className="description"><a
                  href="/news/provencut-lite-versus-pro/"
                  rel="noopener"
                  target="_blank">Click
                  here</a> to
                  learn more about our subscriptions.
                </div>

                <Form.Group inline>
                  <Form.Field>
                    <label>Code</label>
                    <Input
                      placeholder="Code"
                      name="discount_code"
                      onChange={changeInput}
                    />
                  </Form.Field>
                  {/*<Form.Field width={10}>*/}
                  {/*<label>Code</label>*/}
                  {/*<Input*/}
                  {/*placeholder='Code'*/}
                  {/*name="discount_code"*/}
                  {/*width={16}*/}
                  {/*/>*/}
                  {/*</Form.Field>*/}
                  {/*<Form.Field width={6}>*/}
                  {/*<Button*/}
                  {/*onClick={clickApplyDiscountCode}*/}
                  {/*>Apply</Button>*/}
                  {/*</Form.Field>*/}
                </Form.Group>

                <Grid.Row className="signup__auto-rebill text">
                  Automatically Renews / Cancel Renewal Anytime
                </Grid.Row>

                <Grid.Row className="signup__accept text">
                  <Form.Group inline>
                    <Checkbox
                      name="accept_terms"
                      required
                      onClick={changeAcceptTerms}
                      validations="isTrue"
                      validationErrors={{
                        isTrue: 'This is non-negotiable',
                        isDefaultRequiredValue: 'You\'ll have to check this box',
                      }}
                      instantValidation
                      errorLabel={<Label
                        color="red"
                        className="form-error"
                        pointing="left"/>}
                    />

                    <label>I agree to Proven Cut <a
                      href="/terms-of-service"
                      rel="noopener noreferrer"
                      target="_blank">Terms
                      & Conditions </a>
                      and <a
                        href="/privacy-policy"
                        rel="noopener noreferrer"
                        target="_blank">Privacy
                        Policy</a></label>
                  </Form.Group>
                </Grid.Row>

                {!receipt.init ? (<div>
                  <Grid.Row className="signup__subtotal text">
                    {`Subtotal $${receipt.subtotal}`}
                  </Grid.Row>

                  {receipt.discount > 0 ?
                    <Grid.Row className="signup__discount text">
                      {`Discount -$${receipt.discount}`}
                    </Grid.Row> : ''}

                  {receipt.credit > 0 ?
                    <Grid.Row className="signup__credtit text">
                      {`Credit -$${receipt.credit}`}
                    </Grid.Row> : ''}

                  {receipt.tax > 0 ?
                    <Grid.Row className="signup__tax text">
                      {`Tax $${receipt.tax}`}
                    </Grid.Row> : ''}

                  <Grid.Row className="signup__total text">
                    {`Total $${receipt.total}`}
                  </Grid.Row></div>) : ''}

                <Grid.Row>
                  {get(settings, 'config.recaptcha_key') !== undefined ?
                    <GoogleReCaptchaProvider reCaptchaKey={settings.config.recaptcha_key}>
                      <GoogleReCaptcha onVerify={token => debug.log('recaptcha token', token)}/>
                    </GoogleReCaptchaProvider> : ''}
                  {/*{console.log("submit button: loading", settings.fetchingOrder || settings.creatingUser, "acceptTerms", acceptTerms, "stripe error", has(stripeError, "code"))}*/}
                  <Form.Button
                    disabled={!formValid || loading || settings.fetchingOrder || settings.creatingUser || !acceptTerms || has(stripeError, 'code')}
                    content={orderState === 'review' ? 'Review' : 'Signup'}
                    className="signup-form__signup-button"/>
                </Grid.Row>
              </Grid.Column>

            </Grid.Row>

          </Grid>

        </Grid.Row>
      </Form></React.Fragment>);
}

function mapStateToProps(state) {
  //console.log("SignUp: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    order: state.orderData,
  };
}

export default injectStripe(connect(mapStateToProps, {
  updateSettings,
  retrieveOrderReview,
  resetOrderReview,
  createUser
})(withRouter(SignUpForm)));
