import isEmpty from "lodash/isEmpty";
import React from "react";
import { connect } from "react-redux";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import Footer from "./Footer";
import PageHeader from "./PageHeader";

// https://www.npmjs.com/package/react-scroll-up-button
function Layout (props) {
  const {children, settings} = props;
  //console.log("LAYOUT", props);

  return (<React.Fragment>
      <PageHeader/>
      {!isEmpty(settings) ? children : <div>Loading</div>}
      <ScrollUpButton
        ContainerClassName="ScrollUpButton__Container"
        TransitionClassName="ScrollUpButton__Toggled"
        style={{borderColor: "#2D98CF"}}
      />
      <Footer/>
    </React.Fragment>
  );
}

function mapStateToProps (state) {
  //console.log("Layout: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, {})(Layout);