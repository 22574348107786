import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Grid, Loader } from "semantic-ui-react";
import { retrieveRecipes } from "../../redux/actions/recipes";

function RecipesPagination (props) {
  const {recipes, totalPages, totalRecipes, settings, retrieveRecipes} = props;

  const [requestingRecipes, setRequestingRecipes] = useState(false);

  useEffect(() => {
    //console.log("useEffect: Recipe Pagination", props);

    if (requestingRecipes) {
      setTimeout(function () {
        //window.scrollBy(0, 200);
        //window.scrollTo(0, document.body.scrollHeight);
      }, 1000);
    }

    // we only want to allow fetching more recipes after we've received an updated recipe set
    setRequestingRecipes(false);

  }, [recipes]);

  /*
  const onUpdate = (e, {calculations}) => {
    //console.log("Visibility Calcs", calculations);

    if (calculations.bottomVisible) {
      loadMoreRecipes();
    }
  };
*/

  const loadMoreRecipes = () => {
    //console.log("Load More", props, requestingRecipes);
    if (settings.currentPage < totalPages && !settings.fetchingRecipes && !requestingRecipes) {
      //console.log("LOADING MORE RECIPES");
      setRequestingRecipes(true);
      retrieveRecipes({data: {load_more: true, page: (settings.currentPage + 1)}});
    }
  };

  return (totalRecipes > 1 && recipes.length < totalRecipes ?
      <Grid className="recipes-container-pagination">
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Loader
              active={settings.fetchingRecipes}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Button onClick={loadMoreRecipes} disabled={settings.fetchingRecipes}>Load More </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      : <div></div>
  );

  /*
  <Visibility onUpdate={onUpdate}>
      <div className="recipes-container-pagination">
        <Loader active={settings.fetchingRecipes} inline='centered'/>
      </div>
    </Visibility>
   */
}

function mapStateToProps (state) {
  //console.log("Pagination: Map State to Props", state);
  return {
    recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    totalPages: state.recipesData.totalPages,
    totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, {retrieveRecipes})(RecipesPagination);