import React from 'react';
import { connect } from 'react-redux';
import { retrieveParts } from '../../redux/actions/parts';
import { updateSettings } from '../../redux/actions/settings';
import { createUserFilters } from '../../redux/actions/userFilters';
import { Button, Grid } from 'semantic-ui-react';
import { debug } from '../../common/helpers';
import PartListFilter from './PartListFilter';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

function PartFilters(props) {
  const { selectedFilters, updateSettings, retrieveParts } = props;

  const resetFilters = () => {
    //setFiltersState(old => (initialFiltersState));

    updateSettings({
      activePartFilters: {},
      previewPartID: 0,
    });
    retrieveParts();
  };

  function shouldDisableFilters() {
    debug.log('selected filters', selectedFilters);
    return !(selectedFilters && !isEmpty(get(selectedFilters, 'part_types.ids')));
  }

  return (
    <div className="filters">
      <div className="filters-top">
        <Grid
          className="filters__heading"
          columns={2}>
          <Grid.Row className="filters__user-recipes">
            <Grid.Column
              width={8}
              className="heading">FILTERS</Grid.Column>
            <Grid.Column
              width={8}
              align="right">
              <Button
                onClick={resetFilters}
                className="link">Clear All</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="filters-list">
        {/*<PartListFilter path="id" property="id" className="recipe-id" title="Recipe ID" defaultToTextField/> */}

        <PartListFilter
          path="machines.ids"
          property="name"
          className="machine-ids"
          title="Machine"
          showSearch={true}
          initOpen={true}
          disabled={shouldDisableFilters()}/>

        <PartListFilter
          path="materials.ids"
          property="name"
          className="material-ids"
          title="Material"
          showSearch={true}
          initOpen={true}
          disabled={shouldDisableFilters()}/>
        {/*

        - if selectedFilters.part_types.ids is not empty (a part_type has been selected) so enabled each of these filters
        - use an enable attribute

        */}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  //console.log("Recipes: Map State to Props", state);
  return {
    parts: state.partsData.parts,
    filters: state.partsData.filters,
    selectedFilters: state.partsData.selectedFilters,
    settings: state.settings,
    user: state.userData,
    //userTools: state.userToolsData,
  };
}

export default connect(mapStateToProps, { retrieveParts, updateSettings, createUserFilters })(PartFilters);