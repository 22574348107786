import {
  CREATED_USER_FILTERS,
  DELETED_USER_FILTERS,
  FOUND_USER_FILTERS,
  TOGGLE_USER_FILTERS,
  UPDATED_USER_FILTERS
} from "../../common/consts";
import { debug } from "../../common/helpers";
import api from "../api";
import { updateSettings } from "./settings";

export const findUserFilters = userFiltersCriteria => dispatch => {
  window.store.dispatch(updateSettings({fetchingUserFilters: true}));
  api.user.user_filters(userFiltersCriteria).then(userFiltersResponse => {
      //console.log("Finding User Filters: response", userFiltersResponse);
      debug.log(userFiltersResponse, "Finding User Filters: response");
      window.store.dispatch(updateSettings({fetchingUserFilters: false}));
      dispatch(foundUserFilters(userFiltersResponse));
    }
  );
};

export const foundUserFilters = userFiltersCriteria => ({
  type: FOUND_USER_FILTERS,
  criteria: userFiltersCriteria
});

export const createUserFilters = userFiltersCriteria => dispatch => {
  window.store.dispatch(updateSettings({creatingUserFilters: true}));
  api.user.create_user_filters(userFiltersCriteria).then(userFiltersResponse => {
      // console.log("Create User Filters: response", userFiltersResponse);
      debug.log(userFiltersResponse, "Create User Filters: response");
      window.store.dispatch(updateSettings({creatingUserFilters: false}));
      dispatch(createdUserFilters(userFiltersResponse));
    }
  );
};

export const createdUserFilters = userFiltersResponse => ({
  type: CREATED_USER_FILTERS,
  criteria: userFiltersResponse
});

export const deleteUserFilters = userFiltersCriteria => dispatch => {
  window.store.dispatch(updateSettings({deletingUserFilters: true}));
  api.user.delete_user_filters(userFiltersCriteria).then(userFiltersResponse => {
      // console.log("Delete User Filters: response", userFiltersResponse);
      debug.log(userFiltersResponse, "Delete User Filters: response");
      window.store.dispatch(updateSettings({deletingUserFilters: false}));
      dispatch(deletedUserFilters(userFiltersResponse));
    }
  );
};

export const deletedUserFilters = userFiltersResponse => ({
  type: DELETED_USER_FILTERS,
  criteria: userFiltersResponse
});

export const updateUserFilters = userFiltersCriteria => dispatch => {
  window.store.dispatch(updateSettings({updatingUserFilters: true}));
  api.user.update_user_filters(userFiltersCriteria).then(userFiltersResponse => {
      debug.log(userFiltersResponse, "Update User Filters: response");
      window.store.dispatch(updateSettings({updatingUserFilters: false}));
      dispatch(updatedUserFilters(userFiltersResponse));
    }
  );
};

export const updatedUserFilters = userFiltersResponse => ({
  type: UPDATED_USER_FILTERS,
  criteria: userFiltersResponse
});

export const toggleUserFilters = toggle => ({
  type: TOGGLE_USER_FILTERS,
  toggle
});