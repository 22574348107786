import {
  CREATED_GROUP_MEMBER,
  FOUND_GROUP_MEMBERS,
  RESENT_GROUP_MEMBER_INVITE,
  UPDATED_GROUP_MEMBER
} from "../../common/consts";
import findIndex from "lodash/findIndex";
import { checkInvalidSessionID, debug, hasValue } from "../../common/helpers";

const groupsData = (state = {}, action = {}) => {
  //debug.log("Groups Reducers", state, action);
  if (checkInvalidSessionID({action})) {
    return {
      ...state
    };
  }

  switch (action.type) {
    case FOUND_GROUP_MEMBERS:
      debug.log("Found Group Members", state, action);

      return {
        ...processGroups(state, action),
        members: {
          ...state.members,
          [action.criteria.response.group.id]: action.criteria.response.users
        },
      };

    // return {
    //   ...state,
    //   members: {
    //     ...state.members,
    //     [action.criteria.response.group.id]: action.criteria.response.users
    //   },
    //   counts: {
    //     ...state.counts,
    //     [action.criteria.response.group.id]: {
    //       // these properties are dynamic and will change as users are updated so we need to keep them up-to-date
    //       active_group_members_count: action.criteria.response.group.active_group_members_count,
    //       group_members_count: action.criteria.response.group.group_members_count
    //     }
    //   }
    // };

    case UPDATED_GROUP_MEMBER:
      debug.log("Updated Group Member", state, action);

      if (state.members !== null) {
        const groupMembers = state.members[action.criteria.response.group.id];
        const groupMemberIndex = findIndex(groupMembers, {"uid": action.criteria.response.user.uid});
        //debug.log("group", group, "member index", groupMemberIndex);
        if (action.criteria.response.user.removed) {
          delete state.members[action.criteria.response.group.id][groupMemberIndex];
        } else {
          state.members[action.criteria.response.group.id][groupMemberIndex] = action.criteria.response.user;
        }
      }

      return {
        ...processGroups(state, action)
      };
    // return {
    //   ...state,
    //   counts: {
    //     ...state.counts,
    //     [action.criteria.response.group.id]: {
    //       // these properties are dynamic and will change as users are updated so we need to keep them up-to-date
    //       active_group_members_count: action.criteria.response.group.active_group_members_count,
    //       group_members_count: action.criteria.response.group.group_members_count
    //     }
    //   }
    // };

    case CREATED_GROUP_MEMBER:
      debug.log("Created Group Member", state, action);

      if (state.members !== null && action.criteria.result !== "failed") {
        //const groupMembers = state.members[action.criteria.response.group.id];
        state.members[action.criteria.response.group.id].push(action.criteria.response.user);
      }

      return {
        ...processGroups(state, action),
        createGroupMemberMessage: true,
      };
    // return {
    //   ...state,
    //   counts: {
    //     ...state.counts,
    //     [action.criteria.response.group.id]: {
    //       // these properties are dynamic and will change as users are updated so we need to keep them up-to-date
    //       active_group_members_count: action.criteria.response.group.active_group_members_count,
    //       group_members_count: action.criteria.response.group.group_members_count
    //     }
    //   },
    //   createGroupMemberMessage: true,
    // };

    case RESENT_GROUP_MEMBER_INVITE:
      debug.log("Resent Group Member Invite", state, action);

      return {
        ...processGroups(state, action),
      };

    default:
      return {
        ...state,
        members: (state.members === null) ? null : state.members,
        info: (state.info === null) ? null : state.info
      };
  }

  function processGroups (state, action) {
    debug.log("process groups", state, action);

    let _return = {
      ...state,
      result: action.criteria.result,
      info: {
        ...state.info,
        [action.criteria.response.group.id]: {
          // these properties are dynamic and will change as users are updated so we need to keep them up-to-date
          active_group_members_count: action.criteria.response.group.active_group_members_count,
          inactive_group_members_count: action.criteria.response.group.inactive_group_members_count,
          max_inactive_group_members: action.criteria.response.group.max_inactive_group_members,
          group_members_count: action.criteria.response.group.group_members_count,
          seats: action.criteria.response.group.seats,
        }
      }
    };

    if (hasValue(action.criteria.response.message)) {
      _return.message = action.criteria.response.message;
    }

    if (hasValue(action.criteria.response.group)) {
      _return.group_id = action.criteria.response.group.id;
    }

    if (hasValue(action.criteria.response.group_id)) {
      _return.group_id = action.criteria.response.group_id;
    }

    debug.log("process groups return", _return);
    return _return;
  }
};

export default groupsData;