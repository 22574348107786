import { Form, Input } from "formsy-semantic-ui-react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import React, { useEffect } from "react";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid, Header, Label, Message } from "semantic-ui-react";
import {
  DEFAULT_AUTHENTICATED_ROUTE,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  MIN_PASSWORD_NUMBERS
} from "../../common/consts";
import { isAuthenticated } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
import { resetPassword } from "../../redux/actions/user";
import Layout from "../global/Layout";
import * as Formsy from "formsy-react";

function LogIn (props) {
  const {settings, user, resetPassword} = props;

  const parsedURLParams = queryString.parse(props.location.search);

  useEffect(() => {
    //console.log("useEffect: LogIn", props);

    // if (settings.activeMenuItem !== "login") {
    //   updateSettings({activeMenuItem: "login"});
    // }
  }, [settings, user]);

  //const [formState, setOrderState] = React.useState({sort: settings.defaultSort, order: "asc"});

  const attemptResetPassword = (formData) => {
    //console.log(formData);

    resetPassword({password: formData.password, reset_token: formData.reset_token});
  };

  const errorLabel = <Label color="red" pointing/>;

  // Formsy
  // https://github.com/christianalfoni/formsy-react/blob/master/API.md
  Formsy.addValidationRule("isValidPassword", function (values, value) {
    //console.log("password validation", values, value);
    const hasNumber = /\d/;
    if (value && value.length >= MIN_PASSWORD_LENGTH && value.length <= MAX_PASSWORD_LENGTH && hasNumber.test(value)) {
      return true;
    }
    return false;
  });

  //console.log("params", parsedURLParams);

  if (user.reset_password) {
    return (<Redirect to="/login"/>);
  }

  return (
    isAuthenticated() ? <Redirect to={DEFAULT_AUTHENTICATED_ROUTE}/> : isEmpty(parsedURLParams.reset_token) ? <Redirect
        to="/"/> :
      <Layout>
        <Grid className="reset-password-content content">
          <Grid.Row className="reset-password-content__heading" centered>
            <Grid.Column className="reset-password-content__header" width={5}><Header as="h1">Reset Password</Header>
              {/*<p>Already have an account? <a href="/login">Login</a> here.</p>*/}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="reset-password-content__form" centered>
            <Grid.Column width={5}>
              {user.failed === true ?
                <Message
                  error
                  header='You have entered an invalid username or password'
                  list={[
                    "Please try re-entering your username and password",
                  ]}
                /> : ""}
              <Form size="large" className="reset-password-form" onValidSubmit={attemptResetPassword}
                    loading={settings.resettingPassword}>
                <Form.Field>
                  <label>New Password <span className="red">*</span></label>
                  <Input type="password" placeholder='******' name="password" required
                         validations="minLength:8" validationErrors={{
                    //minLength: "Must be at least 8 characters long",
                    isValidPassword: `Password must be between ${MIN_PASSWORD_LENGTH} and ${MAX_PASSWORD_LENGTH} characters long and contain ${MIN_PASSWORD_NUMBERS} number`,
                    isDefaultRequiredValue: "Password is Required"
                  }}
                         errorLabel={errorLabel}/>
                  <Input type="hidden" name="reset_token" value={parsedURLParams.reset_token}/>
                </Form.Field>

                {get(settings, "config.recaptcha_key") !== undefined ?
                  <GoogleReCaptchaProvider reCaptchaKey={settings.config.recaptcha_key}>
                    <GoogleReCaptcha onVerify={token => console.log(token)}/>
                  </GoogleReCaptchaProvider> : ""}
                <Form.Button content='Reset Password' className="reset-password-form__signin-button"/>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Layout>);
}

function mapStateToProps (state) {
  //console.log("LogIn: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
  };
}

export default connect(mapStateToProps, {updateSettings, resetPassword})(LogIn);