import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { retrieveParts } from '../../redux/actions/parts';
import { updateSettings } from '../../redux/actions/settings';
import { createUserFilters } from '../../redux/actions/userFilters';
import { Card, Grid, Header, Image } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import { debug } from '../../common/helpers';
import classNames from 'classnames/bind';
import set from 'lodash/set';
import get from 'lodash/get';
import includes from 'lodash/includes';

function PartTypeFilter(props) {
  const { settings, filters, show, selectedFilters, updateSettings, retrieveParts } = props;

  const [showPartTypeFilter, setShowPartTypeFilter] = useState(true);

  useEffect(() => {

    if (showPartTypeFilter && selectedFilters && !isEmpty(get(selectedFilters, 'part_types.ids'))) {
      setShowPartTypeFilter(false);
    }
    //setShowPartTypeFilter(selectedFilters && !isEmpty(get(selectedFilters, "part_types.ids")));

  }, [selectedFilters]);

  function clickPartType(e, id) {
    e.preventDefault();

    updateSettings({
      activePartFilters: set(settings.activePartFilters, 'part_types.ids', [id])
    });

    retrieveParts();
  }

  function clickShowPartTypeFilter() {
    setShowPartTypeFilter(true);
  }

  useEffect(() => {
    if (isEmpty(settings.activePartFilters)) {
      setShowPartTypeFilter(true);
    }
  }, [settings]);

  return (
    <React.Fragment>
      {/*{selectedFilters && isEmpty(get(selectedFilters, "part_types.ids") )*/}
      {/*&& <Header as='h1' className="part-type-filter-container__select-part-type">SELECT A PART-TYPE TO*/}
      {/*  BEGIN</Header>}*/}

      {/*{selectedFilters && !isEmpty(get(selectedFilters, "part_types.ids")) &&*/}

      {(filters.part_types.ids.length === 0) && <Header
        as='h1'
        className="part-type-filter-container__no-parts">NO
        PARTS</Header>}

      {/*{debug.log("showPartTypeFilter", showPartTypeFilter)}*/}
      {(filters.part_types.ids.length > 0) && !showPartTypeFilter &&
      <div
        className={classNames('collapsable-container', { 'collapsable-container--hide': show })}
        onClick={clickShowPartTypeFilter}>
        <div className="collapsable-container__label">{`${(show ? 'Hide Part Types' : 'Show Part Types')} `}</div>
        <div className="collapsable-container__divider"></div>
      </div>

        // <Grid className={classNames("collapsable-container", {"collapsable-container--hide": show})} columns={2}>
        //   <Grid.Row>
        //     <Grid.Column width={3} className="collapsable-container__label"
        //                  onClick={clickShowPartTypeFilter}>{`${(show ? "Hide Part Types" : "Show Part Types")} `}</Grid.Column>
        //     <Grid.Column width={13} className="collapsable-container__divider" align="right">
        //     </Grid.Column>
        //   </Grid.Row>
        // </Grid>

      }

      {(filters.part_types.ids.length > 0) && showPartTypeFilter &&
      <React.Fragment>
        <Header
          as='h1'
          className="part-type-filter-container__select-part-type">SELECT A PART-TYPE TO BEGIN</Header>
        <Card.Group
          className="part-type-filter-container-cards"
          itemsPerRow={3}>
          {/*{debug.log("part types", filters.part_types)}*/}
          {debug.log('activePartFilters', settings.activePartFilters)}
          {debug.log('selected filters', selectedFilters)}

          {filters.part_types.ids.map((part_type, index) => {
            const part_type_selected = selectedFilters && !isEmpty(get(selectedFilters, 'part_types.ids')) && includes(selectedFilters.part_types.ids, part_type.id);

            return (<Card
              id={`part-type-id--${part_type.id}`}
              part-type-id={part_type.id}
              key={part_type.id}
              className={classNames('part-type-card', 'part-type', `part-type-card--${index}`, `part-type--${part_type.id}`,
                {
                  'part-type-card--selected': part_type_selected,
                  // "part_type-card--free-access": free_user_access_recipe
                })}>
              <Card.Content className="part-type-card__content">
                <div className="part-type-card__thumbnail"><Image
                  src={part_type.image.thumb}
                  as='div'
                  size='medium'
                  onClick={(e) => clickPartType(e, part_type.id)}
                /></div>
                <Grid
                  columns={2}
                  className="part-type-bar">
                  <Grid.Row>
                    <Grid.Column
                      width={10}
                      floated='left'
                      className="part-type-bar__name">{part_type.name}</Grid.Column>
                    <Grid.Column
                      width={6}
                      floated='right'
                      className=""></Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>);
          })}
        </Card.Group></React.Fragment>}
    </React.Fragment>);
}

function mapStateToProps(state) {
  //console.log("Recipes: Map State to Props", state);
  return {
    //totalParts: state.recipesData.totalRecipes,
    parts: state.partsData.parts,
    filters: state.partsData.filters,
    selectedFilters: state.partsData.selectedFilters,
    settings: state.settings,
    user: state.userData,
    //userTools: state.userToolsData,
  };
}

export default connect(mapStateToProps, { retrieveParts, updateSettings, createUserFilters })(PartTypeFilter);