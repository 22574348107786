import axios from 'axios';
import queryString from 'query-string';
import { apiURL, baseParams } from '../common/consts';
import { debug, isAuthenticated } from '../common/helpers';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export default {
  config: {
    retrieve: (options) => {
      debug.log('API: Retrieving Config', options);

      // TEMP
      /*
      return axios
        .get("/data/dev_config.json")
        .then(response => response.data);

      if (process.env.NODE_ENV === "development") {
        return axios
          .get("/data/dev_config.json")
          .then(response => response.data);
      }
      */
      // if we are in a production environment we should
      // load the data from a local variable which we create by PHP on page load

      const params = {
        ...baseParams({ skip_session_id: true }),
      };
      return axios.get(`${apiURL}config/retrieve?${queryString.stringify(params)}`).then(response => response.data.config);
      //return new Promise(resolve => resolve(window.config));
    }
  },

  recipe: {
    retrieve: (options = { data: {} }) => {
      debug.log('API: Retrieving Recipe', options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
      };

      if (isAuthenticated()) {
        params.auth_token = globalState.userData.auth_token;
      }
      debug.log('Recipe Retrieve: Params', params);

      const data = {
        filters: {
          id: options.id
        },
        //"page": 1,
        //"sort": "rid",
        //"direction": "desc",
        //filters: globalState.settings.activeFilters,
        ...options.data
      };

      if (!isEmpty(options.user)) {
        data.filters.user = options.user;
      }

      debug.log('Recipe Retrieve: Data', data);
      return axios.post(`${apiURL}recipe/search?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },
    viewed: (options = { data: {} }) => {
      // 5/21/19 not using this yet since we can flag the retrieve method
      // to force the backend to increment the recipe viewed count

      debug.log('API: Viewing Recipe', options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
      };

      if (isAuthenticated()) {
        params.auth_token = globalState.userData.auth_token;
      }
      debug.log('Recipe Viewed: Params', params);

      const data = {
        id: options.id,
        ...options.data
      };
      debug.log('Recipe Viewed: Data', data);
      return axios.post(`${apiURL}recipe/viewed?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    }
  },

  recipes: {
    retrieve: (options = { data: {} }) => {
      //console.log("API: Retrieving Recipes", options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
      };

      if (isAuthenticated()) {
        //console.log("retrieving recipes as authed");
        params.auth_token = globalState.userData.auth_token;
      }
      debug.log('Recipes Retrieve: Params', params);

      //if (!options.data) options.data = {};

      const data = {
        'page': 1,
        //"sort": "rid",
        //"direction": "desc",
        filters: globalState.settings.activeRecipeFilters,
        sort_order: globalState.settings.activeRecipeSort,
        direction: globalState.settings.activeRecipeSortDirection,
        ...options.data
      };

      // we don't want this cause this will only show recipes that are flagged with a tier that matches the user
      // if we have a user and the filter tier is not set, set it now
      // if (!isEmpty(globalState.userData) && isEmpty(data.filters.tier)) {
      //   data.filters.tier = globalState.userData.tier;
      // }

      debug.log('Recipes Retrieve: Data', data);
      return axios.post(`${apiURL}recipe/search?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    }
  },

  part: {
    retrieve: (options = { data: {} }) => {
      debug.log('API: Retrieving Part', options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
      };

      if (isAuthenticated()) {
        params.auth_token = globalState.userData.auth_token;
      }
      debug.log('Part Retrieve: Params', params);

      const data = {
        filters: {
          id: options.id
        },
        //"page": 1,
        //"sort": "rid",
        //"direction": "desc",
        //filters: globalState.settings.activeFilters,
        ...options.data
      };

      if (!isEmpty(options.user)) {
        data.filters.user = options.user;
      }

      debug.log('Part Retrieve: Data', data);
      return axios.post(`${apiURL}part/search?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    viewed: (options = { data: {} }) => {
      // 5/21/19 not using this yet since we can flag the retrieve method
      // to force the backend to increment the recipe viewed count

      debug.log('API: Viewing Part', options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
      };

      if (isAuthenticated()) {
        params.auth_token = globalState.userData.auth_token;
      }
      debug.log('Part Viewed: Params', params);

      const data = {
        id: options.id,
        ...options.data
      };
      debug.log('Part Viewed: Data', data);
      return axios.post(`${apiURL}part/viewed?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    }
  },

  parts: {
    retrieve: (options = { data: {} }) => {
      //console.log("API: Retrieving Recipes", options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
      };

      if (isAuthenticated()) {
        //console.log("retrieving recipes as authed");
        params.auth_token = globalState.userData.auth_token;
      }
      debug.log('Parts Retrieve: Params', params);

      //if (!options.data) options.data = {};

      const data = {
        'page': 1,
        //"sort": "rid",
        //"direction": "desc",
        filters: globalState.settings.activePartFilters,
        sort_order: globalState.settings.activePartSort,
        direction: globalState.settings.activePartSortDirection,
        ...options.data
      };

      // we don't want this cause this will only show recipes that are flagged with a tier that matches the user
      // if we have a user and the filter tier is not set, set it now
      // if (!isEmpty(globalState.userData) && isEmpty(data.filters.tier)) {
      //   data.filters.tier = globalState.userData.tier;
      // }

      debug.log('Parts Retrieve: Data', data);
      return axios.post(`${apiURL}part/search?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    }
  },

  user: {
    create: (options = { data: {} }) => {
      debug.log('API: Create User', options);

      //const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        // only skip if we have a session id
        ...baseParams({ skip_session_id: options.session_id !== null }),
      };

      let data = {
        ...options.data
      };

      // only add the session id if we have one
      if (options.session_id) {
        data.session_id = options.session_id;
      }

      debug.log('User: Data', data);
      return axios.post(`${apiURL}user/create?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    status: (options = { data: {} }) => {
      debug.log('API: User Status', options);

      const globalState = window.store.getState();
      //debug.log(globalState, 'Global State');

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Logout: Data", data);
      return axios.get(`${apiURL}user/status?${queryString.stringify(params)}`).then(response => response.data);
    },

    cancel: (options = { data: {} }) => {
      debug.log('API: Cancel User', options);

      const globalState = window.store.getState();
      console.log('Global State', globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      const data = {
        ...options.data
      };

      debug.log('User: Data', data);
      return axios.post(`${apiURL}user/cancel?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    reactivate: (options = { data: {} }) => {
      debug.log('API: Reactivate User', options);

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      const data = {
        ...options.data
      };

      debug.log('User: Data', data);
      return axios.post(`${apiURL}user/reactivate?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    migrate: (options = { data: {} }) => {
      debug.log('API: Migrate User', options);

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };
      debug.log('params', params);

      const data = {
        ...options.data
      };

      debug.log('User: Data', data);
      return axios.post(`${apiURL}user/migrate?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    update: (options = { data: {} }) => {
      debug.log('API: Update User', options);

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      const data = {
        ...options.data
      };

      debug.log('User: Data', data);
      return axios.put(`${apiURL}user/update?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    password_reset_request: (options = { data: {} }) => {
      debug.log(options, 'API: Password Reset Request');

      //const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        //auth_token: globalState.userData.auth_token,
      };

      const data = {
        ...options.data,
        email: options.email,
      };

      debug.log('Password Reset Request: Data', data);
      return axios.post(`${apiURL}user/password_reset_request?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    password_reset: (options = { data: {} }) => {
      debug.log('API: Password Reset', options);

      //const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        //auth_token: globalState.userData.auth_token,
      };

      const data = {
        //...options.data
        password: options.password,
        reset_token: options.reset_token
      };

      debug.log('Password Reset: Data', data);
      return axios.post(`${apiURL}user/password_reset?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    login: (options = { data: {} }) => {
      debug.log('API: Log In', options);
      //const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams({ skip_session_id: true }),
      };

      const data = {
        email: options.email,
        password: options.password,
        session_id: options.session_id,
        //direction: globalState.settings.activeSortDirection,
        //...options.data
      };
      debug.log('Login: Data', data);
      return axios.post(`${apiURL}user/login?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    logout: (options = { data: {} }) => {
      debug.log('API: Log Out', options);
      const globalState = window.store.getState();
      debug.log(globalState, 'Global State');

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Logout: Data", data);
      return axios.get(`${apiURL}user/logout?${queryString.stringify(params)}`).then(response => response.data);
    },

    update_default_units: (options = { data: {} }) => {
      debug.log('API: Update Default Unit', options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      const data = {
        default_units: options.default_units,
        //direction: globalState.settings.activeSortDirection,
        //...options.data
      };
      debug.log('Default Units: Data', data);
      return axios.post(`${apiURL}user/update?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    user_tools: (options = { data: {} }) => {
      //console.log("API: User Tools", options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };
      //debug.log("params", params);
      const data = {
        uid: options.uid
        //email: options.email,
        //password: options.password
        //direction: globalState.settings.activeSortDirection,
        //...options.data
      };
      debug.log('User Tools: Data', data);
      return axios.post(`${apiURL}user_tool/find?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    create_user_tool: (options = { data: {} }) => {
      debug.log('API: Create User Tool', options);

      if (!isAuthenticated()) {
        return false;
      }

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Update User Recipe: Params", params);
      const data = {};

      if (options.data.tool_id) {
        data.tool_id = options.data.tool_id;
      }

      if (options.data.machine_id) {
        data.machine_id = options.data.machine_id;
      }

      debug.log('User Tool: Data', data);
      return axios.post(`${apiURL}user_tool/create?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    delete_user_tool: (options = { data: {} }) => {
      debug.log('API: Delete User Tool', options);

      if (!isAuthenticated()) {
        return false;
      }

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Delete User Tool: Params", params);
      const data = {
        id: options.id,
      };
      debug.log('User Tool: Data', data);
      return axios.post(`${apiURL}user_tool/delete?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    user_recipe_modifiers: (options = { data: {} }) => {
      //console.log("API: User Recipe Modifiers", options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };
      //debug.log("params", params);
      const data = {
        uid: options.uid
        //email: options.email,
        //password: options.password
        //direction: globalState.settings.activeSortDirection,
        //...options.data
      };
      debug.log('User Recipe Modifiers: Data', data);
      return axios.post(`${apiURL}user_recipe_modifiers/find?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    create_user_recipe_modifiers: (options = { data: {} }) => {
      debug.log('API: Create User Recipe Modifiers', options);

      if (!isAuthenticated()) {
        return false;
      }

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Update User Recipe: Params", params);
      const data = { ...options.data };

      debug.log('Create - User Recipe Modifiers: Data', data);
      return axios.post(`${apiURL}user_recipe_modifiers/create?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    update_user_recipe_modifiers: (options = { data: {} }) => {
      debug.log('API: Update User Recipe Modifiers', options);

      if (!isAuthenticated()) {
        return false;
      }

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      const data = { ...options.data };

      //debug.log("Update - User Recipe Modifiers: Data", data);
      return axios.put(`${apiURL}user_recipe_modifiers/update?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    delete_user_recipe_modifiers: (options = { data: {} }) => {
      debug.log('API: Delete User Recipe Modifiers', options);

      if (!isAuthenticated()) {
        return false;
      }

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Delete User Tool: Params", params);
      const data = {
        id: options.id,
      };
      debug.log('Delete - User Recipe Modifiers: Data', data);
      return axios.post(`${apiURL}user_recipe_modifiers/delete?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    user_filters: (options = { data: {} }) => {
      //console.log("API: User Tools", options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };
      // debug.log("params", params);
      const data = {
        uid: options.uid
        //email: options.email,
        //password: options.password
        //direction: globalState.settings.activeSortDirection,
        //...options.data
      };
      debug.log('User Filters: Data', data);
      return axios.post(`${apiURL}user_filters/find?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    create_user_filters: (options = { data: {} }) => {
      debug.log('API: Create User Filters', options);

      if (!isAuthenticated()) {
        return false;
      }

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Update User Recipe: Params", params);
      const data = {};

      if (options.data.title) {
        data.title = options.data.title;
      }

      if (options.data.filters) {

        if (get(data.filters, 'user_filters')) {
          delete data.filters.user_filters;
        }
        data.filters = options.data.filters;
      }

      debug.log('User Filters: Data', data);
      return axios.post(`${apiURL}user_filters/create?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    delete_user_filters: (options = { data: {} }) => {
      debug.log('API: Delete User Filters', options);

      if (!isAuthenticated()) {
        return false;
      }

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Delete User Tool: Params", params);
      const data = {
        id: options.id,
      };
      debug.log('User Filter: Data', data);
      return axios.post(`${apiURL}user_filters/delete?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    update_user_filters: (options = { data: {} }) => {
      debug.log('API: Update User Filters', options);

      if (!isAuthenticated()) {
        return false;
      }

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Delete User Tool: Params", params);
      const data = {
        id: options.data.id,
        title: options.data.title,
      };
      debug.log('User Filter: Data', data);
      return axios.put(`${apiURL}user_filters/update?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    create_user_recipe: (options = { data: {} }) => {
      debug.log('API: Create User Recipe', options);

      if (!isAuthenticated()) {
        return false;
      }

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Update User Recipe: Params", params);
      const data = {
        //uid: globalState.userData.uid,
        recipe_id: options.recipe_id,
        //email: options.email,
        //password: options.password
        //direction: globalState.settings.activeSortDirection,
        //...options.data
      };
      debug.log('User Recipe: Data', data);
      return axios.post(`${apiURL}user_recipe/create?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    delete_user_recipe: (options = { data: {} }) => {
      debug.log('API: Delete User Recipe', options);

      if (!isAuthenticated()) {
        return false;
      }

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      //console.log("Delete User Recipe: Params", params);
      const data = {
        recipe_id: options.recipe_id,
      };
      debug.log('User Recipe: Data', data);
      return axios.post(`${apiURL}user_recipe/delete?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },
  },

  order: {
    subscription_review: (options = { data: {} }) => {
      //console.log("API: Subscription Review", options);
      //const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams({ skip_session_id: true }),
      };

      const data = {
        //direction: globalState.settings.activeSortDirection,
        ...options.data
      };
      debug.log('User Subscription: Data', data);
      return axios.post(`${apiURL}user_subscription/review?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },
  },

  faqs: {
    retrieve: (options = { data: {} }) => {
      debug.log('API: Retrieving FAQs', options);
      return axios.get('data/faqs.json').then(response => response.data);
    }
  },

  groups: {
    retrieve_members: (options = { data: {}, page: 1 }) => {
      //console.log("API: User Tools", options);
      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };
      //debug.log("params", params);
      const data = {
        group_id: options.group_id,
        page: options.page
        //...options.data
      };
      debug.log('Retrieve Group Members: Data', data);
      return axios.post(`${apiURL}group/members?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    create_member: (options = { data: {} }) => {
      debug.log('API: Create Group Member', options);

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      const data = {
        ...options.data
      };

      debug.log('Group Member: Data', data);
      return axios.post(`${apiURL}group/create_member?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    update_member: (options = { data: {} }) => {
      debug.log('API: Update Group Member', options);

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      const data = {
        ...options.data
      };

      debug.log('Group Member: Data', data);
      return axios.put(`${apiURL}group/update_member?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },

    resend_invite: (options = { data: {} }) => {
      debug.log('API: Resend Group Member Invite', options);

      const globalState = window.store.getState();
      //console.log("Global State", globalState);

      const params = {
        ...baseParams(),
        auth_token: globalState.userData.auth_token,
      };

      const data = {
        ...options.data
      };

      debug.log('Group Member: Data', data);
      return axios.post(`${apiURL}group/resend_invite?${queryString.stringify(params)}`, { ...data }).then(response => response.data);
    },
  }
};

//{
//console.log("Recipes Response", response);
// map objects
// https://stackoverflow.com/questions/14810506/map-function-for-objects-instead-of-arrays
//setFilters(response.data.response.filters);
// options.setState(old => ({
//   ...old,
//   recipes: response.data.response.recipes,
//   filters: response.data.response.filters,
//   total_pages: response.data.response.total_pages,
//   total_recipes: response.data.response.total_recipes
// }));
//retrievedRecipes()
//});