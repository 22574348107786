import { RETRIEVED_ORDER_REVIEW } from "../../common/consts";
import { checkInvalidSessionID } from "../../common/helpers";

const orderData = (state = {}, action = {}) => {
  //console.log("Order Reducers", state, action);
  if (checkInvalidSessionID({action})) {
    return {
      ...state
    };
  }

  switch (action.type) {
    case RETRIEVED_ORDER_REVIEW:
      return {
        //...state,
        ...action.criteria.response,
        valid: action.criteria.result === "success"
      };

    default:
      return {...state, valid: null};
  }
};

export default orderData;