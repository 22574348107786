import {
  CREATED_USER_RECIPE_MODIFIERS,
  DELETED_USER_RECIPE_MODIFIERS,
  FOUND_USER_RECIPE_MODIFIERS,
  UPDATED_USER_RECIPE_MODIFIERS
} from "../../common/consts";
import { debug } from "../../common/helpers";
import api from "../api";
import { updateSettings } from "./settings";

export const findUserRecipeModifiers = userRecipeModifiersCriteria => dispatch => {
  window.store.dispatch(updateSettings({fetchingUserRecipeModifiers: true}));
  api.user.user_recipe_modifiers(userRecipeModifiersCriteria).then(userRecipeModifiersResponse => {
      debug.log("Finding User Recipe Modifiers: response", userRecipeModifiersResponse);
      window.store.dispatch(updateSettings({fetchingUserRecipeModifiers: false}));
      dispatch(foundUserRecipeModifiers(userRecipeModifiersResponse));
    }
  );
};

export const foundUserRecipeModifiers = userRecipeModifiersCriteria => ({
  type: FOUND_USER_RECIPE_MODIFIERS,
  criteria: userRecipeModifiersCriteria
});

export const createUserRecipeModifiers = userRecipeModifiersCriteria => dispatch => {
  window.store.dispatch(updateSettings({creatingUserRecipeModifiers: true}));
  api.user.create_user_recipe_modifiers(userRecipeModifiersCriteria).then(userRecipeModifiersResponse => {
      debug.log("Create User Recipe Modifiers: response", userRecipeModifiersResponse);
      window.store.dispatch(updateSettings({creatingUserRecipeModifiers: false}));
      dispatch(createdUserRecipeModifiers(userRecipeModifiersResponse));
    }
  );
};

export const createdUserRecipeModifiers = userRecipeModifiersResponse => ({
  type: CREATED_USER_RECIPE_MODIFIERS,
  criteria: userRecipeModifiersResponse
});

export const updateUserRecipeModifiers = userRecipeModifiersCriteria => dispatch => {
  window.store.dispatch(updateSettings({updatingUserRecipeModifiers: true}));
  api.user.update_user_recipe_modifiers(userRecipeModifiersCriteria).then(userRecipeModifiersResponse => {
      debug.log("Update User Recipe Modifiers: response", userRecipeModifiersResponse);
      window.store.dispatch(updateSettings({updatingUserRecipeModifiers: false}));
      dispatch(updatedUserRecipeModifiers(userRecipeModifiersResponse));
    }
  );
};

export const updatedUserRecipeModifiers = userRecipeModifiersResponse => ({
  type: UPDATED_USER_RECIPE_MODIFIERS,
  criteria: userRecipeModifiersResponse
});

export const deleteUserRecipeModifiers = userRecipeModifiersCriteria => dispatch => {
  window.store.dispatch(updateSettings({deletingUserRecipeModifiers: true}));
  api.user.delete_user_recipe_modifiers(userRecipeModifiersCriteria).then(userRecipeModifiersResponse => {
      debug.log("Delete User Recipe Modifiers: response", userRecipeModifiersResponse);
      window.store.dispatch(updateSettings({deletingUserRecipeModifiers: false}));
      dispatch(deletedUserRecipeModifiers(userRecipeModifiersResponse));
    }
  );
};

export const deletedUserRecipeModifiers = userRecipeModifiersResponse => ({
  type: DELETED_USER_RECIPE_MODIFIERS,
  criteria: userRecipeModifiersResponse
});