import {
  CREATED_USER_TOOL,
  DELETED_USER_TOOL,
  FOUND_USER_TOOLS,
  RETRIEVED_RECIPES,
  TOGGLE_USER_TOOL
} from "../../common/consts";
import { checkInvalidSessionID, debug } from "../../common/helpers";

const userToolsData = (state = {}, action = {}) => {
  //debug.log("User Tools Reducers", state, action);

  if (checkInvalidSessionID({action})) {
    return {
      ...state
    };
  }

  switch (action.type) {
    case FOUND_USER_TOOLS:
      //console.log("reducer: user tools", action);

      return {
        ...state,
        user_tools: {
          tools: action.criteria.response.tools,
          machines: action.criteria.response.machines
        },
        total_user_tools: action.criteria.response.tools.length,
        total_user_machines: action.criteria.response.machines.length,
      };

    case CREATED_USER_TOOL:
      //console.log("reducer: user tool - created", action);
      debug.log("reducer: user tool - created", action);
      return {
        ...state,
        is_user_tool: action.criteria.result === "success",
        user_tools: {
          tools: action.criteria.response.tools,
          machines: action.criteria.response.machines
        },
        total_user_tools: action.criteria.response.tools.length,
        total_user_machines: action.criteria.response.machines.length,
      };

    case DELETED_USER_TOOL:
      //console.log("reducer: user tool - deleted", action);
      debug.log("reducer: user tool - deleted", action);
      return {
        ...state,
        is_user_tool: !(action.criteria.result === "success"),
        user_tools: {
          tools: action.criteria.response.tools,
          machines: action.criteria.response.machines
        },
        total_user_tools: action.criteria.response.tools.length,
        total_user_machines: action.criteria.response.machines.length,
      };

    case TOGGLE_USER_TOOL:
      //console.log("reducer: user tool - toggle", action);
      debug.log("reducer: user tool - toggle", action);
      return {
        ...state,
        is_user_tool: action.toggle,
      };

    case RETRIEVED_RECIPES:

      return {
        ...state,
        total_user_tools: action.criteria.response.total_user_tools,
        total_user_machines: action.criteria.response.total_user_machines,
      };

    default:
      //console.log("default reducer: stored user", user, state);
      return {
        ...state,
        is_user_tool: (state.is_user_tool === null) ? null : state.is_user_tool,
      };

  }
};

export default userToolsData;