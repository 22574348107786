import isEmpty from "lodash/isEmpty";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import { isAuthenticated, isLocked, userTierAccess } from "./helpers";

//import { updateSettings } from "../../redux/actions/settings";

function PrivateRoute (props) {
  const {minTier = "0.0.0", bypassLock = false, component: Component, redirectTo = "/login", ...rest} = props;
  //console.log("Private Route", rest, "test", test);
  // console.log("private", props.location);
  //debug.log("private props",props.location);

  //let redirect = "/login";
  let redirect = redirectTo;
  if (!isEmpty(props.location.pathname)) {
    redirect += `?destination=${props.location.pathname}`;
  }

  let locked = false;
  if (isLocked(bypassLock) && !bypassLock) {
    //console.log("locked so redirect");
    redirect = "/account";
    locked = true;
  }

  // test users should redirect to join
  if (!userTierAccess({minTier})) {
    redirect = "/join";
  }

  // useEffect(() => {
  //console.log("useEffect: PrivateRoute", props);
  // }, [settings, user]);
  //return (<Route {...rest} render={props => <Component {...props}/>}/>);
  return (
    <Route {...rest}
           render={props => isAuthenticated() && userTierAccess({minTier}) && !locked ? (<Component {...props}/>) : (
             <Redirect to={redirect}/>)}/>);
}

function mapStateToProps (state) {
  //console.log("FAQs: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    //settings: state.settings,
    //user: state.userData,
  };
}

export default connect(mapStateToProps, {})(withRouter(PrivateRoute));