import { CREATED_USER_RECIPE, DELETED_USER_RECIPE } from "../../common/consts";
import { debug } from "../../common/helpers";
import api from "../api";
import { updateSettings } from "./settings";

export const createUserRecipe = userRecipeCriteria => dispatch => {
  window.store.dispatch(updateSettings({creatingUserRecipe: true}));
  api.user.create_user_recipe(userRecipeCriteria).then(userRecipeResponse => {
      //console.log("Create User Recipe: response", userRecipeResponse);
      debug.log("Create User Recipe: response", userRecipeResponse);
      window.store.dispatch(updateSettings({creatingUserRecipe: false}));
      dispatch(createdUserRecipe(userRecipeResponse));
    }
  );
};

export const createdUserRecipe = userRecipeResponse => ({
  type: CREATED_USER_RECIPE,
  criteria: userRecipeResponse
});

export const deleteUserRecipe = userRecipeCriteria => dispatch => {
  window.store.dispatch(updateSettings({deletingUserRecipe: true}));
  api.user.delete_user_recipe(userRecipeCriteria).then(userRecipeResponse => {
      //console.log("Delete User Recipe: response", userRecipeResponse);
      debug.log("Delete User Recipe: response", userRecipeResponse);
      window.store.dispatch(updateSettings({deletingUserRecipe: false}));
      dispatch(deletedUserRecipe(userRecipeResponse));
    }
  );
};

export const deletedUserRecipe = userRecipeResponse => ({
  type: DELETED_USER_RECIPE,
  criteria: userRecipeResponse
});

/*
export const toggleUserRecipe = toggle => ({
  type: TOGGLE_USER_RECIPE,
  toggle
});
*/