import queryString from "query-string";
import React, { useEffect } from "react";
import { connect } from "react-redux";
// import { Redirect } from "react-router-dom";
import { Elements } from "react-stripe-elements";
import { Grid, Header } from "semantic-ui-react";
// import { DEFAULT_AUTHENTICATED_ROUTE } from "../../common/consts";
// import { isAuthenticated } from "../../common/helpers";
// import { updateSettings } from "../../redux/actions/settings";
import Layout from "../global/Layout";
import isEmpty from "lodash/isEmpty";

function RecipeIssue (props) {
  const {settings, user} = props;

  const parsedURLParams = queryString.parse(props.location.search);
  //console.log("params", parsedURLParams);

  useEffect(() => {
    //console.log("useEffect: SignUp", props);

    // if (settings.activeMenuItem !== "signup") {
    //   updateSettings({activeMenuItem: "signup"});
    // }

  }, [settings]);

  const jotFormID = "203065198332150";
  //let formURL = `https://docs.google.com/forms/d/1YFyqOcSWlNJ8GRJFporCgYcRpYOLrpkEj7XcZiNF_Iw/viewform?embedded=true&usp=pp_url&emailAddress=${user.email}`;
  let formURL = `https://form.jotform.com/${jotFormID}${!isEmpty(user.email) ? `?email=${user.email}&fullName=${user.fullname}` : ""}`;

  if (parsedURLParams.recipe_id) {
    //formURL += `&entry.1159239410=${parsedURLParams.recipe_id}`;
    formURL += `&recipeId=${parsedURLParams.recipe_id}`;
  }

  var ifr = document.getElementById(`JotFormIFrame-${jotFormID}`);
  if (window.location.href && window.location.href.indexOf("?") > -1
  ) {
    var get = window.location.href.substr(window.location.href.indexOf("?") + 1);
    if (ifr && get.length > 0) {
      var src = ifr.src;
      src = src.indexOf("?") > -1 ? src + "&" + get : src + "?" + get;
      ifr.src = src;
    }
  }

  const handleIFrameMessage = function (e) {
    if (typeof e.data === "object") {return;}
    var args = e.data.split(":");
    let iframe = null;
    if (args.length > 2) {iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]);} else {iframe = document.getElementById("JotFormIFrame");}
    if (!iframe) {return;}
    switch (args[0]) {
      case "scrollIntoView":
        iframe.scrollIntoView();
        break;
      case "setHeight":
        iframe.style.height = args[1] + "px";
        break;
      case "collapseErrorPage":
        if (iframe.clientHeight > window.innerHeight) {iframe.style.height = window.innerHeight + "px";}
        break;
      case "reloadPage":
        window.location.reload();
        break;
      case "loadScript":
        var src = args[1];
        if (args.length > 3) {src = args[1] + ":" + args[2];}
        var script = document.createElement("script");
        script.src = src;
        script.type = "text/javascript";
        document.body.appendChild(script);
        break;
      case "exitFullscreen":
        if (window.document.exitFullscreen) window.document.exitFullscreen(); else if (window.document.mozCancelFullScreen) window.document.mozCancelFullScreen(); else if (window.document.mozCancelFullscreen) window.document.mozCancelFullScreen(); else if (window.document.webkitExitFullscreen) window.document.webkitExitFullscreen(); else if (window.document.msExitFullscreen) window.document.msExitFullscreen();
        break;
      default:
        break;
    }
    var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
    if (isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
      var urls = {
        "docurl": encodeURIComponent(document.URL),
        "referrer": encodeURIComponent(document.referrer)
      };
      iframe.contentWindow.postMessage(JSON.stringify({"type": "urls", "value": urls}), "*");
    }
  };
  if (window.addEventListener) {window.addEventListener("message", handleIFrameMessage, false);} else if (window.attachEvent) {window.attachEvent("onmessage", handleIFrameMessage);}

  return (
    <Elements>
      <Layout>
        <Grid className="recipe-issue-content content">
          <Grid.Row className="recipe-issue-content__heading">
            <Grid.Column className="recipe-issue-content__header" width={5}><Header as="h1">Report Recipe
              Issue</Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid className="recipe-issue-content__form-columns" columns={1}>
              <Grid.Row>
                <Grid.Column width={16}>

                  <iframe id={`JotFormIFrame-${jotFormID}`} title="Recipe Issue Form"
                          onLoad={window.parent.scrollTo(0, 0)}
                          allowTransparency="true" allowFullScreen={true} allow="geolocation; microphone; camera"
                          src={formURL}
                          frameBorder="0"
                          style={{"minWidth": "100%", "height": "539px", "border": "none"}} scrolling="no"></iframe>


                  {/*<iframe*/}
                  {/*  title="Recipe Issue Form"*/}
                  {/*  src={formURL}*/}
                  {/*  width="100%" height="1000" frameBorder="0" marginHeight="0" marginWidth="0">Loading...*/}
                  {/*</iframe>*/}

                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>


        </Grid>
      </Layout></Elements>);
}

function mapStateToProps (state) {
  //console.log("SignUp: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    //order: state.orderData,
  };
}

export default connect(mapStateToProps, {})(RecipeIssue);
