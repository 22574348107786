import React from "react";
import { connect } from "react-redux";
import { Grid, Radio } from "semantic-ui-react";
import { updateSettings } from "../../redux/actions/settings";
import { updateUser } from "../../redux/actions/user";
import { imperialUnits } from "../../common/helpers";

function PartToolbar (props) {
  const {updateUser, position = "top"} = props;

  /*
  const clickCloseTab = () => {
    //console.log("close");
    window.close();
  };
*/

  // When we toggle the Imperial / Metric values we update the settings which will automatically rerender the cards
  const handleUnitChange = (e, {value}) => {
    // updateSettings({imperial: value === "true"});
    updateUser({data: {default_units: value === "true" ? "imperial" : "metric"}});
  };

  return (<Grid className="part-toolbar toolbar" columns={2}>
    <Grid.Row>
      {position === "top" && <React.Fragment>
        <Grid.Column className="toolbar__units" width={5} floated='right'><span className="label">UNITS:</span>
          <Radio label="Imperial" name="units" value="true" checked={imperialUnits()}
                 onChange={handleUnitChange}/>
          <Radio label="Metric" name="units" value="false" checked={!imperialUnits()} onChange={handleUnitChange}/>
        </Grid.Column></React.Fragment>
      }
    </Grid.Row>
  </Grid>);
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //parts: state.partsData.parts,
    //filters: state.partsData.filters,
    // totalPages: state.partsData.totalPages,
    //totalParts: state.partsData.totalParts,
    settings: state.settings,
    user: state.userData,
  };
}

export default connect(mapStateToProps, {updateSettings, updateUser})(PartToolbar);
