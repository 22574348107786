import classNames from "classnames/bind";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Loader,
  Visibility,
} from "semantic-ui-react";
import store from "store";
import {
  ADAPTIVE_BOTH_WAYS_CUT_TYPE_ID,
  ADAPTIVE_CUT_TYPE_ID,
  apiURL,
  baseParams,
  CHAMFER_TOOL_TYPE_ID,
  DRILLING_CUT_TYPE_ID,
  SLOTTING_CUT_TYPE_ID,
  NO_FIELD_VALUE,
  TAPPING_CUT_TYPE_ID,
  REAMING_CUT_TYPE_ID,
} from "../../common/consts";
import {
  debug,
  hasValue,
  isAuthenticated,
  numberFormat,
  showMessage,
  userTierAccess,
  isTurningCutType,
  isTurningRoughingCutType,
  isTurningFinishingCutType,
} from "../../common/helpers";
import { retrieveRecipe } from "../../redux/actions/recipes";
import { updateSettings } from "../../redux/actions/settings";
import {
  createUserRecipe,
  deleteUserRecipe,
} from "../../redux/actions/userRecipes";
import { findUserRecipeModifiers } from "../../redux/actions/userRecipeModifiers";
import { createUserTool } from "../../redux/actions/userTools";
import CallToAction from "../global/CallToAction";
import Layout from "../global/Layout";
import UserSurveyCTA from "../global/UserSurveyCTA";
import RecipeField from "./RecipeField";
import RecipeMedia from "./RecipeMedia";
import RecipeToolbar from "./RecipeToolbar";
import UserRecipeButton from "./UserRecipeButton";
import RecipeVoteButtons from "./RecipeVoteButtons";
import commentBox from "commentbox.io";
import axios from "axios";
import queryString from "query-string";
import { HashLink as Link } from "react-router-hash-link";
import { scroller } from "react-scroll";
import { recipeModifiers } from "../../common/recipeModifiers";
import ReactGA from "react-ga4";

// ---------------------------------------------- RECIPE COMPONENT ---------------------------------------------- //
function Recipe(props) {
  //console.log("Recipe", props);
  const {
    recipe,
    user,
    userRecipes,
    userRecipeModifiers,
    findUserRecipeModifiers,
    userTools,
    settings,
    retrieveRecipe,
    createUserTool,
  } = props;
  const recipeID = props.match.params.recipeID;
  //const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

  //debug.log(publicUrl, props.match);
  const [_recipe, setRecipe] = useState({});
  const [showUserSurveyCTA, setShowUserSurveyCTA] = useState(false);
  const [userRecipeModifierOptions, setUserRecipeModifierOptions] = useState(
    []
  );
  const [
    userRecipeModifierCalculatedValues,
    setUserRecipeModifierCalculatedValues,
  ] = useState({});
  const [loadInitUserRecipeModifier, setLoadInitUserRecipeModifier] = useState(
    false
  );
  const [showMore, setShowMore] = useState(false);

  //const [isUserRecipe, setIsUserRecipe] = useState(false);
  //const [isUpdatingUserRecipe, setIsUpdatingUserRecipe] = useState(false);

  const COLUMN_SIZES = {
    first: {
      label: 7,
      value: 9,
    },
    second: {
      label: 7,
      value: 9,
    },
    third: {
      label: 11,
      value: 5,
    },
  };

  const handleOpenCalculatorPage = (e, data, section = "") => {
    //debug.log(section);
    //setPlaying(false);
    window.open(`/calculator/${_recipe.id}`, "_blank");
  };

  const handleToggleShowMore = () => {
    setShowMore(!showMore);
  };

  commentBox("5684179551387648-proj", {
    singleSignOn: {
      buttonText: "ProvenCut", // The text to show on the sign in button.
      buttonIcon:
        "https://d3827p95tv2r1a.cloudfront.net/cdn/logos/provencut_logo_square.png", // The icon to show on the sign in button. Must be an absolute URL.
      buttonColor: "", // The sign in button's color. Default is black.
      autoSignOn: true, // Attempts to automatically log the user into CommentBox.io with custom auth.
      onSignOn(onComplete, onError) {
        // pass in the signed user payload to onComplete.

        if (!isAuthenticated()) {
          return false;
        }

        const globalState = window.store.getState();
        //console.log("Global State", globalState);

        const params = {
          ...baseParams(),
          auth_token: globalState.userData.auth_token,
        };
        debug.log("Comment Single Signon: Params", params);
        const data = {
          uid: globalState.userData.uid,
          //...options.data
        };
        debug.log("Comment Single Signon: Data", data);

        return axios
          .post(
            `${apiURL}user/commentbox_token?${queryString.stringify(params)}`,
            { ...data }
          )
          .then((response) => {
            //debug.log("sso token", response.data.response.token, response.data.response);
            //console.log("sso token", response.data.response.token, response.data.response);
            onComplete(response.data.response.token);
          });
      },
      onSignOut() {
        // optionally log the user out of your website's native auth system.
      },
    },
    onCommentCount(count) {
      console.log("loaded comments");
      document.getElementsByClassName(
        ".commentbox .logout .button"
      )[0].style.visibility = "hidden";
    },
  });

  useEffect(() => {
    //console.log("useEffect: Recipe", props);
    //console.log("user", user);
    if (
      !settings.fetchingRecipes &&
      !isEmpty(recipe) &&
      !isEqual(recipe, _recipe)
    ) {
      setRecipe(recipe);
      //console.log("SETTING TOGGLE USER RECIPE");
      // don't think we need this anymore (as of 10/30/19)
      //toggleUserRecipe(recipe.is_user_recipe);
    }

    const hash = window.location.hash.replace("#", "");
    //debug.log("hash", hash);

    if (!isEmpty(hash) && !isEmpty(recipe)) {
      scroller.scrollTo(hash, {
        duration: 1500,
        delay: 100,
        smooth: true,
        //containerId: "ContainerElementID",
        offset: -5, // Scrolls to element + 50 pixels down the page
      });
    }

    if (
      !settings.fetchingUserRecipeModifiers &&
      !isEmpty(userRecipeModifiers.user_recipe_modifiers) &&
      isEmpty(userRecipeModifierOptions)
    ) {
      debug.log("loaded user recipe modifiers", userRecipeModifiers);

      buildUserRecipeModifiersList();
    }

    if (
      !isEmpty(userRecipeModifierOptions) &&
      !loadInitUserRecipeModifier &&
      !isEmpty(recipe)
    ) {
      setLoadInitUserRecipeModifier(true);
      changeRecipeModifier(null, { value: userRecipeModifierOptions[0].value });
    }

    //console.log("userRecipes.is_user_recipe", userRecipes.is_user_recipe, "isUserRecipe", isUserRecipe);
    if (userRecipes.is_user_recipe !== null) {
      //setRecipe({..._recipe, is_user_recipe: true})
      //setIsUserRecipe(!isUserRecipe);
      //setIsUpdatingUserRecipe(false);
    }
  });

  const buildUserRecipeModifiersList = () => {
    setUserRecipeModifierOptions([]);

    if (!isEmpty(userRecipeModifiers.user_recipe_modifiers)) {
      setUserRecipeModifierOptions(
        userRecipeModifiers.user_recipe_modifiers.map(
          (user_recipe_modifier) => ({
            key: user_recipe_modifier.id,
            value: user_recipe_modifier.id,
            text: user_recipe_modifier.title,
          })
        )
      );
      // setUserRecipeModifierCalculatedValues(recipeModifiers({
      //   recipe,
      //   modifiers: {
      //     rpm: userRecipeModifiers.user_recipe_modifiers[0].rpm
      //   }
      // }));
      // changeRecipeModifier(null, {value: userRecipeModifiers.user_recipe_modifiers[0].value});
    }
  };

  /*
  if (hasValue(options.recipe.horsepower)) {
    if (options.recipe.horsepower < horsepower) {
      // set horsepower in response object to an error
      //  + RED color
      //   + Exceeds machine HP
      calculated.horsepowerError = 'Exceeds M'
    } else {

    }
  }
*/
  init();

  function init() {
    useEffect(() => {
      let retrieveOptions = { id: recipeID, data: { view: true } };

      if (userTierAccess({ minTier: "1.0.0", maxTier: "1.9.9" })) {
        retrieveOptions.user = { tier: "2.0.0" };
      }

      // console.log("useEffect: Recipe Init", props);
      retrieveRecipe(retrieveOptions);

      if (userTierAccess({ minTier: "1.0.0" })) {
        findUserRecipeModifiers({ uid: user.uid });
      }
    }, []);
  }

  const changeRecipeModifier = (e, data) => {
    const recipeModifier = find(userRecipeModifiers.user_recipe_modifiers, {
      id: data.value,
    });
    debug.log("recipeModifier", recipeModifier);

    setUserRecipeModifierCalculatedValues(
      recipeModifiers({
        recipe: { ...recipe }, // we want to copy the recipe otherwise we will be modifying the original and changing the values
        modifiers: {
          rpm: recipeModifier.rpm,
        },
      })
    );
  };

  function addToMyTools(e) {
    e.preventDefault();

    //console.log("add to my tools", _recipe.cutting_tools[0].id);
    // eventually we'll move this to a Redux action
    createUserTool({ data: { tool_id: _recipe.cutting_tools[0].id } });
  }

  const onUpdate = (e, { calculations }) => {
    //console.log("Visibility Calcs", calculations);

    if (calculations.bottomVisible) {
      //console.log("updating settings");
      setShowUserSurveyCTA(true);
    }
  };

  function trackExternalLink(options) {
    const label = options.action ? options.action : options.url;

    ReactGA.event({
      category: "External Link",
      action: "Click External Link",
      label,
      value: 1,
    });
  }

  // look at https://react.semantic-ui.com/views/card/#content-header-card
  return (
    <Layout>
      <Visibility onUpdate={onUpdate}>
        {!isEmpty(_recipe) ? (
          <Grid
            className={classNames(
              "recipe-content",
              "content",
              `recipe recipe-${_recipe.id}`
            )}
          >
            <Grid.Row className="recipe-toolbar__wrapper">
              <RecipeToolbar />
            </Grid.Row>
            <Grid.Row className="recipe-media__wrapper">
              <RecipeMedia
                recipe={_recipe}
                minTier="1"
                checkProWithTier={true}
                scrollOnClick={true}
              />
            </Grid.Row>

            {/* ------------------------------------- BAR ------------------------------------- */}
            <Grid.Row className="recipe-bar__wrapper">
              <Grid className="recipe-bar" columns={2}>
                <Grid.Row>
                  {isAuthenticated() ? (
                    <Grid.Column width={2} className="recipe-bar__buttons">
                      {userTierAccess({ minTier: "1.0.0", maxTier: "1.9.9" }) &&
                      recipe.pro_tier ? (
                        <a className="recipe-bar__lock" href="/account">
                          <Icon name="lock" size="small" /> PRO
                        </a>
                      ) : (
                        ""
                      )}
                      {/*{(userTierAccess({minTier: "1"}) && !_recipe.pro_tier) || userTierAccess({minTier: "2"}) ?*/}
                      {/*  <UserRecipeButton*/}
                      {/*    recipe={recipe}/> : ""}*/}
                      <UserRecipeButton recipe={recipe} />
                    </Grid.Column>
                  ) : (
                    ""
                  )}
                  <Grid.Column width={2} className="recipe-bar__id">
                    Recipe ID: {_recipe.id}
                  </Grid.Column>

                  <Grid.Column width={12} className="recipe-bar__voting">
                    <RecipeVoteButtons recipe={recipe} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>

            <Grid.Row className="recipe-bottom__wrapper">
              <Grid className="recipe-bottom">
                {/* ------------------------------------- COMMENTS ------------------------------------- */}
                {debug.log("_recipe", _recipe)}
                <Grid.Row className="internal-comments">
                  <Grid.Column>
                    {/*{!isEmpty(_recipe.comments) && userTierAccess({minTier: "2"}) && !includes(_recipe.comments, RESTRICTED_ACCESS_TOKEN) ?*/}
                    {/*{!isEmpty(_recipe.comments) && userTierAccess({minTier: _recipe.tier}) && !includes(_recipe.comments, RESTRICTED_ACCESS_TOKEN) ?*/}
                    {!isEmpty(_recipe.comments) ? (
                      <Grid className="comment comment__recipes">
                        <Grid.Row>
                          <span className="label">Recipe Comments:</span>
                          <span className="value">
                            {ReactHtmlParser(_recipe.comments)}
                          </span>
                        </Grid.Row>
                      </Grid>
                    ) : (
                      ""
                    )}

                    {/*{!isEmpty(_recipe.cutting_tools) && !isEmpty(_recipe.cutting_tools[0]) && !isEmpty(_recipe.cutting_tools[0].comments) && userTierAccess({minTier: "2"}) ?*/}
                    {/*{!isEmpty(_recipe.cutting_tools) && !isEmpty(_recipe.cutting_tools[0]) && !isEmpty(_recipe.cutting_tools[0].comments) && userTierAccess({minTier: _recipe.tier}) ?*/}
                    {!isEmpty(_recipe.cutting_tools) &&
                    !isEmpty(_recipe.cutting_tools[0]) &&
                    !isEmpty(_recipe.cutting_tools[0].comments) ? (
                      <Grid className="comment comment__tools">
                        <Grid.Row>
                          <span className="label">Cutting Tool Comments:</span>
                          <span className="value">
                            {ReactHtmlParser(_recipe.cutting_tools[0].comments)}
                          </span>
                        </Grid.Row>
                      </Grid>
                    ) : (
                      ""
                    )}

                    {/*{!isEmpty(_recipe.indexable_inserts) && !isEmpty(_recipe.indexable_inserts[0]) && !isEmpty(_recipe.indexable_inserts[0].comments) && userTierAccess({minTier: "2"}) ?*/}
                    {/*{!isEmpty(_recipe.indexable_inserts) && !isEmpty(_recipe.indexable_inserts[0]) && !isEmpty(_recipe.indexable_inserts[0].comments) && userTierAccess({minTier: _recipe.tier}) ?*/}
                    {!isEmpty(_recipe.indexable_inserts) &&
                    !isEmpty(_recipe.indexable_inserts[0]) &&
                    !isEmpty(_recipe.indexable_inserts[0].comments) ? (
                      <Grid className="comment comment__insert">
                        <Grid.Row>
                          <span className="label">
                            Indexable Insert Comments:
                          </span>
                          <span className="value">
                            {ReactHtmlParser(
                              _recipe.indexable_inserts[0].comments
                            )}
                          </span>
                        </Grid.Row>
                      </Grid>
                    ) : (
                      ""
                    )}

                    {/*{!isEmpty(_recipe.milling_tool_holders) && !isEmpty(_recipe.milling_tool_holders[0]) && !isEmpty(_recipe.milling_tool_holders[0].comments) && userTierAccess({minTier: "2"}) ?*/}
                    {/*{!isEmpty(_recipe.milling_tool_holders) && !isEmpty(_recipe.milling_tool_holders[0]) && !isEmpty(_recipe.milling_tool_holders[0].comments) && userTierAccess({minTier: _recipe.tier}) ?*/}
                    {!isEmpty(_recipe.milling_tool_holders) &&
                    !isEmpty(_recipe.milling_tool_holders[0]) &&
                    !isEmpty(_recipe.milling_tool_holders[0].comments) ? (
                      <Grid className="comment comment__holder">
                        <Grid.Row>
                          <span className="label">
                            Milling Tool Holder Comments:
                          </span>
                          <span className="value">
                            {ReactHtmlParser(
                              _recipe.milling_tool_holders[0].comments
                            )}
                          </span>
                        </Grid.Row>
                      </Grid>
                    ) : (
                      ""
                    )}

                    {/*{!isEmpty(_recipe.cnc_machine) && !isEmpty(_recipe.cnc_machine.comments) && userTierAccess({minTier: "2"}) ?*/}
                    {/*{!isEmpty(_recipe.cnc_machine) && !isEmpty(_recipe.cnc_machine.comments) && userTierAccess({minTier: _recipe.tier}) ?*/}
                    {!isEmpty(_recipe.cnc_machine) &&
                    !isEmpty(_recipe.cnc_machine.comments) ? (
                      <Grid className="comment comment__machine">
                        <Grid.Row>
                          <span className="label">Machine Comments:</span>
                          <span className="value">
                            {ReactHtmlParser(_recipe.cnc_machine.comments)}
                          </span>
                        </Grid.Row>
                      </Grid>
                    ) : (
                      ""
                    )}

                    {/*{!isEmpty(_recipe.material) && !isEmpty(_recipe.material.comments) && userTierAccess({minTier: "2"}) ?*/}
                    {/*{!isEmpty(_recipe.material) && !isEmpty(_recipe.material.comments) && userTierAccess({minTier: _recipe.tier}) ?*/}
                    {!isEmpty(_recipe.material) &&
                    !isEmpty(_recipe.material.comments) ? (
                      <Grid className="comment comment__material">
                        <Grid.Row>
                          <span className="label">Material Comments:</span>
                          <span className="value">
                            {ReactHtmlParser(_recipe.material.comments)}
                          </span>
                        </Grid.Row>
                      </Grid>
                    ) : (
                      ""
                    )}

                    {/*{!isEmpty(_recipe.coolant) && !isEmpty(_recipe.coolant.comments) && userTierAccess({minTier: "2"}) ?*/}
                    {/*{!isEmpty(_recipe.coolant) && !isEmpty(_recipe.coolant.comments) && userTierAccess({minTier: _recipe.tier}) ?*/}
                    {!isEmpty(_recipe.coolant) &&
                    !isEmpty(_recipe.coolant.comments) ? (
                      <Grid className="comment comment__coolant">
                        <Grid.Row>
                          <span className="label">Coolant Comments:</span>
                          <span className="value">
                            {ReactHtmlParser(_recipe.coolant.comments)}
                          </span>
                        </Grid.Row>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid.Column>
                </Grid.Row>

                {/*{!isEmpty(_recipe.files) && isAuthenticated() && ((userTierAccess({minTier: "1"}) && !_recipe.pro_tier) || userTierAccess({minTier: "2"})) ?*/}
                {!isEmpty(_recipe.files) &&
                isAuthenticated() &&
                userTierAccess({ minTier: _recipe.tier }) ? (
                  <Grid.Row className="files">
                    <Grid columns={1} divided className="recipe__info-columns">
                      <Grid.Row>
                        <Grid.Column className="recipe__info-column">
                          <div className="recipe__info-column-title">FILES</div>
                          <Grid className="file recipe__info-details">
                            {_recipe.files.map((file, index) => {
                              return (
                                <Grid.Row key={index}>
                                  <Grid.Column className="label value">
                                    <a
                                      href={`fusion360://command=open&file=${encodeURIComponent(
                                        file.file
                                      )}`}
                                      rel="noopener noreferrer"
                                      onClick={() =>
                                        trackExternalLink({
                                          url: `fusion360://command=open&file=${encodeURIComponent(
                                            file.file
                                          )}`,
                                        })
                                      }
                                    >
                                      {!isEmpty(file.description)
                                        ? file.description
                                        : `Open in Fusion 360 ${file.filename}`}
                                    </a>
                                    <span className="separator ten grey-dim">
                                      |
                                    </span>
                                    <a
                                      href={file.file}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                      onClick={() =>
                                        trackExternalLink({
                                          url: file.file,
                                        })
                                      }
                                    >
                                      {!isEmpty(file.description)
                                        ? file.description
                                        : `Download in Fusion 360 ${file.filename}`}
                                    </a>
                                    <span className="separator ten grey-dim">
                                      |
                                    </span>
                                    <a
                                      href={`https://www.autodesk.com/products/fusion-360/free-trial?mktvar002=1078466&utm_medium=mngd-referral&utm_source=industry-partner&utm_campaign=americas-dm-fusion-360-provencut-free-trial-page&utm_id=1078466`}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      onClick={() =>
                                        trackExternalLink({
                                          url: `https://www.autodesk.com/products/fusion-360/free-trial`,
                                          action: "Fusion 360 Free Trial",
                                        })
                                      }
                                    >
                                      Sign up for your Fusion 360{" "}
                                      <strong>free trial</strong>
                                    </a>
                                  </Grid.Column>
                                  {/*<Grid.Column width={3} className="label value">*/}
                                  {/*  <a href={`fusion360://command=open&file=${encodeURIComponent(file.file)}`}*/}
                                  {/*     rel="noopener noreferrer">{!isEmpty(file.description) ? file.description : `Open in Fusion 360 ${file.filename}`}</a>*/}
                                  {/*</Grid.Column>*/}
                                  {/*<Grid.Column width={3} className="label value">*/}
                                  {/*  <a href={file.file} target="_blank"*/}
                                  {/*     rel="noopener noreferrer"*/}
                                  {/*     download>{!isEmpty(file.description) ? file.description : `Download ${file.filename}`}</a>*/}
                                  {/*</Grid.Column>*/}
                                  {/*<Grid.Column width={4} className="label value">*/}
                                  {/*  <a*/}
                                  {/*    href={`https://www.autodesk.com/products/fusion-360/free-trial?mktvar002=1078466&utm_medium=mngd-referral&utm_source=industry-partner&utm_campaign=americas-dm-fusion-360-provencut-free-trial-page&utm_id=1078466`}*/}
                                  {/*    rel="noopener noreferrer">Sign up for your Fusion 360 free trial</a>*/}
                                  {/*</Grid.Column>*/}
                                </Grid.Row>
                              );
                            })}
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Row>
                ) : (
                  ""
                )}

                <Grid.Row className="data">
                  {/* ------------------------------------- INFO ------------------------------------- */}
                  <Grid
                    columns={3}
                    divided
                    className={`recipe__info-columns ${
                      showMore ? "show-desktop-only" : ""
                    } `}
                  >
                    <Grid.Row>
                      {/* ------------------------------------- ROW 1 COLUMN 1 ------------------------------------- */}
                      <Grid.Column className="recipe__info-column">
                        <Grid columns={1} className="recipe__info-header">
                          <Grid.Row>
                            <Grid.Column className="recipe__info-column-title">
                              RECIPE INFO
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid columns={2} className="recipe__info-details">
                          <RecipeField
                            id={_recipe.id}
                            name="machine"
                            label="Machine"
                            value={
                              !isEmpty(_recipe.cnc_machine)
                                ? `${_recipe.cnc_machine.brand.title} ${_recipe.cnc_machine.part_number.name}`
                                : NO_FIELD_VALUE
                            }
                            isText={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="material"
                            label="Material"
                            value={
                              !isEmpty(_recipe.material)
                                ? `${_recipe.material.title} ${_recipe.material.class.name}`
                                : NO_FIELD_VALUE
                            }
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="material-purchase-url"
                            label="Purchase URL"
                            value={
                              !isEmpty(_recipe.material) &&
                              !isEmpty(_recipe.material.purchase_url)
                                ? "Click here to view"
                                : ""
                            }
                            url={
                              !isEmpty(_recipe.material) &&
                              !isEmpty(_recipe.material.purchase_url)
                                ? _recipe.material.purchase_url
                                : ""
                            }
                            urlOptions={{
                              target: "_blank",
                              rel: "noopener noreferrer",
                            }}
                            isURL={
                              !isEmpty(_recipe.material) &&
                              !isEmpty(_recipe.material.purchase_url)
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="cut-style"
                            label="Cut Style"
                            value={
                              !isEmpty(_recipe.cut_type)
                                ? _recipe.cut_type.name
                                : NO_FIELD_VALUE
                            }
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="mrr"
                            label="MRR"
                            value={numberFormat({
                              decimalPlaces: 2,
                              value: _recipe.mrr,
                            })}
                            convertible
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="estimated-horsepower"
                            label="Est Horsepower"
                            value={
                              hasValue(_recipe.horsepower)
                                ? _recipe.horsepower
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="spindle-load"
                            label="Max Spindle Load"
                            value={
                              hasValue(_recipe.spindle_load)
                                ? `${_recipe.spindle_load}%`
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="visible-spindle-load"
                            label="Visible Spindle Load"
                            value={
                              hasValue(_recipe.visible_spindle_load)
                                ? `${_recipe.visible_spindle_load}%`
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="tirr"
                            label="Total Indicated Reading (Radial)"
                            value={
                              hasValue(_recipe.total_indicated_reading_radial)
                                ? _recipe.total_indicated_reading_radial
                                : NO_FIELD_VALUE
                            }
                            convertOptions={{ type: "inches", precision: 4 }}
                            convertible
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="tira"
                            label="Total Indicated Reading (Axial)"
                            value={
                              hasValue(_recipe.total_indicated_reading_axial)
                                ? _recipe.total_indicated_reading_axial
                                : NO_FIELD_VALUE
                            }
                            convertOptions={{ type: "inches", precision: 4 }}
                            convertible
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="gauge-length"
                            label="Gauge Length"
                            value={_recipe.gage_length}
                            convertOptions={{ type: "inches" }}
                            convertible
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="stickout"
                            label="Stickout"
                            value={
                              hasValue(_recipe.stickout)
                                ? _recipe.stickout
                                : NO_FIELD_VALUE
                            }
                            convertOptions={{ type: "inches" }}
                            convertible
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="coolant-style"
                            label="Coolant Style"
                            value={
                              !isEmpty(_recipe.coolant_style.name)
                                ? _recipe.coolant_style.name
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="coolant"
                            label="Coolant"
                            value={
                              !isEmpty(_recipe.coolant.brand) &&
                              !isEmpty(_recipe.coolant.part_number)
                                ? `${_recipe.coolant.brand.title} ${_recipe.coolant.part_number}`
                                : NO_FIELD_VALUE
                            }
                            isText={true}
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="brix"
                            label="BRIX"
                            value={
                              hasValue(_recipe.brix)
                                ? _recipe.brix
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />
                          {/*
                        <RecipeField
                          id={_recipe.id}
                          name="torque-holder"
                          label="Torque Holder"
                          value={hasValue(_recipe.torque_holder) ? _recipe.torque_holder : NO_FIELD_VALUE}
                          minTier="2"
                        />
                        */}

                          <RecipeField
                            id={_recipe.id}
                            name="date-published"
                            label="Date Published"
                            value={
                              !isEmpty(_recipe.published)
                                ? _recipe.published
                                : NO_FIELD_VALUE
                            }
                            isDate={!isEmpty(_recipe.published)}
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.first.label}
                            valueWidth={COLUMN_SIZES.first.value}
                          />
                        </Grid>
                      </Grid.Column>

                      {/* ------------------------------------- ROW 1 COLUMN 2 ------------------------------------- */}
                      <Grid.Column className="recipe__info-column">
                        <Grid columns={2} className="recipe__info-header">
                          <Grid.Row>
                            <Grid.Column className="recipe__info-column-title">
                              TOOL INFO
                            </Grid.Column>
                            <Grid.Column className="recipe__info-column-title recipe__tool-add-my-tools">
                              {/*{console.log("find tool", (!isEmpty(userTools.user_tools) && !find(userTools.user_tools.tools, {tool_id: _recipe.cutting_tools[0].id})) || isEmpty(userTools.user_tools))}*/}

                              {// if this recipe has cutting tools and the cutting tool is not a user tool
                              // AND
                              // if user tools is not empty and this tool isn't in user tools OR if user tools is empty
                              !isEmpty(_recipe.cutting_tools[0]) &&
                              !_recipe.cutting_tools[0].is_user_tool &&
                              ((!isEmpty(userTools.user_tools) &&
                                !find(userTools.user_tools.tools, {
                                  tool_id: _recipe.cutting_tools[0].id,
                                })) ||
                                isEmpty(userTools.user_tools)) &&
                              // AND

                              // if the user tier is at least 1 AND the recipe isn't pro tier OR if the user min tier is at least 2
                              ((userTierAccess({ minTier: "1" }) &&
                                !_recipe.pro_tier) ||
                                userTierAccess({ minTier: "2" })) ? (
                                <div>
                                  <Button
                                    onClick={addToMyTools}
                                    className="link"
                                  >
                                    + Add to my tools
                                  </Button>
                                  <Loader
                                    active={settings.creatingUserTool}
                                    inline
                                    size="mini"
                                  />
                                </div>
                              ) : !isEmpty(userTools.user_tools) &&
                                find(userTools.user_tools.tools, {
                                  tool_id: _recipe.cutting_tools[0].id,
                                }) ? (
                                <div>
                                  Added to{" "}
                                  <a href="/account" rel="noopener noreferrer">
                                    My Tools
                                  </a>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid columns={2} className="recipe__info-details">
                          <RecipeField
                            id={_recipe.id}
                            name="tool-name"
                            label="Tool Name"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].aka
                                : NO_FIELD_VALUE
                            }
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="tool-brand"
                            label="Tool Brand"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].brand.title
                                : NO_FIELD_VALUE
                            }
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="tool-type"
                            label="Tool Type"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].type.name
                                : NO_FIELD_VALUE
                            }
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="tool-diameter"
                            label="Tool Diameter"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].cutting_diameter
                                : NO_FIELD_VALUE
                            }
                            convertOptions={{ type: "inches", precision: 4 }}
                            convertible
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="flutes"
                            label="Flutes"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].number_of_flutes
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="flute-length"
                            label="Flute Length"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].flute_length
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            convertOptions={{ type: "inches" }}
                            convertible
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="corner-radius"
                            label="Corner Radius"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].corner_radius
                                : NO_FIELD_VALUE
                            }
                            convertOptions={{ type: "inches" }}
                            convertible
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="shank-diameter"
                            label="Shank Diameter"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].shank_diameter
                                : NO_FIELD_VALUE
                            }
                            convertOptions={{ type: "inches" }}
                            convertible
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="overall-length"
                            label="Overall Length"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].overall_length
                                : NO_FIELD_VALUE
                            }
                            convertible
                            convertOptions={{ type: "inches" }}
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="part-number"
                            label="Part Number"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].part_number
                                : NO_FIELD_VALUE
                            }
                            isText={true}
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />
                          {/*{console.log("tool url", _recipe.cutting_tools[0].url)}*/}
                          <RecipeField
                            id={_recipe.id}
                            name="tool-url"
                            label="URL"
                            value={
                              !isEmpty(_recipe.cutting_tools[0]) &&
                              !isEmpty(_recipe.cutting_tools[0].url)
                                ? "Click here to view"
                                : ""
                            }
                            url={
                              !isEmpty(_recipe.cutting_tools[0]) &&
                              !isEmpty(_recipe.cutting_tools[0].url)
                                ? _recipe.cutting_tools[0].url
                                : ""
                            }
                            urlOptions={{
                              target: "_blank",
                              rel: "noopener noreferrer",
                            }}
                            isURL={
                              !isEmpty(_recipe.cutting_tools[0]) &&
                              !isEmpty(_recipe.cutting_tools[0].url)
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="tool-purchase-url"
                            label="Purchase URL"
                            value={
                              !isEmpty(_recipe.cutting_tools[0]) &&
                              !isEmpty(_recipe.cutting_tools[0].purchase_url)
                                ? "Click here to view"
                                : ""
                            }
                            url={
                              !isEmpty(_recipe.cutting_tools[0]) &&
                              !isEmpty(_recipe.cutting_tools[0].purchase_url)
                                ? _recipe.cutting_tools[0].purchase_url
                                : ""
                            }
                            urlOptions={{
                              target: "_blank",
                              rel: "noopener noreferrer",
                            }}
                            isURL={
                              !isEmpty(_recipe.cutting_tools[0]) &&
                              !isEmpty(_recipe.cutting_tools[0].purchase_url)
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="tool-price"
                            label="Price (USD)"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].price
                                : NO_FIELD_VALUE
                            }
                            isPrice={!isEmpty(_recipe.cutting_tools[0])}
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="tool-price-date"
                            label="Price Date"
                            value={
                              !isEmpty(_recipe.cutting_tools[0])
                                ? _recipe.cutting_tools[0].date_of_price
                                : NO_FIELD_VALUE
                            }
                            isDate={!isEmpty(_recipe.cutting_tools[0])}
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            labelWidth={COLUMN_SIZES.second.label}
                            valueWidth={COLUMN_SIZES.second.value}
                          />
                        </Grid>
                      </Grid.Column>

                      {/* ------------------------------------- ROW 1 COLUMN 3 ------------------------------------- */}
                      <Grid.Column className="recipe__info-column">
                        <Grid columns={1} className="recipe__info-header">
                          <Grid.Row>
                            <Grid.Column className="recipe__info-column-title">
                              CUT INFO
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid columns={2} className="recipe__info-details">
                          {/* Only if Cut Type is Drilling */}
                          {_recipe.cut_type.id === DRILLING_CUT_TYPE_ID ||
                          isTurningCutType(_recipe.cut_type.id) ? (
                            <RecipeField
                              id={_recipe.id}
                              name="feed-per-revolution"
                              label="Feed per Revolution"
                              value={
                                hasValue(_recipe.inches_per_revolution)
                                  ? _recipe.inches_per_revolution
                                  : NO_FIELD_VALUE
                              }
                              convertOptions={{ type: "inches", precision: 4 }}
                              convertible
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                              labelWidth={COLUMN_SIZES.third.label}
                              valueWidth={COLUMN_SIZES.third.value}
                            />
                          ) : (
                            <React.Fragment>
                              <RecipeField
                                id={_recipe.id}
                                name="feed-per-tooth"
                                label="Feed per Tooth"
                                value={
                                  hasValue(_recipe.programmed_ipt)
                                    ? _recipe.programmed_ipt
                                    : NO_FIELD_VALUE
                                }
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                convertOptions={{
                                  type: "inches",
                                  precision: 4,
                                }}
                                convertible
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                                hideOnMobile={true}
                              />
                              <RecipeField
                                id={_recipe.id}
                                name="effective-ipt"
                                label="Effective Feed/Tooth"
                                labelItalics
                                value={
                                  hasValue(_recipe.effective_ipt)
                                    ? _recipe.effective_ipt
                                    : NO_FIELD_VALUE
                                }
                                valueItalics
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                convertOptions={{
                                  type: "inches",
                                  precision: 4,
                                }}
                                convertible
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />
                            </React.Fragment>
                          )}

                          <RecipeField
                            id={_recipe.id}
                            name="feed-per-minute"
                            label="Feed per Minute"
                            value={
                              hasValue(_recipe.ipm)
                                ? numberFormat({
                                    decimalPlaces: 1,
                                    value: _recipe.ipm,
                                  })
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            convertOptions={{ type: "inches" }}
                            convertible
                            labelWidth={COLUMN_SIZES.third.label}
                            valueWidth={COLUMN_SIZES.third.value}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="surface-speed"
                            label="Surface Speed"
                            value={
                              hasValue(_recipe.surface_feet_per_minute)
                                ? numberFormat({
                                    decimalPlaces: 0,
                                    value: _recipe.surface_feet_per_minute,
                                  })
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            convertOptions={{ type: "feet/min" }}
                            convertible
                            labelWidth={COLUMN_SIZES.third.label}
                            valueWidth={COLUMN_SIZES.third.value}
                          />

                          {!isTurningCutType(_recipe.cut_type.id) ||
                          (isTurningCutType(_recipe.cut_type.id) &&
                            !_recipe.programmed_with_const_surface_speed) ? (
                            <RecipeField
                              id={_recipe.id}
                              name="rpm"
                              label="RPM"
                              value={
                                hasValue(_recipe.rpm)
                                  ? _recipe.rpm
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                              labelWidth={COLUMN_SIZES.third.label}
                              valueWidth={COLUMN_SIZES.third.value}
                            />
                          ) : (
                            ""
                          )}

                          {/* Only if Cut Type is not Drilling, Tapping, Reaming, Turning Profile Roughing, or Turning Profile Finishing */}
                          {/*
                        (_recipe.cut_type.id !== DRILLING_CUT_TYPE_ID) ? (
                        */}
                          {/*{![DRILLING_CUT_TYPE_ID, TAPPING_CUT_TYPE_ID, REAMING_CUT_TYPE_ID, TURNING_PROFILE_ROUGHING_CUT_TYPE_ID, TURNING_PROFILE_FINISHING_CUT_TYPE_ID].includes(recipe.cut_type.id) ? (*/}
                          {![
                            DRILLING_CUT_TYPE_ID,
                            TAPPING_CUT_TYPE_ID,
                            REAMING_CUT_TYPE_ID,
                          ].includes(recipe.cut_type.id) &&
                          !isTurningCutType(recipe.cut_type.id) ? (
                            <React.Fragment>
                              <RecipeField
                                id={_recipe.id}
                                name="radial-doc-value"
                                label="RADIAL D.O.C"
                                value={
                                  hasValue(_recipe.radial_depth_of_cut_value)
                                    ? _recipe.radial_depth_of_cut_value
                                    : NO_FIELD_VALUE
                                }
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                convertOptions={{
                                  type: "inches",
                                  precision: 3,
                                }}
                                convertible
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />

                              <RecipeField
                                id={_recipe.id}
                                name="radial-doc-percent"
                                label="RADIAL D.O.C %"
                                value={
                                  hasValue(_recipe.radial_depth_of_cut_percent)
                                    ? _recipe.radial_depth_of_cut_percent
                                    : NO_FIELD_VALUE
                                }
                                // roundNumber={true}
                                precision={3}
                                isPercent={true}
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />

                              <RecipeField
                                id={_recipe.id}
                                name="axial-doc-value"
                                label="AXIAL D.O.C"
                                value={
                                  hasValue(_recipe.axial_depth_of_cut_value)
                                    ? _recipe.axial_depth_of_cut_value
                                    : NO_FIELD_VALUE
                                }
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                convertOptions={{
                                  type: "inches",
                                  precision: 3,
                                }}
                                convertible
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />

                              <RecipeField
                                id={_recipe.id}
                                name="axial-doc-percent"
                                label="AXIAL D.O.C %"
                                value={
                                  hasValue(_recipe.axial_depth_of_cut_percent)
                                    ? _recipe.axial_depth_of_cut_percent
                                    : NO_FIELD_VALUE
                                }
                                // roundNumber={true}
                                precision={3}
                                isPercent={true}
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />
                            </React.Fragment>
                          ) : (
                            ""
                          )}

                          {/* Only if Cut Type is Turning */}
                          {/*{(recipe.cut_type.id === TURNING_PROFILE_ROUGHING_CUT_TYPE_ID) ? (*/}
                          {isTurningRoughingCutType(recipe.cut_type.id) ? (
                            <React.Fragment>
                              <RecipeField
                                id={recipe.id}
                                name="depth-of-cut"
                                label="D.O.C"
                                value={
                                  hasValue(recipe.depth_of_cut)
                                    ? recipe.depth_of_cut
                                    : NO_FIELD_VALUE
                                }
                                minTier="2"
                                recipe={recipe}
                                isPro={recipe.pro_tier}
                                checkProWithTier={true}
                                convertOptions={{
                                  type: "inches",
                                  precision: 3,
                                }}
                                convertible
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />
                            </React.Fragment>
                          ) : (
                            ""
                          )}

                          {/* Only if Cut Type is Turning Profile Finishing */}
                          {/*{(recipe.cut_type.id === TURNING_PROFILE_FINISHING_CUT_TYPE_ID) ? (*/}
                          {isTurningFinishingCutType(recipe.cut_type.id) ? (
                            <React.Fragment>
                              <RecipeField
                                id={recipe.id}
                                name="depth-of-cut-x"
                                label="D.O.C X"
                                value={
                                  hasValue(recipe.depth_of_cut_x)
                                    ? recipe.depth_of_cut_x
                                    : NO_FIELD_VALUE
                                }
                                minTier="2"
                                recipe={recipe}
                                isPro={recipe.pro_tier}
                                checkProWithTier={true}
                                convertOptions={{
                                  type: "inches",
                                  precision: 3,
                                }}
                                convertible
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />
                              <RecipeField
                                id={recipe.id}
                                name="depth-of-cut-z"
                                label="D.O.C Z"
                                value={
                                  hasValue(recipe.depth_of_cut_z)
                                    ? recipe.depth_of_cut_z
                                    : NO_FIELD_VALUE
                                }
                                minTier="2"
                                recipe={recipe}
                                isPro={recipe.pro_tier}
                                checkProWithTier={true}
                                convertOptions={{
                                  type: "inches",
                                  precision: 3,
                                }}
                                convertible
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />
                            </React.Fragment>
                          ) : (
                            ""
                          )}

                          {/*<RecipeField*/}
                          {/*id={_recipe.id}*/}
                          {/*name="tool-entry"*/}
                          {/*label="Tool Entry"*/}
                          {/*value={!isEmpty(_recipe.tool_entry) ? _recipe.tool_entry.name : NO_FIELD_VALUE}*/}
                          {/*minTier="2"*/}
                          {/*/>*/}

                          {/* Only if Cut Type is Drilling */}
                          {_recipe.cut_type.id === DRILLING_CUT_TYPE_ID ? (
                            <React.Fragment>
                              <RecipeField
                                id={_recipe.id}
                                name="drilling-type"
                                label="Drilling Type"
                                value={
                                  !isEmpty(_recipe.drilling_type)
                                    ? _recipe.drilling_type.name
                                    : NO_FIELD_VALUE
                                }
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />

                              <RecipeField
                                id={_recipe.id}
                                name="peck-depth"
                                label="Peck Depth"
                                value={
                                  hasValue(_recipe.peck_depth)
                                    ? _recipe.peck_depth
                                    : NO_FIELD_VALUE
                                }
                                convertOptions={{ type: "inches" }}
                                convertible
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />

                              <RecipeField
                                id={_recipe.id}
                                name="peck-depth-reduction"
                                label="Peck Depth Reduction"
                                value={
                                  hasValue(_recipe.peck_depth_reduction)
                                    ? _recipe.peck_depth_reduction
                                    : NO_FIELD_VALUE
                                }
                                convertOptions={{ type: "inches" }}
                                convertible
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />

                              <RecipeField
                                id={_recipe.id}
                                name="minimum-pecking-depth"
                                label="Min. Pecking Depth"
                                value={
                                  hasValue(_recipe.minimum_pecking_depth)
                                    ? _recipe.minimum_pecking_depth
                                    : NO_FIELD_VALUE
                                }
                                convertOptions={{ type: "inches" }}
                                convertible
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />

                              <RecipeField
                                id={_recipe.id}
                                name="accumulated-pecking-depth"
                                label="Accumulated Pecking Depth"
                                value={
                                  hasValue(_recipe.accumulated_pecking_depth)
                                    ? _recipe.accumulated_pecking_depth
                                    : NO_FIELD_VALUE
                                }
                                convertOptions={{ type: "inches" }}
                                convertible
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />

                              <RecipeField
                                id={_recipe.id}
                                name="chip-break-distance"
                                label="Chip Break Distance"
                                value={
                                  hasValue(_recipe.chip_break_distance)
                                    ? _recipe.chip_break_distance
                                    : NO_FIELD_VALUE
                                }
                                convertOptions={{ type: "inches" }}
                                convertible
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />

                              <RecipeField
                                id={_recipe.id}
                                name="dewlling-period"
                                label="Dwelling Period"
                                value={
                                  hasValue(_recipe.dwelling_period)
                                    ? `${_recipe.dwelling_period} s`
                                    : NO_FIELD_VALUE
                                }
                                minTier="2"
                                recipe={_recipe}
                                isPro={_recipe.pro_tier}
                                checkProWithTier={true}
                                labelWidth={COLUMN_SIZES.third.label}
                                valueWidth={COLUMN_SIZES.third.value}
                              />
                            </React.Fragment>
                          ) : (
                            ""
                          )}

                          {/* Only if Tool Type is Chamfer */}
                          {!isEmpty(_recipe.cutting_tools[0]) &&
                          _recipe.cutting_tools[0].type.id ===
                            CHAMFER_TOOL_TYPE_ID ? (
                            <RecipeField
                              id={_recipe.id}
                              name="chamfer-width"
                              label="Chamfer Width"
                              value={
                                hasValue(_recipe.chamfer_width)
                                  ? _recipe.chamfer_width
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                              labelWidth={COLUMN_SIZES.third.label}
                              valueWidth={COLUMN_SIZES.third.value}
                            />
                          ) : (
                            ""
                          )}
                        </Grid>

                        {/* ------------------------------------- Adaptive / Adaptive Both Ways ------------------------------------- */}
                        {(_recipe.cut_type.id === ADAPTIVE_CUT_TYPE_ID ||
                          _recipe.cut_type.id ===
                            ADAPTIVE_BOTH_WAYS_CUT_TYPE_ID ||
                          _recipe.cut_type.id === SLOTTING_CUT_TYPE_ID) &&
                          (hasValue(_recipe.ramp_spindle_speed) ||
                            hasValue(_recipe.ramp_feedrate) ||
                            hasValue(_recipe.ramping_angle) ||
                            hasValue(_recipe.ramp_angle_taper) ||
                            hasValue(_recipe.ramp_clearance_height) ||
                            hasValue(_recipe.helical_ramp_diameter) ||
                            hasValue(_recipe.minimum_ramp_diameter)) && (
                            <Grid.Row className="adaptive">
                              <Grid
                                columns={1}
                                divided
                                className="recipe__info-columns"
                              >
                                <Grid.Row>
                                  <Grid.Column className="recipe__info-column">
                                    <Grid
                                      columns={1}
                                      className="recipe__info-header"
                                    >
                                      <Grid.Row>
                                        <Grid.Column className="recipe__info-column-title">
                                          HELICAL RAMP ENTRY
                                        </Grid.Column>
                                      </Grid.Row>
                                    </Grid>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                              <Grid
                                columns={2}
                                className="recipe__info-details"
                              >
                                {hasValue(_recipe.ramp_spindle_speed) && (
                                  <RecipeField
                                    id={_recipe.id}
                                    name="ramp-spindle-speed"
                                    label="Ramp Spindle Speed (RPM)"
                                    value={
                                      hasValue(_recipe.ramp_spindle_speed)
                                        ? _recipe.ramp_spindle_speed
                                        : NO_FIELD_VALUE
                                    }
                                    minTier="2"
                                    recipe={_recipe}
                                    isPro={_recipe.pro_tier}
                                    checkProWithTier={true}
                                    labelWidth={COLUMN_SIZES.third.label}
                                    valueWidth={COLUMN_SIZES.third.value}
                                  />
                                )}

                                {hasValue(_recipe.ramp_feedrate) && (
                                  <RecipeField
                                    id={_recipe.id}
                                    name="ramp-feedrate"
                                    label="Ramp Feed Rate"
                                    value={
                                      hasValue(_recipe.ramp_feedrate)
                                        ? numberFormat({
                                            decimalPlaces: 2,
                                            value: _recipe.ramp_feedrate,
                                          })
                                        : NO_FIELD_VALUE
                                    }
                                    minTier="2"
                                    recipe={_recipe}
                                    isPro={_recipe.pro_tier}
                                    checkProWithTier={true}
                                    convertOptions={{ type: "inches" }}
                                    convertible
                                    labelWidth={COLUMN_SIZES.third.label}
                                    valueWidth={COLUMN_SIZES.third.value}
                                  />
                                )}

                                {hasValue(_recipe.ramping_angle) && (
                                  <RecipeField
                                    id={_recipe.id}
                                    name="ramping-angle"
                                    label="Ramping Angle"
                                    value={
                                      hasValue(_recipe.ramping_angle)
                                        ? ReactHtmlParser(
                                            `${_recipe.ramping_angle}&deg;`
                                          )
                                        : NO_FIELD_VALUE
                                    }
                                    minTier="2"
                                    recipe={_recipe}
                                    isPro={_recipe.pro_tier}
                                    checkProWithTier={true}
                                    labelWidth={COLUMN_SIZES.third.label}
                                    valueWidth={COLUMN_SIZES.third.value}
                                  />
                                )}

                                {hasValue(_recipe.ramp_angle_taper) && (
                                  <RecipeField
                                    id={_recipe.id}
                                    name="ramping-taper-angle"
                                    label="Ramping Taper Angle "
                                    value={
                                      hasValue(_recipe.ramp_angle_taper)
                                        ? ReactHtmlParser(
                                            `${_recipe.ramp_angle_taper}&deg;`
                                          )
                                        : NO_FIELD_VALUE
                                    }
                                    minTier="2"
                                    recipe={_recipe}
                                    isPro={_recipe.pro_tier}
                                    checkProWithTier={true}
                                    labelWidth={COLUMN_SIZES.third.label}
                                    valueWidth={COLUMN_SIZES.third.value}
                                  />
                                )}

                                {hasValue(_recipe.ramp_clearance_height) && (
                                  <RecipeField
                                    id={_recipe.id}
                                    name="ramp-clearance-height"
                                    label="Ramp Clearance Height"
                                    value={
                                      hasValue(_recipe.ramp_clearance_height)
                                        ? numberFormat({
                                            decimalPlaces: 3,
                                            value:
                                              _recipe.ramp_clearance_height,
                                          })
                                        : NO_FIELD_VALUE
                                    }
                                    minTier="2"
                                    recipe={_recipe}
                                    isPro={_recipe.pro_tier}
                                    checkProWithTier={true}
                                    convertOptions={{ type: "inches" }}
                                    convertible
                                    labelWidth={COLUMN_SIZES.third.label}
                                    valueWidth={COLUMN_SIZES.third.value}
                                  />
                                )}

                                {hasValue(_recipe.helical_ramp_diameter) && (
                                  <RecipeField
                                    id={_recipe.id}
                                    name="helical-ramp-diameter"
                                    label="Helical Ramp Diameter"
                                    value={
                                      hasValue(_recipe.helical_ramp_diameter)
                                        ? numberFormat({
                                            decimalPlaces: 3,
                                            value:
                                              _recipe.helical_ramp_diameter,
                                          })
                                        : NO_FIELD_VALUE
                                    }
                                    minTier="2"
                                    recipe={_recipe}
                                    isPro={_recipe.pro_tier}
                                    checkProWithTier={true}
                                    convertOptions={{ type: "inches" }}
                                    convertible
                                    labelWidth={COLUMN_SIZES.third.label}
                                    valueWidth={COLUMN_SIZES.third.value}
                                  />
                                )}

                                {hasValue(_recipe.minimum_ramp_diameter) && (
                                  <RecipeField
                                    id={_recipe.id}
                                    name="minimum-ramp-diameter"
                                    label="Minimum Ramp Diameter"
                                    value={
                                      hasValue(_recipe.minimum_ramp_diameter)
                                        ? numberFormat({
                                            decimalPlaces: 3,
                                            value:
                                              _recipe.minimum_ramp_diameter,
                                          })
                                        : NO_FIELD_VALUE
                                    }
                                    minTier="2"
                                    recipe={_recipe}
                                    isPro={_recipe.pro_tier}
                                    checkProWithTier={true}
                                    convertOptions={{ type: "inches" }}
                                    convertible
                                    labelWidth={COLUMN_SIZES.third.label}
                                    valueWidth={COLUMN_SIZES.third.value}
                                  />
                                )}
                              </Grid>
                            </Grid.Row>
                          )}

                        {/* ------------------------------------- MODIFIERS ------------------------------------- */}
                        {isAuthenticated() &&
                        userTierAccess({ minTier: "1" }) ? (
                          <Grid.Row className="modifiers">
                            <Grid
                              columns={1}
                              divided
                              className="recipe__info-columns"
                            >
                              <Grid.Row>
                                <Grid.Column className="recipe__info-column">
                                  <Grid
                                    columns={2}
                                    className="recipe__info-header"
                                  >
                                    <Grid.Row>
                                      <Grid.Column className="recipe__info-column-title">
                                        MODIFIED RECIPE
                                      </Grid.Column>
                                      <Grid.Column className="recipe__info-column-title recipe__tool-add-my-recipe-modifiers">
                                        <div>
                                          <Link
                                            to="/account#recipe-modifiers"
                                            target="_blank"
                                          >
                                            + Add Modifiers
                                          </Link>
                                        </div>
                                      </Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                  <Grid
                                    columns={2}
                                    className="recipe__info-details"
                                  >
                                    {!isEmpty(userRecipeModifierOptions) ? (
                                      <Dropdown
                                        placeholder="Select Modifier"
                                        className="modifier-dropdown"
                                        //text='Select Modifier'
                                        //button
                                        //icon='filter'
                                        fluid
                                        search
                                        selection
                                        defaultValue={
                                          userRecipeModifierOptions[0].value
                                        }
                                        loading={
                                          settings.fetchingUserRecipeModifiers
                                        }
                                        options={userRecipeModifierOptions}
                                        onChange={changeRecipeModifier}
                                      />
                                    ) : (
                                      ""
                                    )}

                                    {!isEmpty(
                                      userRecipeModifierCalculatedValues
                                    ) ? (
                                      <React.Fragment>
                                        {// if modifier rpm > recipe rpm AND machine max rpm != recipe rpm
                                        userRecipeModifierCalculatedValues.rpm >
                                          _recipe.rpm &&
                                        hasValue(
                                          _recipe.cnc_machine.max_rpms
                                        ) &&
                                        _recipe.cnc_machine.max_rpms !==
                                          _recipe.rpm ? (
                                          showMessage({
                                            messageType: "modifier_invalid_rpm",
                                            isError: true,
                                            //messageText: "<em>Modifier RPMs exceeds Recipe RPMs</em>"
                                          })
                                        ) : (
                                          <React.Fragment>
                                            {// in this case we're always going to show the calculated fields, but check if we should also so a warning
                                            // if modifier rpm > recipe rpm AND machine max rpm = recipe rpm
                                            userRecipeModifierCalculatedValues.rpm >
                                              _recipe.rpm &&
                                            hasValue(
                                              _recipe.cnc_machine.max_rpms
                                            ) &&
                                            _recipe.cnc_machine.max_rpms ===
                                              _recipe.rpm ? (
                                              showMessage({
                                                messageType:
                                                  "modifer_higher_and_max_equals_rpms",
                                                isError: true,
                                                //messageText: "<em>Modifier RPMs exceeds Recipe RPMs</em>"
                                              })
                                            ) : (
                                              <React.Fragment />
                                            )}

                                            <RecipeField
                                              id={_recipe.id}
                                              name="modifier-rpm"
                                              label="Modified RPM"
                                              value={
                                                !isEmpty(
                                                  userRecipeModifierCalculatedValues.rpm
                                                )
                                                  ? userRecipeModifierCalculatedValues.rpm
                                                  : NO_FIELD_VALUE
                                              }
                                              minTier="2"
                                              recipe={_recipe}
                                              isPro={_recipe.pro_tier}
                                              checkProWithTier={true}
                                              labelWidth={
                                                COLUMN_SIZES.third.label
                                              }
                                              valueWidth={
                                                COLUMN_SIZES.third.value
                                              }
                                            />

                                            <RecipeField
                                              id={_recipe.id}
                                              name="modifier-surface-speed"
                                              label="Surface Speed"
                                              value={
                                                !isEmpty(
                                                  userRecipeModifierCalculatedValues.sfpm
                                                )
                                                  ? numberFormat({
                                                      decimalPlaces: 0,
                                                      value:
                                                        userRecipeModifierCalculatedValues.sfpm,
                                                    })
                                                  : NO_FIELD_VALUE
                                              }
                                              minTier="2"
                                              recipe={_recipe}
                                              isPro={_recipe.pro_tier}
                                              checkProWithTier={true}
                                              convertOptions={{
                                                type: "feet/min",
                                              }}
                                              convertible
                                              labelWidth={
                                                COLUMN_SIZES.third.label
                                              }
                                              valueWidth={
                                                COLUMN_SIZES.third.value
                                              }
                                            />

                                            <RecipeField
                                              id={_recipe.id}
                                              name="modifier-feed-per-minute"
                                              label="Feed per Minute"
                                              value={
                                                !isEmpty(
                                                  userRecipeModifierCalculatedValues.ipm
                                                )
                                                  ? numberFormat({
                                                      decimalPlaces: 1,
                                                      value:
                                                        userRecipeModifierCalculatedValues.ipm,
                                                    })
                                                  : NO_FIELD_VALUE
                                              }
                                              minTier="2"
                                              recipe={_recipe}
                                              isPro={_recipe.pro_tier}
                                              checkProWithTier={true}
                                              convertOptions={{
                                                type: "inches",
                                                precision: 1,
                                              }}
                                              convertible
                                              labelWidth={
                                                COLUMN_SIZES.third.label
                                              }
                                              valueWidth={
                                                COLUMN_SIZES.third.value
                                              }
                                            />

                                            <RecipeField
                                              id={_recipe.id}
                                              name="mrr"
                                              label="MRR"
                                              value={numberFormat({
                                                decimalPlaces: 2,
                                                value:
                                                  userRecipeModifierCalculatedValues.mrr,
                                              })}
                                              convertible
                                              labelWidth={
                                                COLUMN_SIZES.third.label
                                              }
                                              valueWidth={
                                                COLUMN_SIZES.third.value
                                              }
                                            />

                                            <RecipeField
                                              id={_recipe.id}
                                              name="modifier-estimated-horsepower"
                                              label="Est Horsepower"
                                              value={
                                                !isEmpty(
                                                  userRecipeModifierCalculatedValues.horsepower
                                                )
                                                  ? userRecipeModifierCalculatedValues.horsepower
                                                  : NO_FIELD_VALUE
                                              }
                                              minTier="2"
                                              recipe={_recipe}
                                              isPro={_recipe.pro_tier}
                                              checkProWithTier={true}
                                              labelWidth={
                                                COLUMN_SIZES.third.label
                                              }
                                              valueWidth={
                                                COLUMN_SIZES.third.value
                                              }
                                            />
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Grid.Row>
                        ) : (
                          <React.Fragment />
                        )}
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      {/* ------------------------------------- ROW 2 COLUMN 1 ------------------------------------- */}
                      {!isEmpty(_recipe.milling_tool_holders[0]) &&
                      _recipe.milling_tool_holders[0].id > 0 ? (
                        <Grid.Column className="recipe__info-column">
                          <Grid columns={1} className="recipe__info-header">
                            <Grid.Row>
                              <Grid.Column className="recipe__info-column-title">
                                HOLDER INFO
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                          <Grid columns={2} className="recipe__info-details">
                            <RecipeField
                              id={_recipe.id}
                              name="holder-known-as"
                              label="Known As"
                              value={
                                !isEmpty(_recipe.milling_tool_holders[0].aka)
                                  ? _recipe.milling_tool_holders[0].aka
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="holder-brand"
                              label="Brand"
                              value={
                                !isEmpty(_recipe.milling_tool_holders[0].brand)
                                  ? _recipe.milling_tool_holders[0].brand.title
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="holder-part-number"
                              label="Part Number"
                              value={
                                !isEmpty(
                                  _recipe.milling_tool_holders[0].part_number
                                )
                                  ? _recipe.milling_tool_holders[0].part_number
                                  : NO_FIELD_VALUE
                              }
                              isText={true}
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            {/*<RecipeField*/}
                            {/*id={_recipe.id}*/}
                            {/*name="holder-material"*/}
                            {/*label="Material"*/}
                            {/*value={!isEmpty(_recipe.milling_tool_holders[0].material) ? _recipe.milling_tool_holders[0].material.name : NO_FIELD_VALUE}*/}
                            {/*minTier="2"*/}
                            {/*/>*/}

                            <RecipeField
                              id={_recipe.id}
                              name="holder-style"
                              label="Style"
                              value={
                                !isEmpty(_recipe.milling_tool_holders[0].style)
                                  ? _recipe.milling_tool_holders[0].style.name
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            {/*<RecipeField*/}
                            {/*id={_recipe.id}*/}
                            {/*name="holder-collet"*/}
                            {/*label="Collet"*/}
                            {/*value={!isEmpty(_recipe.milling_tool_holders[0].collet) ? _recipe.milling_tool_holders[0].collet.name : NO_FIELD_VALUE}*/}
                            {/*minTier="2"*/}
                            {/*/>*/}

                            <RecipeField
                              id={_recipe.id}
                              name="holder-url"
                              label="URL"
                              value={
                                !isEmpty(_recipe.milling_tool_holders[0]) &&
                                !isEmpty(_recipe.milling_tool_holders[0].url)
                                  ? "Click here to view"
                                  : ""
                              }
                              url={
                                !isEmpty(_recipe.milling_tool_holders[0]) &&
                                !isEmpty(_recipe.milling_tool_holders[0].url)
                                  ? _recipe.milling_tool_holders[0].url
                                  : ""
                              }
                              urlOptions={{
                                target: "_blank",
                                rel: "noopener noreferrer",
                              }}
                              isURL={
                                !isEmpty(_recipe.milling_tool_holders[0]) &&
                                !isEmpty(_recipe.milling_tool_holders[0].url)
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="holder-purchase-url"
                              label="Purchase URL"
                              value={
                                !isEmpty(_recipe.milling_tool_holders[0]) &&
                                !isEmpty(
                                  _recipe.milling_tool_holders[0].purchase_url
                                )
                                  ? "Click here to view"
                                  : ""
                              }
                              url={
                                !isEmpty(_recipe.milling_tool_holders[0]) &&
                                !isEmpty(
                                  _recipe.milling_tool_holders[0].purchase_url
                                )
                                  ? _recipe.milling_tool_holders[0].purchase_url
                                  : ""
                              }
                              urlOptions={{
                                target: "_blank",
                                rel: "noopener noreferrer",
                              }}
                              isURL={
                                !isEmpty(_recipe.milling_tool_holders[0]) &&
                                !isEmpty(
                                  _recipe.milling_tool_holders[0].purchase_url
                                )
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />
                          </Grid>
                        </Grid.Column>
                      ) : (
                        ""
                      )}

                      {/* ------------------------------------- ROW 2 COLUMN 2 ------------------------------------- */}
                      {/*{debug.log("insert", _recipe.indexable_inserts[0].id)}*/}
                      {!isEmpty(_recipe.indexable_inserts[0]) &&
                      _recipe.indexable_inserts[0].id > 0 ? (
                        <Grid.Column className="recipe__info-column">
                          <div className="recipe__info-column-title">
                            INSERT INFO
                          </div>
                          <Grid columns={2} className="recipe__info-details">
                            <RecipeField
                              id={_recipe.id}
                              name="insert-known-as"
                              label="Known As"
                              value={
                                !isEmpty(_recipe.indexable_inserts[0].aka)
                                  ? _recipe.indexable_inserts[0].aka
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-brand"
                              label="Brand"
                              value={
                                !isEmpty(_recipe.indexable_inserts[0].brand)
                                  ? _recipe.indexable_inserts[0].brand.title
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-part-number"
                              label="Part Number"
                              value={
                                !isEmpty(
                                  _recipe.indexable_inserts[0].part_number
                                )
                                  ? _recipe.indexable_inserts[0].part_number
                                  : NO_FIELD_VALUE
                              }
                              isText={true}
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-material"
                              label="Material"
                              value={
                                !isEmpty(_recipe.indexable_inserts[0].material)
                                  ? _recipe.indexable_inserts[0].material.name
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-number-of-edges"
                              label="Number of Edges"
                              value={
                                hasValue(
                                  _recipe.indexable_inserts[0].number_of_edges
                                )
                                  ? _recipe.indexable_inserts[0].number_of_edges
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-corner-radius"
                              label="Corner Radius"
                              value={
                                hasValue(
                                  _recipe.indexable_inserts[0].corner_radius
                                )
                                  ? _recipe.indexable_inserts[0].corner_radius
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-url"
                              label="URL"
                              value={
                                !isEmpty(_recipe.indexable_inserts[0]) &&
                                !isEmpty(_recipe.indexable_inserts[0].url)
                                  ? "Click here to view"
                                  : ""
                              }
                              url={
                                !isEmpty(_recipe.indexable_inserts[0]) &&
                                !isEmpty(_recipe.indexable_inserts[0].url)
                                  ? _recipe.indexable_inserts[0].url
                                  : ""
                              }
                              urlOptions={{
                                target: "_blank",
                                rel: "noopener noreferrer",
                              }}
                              isURL={
                                !isEmpty(_recipe.indexable_inserts[0]) &&
                                !isEmpty(_recipe.indexable_inserts[0].url)
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-purchase-url"
                              label="Purchase URL"
                              value={
                                !isEmpty(_recipe.indexable_inserts[0]) &&
                                !isEmpty(
                                  _recipe.indexable_inserts[0].purchase_url
                                )
                                  ? "Click here to view"
                                  : ""
                              }
                              url={
                                !isEmpty(_recipe.indexable_inserts[0]) &&
                                !isEmpty(
                                  _recipe.indexable_inserts[0].purchase_url
                                )
                                  ? _recipe.indexable_inserts[0].purchase_url
                                  : ""
                              }
                              urlOptions={{
                                target: "_blank",
                                rel: "noopener noreferrer",
                              }}
                              isURL={
                                !isEmpty(_recipe.indexable_inserts[0]) &&
                                !isEmpty(
                                  _recipe.indexable_inserts[0].purchase_url
                                )
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-price"
                              label="Price (USD)"
                              value={
                                !isEmpty(_recipe.indexable_inserts[0])
                                  ? _recipe.indexable_inserts[0].price
                                  : NO_FIELD_VALUE
                              }
                              isPrice={!isEmpty(_recipe.indexable_inserts[0])}
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-price-date"
                              label="Price Date"
                              value={
                                !isEmpty(_recipe.indexable_inserts[0])
                                  ? _recipe.indexable_inserts[0].date_of_price
                                  : NO_FIELD_VALUE
                              }
                              isDate={!isEmpty(_recipe.indexable_inserts[0])}
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-iso"
                              label="ISO"
                              value={
                                !isEmpty(_recipe.indexable_inserts[0].iso)
                                  ? _recipe.indexable_inserts[0].iso
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />

                            <RecipeField
                              id={_recipe.id}
                              name="insert-ansi"
                              label="ANSI"
                              value={
                                !isEmpty(_recipe.indexable_inserts[0].ansi)
                                  ? _recipe.indexable_inserts[0].ansi
                                  : NO_FIELD_VALUE
                              }
                              minTier="2"
                              recipe={_recipe}
                              isPro={_recipe.pro_tier}
                              checkProWithTier={true}
                            />
                          </Grid>
                        </Grid.Column>
                      ) : (
                        ""
                      )}

                      {/* ------------------------------------- ROW 2 COLUMN 3 ------------------------------------- */}
                      <Grid.Column className="recipe__info-column">
                        <Grid columns={1} className="recipe__info-header">
                          <Grid.Row>
                            <Grid.Column className="recipe__info-column-title">
                              MACHINE INFO
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid columns={2} className="recipe__info-details">
                          <RecipeField
                            id={_recipe.id}
                            name="machine-brand"
                            label="Brand"
                            value={
                              !isEmpty(_recipe.cnc_machine.brand)
                                ? _recipe.cnc_machine.brand.title
                                : NO_FIELD_VALUE
                            }
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="machine-part-number"
                            label="Part Number"
                            value={
                              !isEmpty(_recipe.cnc_machine.part_number)
                                ? _recipe.cnc_machine.part_number.name
                                : NO_FIELD_VALUE
                            }
                            isText={true}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="machine-year"
                            label="Year"
                            value={
                              hasValue(_recipe.cnc_machine.year)
                                ? _recipe.cnc_machine.year
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                            isText={true}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="machine-horsepower"
                            label="Horsepower"
                            value={
                              hasValue(_recipe.cnc_machine.spindle_horsepower)
                                ? _recipe.cnc_machine.spindle_horsepower
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="machine-spindle-taper"
                            label="Spindle Taper"
                            // value={hasValue(_recipe.cnc_machine.spindle_taper) ? _recipe.cnc_machine.spindle_taper : NO_FIELD_VALUE}
                            value={
                              !isEmpty(_recipe.cnc_machine.spindle_taper)
                                ? _recipe.cnc_machine.spindle_taper.name
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="machine-max-rpms"
                            label="Max RPM"
                            value={
                              hasValue(_recipe.cnc_machine.max_rpms)
                                ? _recipe.cnc_machine.max_rpms
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                          />

                          <RecipeField
                            id={_recipe.id}
                            name="machine-tsc-psi"
                            label="TSC PSI"
                            value={
                              hasValue(_recipe.cnc_machine.tsc_psi)
                                ? _recipe.cnc_machine.tsc_psi
                                : NO_FIELD_VALUE
                            }
                            minTier="2"
                            recipe={_recipe}
                            isPro={_recipe.pro_tier}
                            checkProWithTier={true}
                          />
                        </Grid>
                      </Grid.Column>

                      {/* ------------------------------------- ROW 2 COLUMN 4 ------------------------------------- */}
                      {userTierAccess({ minTier: "1.0.0" }) ? (
                        <Grid.Column className="recipe__info-column">
                          <Grid columns={1} className="recipe__info-header">
                            <Grid.Row>
                              <Grid.Column className="recipe__info-column-title">
                                OTHER TOOLS
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                          <Grid columns={2} className="recipe__info-details">
                            <Button
                              animated
                              secondary
                              className=""
                              onClick={handleOpenCalculatorPage}
                            >
                              <Button.Content visible>
                                Speeds & Feeds Calculator
                              </Button.Content>
                              <Button.Content hidden>
                                <Icon name="arrow right" />
                              </Button.Content>
                            </Button>
                          </Grid>
                        </Grid.Column>
                      ) : (
                        ""
                      )}

                      <Grid.Column className="recipe__info-column">
                        <Grid columns={1} className="">
                          <Grid.Row>
                            <Button
                              textAlign="center"
                              className="show-more-btn"
                              onClick={handleToggleShowMore}
                            >
                              Show More
                            </Button>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Row>

                {/* ------------------------------------- BOTTOM NOTICES ------------------------------------- */}
                <Grid.Row className="bottom-notices">
                  <Grid columns={1} divided className="recipe__info-columns">
                    {isAuthenticated() &&
                    ((userTierAccess({ minTier: "1" }) && !_recipe.pro_tier) ||
                      userTierAccess({ minTier: "2" })) ? (
                      <Grid.Row>
                        {/* ------------------------------------- ROW REPORT COLUMN 1 ------------------------------------- */}
                        <Grid.Column className="recipe__info-column">
                          <Grid columns={2} className="recipe__info-details">
                            <a
                              className="recipe-issue-link"
                              href={`/report-recipe-issue?recipe_id=${_recipe.id}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Click here to report an issue with this Recipe
                            </a>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid.Row>

                {/* ------------------------------------- COMMENTS ------------------------------------- */}
                {isAuthenticated() &&
                userTierAccess({ minTier: "1" }) &&
                !_recipe.disable_comments ? (
                  <Grid.Row className="comments" id="comments">
                    <Grid columns={1} divided className="recipe__info-columns">
                      <Grid.Row>
                        <Grid.Column width={16} className="recipe__info-column">
                          <Grid className="recipe__info-details">
                            {/*<Disqus.DiscussionEmbed shortname={DEFAULT_DISQUS_SHORTNAME} config={disqusConfig}/>*/}
                            {/*<Commento id="recipe-commento"/>*/}
                            <div className="commentbox" />
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Row>
                ) : (
                  ""
                )}
              </Grid>
            </Grid.Row>

            <Grid.Row className="recipe-toolbar__wrapper">
              {!isAuthenticated() || !userTierAccess({ minTier: "1" }) ? (
                <CallToAction position="bottom" />
              ) : (
                ""
              )}
              <RecipeToolbar position="bottom" />
            </Grid.Row>
            {/*{console.log("showUserSurveyCTA", settings.showUserSurveyCTA, "offered_user_survey", store.get("offered_user_survey"))}*/}
            {userTierAccess({ minTier: "1" }) &&
            showUserSurveyCTA &&
            !store.get("visited_user_survey") ? (
              <UserSurveyCTA />
            ) : (
              ""
            )}
          </Grid>
        ) : (
          <div>Loading</div>
        )}
      </Visibility>
    </Layout>
  );
}

// ---------------------------------------------- //RECIPE COMPONENT ---------------------------------------------- //

function mapStateToProps(state) {
  // console.log("Recipe: Map State to Props", state);
  return {
    recipe: state.recipesData.recipe,
    filters: state.recipesData.filters,
    settings: state.settings,
    user: state.userData,
    userRecipes: state.userRecipesData,
    userRecipeModifiers: state.userRecipeModifiersData,
    userTools: state.userToolsData,
  };
}

export default connect(
  mapStateToProps,
  {
    retrieveRecipe,
    createUserRecipe,
    deleteUserRecipe,
    // toggleUserRecipe,
    findUserRecipeModifiers,
    createUserTool,
    updateSettings,
  }
)(Recipe);
