import isEmpty from "lodash/isEmpty";
import { RETRIEVED_PART, RETRIEVED_PARTS } from "../../common/consts";
import { debug } from "../../common/helpers";
import api from "../api";
import { updateSettings } from "./settings";

export const retrieveParts = partCriteria => dispatch => {
  window.store.dispatch(updateSettings({fetchingParts: true}));
  api.parts.retrieve(partCriteria).then(partsResponse => {
      //console.log("Retrieve Parts: response", partsResponse);
      debug.log("Retrieve Parts: response", partsResponse);
      window.store.dispatch(updateSettings({fetchingParts: false}));
      const globalState = window.store.getState();
      if (!isEmpty(globalState.settings.activePartFilters)) {
        window.store.dispatch(updateSettings({userInteractionEnableUserSurveyCTA: true}));
      }
      dispatch(retrievedParts(partsResponse));
      window.store.dispatch(updateSettings({partsTime: partsResponse.response.time}));
    }
  );
};

export const retrievedParts = partCriteria => ({
  type: RETRIEVED_PARTS,
  criteria: partCriteria
});

export const retrievePart = partCriteria => dispatch => {
  window.store.dispatch(updateSettings({fetchingPart: true}));
  api.part.retrieve(partCriteria).then(partResponse => {
      //console.log("Retrieve Part: response", partResponse);
      debug.log("Retrieve Part: response", partResponse);
      window.store.dispatch(updateSettings({fetchingPart: false}));
      dispatch(retrievedPart(partResponse));
      window.store.dispatch(updateSettings({partTime: partResponse.response.time}));
    }
  );
};

export const retrievedPart = partCriteria => ({
  type: RETRIEVED_PART,
  criteria: partCriteria
});