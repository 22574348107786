import { updateSettings } from "./settings";
import api from "../api";
import {
  CREATED_GROUP_MEMBER,
  FOUND_GROUP_MEMBERS,
  RESENT_GROUP_MEMBER_INVITE,
  UPDATED_GROUP_MEMBER
} from "../../common/consts";

export const findGroupMembers = foundGroupMembersCriteria => dispatch => {
  window.store.dispatch(updateSettings({fetchingGroupMembers: true}));
  api.groups.retrieve_members(foundGroupMembersCriteria).then(groupMembersResponse => {
      //debug.log("Finding Group Members: response", groupMembersResponse);
      window.store.dispatch(updateSettings({fetchingGroupMembers: false}));
      dispatch(foundGroupMembers(groupMembersResponse));
    }
  );
};

export const foundGroupMembers = foundGroupMembersCriteria => ({
  type: FOUND_GROUP_MEMBERS,
  criteria: foundGroupMembersCriteria
});

export const updateGroupMember = updateGroupMemberCriteria => dispatch => {
  window.store.dispatch(updateSettings({updatingGroupMember: true}));
  api.groups.update_member(updateGroupMemberCriteria).then(updateGroupMemberResponse => {
      //debug.log("Updating Group Member: response", updateGroupMemberResponse);
      window.store.dispatch(updateSettings({updatingGroupMember: false}));
      dispatch(updatedGroupMember(updateGroupMemberResponse));
    }
  );
};

export const updatedGroupMember = updateGroupMemberCriteria => ({
  type: UPDATED_GROUP_MEMBER,
  criteria: updateGroupMemberCriteria
});

export const createGroupMember = createGroupMemberCriteria => dispatch => {
  window.store.dispatch(updateSettings({creatingGroupMember: true}));
  api.groups.create_member(createGroupMemberCriteria).then(createGroupMemberResponse => {
      //debug.log("Creating Group Member: response", createGroupMemberResponse);
      window.store.dispatch(updateSettings({creatingGroupMember: false}));
      dispatch(createdGroupMember(createGroupMemberResponse));
    }
  );
};

export const createdGroupMember = createGroupMemberCriteria => ({
  type: CREATED_GROUP_MEMBER,
  criteria: createGroupMemberCriteria
});

export const resendGroupMemberInvite = groupMemberInviteCriteria => dispatch => {
  window.store.dispatch(updateSettings({resendingGroupMemberInvite: true}));
  api.groups.resend_invite(groupMemberInviteCriteria).then(groupMemberInviteResponse => {
      //console.log("Resending Group Member Invite: response", groupMemberInviteResponse);
      window.store.dispatch(updateSettings({resendingGroupMemberInvite: false}));
      dispatch(resentGroupMemberInvite(groupMemberInviteResponse));
    }
  );
};

export const resentGroupMemberInvite = groupMemberInviteCriteria => ({
  type: RESENT_GROUP_MEMBER_INVITE,
  criteria: groupMemberInviteCriteria
});

