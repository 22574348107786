import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import App from "./App";
import { stripePublicAPIKey } from "./common/helpers";
import configureStore from "./redux/store/store";
import * as serviceWorker from "./serviceWorker";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";

window.store = configureStore();

// https://www.npmjs.com/package/@datapunt/matomo-tracker-react
const matomoInstance = createInstance({
  urlBase: "https://matomo.provencut.com/",
  siteId: 2, // optional, default value: `1`
  //trackerUrl: "https://LINK.TO.DOMAIN/tracking.php", // optional, default value: `${urlBase}matomo.php`
  //srcUrl: "https://LINK.TO.DOMAIN/tracking.js" // optional, default value: `${urlBase}matomo.js`
});

//console.log(window);
ReactDOM.render(<MatomoProvider value={matomoInstance}>
  <StripeProvider apiKey={stripePublicAPIKey()}><BrowserRouter><Provider store={window.store}><Route
    component={App}/></Provider></BrowserRouter></StripeProvider></MatomoProvider>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();

