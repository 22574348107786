import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Elements } from "react-stripe-elements";
import { Grid, Header } from "semantic-ui-react";
import { DEFAULT_AUTHENTICATED_ROUTE } from "../../common/consts";
import { isAuthenticated, showMessage } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
import Layout from "../global/Layout";
import FreeSignUpForm from "./FreeSignUpForm";
import isEmpty from "lodash/isEmpty";

function FreeSignUp (props) {
  const {settings, user} = props;
  //console.log("sign up", props);

  useEffect(() => {
    //console.log("useEffect: SignUp", props);

    // if (settings.activeMenuItem !== "signup") {
    //   updateSettings({activeMenuItem: "signup"});
    // }

  }, [settings]);

  if (isAuthenticated() && !user.new) {
    return <Redirect to={DEFAULT_AUTHENTICATED_ROUTE}/>;
  }

  if (isAuthenticated() && user.new) {
    // return <Redirect to="/recipes?referrer=signup#message=new_testdrive_signup"/>;
    return <Redirect to="/recipes?referrer=signup"/>;
  }

  return (
    <Elements>
      <Layout>
        <Grid className="signup-content free-signup content">
          <Grid.Row className="signup-content__heading">
            <Grid.Column className="signup-content__header" width={5}><Header as="h1">Test Drive</Header>
              <p>Already have an account? <a href="/login">Login</a> here.</p>
              {!isEmpty(user.code) ? showMessage({messageType: user.code, isError: true}) : ""}
            </Grid.Column>
          </Grid.Row>
          <FreeSignUpForm/>
        </Grid>
      </Layout></Elements>);
}

function mapStateToProps (state) {
  //console.log("SignUp: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    order: state.orderData,
  };
}

export default connect(mapStateToProps, {updateSettings})(FreeSignUp);