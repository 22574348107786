import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Checkbox, Dimmer, Grid, Header, Icon, Label, Loader, Modal, Table } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";

import { updateSettings } from "../../redux/actions/settings";
import {
  createGroupMember,
  findGroupMembers,
  resendGroupMemberInvite,
  updateGroupMember
} from "../../redux/actions/groups";
import { debug, hasValue, showMessage, tokenize } from "../../common/helpers";
import isEmpty from "lodash/isEmpty";
import Moment from "react-moment";
import indexOf from "lodash/indexOf";
import find from "lodash/find";
import get from "lodash/get";
import classnames from "classnames";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function GroupTable (props) {
  const {findGroupMembers, updateGroupMember, createGroupMember, resendGroupMemberInvite, settings, groups, _group} = props;

  const [initGroups, setInitGroups] = useState(true);
  const [removeGroupMemberModalOpen, setRemoveGroupMemberModalOpen] = useState(false);
  const [removeGroupMemberData, setRemoveGroupMemberData] = useState({});
  const [createGroupMemberFormValid, setCreateGroupMemberFormValid] = useState(false);
  const [group, setGroup] = useState({..._group});

  const createGroupMemberFormRef = createRef();

  useEffect(() => {
    if (initGroups) {
      setInitGroups(false);
      //debug.log("_group", _group);
      findGroupMembers({group_id: _group.id});
    }

    //debug.log("groups", groups);
    if (!isEmpty(groups.result)) {
      setGroup({...groups.info[_group.id], members: groups.members[_group.id]});
    }

    if (groups.createGroupMemberMessage && !isEmpty(groups.result) && !isEmpty(groups.message) && hasValue(_group.id)) {
      debug.log("reset form");
      createGroupMemberFormRef.current.reset();
    }

  }, [settings, groups]);

  // const sortBy = options => {
  //   //
  // };

  const changeValid = options => {
    //debug.log("valid", options);
    setCreateGroupMemberFormValid(true);
  };

  const changeInvalid = options => {
    // console.log("invalid");
    setCreateGroupMemberFormValid(false);
  };

  const isGroupMemberActive = options => {
    return indexOf(options.member.roles, "group member") > -1;
  };

  const addGroupMember = (data, e) => {
    data.group_id = _group.id;
    debug.log("add group member data", data, e);
    createGroupMember({data});
  };

  const resendInvite = (data) => {
    data.group_id = _group.id;
    debug.log("resend invite data", data);

    resendGroupMemberInvite({data});
  };

  const showRemoveGroupMemberModal = (data) => {
    //debug.log("data", data);

    data.groupMember = groupMember({uid: data.uid});

    setRemoveGroupMemberData(data);
    setRemoveGroupMemberModalOpen(true);
  };

  const hideRemoveGroupMemberModal = () => {
    setRemoveGroupMemberModalOpen(false);
  };

  const removeGroupMember = () => {
    debug.log("remove group member", removeGroupMemberData);
    hideRemoveGroupMemberModal();

    removeGroupMemberData.disableGroupMember = true;

    let data = baseUpdateGroupMember(removeGroupMemberData);
    data.remove = true;

    debug.log("remove group member data", data);
    updateGroupMember({data});
  };

  const cancelRemoveGroupMember = () => {
    hideRemoveGroupMemberModal();
  };

  const allowToggleGroupMember = options => {
    const allowToggleActive = group.active_group_members_count < group.seats;
    //debug.log("inactive members", inactiveGroupMembers, "max inactive group members", maxInactiveGroupMembers);
    const allowToggleInactive = group.inactive_group_members_count < group.max_inactive_group_members;
    const isActiveGroupMember = isGroupMemberActive({member: options.member});
    //debug.log("is active", isActiveGroupMember, "allow toggle active", allowToggleActive, "allow toggle inactive", allowToggleInactive);

    if (isActiveGroupMember) {
      //debug.log("is active", isActiveGroupMember, "allow toggle inactive", allowToggleInactive);
      return allowToggleInactive;
    } else {
      //debug.log("is inactive", allowToggleActive, "allow toggle active", allowToggleActive);
      return allowToggleActive;
    }
  };

  const allowCreateGroupMember = options => {
    const groupHasOpenSlots = group.active_group_members_count < group.seats;
    //debug.log("has open slots", groupHasOpenSlots, "active:", activeGroupMembers, "seats", groupInfo.seats);
    return groupHasOpenSlots;
  };

  const toggleGroupMemberActive = (e, componentData) => {
    //debug.log("toggle group member active", e, componentData);

    if (componentData.checked) {
      componentData.enableGroupMember = true;
    }

    if (!componentData.checked) {
      componentData.disableGroupMember = true;
    }

    componentData.uid = componentData.value;

    let data = baseUpdateGroupMember(componentData);

    //debug.log("data", data);
    updateGroupMember({data});
  };

  const baseUpdateGroupMember = options => {
    let data = {
      group_id: _group.id,
      uid: options.uid,
    };

    if (options.enableGroupMember) {
      data.enable = true;
    }

    if (options.disableGroupMember) {
      data.disable = true;
    }
    //debug.log("options", options, "base update group member", data);
    return data;
  };

  const groupMember = options => {
    if (!isEmpty(group.members)) {
      const _groupMember = find(group.members, {uid: options.uid});
      return _groupMember;
    }

    return {};
  };

  const seatsLabel = options => {
    //debug.log("seatsLabel", options);
    let _seatsLabel = "";
    let activeGroupMembers = [];

    if (!isEmpty(group)) {
      // we use this method to get any data that needs to be up-to-date
      // because the groups object is updated each a group is changed via the groups reducer
      // whereas the options.group object is stagnant since it's only a reference to the group when the page first loads
      activeGroupMembers = group.active_group_members_count;
      const openSeats = group.seats - activeGroupMembers;

      _seatsLabel = `${activeGroupMembers}/${group.seats} Active Seats | ${openSeats} Open Seats`;
    } else {
      _seatsLabel = "";
    }

    return _seatsLabel;
  };

  const errorLabel = <Label color="red" pointing/>;

  return (<Grid.Column className="account__info-column">
    {!isEmpty(groups.result) && !isEmpty(groups.message) && _group.id === groups.group_id ? showMessage({
      messageText: groups.message.en.html,
      messageHeader: groups.message.en.title,
      isError: groups.result !== "success"
    }) : ""}
    <div
      className="account__info-column-title">{_group.title} ({seatsLabel()})
    </div>
    <Dimmer
      active={settings.fetchingGroupMembers} inverted>
      <Loader size='large'>Loading</Loader>
    </Dimmer>
    <div className={classnames("group__table", `group__table_${_group.id}`)}>
      <Table celled compact sortable striped>
        <Table.Header fullWidth><Table.Row>
          <Table.HeaderCell/>
          <Table.HeaderCell
            // sorted={sorted[group.id].column === "firstname" ? sorted[group.id].direction : null}
            // onClick={sortBy({field: "firstname"})}
          >First Name</Table.HeaderCell>
          <Table.HeaderCell>Last Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Last Login</Table.HeaderCell>
          <Table.HeaderCell>Active</Table.HeaderCell>
          <Table.HeaderCell/>
        </Table.Row>
        </Table.Header>
        {!isEmpty(group.members) ?
          <React.Fragment><Table.Body>{group.members.map((member, index) => {
            // debug.log(member, index);
            return (<Table.Row key={index}
                               positive={isGroupMemberActive({member})}
                               negative={!isGroupMemberActive({member})}
              // disabled={!isGroupMemberActive({member})}
            ><Table.Cell collapsing>{index + 1}</Table.Cell>
              <Table.Cell>{member.firstname}</Table.Cell>
              <Table.Cell>{member.lastname}</Table.Cell>
              <Table.Cell>{member.email}</Table.Cell>
              <Table.Cell>{member.last_login > 1 ? <Moment format="MM/DD/YYYY hh:mm a"
                                                           unix>{member.last_login}</Moment> :
                <Button
                  color="yellow"
                  disabled={!isGroupMemberActive({member})}
                  onClick={() => resendInvite({
                    group_id: group.id,
                    group_title: group.title,
                    uid: member.uid
                  })}>Re-send Invite</Button>}</Table.Cell>
              <Table.Cell>
                <Checkbox toggle onChange={toggleGroupMemberActive}
                          checked={isGroupMemberActive({member})}
                          disabled={!allowToggleGroupMember({member})}
                  // group_id={group.id}
                          value={member.uid}/>
              </Table.Cell>
              <Table.Cell collapsing className="my-group__buttons">
                {/*<Button negative*/}
                {/*        onClick={() => removeGroupMember({group_id: group.id, uid: member.uid})}>Remove</Button>*/}
                <React.Fragment><Modal
                  trigger={<Button
                    negative
                    onClick={() => showRemoveGroupMemberModal({
                      // group_id: group.id,
                      // group_title: group.title,
                      uid: member.uid
                    })}>Remove</Button>}
                  open={removeGroupMemberModalOpen}
                  onClose={hideRemoveGroupMemberModal}
                  basic
                  size='small'
                  className="remove-group-member-modal"
                >
                  <Header icon='user times' color='red' content='Remove Group Member'/>
                  <Modal.Content>
                    {!isEmpty(removeGroupMemberData) ?
                      <h3>Are you sure you want to remove <span
                        className="red">{removeGroupMemberData.groupMember.fullname}</span> from {_group.title}?
                      </h3> : ""}
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color="red" onClick={removeGroupMember}
                            inverted>
                      <Icon name='remove'/> Remove
                    </Button>
                    <Button inverted onClick={cancelRemoveGroupMember}>
                      <Icon name='checkmark'/> Cancel
                    </Button>
                  </Modal.Actions>
                </Modal></React.Fragment>
              </Table.Cell>
            </Table.Row>);
          })}</Table.Body><Table.Footer fullWidth>
            <Table.Row><Table.HeaderCell colSpan='7'><Form size="large"
                                                           className="add-group-member-content__form"
                                                           onValidSubmit={addGroupMember}
                                                           onValid={() => changeValid()}
                                                           onInvalid={() => changeInvalid()}
                                                           ref={createGroupMemberFormRef}
              // loading={hasValue(creatingGroupMemberForGroup)}
            >
              {allowCreateGroupMember() ? <React.Fragment>
                  {tokenize({
                    messageType: "group_member_add_new_members_notice",
                    data: {
                      seats: `${group.seats}`,
                      active_members: `${group.active_group_members_count}`,
                      inactive_members: `${group.inactive_group_members_count}`
                    }
                  })}
                  <Form.Group>
                    <Form.Field width={5}>
                      <Input
                        placeholder='First Name'
                        name="firstname"
                        required
                        validationErrors={{
                          isDefaultRequiredValue: "First Name is Required"
                        }}
                        instantValidation
                        errorLabel={errorLabel}/>
                    </Form.Field>
                    <Form.Field width={5}>
                      <Input
                        placeholder='Last Name'
                        name="lastname"
                        required
                        validationErrors={{
                          isDefaultRequiredValue: "Last Name is Required"
                        }}
                        instantValidation
                        errorLabel={errorLabel}/>
                    </Form.Field>
                    <Form.Field width={5}>
                      <Input
                        placeholder='Email'
                        name="email"
                        required
                        validations="isEmail"
                        validationErrors={{
                          isEmail: "Must be a valid email address",
                          isDefaultRequiredValue: "Email is Required"
                        }}
                        instantValidation
                        errorLabel={errorLabel}/>
                    </Form.Field>
                    {get(settings, "config.recaptcha_key") !== undefined ?
                      <GoogleReCaptchaProvider reCaptchaKey={settings.config.recaptcha_key}>
                        <GoogleReCaptcha onVerify={token => debug.log(token)}/>
                      </GoogleReCaptchaProvider> : ""}
                    <Form.Button
                      width={4}
                      floated='left'
                      disabled={!createGroupMemberFormValid || settings.creatingGroupMember}
                      icon
                      labelPosition='left'
                      size='small'
                    ><Icon name='user'/> Add Group Member</Form.Button>
                  </Form.Group></React.Fragment>
                : <React.Fragment/>}
            </Form>
            </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
          </React.Fragment> : <Table.Body><Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell>There are no members associated with {_group.title}.</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          </Table.Body>}
      </Table>
    </div>
  </Grid.Column>);
}

function mapStateToProps (state) {
  //console.log("User Tools: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    //user: state.userData,
    groups: state.groupsData,
    //userTools: state.userToolsData,
  };
}

export default connect(mapStateToProps, {
  updateSettings,
  findGroupMembers,
  updateGroupMember,
  createGroupMember,
  resendGroupMemberInvite
})(withRouter(GroupTable));