import axios from "axios";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiURL, baseParams } from "../../common/consts";
import { debug, isAuthenticated, userTierAccess } from "../../common/helpers";
import { retrieveRecipe, toggleIsUserRecipe } from "../../redux/actions/recipes";
import classNames from "classnames";
//import { updateSettings } from "../../redux/actions/settings";
import { createUserRecipe, deleteUserRecipe } from "../../redux/actions/userRecipes";

function UserRecipeButton (props) {
  const {recipe, settings, toggleIsUserRecipe} = props;
  const [togglingUserRecipe, setTogglingUserRecipe] = useState(false);
  const [isUserRecipe, setIsUserRecipe] = useState(false);

  const createUserRecipe = (options) => {
    //console.log("API: Create User Recipe", options);

    if (!isAuthenticated()) {
      return false;
    }

    const globalState = window.store.getState();
    //console.log("Global State", globalState);

    const params = {
      ...baseParams(),
      auth_token: globalState.userData.auth_token,
    };
    debug.log("Update User Recipe: Params", params);
    const data = {
      //uid: globalState.userData.uid,
      recipe_id: options.recipe_id,
      //email: options.email,
      //password: options.password
      //direction: globalState.settings.activeSortDirection,
      //...options.data
    };
    debug.log("User Recipe: Data", data);

    setTogglingUserRecipe(true);
    return axios.post(`${apiURL}user_recipe/create?${queryString.stringify(params)}`, {...data}).then(response => response.data);
  };

  const deleteUserRecipe = (options) => {
    if (!isAuthenticated()) {
      return false;
    }

    const globalState = window.store.getState();
    //console.log("Global State", globalState);

    const params = {
      ...baseParams(),
      auth_token: globalState.userData.auth_token,
    };

    //console.log("Delete User Recipe: Params", params);
    const data = {
      recipe_id: options.recipe_id,
    };
    //console.log("User Recipe: Data", data);
    setTogglingUserRecipe(true);
    return axios.post(`${apiURL}user_recipe/delete?${queryString.stringify(params)}`, {...data}).then(response => response.data);
  };

  useEffect(() => {
  },);

  function checkIfUserRecipe () {
    const _isUserRecipe = (recipe.is_user_recipe && !isUserRecipe) || isUserRecipe;
    //console.log("checking is user recipe: IN RECIPE", recipe.is_user_recipe, "local", isUserRecipe);
    return _isUserRecipe;
  }

  const toggleUserRecipeHandler = (e) => {
    //console.log("toggle user recipe", {card: e.currentTarget});

    // make sure we're not already talking to the server
    if (!settings.creatingUserRecipe && !settings.deletingUserRecipe) {
      const currentIsUserRecipe = checkIfUserRecipe();
      //console.log("updating user recipes");
      if (!currentIsUserRecipe) {
        setIsUserRecipe(true);

        createUserRecipe({recipe_id: recipe.id}).then(response => {
          // console.log("done creating", response);
          if (response.result === "success") {
            toggleIsUserRecipe({id: recipe.id, is_user_recipe: true});
            //debug.log("user recipes", userRecipes);
            setTogglingUserRecipe(false);
          }
        });
      } else {

        deleteUserRecipe({recipe_id: recipe.id}).then(response => {
          // console.log("done deleting", response);
          if (response.result === "success") {
            setIsUserRecipe(false);
            toggleIsUserRecipe({id: recipe.id, is_user_recipe: false});
            //debug.log("user recipes", userRecipes);
            setTogglingUserRecipe(false);
          }
        });
      }
    }
  };

  // return (isAuthenticated() ? (
  //<div
  //className={classNames("recipe-bar__favorite-icon", `recipe-bar__favorite-icon--${checkIfUserRecipe() ? "on" : "off"}`, {"recipe-bar__favorite-icon--dim": togglingUserRecipe})}
  //onClick={toggleUserRecipeHandler} title="bookmark recipe"></div>) : "");
// );

  return (isAuthenticated() && ((userTierAccess({minTier: "1"}) && !recipe.pro_tier) || userTierAccess({minTier: "2"})) ?
    <div
      className={classNames("recipe-bar__favorite-icon", `recipe-bar__favorite-icon--${checkIfUserRecipe() ? "on" : "off"}`, {"recipe-bar__favorite-icon--dim": togglingUserRecipe})}
      onClick={toggleUserRecipeHandler} title="bookmark recipe"></div> : "");
  /*
  return (isAuthenticated() && ((userTierAccess({minTier: "1"}) && !recipe.pro_tier) || userTierAccess({minTier: "2"})) ?
    <Icon.Group size='large'>
      <Icon loading size='big' name='circle notch'/>
      <Icon name={checkIfUserRecipe() ? "bookmark" : "bookmark outline"}/>
    </Icon.Group> : "");*/
}

function mapStateToProps (state) {
  //console.log("User Recipe Button: Map State to Props", state);
  return {
    //recipe: state.recipesData.recipe,
    recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    settings: state.settings,
    //user: state.userData,
    //userRecipes: state.userRecipesData,
  };
}

export default connect(mapStateToProps, {
  retrieveRecipe,
  createUserRecipe,
  deleteUserRecipe,
  toggleIsUserRecipe,
})(UserRecipeButton);