import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getHTML } from "../../common/helpers";
import { Button, Icon } from "semantic-ui-react";

//import { updateSettings } from "../../redux/actions/settings";

function CallToAction (props) {
  const {position = "top"} = props;

  const joinProvenCut = () => {
    props.history.push("/join");
  };

  const html = getHTML({key: "top_cta"});

  if ((position === "top" || position === "bottom") && html !== "") {
    return (
      <div className="pc-cta--top pc-cta">
        <span className="pc-cta__cta-message">{html}</span>
        <Button animated secondary className="pc-cta__cta-button" onClick={joinProvenCut}>
          <Button.Content visible>Join ProvenCut</Button.Content>
          <Button.Content hidden><Icon name='arrow right'/></Button.Content>
        </Button>
      </div>);
  }
  return (
    <div></div>);
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    //settings: state.settings,
  };
}

export default connect(mapStateToProps, {})(withRouter(CallToAction));