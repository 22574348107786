import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { Grid, Header } from "semantic-ui-react";
import { updateSettings } from "../../redux/actions/settings";
import Layout from "../global/Layout";

function HTML (props) {
  const {propertyName = "html", menuItem = "HTML", header = "", settings, updateSettings} = props;

  useEffect(() => {
    //console.log("useEffect: HTML", props);

    if (settings.activeMenuItem !== menuItem) {
      //retrieveFAQs();
      updateSettings({activeMenuItem: menuItem});
    }
  }, [settings]);

  if (isEmpty(get(settings, `config.htmls.${propertyName}`))) {
    return (<Layout/>);
  }

  return (
    <Layout>
      <Grid className={`${propertyName}-content content`}>
        <Grid.Row className={`${propertyName}-content__title`} columns={2}>
          <Grid.Column><Header as="h1">{header}</Header></Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid.Row>
        <Grid.Row className={`${propertyName}-content__html`}>
          <Grid.Column>{ReactHtmlParser(get(settings, `config.htmls.${propertyName}.html`))}</Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
}

function mapStateToProps (state) {
  //console.log("HTML: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    //user: state.userData,
  };
}

export default connect(mapStateToProps, {updateSettings})(HTML);