//import queryString from "query-string";
import React, { useEffect } from "react";
import { connect } from "react-redux";
// import { Redirect } from "react-router-dom";
import { Grid, Header } from "semantic-ui-react";
import store from "store";
// import { DEFAULT_AUTHENTICATED_ROUTE } from "../../common/consts";
// import { isAuthenticated } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
import Layout from "../global/Layout";

function UserSurvey (props) {
  const {settings, user, updateSettings} = props;

  //const parsedURLParams = queryString.parse(props.location.search);
  // console.log("params", parsedURLParams);

  useEffect(() => {
    //console.log("useEffect: SignUp", props);

    if (settings.activeMenuItem !== "user-survey") {
      updateSettings({activeMenuItem: "user-survey"});

      setTimeout(function () {
        store.set("visited_user_survey", true);
      }, 60000);
    }

  }, [settings]);

  let formURL = `https://docs.google.com/forms/d/1B1b_TmUPJit8Lflkgf950oowGcOkB-2P1-OUwu1cr-A/viewform?embedded=true&usp=pp_url&emailAddress=${user.email}`;

  return (
    <Layout>
      <Grid className="user-survey-content content">
        <Grid.Row className="user-survey-content__heading">
          <Grid.Column className="user-survey-content__header" width={5}><Header as="h1">Member Survey</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid className="user-survey-content__form-columns" columns={1}>
            <Grid.Row>
              <Grid.Column width={16}>
                <iframe
                  title="Member Survey Form"
                  src={formURL}
                  width="100%" height="1000" frameBorder="0" marginHeight="0" marginWidth="0">Loading...
                </iframe>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Row>
      </Grid>
    </Layout>);
}

function mapStateToProps (state) {
  //console.log("SignUp: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    //order: state.orderData,
  };
}

export default connect(mapStateToProps, {updateSettings})(UserSurvey);
