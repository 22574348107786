import { Form, Input } from 'formsy-semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid, Header, Label } from 'semantic-ui-react';
import { DEFAULT_AUTHENTICATED_ROUTE } from '../../common/consts';
import { isAuthenticated, showMessage } from '../../common/helpers';
import { updateSettings } from '../../redux/actions/settings';
import { loginUser } from '../../redux/actions/user';
import Layout from '../global/Layout';

function LogIn(props) {
  const { settings, user, updateSettings, loginUser } = props;
  const isLoading = false;

  const parsedURLParams = queryString.parse(props.location.search);
  //console.log("params", parsedURLParams);

  useEffect(() => {
    //console.log("useEffect: LogIn", props);

    if (settings.activeMenuItem !== 'login') {
      updateSettings({ activeMenuItem: 'login' });
    }
  }, [settings, user]);

  //const [formState, setOrderState] = React.useState({sort: settings.defaultSort, order: "asc"});

  const attemptLoginUser = (formData) => {
    //console.log(formData);

    loginUser({ email: formData.email, password: formData.password });
  };

  const errorLabel = <Label
    color="red"
    pointing/>;

  // Formsy
  // https://github.com/christianalfoni/formsy-react/blob/master/API.md

  return (
    isAuthenticated() ? <Redirect
        to={!isEmpty(parsedURLParams.destination) ? parsedURLParams.destination : DEFAULT_AUTHENTICATED_ROUTE}/> :
      <Layout>
        <Grid className="login-content content">
          <Grid.Row
            className="login-content__heading"
            centered>
            <Grid.Column
              className="login-content__header"
              mobile={15}
              computer={5}><Header as="h1">Log In</Header>
              <p>Need an account? <a href="/join">Sign Up</a> here.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            className="login-content__form"
            centered>
            <Grid.Column
              mobile={15}
              computer={5}>
              {!isEmpty(user.code) ? showMessage({ messageType: user.code, isError: true }) : ''}
              {/*{user.response.failed === true ?*/}
              {/*<Message*/}
              {/*error*/}
              {/*header='You have entered an invalid username or password'*/}
              {/*list={[*/}
              {/*"Please try re-entering your username and password",*/}
              {/*]}*/}
              {/*/> : ""}*/}
              <Form
                size="large"
                className="login-form"
                onValidSubmit={attemptLoginUser}
                loading={isLoading}>
                <Form.Field>
                  <label>Email <span className="red">*</span></label>
                  <Input
                    placeholder="Email"
                    name="email"
                    validations="isEmail"
                    required
                    validationErrors={{ isEmail: 'Email not valid', isDefaultRequiredValue: 'Email is Required' }}
                    errorLabel={errorLabel}/>
                </Form.Field>
                <Form.Field>
                  <label>Password <span className="red">*</span></label>
                  <Input
                    type="password"
                    placeholder="******"
                    name="password"
                    required
                    validations="minLength:8"
                    validationErrors={{
                      minLength: 'Must be at least 8 characters long',
                      isDefaultRequiredValue: 'Password is Required'
                    }}
                    errorLabel={errorLabel}/>
                  <a href="/forgot-password">Forgot Password</a>
                </Form.Field>
                <Form.Button
                  content="Sign In"
                  className="login-form__signin-button"/>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Layout>);
}

function mapStateToProps(state) {
  //console.log("LogIn: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
  };
}

export default connect(mapStateToProps, { updateSettings, loginUser })(LogIn);