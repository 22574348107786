import React from "react";
import { connect } from "react-redux";
import { Grid, Image } from "semantic-ui-react";

function Footer (props) {
  return (
    <footer>
      <Grid stackable verticalAlign='middle' className="footer">
        <Grid.Column className="footer__logo" floated='left' width={2}>
          <Image src="https://d3827p95tv2r1a.cloudfront.net/cdn/logos/provencut_logo_footer.png" alt="Footer Logo"/>
        </Grid.Column>
        <Grid.Column className="footer__links" floated='left' width={6}>
          <a href="/privacy-policy">Privacy Policy</a><span className="footer__links-separator"> | </span><a
          href="/terms-of-service">Terms of Service</a><span className="footer__links-separator"> | </span><a
          href="/contact-us">Contact Us</a>

          {/*<a href="mailto:support@provencut.com?subject=Support" target="_blank" rel="noopener noreferrer">Contact Us</a>*/}
        </Grid.Column>
        <Grid.Column className="footer__copyright" floated='right' width={8}>
          &copy; {`${new Date().getFullYear()} PROVENCUT. ALL RIGHTS RESERVED. v ${global.appVersion}`}
        </Grid.Column>
      </Grid>
    </footer>);
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    //settings: state.settings,
  };
}

export default connect(mapStateToProps, {})(Footer);