import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Grid, Icon } from "semantic-ui-react";
import { updateSettings } from "../../redux/actions/settings";
import store from "store";

function UserSurveyCTA (props) {
  const {autoClose = true} = props;
  const [showUserSurveyCTA, setShowUserSurveyCTA] = useState(true);

  if (autoClose) {
    setTimeout(function () {
      //setShowUserSurveyCTA(false);
      //updateSettings({userInteractionEnableUserSurveyCTA: false});

      disableUserSurveyCTA();
    }, 30000);
  }

  function closeUserSurveyCTA () {
    disableUserSurveyCTA();
  }

  function disableUserSurveyCTA () {
    // show only one time
    store.set("visited_user_survey", true);

    setShowUserSurveyCTA(false);
  }

  return (showUserSurveyCTA ?
    <div className="survey-cta">
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column className="title"><h2>Feedback</h2></Grid.Column>
          <Grid.Column className="close-btn" textAlign="right"><Button circular
                                                                       onClick={closeUserSurveyCTA}>X</Button></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <p>We would appreciate your feedback. Please help us by
            completing this short survey.</p>
        </Grid.Row>
        <Grid.Row>
          <Button animated secondary href="/member-survey" target="_blank"
                  rel="noopener noreferrer">
            <Button.Content visible>Click Here</Button.Content>
            <Button.Content hidden><Icon name='arrow right'/></Button.Content>
          </Button>
        </Grid.Row>
      </Grid></div>
    : "");
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    //settings: state.settings,
  };
}

export default connect(mapStateToProps, {updateSettings})(withRouter(UserSurveyCTA));