//import Formsy from 'formsy-react-es6';
//import { card, cvc, expiration } from "creditcards";
import { Checkbox, Form, Input, RadioGroup, TextArea } from "formsy-semantic-ui-react";
//import find from "lodash/find";
import get from "lodash/get";
//import qs from "query-string";
import React, { useEffect, useState } from "react";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Label, Radio } from "semantic-ui-react";
import { FREE_PRODUCT_ID } from "../../common/consts";
import { debug } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
//import { resetOrderReview, retrieveOrderReview } from "../../redux/actions/order";
import { createUser } from "../../redux/actions/user";

function FreeSignUpForm (props) {
  const {settings, createUser} = props;
  //console.log("sign up", props);

  const [acceptTerms, setAcceptTerms] = useState(false);

  // const query = Object.assign({product_id}, qs.parse(props.location.search));
  //console.log("query", query);

  const changeAcceptTerms = (e, data) => {
    //console.log("changeAcceptTerms", data);
    setAcceptTerms(data.checked);
  };

  useEffect(() => {
    //console.log("useEffect: SignUp Form. Order State", orderState, "Props", props);
  }, [settings]);

  const submitOrder = (formData) => {
    debug.log("form data", formData);

    let orderData = {
      product_id: FREE_PRODUCT_ID,
      role: "free",
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      company: formData.company,
      questions: {
        which_machines: formData.which_machines,
        skill_level: formData.skill_level,
      }
      //password: formData.password,
    };

    // check that formData.accept === 'accept'
    // pass info to Stripe
    // send to server
    // how do we tell them it was success
    // then what happens? do we redirect?
    createUser({data: orderData});
  };

  const errorLabel = <Label color="red" pointing/>;

  return (<Form size="large" className="signup-content__form" onValidSubmit={submitOrder}
                loading={settings.creatingUser}>
    <Grid.Row>
      <Grid className="signup-content__form-columns" columns={3} stackable divided>
        <Grid.Row>
          {/* ------------------------------------- COLUMN 1 ------------------------------------- */}
          <Grid.Column className="signup__info-column">
            <div className="signup__info-column-title">INFO</div>
            <Form.Field>
              <label>First Name <span className="red">*</span></label>
              <Input
                placeholder='First Name'
                name="firstname"
                required
                validationErrors={{
                  isDefaultRequiredValue: "First Name is Required"
                }}
                errorLabel={errorLabel}/>
            </Form.Field>
            <Form.Field>
              <label>Last Name <span className="red">*</span></label>
              <Input
                placeholder='Last Name'
                name="lastname"
                required
                validationErrors={{
                  isDefaultRequiredValue: "Last Name is Required"
                }}
                errorLabel={errorLabel}/>
            </Form.Field>
            <Form.Field>
              <label>Company</label>
              <Input
                placeholder='Company'
                name="company"/>
            </Form.Field>
            <Form.Field>
              <label>Email <span className="red">*</span></label>
              <Input
                placeholder='Email'
                name="email"
                required
                validations="isEmail"
                validationErrors={{
                  isEmail: "Email not valid",
                  isDefaultRequiredValue: "Email is Required"
                }}
                errorLabel={errorLabel}/>
            </Form.Field>
            <Form.Field>
              <label>Brands and Models of CNC Machines I Run</label>
              <TextArea
                placeholder='My Machines'
                name="which_machines"
              />
            </Form.Field>
            <Form.Field>
              {/*<label>Choose a Subscription <span className="red">*</span></label>*/}
              <RadioGroup
                name="skill_level"
                required
                defaultSelected="professional"
                validationErrors={{
                  isDefaultRequiredValue: "Please select one."
                }}
                // onChange={handleSubscriptionChange}
              >
                <Radio label="Professional" value="professional"/>
                <Radio label="Hobbyist" value="hobbyist"/>
              </RadioGroup>
            </Form.Field>
          </Grid.Column>

          <Grid.Column className="signup__info-column">
            <div className="signup__info-column-title">TERMS</div>
            <Grid.Row className="signup__accept text">
              <Form.Group inline>
                <Checkbox
                  name="accept_terms"
                  required
                  onClick={changeAcceptTerms}
                  validations="isTrue"
                  validationErrors={{
                    isTrue: "This is non-negotiable",
                    isDefaultRequiredValue: "You'll have to check this box",
                  }}
                  errorLabel={<Label color="red" pointing="left"/>}
                />

                <label>I agree to Proven Cut <a href="/terms-of-service" rel="noopener noreferrer"
                                                target="_blank">Terms
                  & Conditions </a>
                  and <a href="/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy
                    Policy</a></label>
              </Form.Group>
            </Grid.Row>

            <Grid.Row>
              {get(settings, "config.recaptcha_key") !== undefined ?
                <GoogleReCaptchaProvider reCaptchaKey={settings.config.recaptcha_key}>
                  <GoogleReCaptcha onVerify={token => console.log(token)}/>
                </GoogleReCaptchaProvider> : ""}
              <Form.Button disabled={!acceptTerms}
                           content="Test Drive"
                           className="signup-form__signup-button"/>
            </Grid.Row>
          </Grid.Column>

        </Grid.Row>

      </Grid>

    </Grid.Row>
  </Form>);
}

function mapStateToProps (state) {
  //console.log("SignUp: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    order: state.orderData,
  };
}

export default connect(mapStateToProps, {
  updateSettings,
  createUser
})(withRouter(FreeSignUpForm));
