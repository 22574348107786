import classNames from 'classnames/bind';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { connect } from 'react-redux';
import { Label, Card, Grid, Icon, Image } from 'semantic-ui-react';
import { NO_FIELD_VALUE } from '../../common/consts';
import { hasValue, isAuthenticated, userTierAccess } from '../../common/helpers';
import { updateSettings } from '../../redux/actions/settings';
import RecipeField from './RecipeField';
import UserRecipeButton from './UserRecipeButton';
import RecipeVoteButtons from './RecipeVoteButtons';

// -------------------------------------------------- RECIPE CARD COMPONENT -------------------------------------------------- //
function RecipeCard(props) {
  //console.log("Recipe Card", props);

  // reset cards
  // setRecipesForCards(recipes);

  const {
    recipe,
    index,
    showRecipePreviewCard,
    hideRecipePreviewCard,
    updateSettings,
    settings,
    cornerLabel,
    additionalClassNames = []
  } = props;
  //debug.log("recipe", recipe);

  //const [thumbnails, setThumbnails] = useState({});

  // create object as list of thumbnails where property is key
  let thumbnails = {
    'video': !isEmpty(recipe.videos[0]) ? recipe.videos[0].thumbnail_url : recipe.images[0].thumb,
  };

  if (!isEmpty(recipe.images)) {
    recipe.images.forEach(recipeImage => {
      if (!isEmpty(recipeImage.title)) {
        thumbnails[recipeImage.title] = recipeImage.thumb;
      }
    });
  }

  // check cutting tools, inserts, holders, machines and material
  if (!isEmpty(recipe.cutting_tools)) {
    recipe.cutting_tools.forEach(tool => {
      if (!isEmpty(tool.images)) {
        //console.log("tool image", tool.images);
        thumbnails['cutting_tool'] = tool.images[0].thumb;
      }
      return;
    });
  }

  if (!isEmpty(recipe.indexable_inserts)) {
    recipe.indexable_inserts.forEach(insert => {
      if (!isEmpty(insert.images)) {
        thumbnails['insert'] = insert.images[0].thumb;
      }
      return;
    });
  }

  if (!isEmpty(recipe.milling_tool_holders)) {
    recipe.milling_tool_holders.forEach(holder => {
      if (!isEmpty(holder.images)) {
        thumbnails['holder'] = holder.images[0].thumb;
      }
      return;
    });
  }
  //debug.log(thumbnails);

  const getThumbnail = () => {
    let _thumbnail = thumbnails[settings.activeRecipeThumbnail];

    if (isEmpty(_thumbnail)) {
      _thumbnail = thumbnails['video'];
    }

    return _thumbnail;
  };

  const handleOpenRecipePage = (e) => {
    window.open(`/recipe/${recipe.id}`, '_blank');
  };

  const clickRecipe = (e) => {
    updateSettings({ previewRecipeID: recipe.id });
    showRecipePreviewCard({ card: e.currentTarget, index, recipe });
  };

  const hoverRecipe = (e) => {
    //debug.log("hover");
  };

  let LastRecipeCardField = '';

  switch (settings.activeRecipeSort) {
    case 'stickout':
      LastRecipeCardField = <RecipeField
        id={recipe.id}
        name="stickout"
        label="Stickout"
        value={hasValue(recipe.stickout) ? recipe.stickout : NO_FIELD_VALUE}
        convertOptions={{ type: 'inches' }}
        convertible
        minTier="2"
        recipe={recipe}
        hideOnMobile={settings.activeRecipeSort !== 'stickout'}
      />;
      break;

    case 'gage_length':
      LastRecipeCardField = <RecipeField
        id={recipe.id}
        name="gauge-length"
        label="Gauge Length"
        value={recipe.gage_length}
        convertOptions={{ type: 'inches' }}
        convertible
        minTier="2"
        recipe={recipe}
        hideOnMobile={settings.activeRecipeSort !== 'gage_length'}
      />;

      break;

    default:
    case 'mrr':
      LastRecipeCardField = <RecipeField
        id={recipe.id}
        name="mrr"
        label="MRR"
        value={recipe.mrr}
        convertible
        hideOnMobile={settings.activeRecipeSort !== 'mrr'}
      />;

      break;
  }

  //debug.log("recipe", recipe.id, "tier", recipe.tier);
  const free_user_access_recipe = userTierAccess({ minTier: '0.0.0' }) && !userTierAccess({ minTier: '0.9.9' }) && userTierAccess({ minTier: recipe.tier });

  // look at https://react.semantic-ui.com/views/card/#content-header-card
  return (//<Card className={`recipe-card-${index} ${recipe.selected ? "recipe-card-selected" : ""} `} index={index}>
    <Card
      id={`recipe--${recipe.id}`}
      className={classNames('recipe-card', 'recipe', `recipe-card--${index}`, `recipe--${recipe.id}`,
        {
          'recipe-card--selected': recipe.selected,
          'recipe-card--free-access': free_user_access_recipe
        }, additionalClassNames)}>
      {/*<div className={`recipe recipe-${recipe.id}`}>*/}
      {cornerLabel && <Label floating>{cornerLabel}</Label>}
      {recipe.selected ? <Card.Content
        className="recipe-card__content"
        onClick={hideRecipePreviewCard}>
        <div className="recipe-card__close">
          <Image
            className="recipe-card__close-icon"
            verticalAlign="middle"
            src="https://d3827p95tv2r1a.cloudfront.net/cdn/misc/close_card_preview_icon.svg"/>
        </div>
        <div className="recipe-card__cta">Recipe Details</div>
        <div className="recipe-card__arrow">&#x25BC;</div>
      </Card.Content> : <Card.Content className="recipe-card__content">
        {/*{console.log("video", recipe.videos[0])}*/}
        <div className="recipe-card__thumbnail"><Image
          src={getThumbnail()}
          as="div"
          size="medium"
          onClick={clickRecipe}
        /></div>

        {/*<div className="recipe-card__thumbnail">{!isEmpty(recipe.videos[0]) ? (<Image*/}
        {/*  src={recipe.videos[0].thumbnail_url}*/}
        {/*  as='div'*/}
        {/*  size='medium'*/}
        {/*  onClick={clickRecipe}*/}
        {/*/>) : !isEmpty(recipe.images[0]) ? (<Image*/}
        {/*  src={recipe.images[0].thumb}*/}
        {/*  as='div'*/}
        {/*  size='medium'*/}
        {/*  onClick={clickRecipe}*/}
        {/*/>) : ""}</div>*/}
        <Grid
          columns={2}
          className="recipe-bar">
          <Grid.Row>
            <Grid.Column
              width={10}
              floated="left"
              className="recipe-bar__id">Recipe
              ID: {recipe.id}</Grid.Column>
            <Grid.Column
              width={6}
              floated="right"
              className="recipe-bar__favorite">

              <Grid>
                <Grid.Row>

                  {isAuthenticated() ?
                    <Grid.Column
                      className="recipe-bar__info"
                      textAlign="right">
                      {userTierAccess({ minTier: '1.0.0', maxTier: '1.9.9' }) && recipe.pro_tier ?
                        <a
                          className="recipe-bar__lock"
                          href="/account"><Icon
                          name="lock"
                          size="small"/> PRO</a> : ''}
                      {/*{(userTierAccess({minTier: "1"}) && !recipe.pro_tier) || userTierAccess({minTier: "2"}) ?*/}
                      {/*  <UserRecipeButton recipe={recipe}/> : ""}*/}
                      <UserRecipeButton recipe={recipe}/>

                      {isAuthenticated() && userTierAccess({ minTier: '1' }) ?
                        <Icon
                          link
                          name="external"
                          size="small"
                          onClick={handleOpenRecipePage}
                          className="recipe-bar__open-recipe"
                          title="open recipe page"/> : <React.Fragment/>}
                    </Grid.Column> : ''}
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div
          className="recipe-card__bottom"
          onClick={clickRecipe}
          onMouseEnter={hoverRecipe}>
          <div className="recipe-card__more-details">
            <div className="recipe-card__cta">View<br/>Recipe Summary</div>
            <div className="recipe-card__arrow">&#x25BC;</div>
          </div>
          <Grid
            className="recipe-card__details"
            columns={2}>
            <RecipeField
              id={recipe.id}
              name="machine"
              label="Machine"
              value={!isEmpty(recipe.cnc_machine) ? `${recipe.cnc_machine.brand.title} ${recipe.cnc_machine.part_number.name}` : NO_FIELD_VALUE}
              isText={true}
              hideOnMobile={true}
            />

            <RecipeField
              id={recipe.id}
              name="material"
              label="Material"
              value={!isEmpty(recipe.material) ? `${recipe.material.title} ${recipe.material.class.name}` : NO_FIELD_VALUE}
              hideOnMobile={true}
            />

            {/*<RecipeField*/}
            {/*  id={recipe.id}*/}
            {/*  name="tool-brand"*/}
            {/*  label="Tool Brand"*/}
            {/*  value={!isEmpty(recipe.cutting_tools[0]) ? recipe.cutting_tools[0].brand.title : NO_FIELD_VALUE}*/}
            {/*/>*/}

            <RecipeField
              id={recipe.id}
              name="tool-type"
              label="Tool Type"
              value={!isEmpty(recipe.cutting_tools[0]) ? recipe.cutting_tools[0].type.name : NO_FIELD_VALUE}
            />

            <RecipeField
              id={recipe.id}
              name="tool-diameter"
              label="Tool Dia."
              value={!isEmpty(recipe.cutting_tools[0]) ? recipe.cutting_tools[0].cutting_diameter : NO_FIELD_VALUE}
              convertOptions={{ type: 'inches' }}
              convertible
            />

            <RecipeField
              id={recipe.id}
              name="cut-style"
              label="Cut Style"
              value={recipe.cut_type !== '' ? recipe.cut_type.name : NO_FIELD_VALUE}
            />
            {/*{console.log("stickout", recipe.stickout)}*/}


            {LastRecipeCardField}
          </Grid>
        </div>

        <Grid
          columns={2}
          className="recipe-bar-footer">
          <Grid.Row>
            {/*<Grid.Column width={10} floated='left' className="recipe-bar__id">{recipe.votes.sum} Votes</Grid.Column>*/}

            <Grid.Column
              width={10}
              floated="left"
              className="recipe-bar__id">
              <RecipeVoteButtons recipe={recipe}/>
            </Grid.Column>


            {/*<Grid.Column width={6} floated='right' className="recipe-bar__favorite">*/}
            {/*  <Grid>*/}
            {/*    <Grid.Row>*/}

            {/*      {isAuthenticated() ?*/}
            {/*        <Grid.Column className="recipe-bar__favorite" textAlign="right">*/}
            {/*          {userTierAccess({minTier: "1.0.0", maxTier: "1.9.9"}) && recipe.pro_tier ?*/}
            {/*            <Icon name='lock' size='small'/> : ""}*/}
            {/*          /!*{(userTierAccess({minTier: "1"}) && !recipe.pro_tier) || userTierAccess({minTier: "2"}) ?*!/*/}
            {/*          /!*  <UserRecipeButton recipe={recipe}/> : ""}*!/*/}
            {/*          <UserRecipeButton recipe={recipe}/>*/}
            {/*          <Icon link name='external' size='small' onClick={handleOpenRecipePage}*/}
            {/*                className="recipe-bar__open-recipe" title="open recipe page"/>*/}
            {/*        </Grid.Column> : ""}*/}
            {/*    </Grid.Row>*/}
            {/*  </Grid>*/}
            {/*</Grid.Column>*/}
          </Grid.Row>
        </Grid>
      </Card.Content>}
      {/*</div>*/}
    </Card>);
}

// -------------------------------------------------- //RECIPE CARD COMPONENT -------------------------------------------------- //

function mapStateToProps(state) {
  //console.log("Recipes: Map State to Props", state);
  return {
    recipes: state.recipesData.recipes,
    filters: state.recipesData.filters,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, { updateSettings })(RecipeCard);