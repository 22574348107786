//import Formsy from 'formsy-react-es6';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Elements } from "react-stripe-elements";
import { Grid, Header } from "semantic-ui-react";
import { DEFAULT_ANONYMOUS_ROUTE } from "../../common/consts";
import { isAuthenticated, isLocked, showMessage } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
import Layout from "../global/Layout";
import GroupsTables from "./GroupsTables";
import isEmpty from "lodash/isEmpty";

function Groups (props) {
  const {settings, user, updateSettings} = props;
  //console.log("account", props);

  useEffect(() => {
    //console.log("useEffect: Account", props);

    if (settings.activeMenuItem !== "manage-groups") {
      updateSettings({activeMenuItem: "manage-groups"});
    }

  }, [settings]);

  return (!isAuthenticated() ? <Redirect to={DEFAULT_ANONYMOUS_ROUTE}/> :
    <Elements>
      <Layout>
        <Grid className="account-content content">
          <Grid.Row className="account-content__heading">
            {isLocked() ? showMessage({messageType: "account_locked", isError: true}) : ""}
            <Grid.Column className="account-content__header" width={5}><Header as="h1">Groups</Header>
            </Grid.Column>
          </Grid.Row>

          {!isEmpty(user.owned_groups) ?
            <Grid.Row>
              <Grid className="account-content__form-columns" columns={1}>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <GroupsTables/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row> : ""}
        </Grid>
      </Layout></Elements>);
}

function mapStateToProps (state) {
  //console.log("Account: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    //order: state.orderData,
  };
}

export default connect(mapStateToProps, {updateSettings})(Groups);