import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import { Grid, Header } from 'semantic-ui-react';
import { DEFAULT_AUTHENTICATED_ROUTE } from '../../common/consts';
import { isAuthenticated, showMessage, userTierAccess } from '../../common/helpers';
import { updateSettings } from '../../redux/actions/settings';
import Layout from '../global/Layout';
import SignUpForm from './SignUpForm';
import isEmpty from 'lodash/isEmpty';
import TagManager from 'react-gtm-module';

function SignUp(props) {
  const { settings, user, updateSettings } = props;
  console.log('sign up', props);

  useEffect(() => {
    //console.log("useEffect: SignUp", props);

    if (settings.activeMenuItem !== 'signup') {
      updateSettings({ activeMenuItem: 'signup' });
    }

  }, [settings]);

  if (isAuthenticated() && userTierAccess({ minTier: '1' }) && !user.new) {
    return <Redirect to={DEFAULT_AUTHENTICATED_ROUTE}/>;
  }

  if (isAuthenticated() && user.new) {
    // gtag("event", "conversion", {
    //     //   "send_to": "AW-704227103/IKcpCP-ho64BEJ_O5s8C",
    //     //   "value": 1.0,
    //     //   "currency": "USD",
    //     //   "transaction_id": ""
    //     // });

    const tagManagerArgs = {
      gtmId: 'AW-704227103/IKcpCP-ho64BEJ_O5s8C',
      events: {
        conversion: {
          value: 1.0,
          currency: 'USD',
          transaction_id: ''
        }
      }
    };
    TagManager.initialize(tagManagerArgs);

    return <Redirect to="/recipes?referrer=signup#message=new_signup"/>;
  }

  return (
    <Elements>
      <Layout>
        <Grid className="signup-content content">
          <Grid.Row className="signup-content__heading">
            <Grid.Column
              className="signup-content__header"
              width={5}><Header as="h1">{user.uid ? 'Rejoin ProvenCut' : 'Sign Up'}</Header>
              {user.uid ? '' : <p>Already have an account? <a href="/login">Login</a> here.</p>}
              {!isEmpty(user.message) ? showMessage({ messageText: user.message, isError: true }) :
                !isEmpty(user.code) ? showMessage({ messageType: user.code, isError: true }) : ''
              }
            </Grid.Column>
          </Grid.Row>
          <SignUpForm/>
        </Grid>
      </Layout></Elements>);
}

function mapStateToProps(state) {
  //console.log("SignUp: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    order: state.orderData,
  };
}

export default connect(mapStateToProps, { updateSettings })(SignUp);