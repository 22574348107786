import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateSettings } from '../../redux/actions/settings';
import { debug} from '../../common/helpers';
import ImageMapper from 'react-image-mapper';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

function PartMedia(props) {
  const { part, onClickZone = null, onEnterZone = null } = props;
  const [areaMap, setAreaMap] = useState({});

  useEffect(() => {

    // make sure we a part, imagemap data, and imagemap large image
    if (part && part.imagemap_data && !isEmpty(get(part, 'imagemap_image.large'))) {
      // debug.log("we have a part and imagemap stuff");

      const areas = part.imagemap_data.map((area, index) => {
        area.name = index;
        area.preFillColor = 'rgba(34,36,38,.1)'; //'rgba(0,140,0,0.25)';
        area.fillColor = 'rgba(45, 152, 207, .5)';
        area.strokeColor = 'white';
        area.lineWidth = 1;
        return area;
      });

      //debug.log("areas", areas);
      setAreaMap({
        name: `part-${part.id}`,
        areas
      });
    }

  }, [part]);

  debug.log('part media', part, 'area map', areaMap);
  // https://github.com/coldiary/react-image-mapper
  // demo: https://coldiary.github.io/react-image-mapper/
  return (<React.Fragment>
    {!isEmpty(areaMap) &&
    <div className="part-media__imagemap"><ImageMapper
      src={part.imagemap_image.large}
      map={areaMap}
      onClick={onClickZone}
      onMouseEnter={onEnterZone}
      // width={1127}
      width={350}
      imgWidth={part.imagemap_image.large_width}
    /></div>}
  </React.Fragment>);
}

function mapStateToProps(state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, { updateSettings })(PartMedia);