import * as compareVersions from 'compare-versions';
import formatCurrency from 'format-currency';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import queryString from 'query-string';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';
import { Message } from 'semantic-ui-react';
import uuid from 'react-uuid';
import store from 'store';
import {
  DRILLING_CUT_TYPE_ID,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  PRODUCTION_DEBUG_KEY,
  REAMING_CUT_TYPE_ID,
  STRIPE_DEVELOPMENT_PUBLIC_API_KEY,
  STRIPE_PRODUCTION_PUBLIC_API_KEY,
  STRIPE_USE_DEV_KEY_IN_DEV_MODE,
  TAPPING_CUT_TYPE_ID,
  TURNING_PROFILE_ROUGHING_CUT_TYPE_ID,
  TURNING_ADAPTIVE_ROUGHING_CUT_TYPE_ID,
  TURNING_PARTING_CUT_TYPE_ID,
  TURNING_OD_THREAD_CUT_TYPE_ID,
  TURNING_ID_THREAD_CUT_TYPE_ID,
  TURNING_PROFILE_FINISHING_CUT_TYPE_ID,
  TURNING_FACING_CUT_TYPE_ID,
  TURNING_BORE_CUT_TYPE_ID,
} from './consts';
import packageJson from '../../package.json';
import indexOf from 'lodash/indexOf';
import convert from 'convert-units';
import { isMobile } from 'react-device-detect';

const MOBILE_MAX_WIDTH = 767;
let isMobileWidth = false;

window.addEventListener('resize', onResize);
onResize();

function onResize(e) {
  isMobileWidth = (parseInt(window.innerWidth) <= MOBILE_MAX_WIDTH);
  // console.log('is mobile width', isMobileWidth);
}

export const isMobileDevice = () => {
  onResize();
  const mobileCheck = isMobileWidth || isMobile;
  console.log('mobile check', mobileCheck);
  return mobileCheck;
};

export const forceLogout = (options) => {
  const minAppVersion = '0.3.52';
  //debug.log("version", compareVersions(packageJson.version, minAppVersion));
  if (compareVersions(packageJson.version, minAppVersion) > 0) {
    // we don't have the minimum version so log out
    //sessionLogout();
  }
};

export const stripePublicAPIKey = () => {
  if (process.env.NODE_ENV === 'development') {
    return (STRIPE_USE_DEV_KEY_IN_DEV_MODE) ? STRIPE_DEVELOPMENT_PUBLIC_API_KEY : STRIPE_PRODUCTION_PUBLIC_API_KEY;
  }
  return STRIPE_PRODUCTION_PUBLIC_API_KEY;
};

export const stringToObjectPath = ({ string, obj }) => string.split('.').reduce((o, i) => o[i], obj);

export const recipeFromID = id => {
  const globalState = window.store.getState();

  const recipe = find(globalState.recipes, { id: id });

  //console.log("recipe", recipe, "id", id);
  debug.log('recipe', { 'recipe': recipe, 'id': id });
};

export const arrayChunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));

export const isValidPassword = value => {
  const hasNumber = /\d/;
  const hasLetter = /[a-z]/i;
  if (value && value.length >= MIN_PASSWORD_LENGTH && value.length <= MAX_PASSWORD_LENGTH && hasNumber.test(value) && hasLetter.test(value)) {
    return true;
  }
  return false;
};

export const isNumber = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const convertMoney = options => {
  // https://www.npmjs.com/package/format-currency
  const { locale = 'en-IN', amount } = options;
  let opts = { format: '%s%v', symbol: '$', locale };

  const money = formatCurrency(amount, opts);
  //console.log("MONEY", money);
  return money;
};

export const defaultUnits = () => {
  const globalState = window.store.getState();
  const user = store.get('user');
  //console.log("stored user", user);
  const _defaultUnits = !isEmpty(user) && !isEmpty(user.default_units) ? user.default_units : (globalState.settings.imperial ? 'imperial' : 'metric');

  return _defaultUnits;
};

export const imperialUnits = (u) => {
  //console.log("default units ", defaultUnits());
  return defaultUnits() === 'imperial';
};

export const convertUnits = options => {
  //const globalState = window.store.getState();
  //console.log("global state", globalState);
  //console.log("convert units", imperialUnits());

  options = Object.assign({
    type: 'cubic_inches',
    time: '',
    //imperial: globalState.settings.imperial,
    imperial: imperialUnits(),
    precision: 3,
    showUnits: true,
    returnAs: 'formatted',
    prefix: '',
    className: 'convertible-value'
  }, options);

  let _value = '';
  let _suffix = '';
  let _output = '';

  //debug.log("options", options);

  const _suffixMap = {
    'in': '"',
    'ft': '\'',
    'in3': 'in&sup3;',
    'ft3': 'ft&sup3;',
    'ft/min': 'Feet/min',

    'mm': 'mm',
    'm': 'm',
    'cm3': 'cm&sup3;',
    'm3': 'm&sup3;',
    'm/min': 'M/min'
  };

  if (!isEmpty(options.to) && !isEmpty(options.from)) {
    // https://www.npmjs.com/package/convert-units
    _value = convert(parseFloat(options.value)).from(options.from).to(options.to);
    _suffix = (!options.time) ? _suffixMap[options.to] : _suffixMap[`${options.to}/${options.time}`];
    //console.log("from to", _value);
    // for now, don't use type
    options.type = null;
  }

  if (options.type) {
    switch (options.type) {
      case 'cubic_inches':
      default:
        //return (options.imperial) ? `${options.value} in&sup3;` : `${round((options.value * 16.387), options.precision)} cm&sup3;`;
        //_value = (options.imperial) ? options.value : round((options.value * 16.387), options.precision);
        _value = (options.imperial) ? options.value : options.value * 16.387;
        _suffix = (options.imperial) ? ' in&sup3;' : ' cm&sup3;';

        break;

      case 'cubic_feet':
//      _value = (options.imperial) ? options.value : round((options.value / 35.315), options.precision);

        _value = (options.imperial) ? options.value : options.value / 35.315;
        _suffix = (options.imperial) ? ' ft&sup3;' : ' m&sup3;';
        break;

      case 'inches':
        //      _value = (options.imperial) ? options.value : round((options.value * 25.4), options.precision);
        _value = (options.imperial) ? options.value : options.value * 25.4;
        _suffix = (options.imperial) ? '"' : ' mm';
        break;

      case 'feet':
        //      _value = (options.imperial) ? options.value : round((options.value / 3.281), options.precision);
        _value = (options.imperial) ? options.value : options.value / 3.281;
        _suffix = (options.imperial) ? '\'' : ' m';
        break;

      case  'feet/min':
        //      _value = (options.imperial) ? options.value : round((options.value / 3.281), options.precision);
        _value = (options.imperial) ? options.value : options.value / 3.281;
        _suffix = (options.imperial) ? ' Feet/min' : ' M/min';
        break;

      case 'mm':
        _value = (!options.imperial) ? options.value : options.value / 25.4;
        _suffix = (!options.imperial) ? 'mm' : ' "';
        break;
    }
  }

  _value = round(_value, options.precision);

  // if _value is within .1 from next number, round up
  if (parseInt(_value) < parseInt(_value + .1)) {
    // _value = parseInt(_value + .1);
  }

  //debug.log("convert units _value", _value,);

  switch (options.returnAs) {
    case 'formatted':
    default:
      _output = <NumberFormat
        value={_value}
        displayType={'text'}
        thousandSeparator={true}
        renderText={value => {
          return <div
            className={options.className}>{options.prefix}{value}{options.showUnits ? ReactHtmlParser(_suffix) : ''}</div>;
        }
        }/>;
      break;

    case 'valueOnly':
      _output = _value;
      break;

    case 'object':
      _output = {
        value: _value,
        prefix: options.prefix,
        suffix: _suffix,
      };
  }

  return _output;
};

export const numberFormat = options => {
  options = Object.assign({}, {
    returnAsString: true,
    decimalPlaces: 2
  }, options);

  let formattedNumber = Number(Math.round(parseFloat(options.value + 'e' + options.decimalPlaces)) + 'e-' + options.decimalPlaces);

  return options.returnAsString ? formattedNumber.toFixed(options.decimalPlaces) : formattedNumber;
};

export const getHTML = (options) => {
  let html = '';
  const globalState = window.store.getState();

  if (!isEmpty(get(globalState.settings, `config.htmls.${options.key}`))) {
    //const htmls = stringToObjectPath({string: "config.htmls", obj: globalState.settings});
    const htmls = get(globalState.settings, 'config.htmls');
    //console.log("htmls", htmls);
    if (htmls[options.key]) {
      html = ReactHtmlParser(htmls[options.key].html);
    }
  }

  return html;
};

export const tokenize = ({ returnComponent = true, messageType = '', template = '', data = {} }) => {
  const globalState = window.store.getState();

  if (!isEmpty(messageType)) {
    if (!isEmpty(get(globalState.settings, `config.htmls.${messageType}`))) {
      //const htmls = stringToObjectPath({string: "config.htmls", obj: globalState.settings});
      const htmls = get(globalState.settings, 'config.htmls');
      template = htmls[messageType].html;
    }
  }

  // Check if the template is a string or a function
  template = typeof (template) === 'function' ? template() : template;
  if (['string', 'number'].indexOf(typeof template) === -1) throw new Error('PlaceholdersJS: please provide a valid template');

  // If no data, return template as-is
  if (!data) return template;

  // Replace our curly braces with data
  template = template.replace(/\{\{([^}]+)\}\}/g, function (match) {

    // Remove the wrapping curly braces
    match = match.slice(2, -2);

    // Get the value
    const val = get(data, match);

    // Replace
    if (!val) return '{{' + match + '}}';
    return val;
  });

  if (returnComponent) {
    template = ReactHtmlParser(template);
  }

  return template;
};

export const showMessage = ({
                              messageType = '',
                              message = '',
                              messageText = '',
                              messageHeader = '',
                              isError = false
                            }) => {

  const globalState = window.store.getState();
  //console.log("Global State", globalState);

  let html = '';

  if (!isEmpty(messageText)) {
    html = <Message
      error={isError}
      info={!isError}>
      {!isEmpty(messageHeader) ? <Message.Header>{messageHeader}</Message.Header> : ''}
      {ReactHtmlParser(messageText)}
    </Message>;
  }

  if (!isEmpty(message)) {
    html = <Message
      error={isError}
      info={!isError}>
      {!isEmpty(messageHeader) ? <Message.Header>{messageHeader}</Message.Header> : ''}
      {message}</Message>;
  }

  if (!isEmpty(get(globalState.settings, `config.htmls.${messageType}`))) {
    //const htmls = stringToObjectPath({string: "config.htmls", obj: globalState.settings});
    const htmls = get(globalState.settings, 'config.htmls');
    //console.log("htmls", htmls);
    if (htmls[messageType]) {
      html = <Message
        error={isError}
        info={!isError}>
        <Message.Header>{htmls[messageType].title}</Message.Header>
        {ReactHtmlParser(htmls[messageType].html)}
        {!isEmpty(messageText) ? ReactHtmlParser(messageText) : ''}
      </Message>;
    }
  }

  return html;

  // if (globalState.settings.config) {
  //   const htmls = stringToObjectPath({string: "config.htmls", obj: globalState.settings});
  //   console.log("htmls", htmls);
  //   if (htmls[messageType]) {
  //     return <Message info header={htmls[messageType].title} content={ReactHtmlParser(htmls[messageType].html)}/>;
  //   } else {
  //     return;
  //   }
  // }
};

export const showSessionMessages = () => {
  const sessionMessages = store.get('session_messages');
  // const sessionMessages = [
  //   "testing 1",
  //   "<strong>testing 2</strong>",
  //   "testing 3"
  // ];
  let messageHTML = '';

  if (sessionMessages) {
    messageHTML = '<ul>';
    sessionMessages.map(sessionMessage => {
      debug.log('session message', sessionMessage);
      messageHTML += `<li>${sessionMessage}</li>`;
      return null;
    });

    messageHTML += '</ul>';

    setTimeout(function () {
      store.remove('session_messages');
    }, 10000);
  }

  if (messageHTML.length) {
    messageHTML = showMessage({ messageText: messageHTML, isError: true });
  }

  return messageHTML;
};

export const sessionLogout = (options) => {
  store.remove('user');
  store.remove('session_id');
};

export const hasValue = (property) => {
  // debug.log("hasValue", property);

  const propertyIsNotUndefined = typeof property !== 'undefined';
  const propertyIsNotNull = property !== null;
  const propertyIsString = typeof property === 'string';
  let propertyHasLength = true;

  if (propertyIsNotNull && propertyIsString) {
    propertyHasLength = property.length !== 0;
  }

  //debug.log("property is undefined", propertyIsUndefined, "property is null", propertyIsNull);

  if (propertyIsNotUndefined && propertyIsNotNull && propertyHasLength) return true;

  //if (typeof property !== "undefined" && property !== null) return true;
//&& (isNumber(property) || property.length !== 0)
  //return some(property, isEmpty);
  // console.log("test value", property, some(property, isEmpty));
};

export const cardsPerRow = (cards) => {
  // console.log("grid", grid);
  // const baseOffset = grid[0].offsetTop;
  // const breakIndex = grid.findIndex(item => item.offsetTop > baseOffset);
  // console.log("break index", breakIndex);
  // return (breakIndex === -1 ? grid.length : breakIndex);

  const cardsWidth = cards.offsetWidth;
  const cardsArray = Array.from(cards.children);
  const cardWidth = cardsArray[0].offsetWidth;
  //console.log("cards width", cardsWidth, "card width", cardWidth);

  return Math.floor(cardsWidth / cardWidth);
};

export const recipeTitle = options => {
  const recipeNameParts = [];

  if (!isEmpty(options.recipe.cnc_machine)) {
    recipeNameParts.push(`${options.recipe.cnc_machine.brand.title} ${options.recipe.cnc_machine.part_number.name}`);
  }

  if (!isEmpty(options.recipe.material)) {
    recipeNameParts.push(`${options.recipe.material.title} ${options.recipe.material.class.name}`);
  }

  if (!isEmpty(options.recipe.cutting_tools[0])) {
    recipeNameParts.push(options.recipe.cutting_tools[0].type.name);
  }

  if (!isEmpty(options.recipe.cutting_tools[0])) {
    recipeNameParts.push(`${options.recipe.cutting_tools[0].cutting_diameter}"`);
  }

  if (options.recipe.cut_type !== '') {
    recipeNameParts.push(options.recipe.cut_type.name);
  }

  const _recipeName = recipeNameParts.join(' - ');
  //debug.log("recipe title", _recipeName);

  return _recipeName;
};

export const recipeCardIndex = options => {
  if (!options.cards) {
    options.cards = options.card ? options.card.closest('.ui.cards') : document.querySelector('.recipes-container-cards');
    //debug.log("cards", options.cards);
  }
  const card = options.cards.querySelector(options.className);
  //const cardIndex = [...card.parentNode.children].indexOf(card);
  const cardIndex = [...card.parentNode.children].filter(cardSibling => {
    //debug.log("card sibling", cardSibling);
    return !cardSibling.classList.contains('recipe-card--preview');
  }).indexOf(card);
  // debug.log("card", card);
  //debug.log("card index", cardIndex);

  return cardIndex;
};

export const previewCardIndex = options => {
  const cards = options.card ? options.card.closest('.ui.cards') : document.querySelector('.recipes-container-cards');
  const cardsArray = Array.from(cards.children);

  //options = Object.assign({}, {cardsPerRow: 3}, options);
  //debug.log("preview card index options", options);

  const cardIndex = recipeCardIndex({ ...options });

  const cpr = cardsPerRow(cards);
  const cardRow = Math.floor(cardIndex / cpr) + 1;
  const lastCardInRowElementIndex = Math.min(cardsArray.length - 1, (cardRow * cpr) - 1);
  //debug.log("card index", cardIndex, "cards per row", cpr, "lastCardInRowElementIndex", lastCardInRowElementIndex);

  return lastCardInRowElementIndex + 1;
};

export const isTurningCutType = (cutTypeID) => {
  if (isTurningRoughingCutType(cutTypeID) || isTurningFinishingCutType(cutTypeID)) {
    return true;
  }
};

export const isTurningRoughingCutType = (cutTypeID) => {
  // LATHE ROUGHING
  if ([TURNING_PROFILE_ROUGHING_CUT_TYPE_ID,
    TURNING_ADAPTIVE_ROUGHING_CUT_TYPE_ID,
    TURNING_PARTING_CUT_TYPE_ID, TURNING_OD_THREAD_CUT_TYPE_ID, TURNING_ID_THREAD_CUT_TYPE_ID].includes(cutTypeID)) {
    return true;
  }
};

export const isTurningFinishingCutType = (cutTypeID) => {
  // LATHE FINISHING
  if ([TURNING_PROFILE_FINISHING_CUT_TYPE_ID,
    TURNING_FACING_CUT_TYPE_ID,
    TURNING_BORE_CUT_TYPE_ID,
    TURNING_OD_THREAD_CUT_TYPE_ID, TURNING_ID_THREAD_CUT_TYPE_ID].includes(cutTypeID)) {
    return true;
  }
};

export const isLocked = () => {
  const user = store.get('user');

  // if the user is authenticated AND locked property exists AND it's TRUE
  if (isAuthenticated() && get(user, 'locked') && user.locked === true) {
    return true;
  }
  return false;
};

export const userTierAccess = (options = { minTier: '0', maxTier: '' }) => {
  //console.log("checking tier");

  if (!options.minTier) {
    options.minTier = '';
  }

  if (!options.maxTier) {
    options.maxTier = '';
  }

  const user = store.get('user');

  //user.tier = "1.0.0";
  // options.minTier = "1.0.0";
  // options.maxTier = "2.0.0";
  // console.log("****** max tier", compareVersions(user.tier, options.minTier), compareVersions(user.tier, options.maxTier));
  // >= 0 AND < 0
  //return true;
  //console.log(`user tier: ${user.tier} min tier: ${options.minTier} max tier: ${options.maxTier}`);
  if (compareVersions('0.0.0', options.minTier) === 0) {
    return true;
  }

  // no reason to deal with non users
  if (!isAuthenticated() || !get(user, 'tier')) {
    return false;
  }

  //debug.log("user tier", user.tier, "min tier", options.minTier, "compare", compareVersions(user.tier, options.minTier));
  if (options.minTier !== '0.0.0' && options.maxTier === '') {
    if (compareVersions(user.tier, options.minTier) >= 0) {
      return true;
    }
  }

  if (options.minTier === '' && options.maxTier !== '0.0.0') {
    if (compareVersions(user.tier, options.maxTier) < 0) {
      return true;
    }
  }

  //console.log("tier", options);
  if (options.minTier !== '' && options.maxTier !== '') {

    //console.log("user tier", user.tier, "min", compareVersions(user.tier, options.minTier) >= 0, "max", compareVersions(user.tier, options.maxTier) <= 0);
    if (compareVersions(user.tier, options.minTier) >= 0 && compareVersions(user.tier, options.maxTier) <= 0) {

      return true;
    }
  }

  /*
  if (isAuthenticated() && get(user, "tier") && compareVersions(user.tier, options.minTier) >= 0) {
    //console.log("user has " + user.tier + " which is at least " + options.minTier);
    return true;
  } else if (isAuthenticated() && get(user, "tier") && options.maxTier !== "" && compareVersions(user.tier, options.maxTier) < 0) {
    //console.log("user has " + user.tier + " which is less than " + options.minTier);
    return true;
  } else {
    return false;
  }
  */
};

export const isAuthenticated = () => {
  const user = store.get('user');
  // console.log("stored user", user);
  const _isAuthenticated = (!isEmpty(user) && !isEmpty(user.auth_token) && user.uid > 0 && !isEmpty(user.email));
  //console.log("is authenticated", _isAuthenticated);
  return _isAuthenticated;
};

export const isCustomer = () => {
  const user = store.get('user');

  return (!isEmpty(user) && indexOf(user.roles, 'customer') > -1 ? true : false);
};

export const checkInvalidSessionID = options => {
  if (options.action.criteria && get(options.action.criteria, 'result') === 'success' && get(options.action.criteria, 'response.code') === 'invalid_session_id') {
    return true;
  }
  return false;
};

export const generateUUID = options => {
  return uuid();
};

export const isStaging = (options) => {
  let _isStaging = false;

  // const domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
  // console.log('domain', domain);
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  // console.log('subdomain', subdomain);

  if (subdomain === 'staging') {
    _isStaging = true;
  }

  return _isStaging;
};

export const isStagingOrDev = () => {
  return isStaging() || process.env.NODE_ENV === 'development';
};

export const Debug = props => {
  const globalState = window.store.getState();
  return (
    <pre>
      <code>{JSON.stringify(globalState, null, 2)}</code>
    </pre>
  );
};

export let debug = {};

const parsedURLParams = queryString.parse(window.location.search);
// console.log("params", parsedURLParams);

if (process.env.NODE_ENV !== 'development' && parsedURLParams.debug !== PRODUCTION_DEBUG_KEY) {
  var __no_op = function () {};

  // https://stackoverflow.com/questions/13815640/a-proper-wrapper-for-console-log-with-correct-line-number
  //window.debug = {
  debug = {
    log: __no_op,
    error: __no_op,
    warn: __no_op,
    info: __no_op,
    group: __no_op,
  };
} else {

  //window.debug = {
  debug = {
    log: window.console.log.bind(window.console), //"%o: %o"
    error: window.console.error.bind(window.console, 'error: %s'),
    info: window.console.info.bind(window.console, 'info: %s'),
    warn: window.console.warn.bind(window.console, 'warn: %s'),
    group: window.console.group.bind(window.console, 'group: %s'),
    groupEnd: window.console.groupEnd.bind(window.console)
  };
}

export const debug2 = (objs, note = '', options = {}) => {
  options = {
    error: false,
    table: false,
    force: false,
    ...options,
  };

  if (!window.console || !console.log) {
    return;
  }

  //console.log("hello", {one: 1, two: 2, three: 3});
  //window.debug.log({"something": "hello", "else": {one: 1, two: 2, three: 3}}, "This is a note");

  const con = {};

  for (var m in console) {
    if (typeof console[m] == 'function') {
      con[m] = console[m].bind(window.console);
    }
  }
  //console.log("con", con);
  //console.log("debug", options);
  // console.log(process.env);
  // console.log(window.location);
  const parsedURLParams = queryString.parse(window.location.search);
  // console.log("params", parsedURLParams);

  if (process.env.NODE_ENV !== 'development' && parsedURLParams.debug !== PRODUCTION_DEBUG_KEY) {
    return;
  }

  if (!Array.isArray(objs)) {
    objs = [objs];
  }

  if (objs.length > 1) {
    con.group(note !== '' ? note : 'Group');
  } else if (note !== '') {
    con.info(note);
  }

  objs.forEach(obj => {
    if (options.error) {
      con.error(obj);
    } else if (options.table) {
      con.table(obj);
    } else {
      con.log(obj);
    }
  });

  if (objs.length > 1) {
    con.groupEnd();
  }

};


