import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { retrievePart } from '../../redux/actions/parts';
import { updateSettings } from '../../redux/actions/settings';
import Layout from '../global/Layout';
import {
  Card,
  Grid, Header,
  Label,
  Table,
  Visibility
} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import PartToolbar from './PartToolbar';
import {
  userTierAccess,
  previewCardIndex,
  recipeCardIndex
} from '../../common/helpers';
import ReactPlayer from 'react-player';
import RecipeCard from '../recipes/RecipeCard';
import RecipeCardPreview from '../recipes/RecipeCardPreview';
import get from 'lodash/get';
import { scroller } from 'react-scroll';
import classNames from 'classnames/bind';
import PartMedia from './PartMedia';
import isEqual from 'lodash/isEqual';

function Parts(props) {
  const { part, settings, retrievePart } = props;
  const partID = props.match.params.partID;

  const [_part, setPart] = useState({});
  const [_partRecipes, setPartRecipes] = useState([]);
  // const playerRef = React.createRef();

  useEffect(() => {
    //console.log("useEffect: Part", props);
    //console.log("user", user);
    if (!settings.fetchingParts && !isEmpty(part) && !isEqual(part, _part)) {
      setPart(part);
      setPartRecipes(part.recipes);
    }

    const hash = window.location.hash.replace('#', '');
    //debug.log("hash", hash);

    if (!isEmpty(hash) && !isEmpty(part)) {
      scroller.scrollTo(hash, {
        duration: 1500,
        delay: 100,
        smooth: true,
        //containerId: "ContainerElementID",
        offset: -5, // Scrolls to element + 50 pixels down the page
      });
    }
  });

  init();

  function init() {
    useEffect(() => {
      let retrieveOptions = { id: partID, data: { view: true } };

      if (userTierAccess({ minTier: '1.0.0', maxTier: '1.9.9' })) {
        retrieveOptions.user = { tier: '2.0.0' };
      }

      retrievePart(retrieveOptions);
    }, []);
  }

  const onUpdate = (e, { calculations }) => {
    //console.log("Visibility Calcs", calculations);
  };

  // temp
  const [clickedZone, setClickedZone] = useState('');
  const [enteredZone, setEnteredZone] = useState('');

  const clickPartMediaZone = area => {
    //debug.log("part media zone", area);
    setClickedZone(area.name);
  };

  const enterPartMediaZone = area => {
    //debug.log("part media zone", area);
    setEnteredZone(area.name);
  };

  const clickOutputRow = outputRowIndex => {
    //debug.log("output row", e);
    setClickedZone(outputRowIndex);
    setEnteredZone(outputRowIndex);
  };

  // const handleOpenRecipePage = (recipeID) => {
  //   window.open(`/recipe/${recipeID}`, '_blank');
  // };

  function resetRecipePreviewCard(options) {
    // iterate through all recipes and modify values
    let rfc = options.recipes.map((recipe, index) => {
      recipe.selected = false;
      return recipe;
    });

    // reset cards by filtering out the one used for the preview if it exists
    const filteredRFC = rfc.filter((recipe, index) => !recipe.preview);

    //debug.log({"recipes for cards": options.recipes, "rfc": rfc, "filteredRFC": filteredRFC}, "resetRecipePreviewCard");
    return filteredRFC;
  }

  function hideRecipePreviewCard() {
    // reassign recipesForCards as recipes
    setPartRecipes(resetRecipePreviewCard({ recipes: _partRecipes }));
    updateSettings({ previewRecipeID: 0 });
  }

  function showRecipePreviewCard(options) {
    if (isEmpty(options.recipes)) {
      options.recipes = _partRecipes;
    }

    const recipeCardID = `recipe--${options.recipe.id}`;
    const recipeCardClassName = `.${recipeCardID}`;

    const pcIndex = previewCardIndex({ className: recipeCardClassName });
    let r = resetRecipePreviewCard({ recipes: options.recipes });

    let rcIndex = recipeCardIndex({ className: recipeCardClassName });
    //debug.log("rcindex", rcIndex);

    r[rcIndex].selected = true;

    r.splice(pcIndex, 0, { ...options.recipe, preview: true });
    //debug.log("recipes",r);
    setPartRecipes(r);
  }

  return (
    <Layout><Visibility onUpdate={onUpdate}>
      {/*{debug.log("part", _part)}*/}
      {!isEmpty(_part) ?
        <Grid className={classNames('part-content', 'content', `part part-${_part.id}`)}>
          <Grid.Row className="part-toolbar__wrapper">
            <PartToolbar/>
          </Grid.Row>
          <Grid.Row columns={3}>

            <Grid.Column
              class="part-media__wrapper">
              <PartMedia
                part={_part}
                minTier="1"
                checkProWithTier={true}
                onClickZone={clickPartMediaZone}
                onEnterZone={enterPartMediaZone}/>
            </Grid.Column>

            <Grid.Column className="part-output">

              <div className="part-output__column-title">OUTPUT</div>

              <div className="part-output__column-table">
                <Table
                  celled
                  compact
                  striped
                  selectable
                  padded>
                  <Table.Body>
                    {!isEmpty(_part.recipes) && _part.recipes.map((partRecipe, index) => {
                      return (
                        <Table.Row
                          key={index}
                          className={enteredZone === index ? 'area-hover' : ''}
                          onClick={() => clickOutputRow(index)}
                          onMouseEnter={() => enterPartMediaZone({ name: index })}>
                          <Table.Cell collapsing>{enteredZone === index ? <Label
                            nm
                            ribbon>{`STEP ${index + 1}`}</Label> : `STEP ${index + 1}`}</Table.Cell>
                          <Table.Cell collapsing>{partRecipe.cut_type.name}</Table.Cell>
                          <Table.Cell collapsing>
                            <a
                              href={`/recipe/${partRecipe.id}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >View Recipe {partRecipe.id}</a>
                          </Table.Cell>
                        </Table.Row>);
                    })}
                  </Table.Body>
                </Table>
              </div>
            </Grid.Column>

            <Grid.Column verticalAlign="middle">
              {
                Number.isInteger(clickedZone) && _part && _part.recipes[clickedZone] && !isEmpty(get(_part.recipes[clickedZone], 'videos[0]')) ?
                  <div className="part-recipe-video__react-player-wrapper">
                    <ReactPlayer
                      className="part-recipe-video__react-player"
                      width="100%"
                      height="100%"
                      url={_part.recipes[clickedZone].videos[0].url}
                      // ref={playerRef}
                      // playing={playing}
                      // onPlay={onPlay}
                      // onPause={onPause}
                      controls={true}
                      config={{
                        vimeo: {
                          preload: true,
                          playerOptions: { responsive: true }
                        }
                      }}
                    />
                  </div> : <Header
                    as="h1"
                    className="part-recipe-video__cta"
                    textAlign="center">Click a row to
                    preview recipe video</Header>

              }
            </Grid.Column>

            {/*<div>Clicked Zone: {clickedZone}<br/>Entered Zone: {enteredZone}</div>*/}
          </Grid.Row>

          {/* ------------------------------------- BAR ------------------------------------- */}
          <Grid.Row className="part-bar__wrapper">
            <Grid
              className="part-bar"
              columns={2}>
              <Grid.Row>
                <Grid.Column
                  width={2}
                  className="part-bar__id">
                  Part ID: {_part.id}
                </Grid.Column>

                <Grid.Column
                  width={3}
                  className="part-bar__part-type">
                  Part ID: {_part.type.name}
                </Grid.Column>

                <Grid.Column
                  width={3}
                  className="part-bar__machine">
                  Machine: {_part.machine.name}
                </Grid.Column>

                <Grid.Column
                  width={3}
                  className="part-bar__material">
                  Material: {_part.material.name}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>

          {/* ------------------------------------- OUTPUT ------------------------------------- */}
          <Grid.Row className="part-output__wrapper">
            <Grid
              className="part-output"
              columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <div className="part-output__column-title">OUTPUT</div>

                  <div className="part-output__column-table">
                    <Table
                      celled
                      compact
                      striped
                      selectable
                      padded>
                      <Table.Body>
                        {!isEmpty(_part.recipes) && _part.recipes.map((partRecipe, index) => {
                          return (
                            <Table.Row
                              key={index}
                              className={enteredZone === index ? 'area-hover' : ''}
                              onClick={() => clickOutputRow(index)}
                              onMouseEnter={() => enterPartMediaZone({ name: index })}>
                              <Table.Cell collapsing>{enteredZone === index ? <Label
                                nm
                                ribbon>{`STEP ${index + 1}`}</Label> : `STEP ${index + 1}`}</Table.Cell>
                              <Table.Cell collapsing>{partRecipe.cut_type.name}</Table.Cell>
                              <Table.Cell collapsing>
                                <a
                                  href={`/recipe/${partRecipe.id}`}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >View Recipe {partRecipe.id}</a>
                              </Table.Cell>
                            </Table.Row>);
                        })}
                      </Table.Body>
                    </Table>
                  </div>
                </Grid.Column>
                <Grid.Column verticalAlign="middle">
                  {
                    Number.isInteger(clickedZone) && _part && _part.recipes[clickedZone] && !isEmpty(get(_part.recipes[clickedZone], 'videos[0]')) ?
                      <div className="part-recipe-video__react-player-wrapper">
                        <ReactPlayer
                          className="part-recipe-video__react-player"
                          width="100%"
                          height="100%"
                          url={_part.recipes[clickedZone].videos[0].url}
                          // ref={playerRef}
                          // playing={playing}
                          // onPlay={onPlay}
                          // onPause={onPause}
                          controls={true}
                          config={{
                            vimeo: {
                              preload: true,
                              playerOptions: { responsive: true }
                            }
                          }}
                        />
                      </div> : <Header
                        as="h1"
                        className="part-recipe-video__cta"
                        textAlign="center">Click a row to
                        preview recipe video</Header>

                  }
                </Grid.Column>
              </Grid.Row></Grid>
          </Grid.Row>

          {/* ------------------------------------- RECIPES ------------------------------------- */}
          <Grid.Row className="part-recipes__wrapper">
            <Grid className="part-recipes">
              <Grid.Row>
                <Grid.Column
                  className="recipes-container"
                  width={16}>
                  <Card.Group
                    className="recipes-container-cards"
                    itemsPerRow={4}>
                    {!isEmpty(_partRecipes) && _partRecipes.map((partRecipe, index) => {
                      if (partRecipe.preview) {
                        return (
                          <RecipeCardPreview
                            key={`${partRecipe.id}-preview`}
                            recipe={partRecipe}
                            // autoShowRecipePreviewCard={autoShowRecipePreviewCard}
                            //                  recipesForCards={_partRecipes}
                            //                  setRecipesForCards={setPartRecipes}
                            //                  hideRecipePreviewCard={hideRecipePreviewCard}
                          ></RecipeCardPreview>
                        );
                        // return (<div key={`${partRecipe.id}-preview`}>PREVIEW {partRecipe.id}</div>);
                      } else {

                        return (
                          <RecipeCard
                            key={partRecipe.id}
                            index={index}
                            recipe={partRecipe}
                            recipesForCards={_part.recipes}
                            cornerLabel={index + 1}
                            additionalClassNames={enteredZone === index ? 'area-hover' : ''}
                            setRecipesForCards={setPartRecipes}
                            showRecipePreviewCard={showRecipePreviewCard}
                            hideRecipePreviewCard={hideRecipePreviewCard}
                          />
                        );
                      }
                    })}
                  </Card.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>
        </Grid>
        :
        <div>Loading</div>}
    </Visibility></Layout>

  );
}

function mapStateToProps(state) {
  //console.log("Part: Map State to Props", state);
  return {
    part: state.partsData.part,

    //totalParts: state.partsData.totalParts,
    //parts: state.partsData.parts,
    //filters: state.partsData.filters,
    //selectedFilters: state.partsData.selectedFilters,
    settings: state.settings,
    user: state.userData,
  };
}

export default connect(mapStateToProps, { retrievePart, updateSettings })(Parts);

/*

imagemap part
part info
part output
part step video
recipes grid

- when part imagemap surface is clicked or when Output step is selected, filter recipes
- play recipe video in recipe card





 */