import { combineReducers } from "redux";
import faqsData from "./faqs";
import orderData from "./order";
import recipesData from "./recipes";
import partsData from "./parts";
import settings from "./settings";
import userData from "./user";
import userRecipesData from "./userRecipes";
import userToolsData from "./userTools";
import userRecipeModifiersData from "./userRecipeModifiers";
import userFiltersData from "./userFilters";
import groupsData from "./groups";

export default combineReducers({
  settings,
  userData,
  userToolsData,
  userRecipeModifiersData,
  userFiltersData,
  userRecipesData,
  orderData,
  recipesData,
  partsData,
  faqsData,
  groupsData,
});

