import {
  DELETED_USER_FILTERS,
  FOUND_USER_FILTERS,
  TOGGLE_USER_FILTERS,
  UPDATED_USER_FILTERS
} from "../../common/consts";
import { checkInvalidSessionID, debug } from "../../common/helpers";

const userFiltersData = (state = {}, action = {}) => {
  //debug.log("User Filters Reducers", state, action);
  if (checkInvalidSessionID({action})) {
    return {
      ...state
    };
  }

  switch (action.type) {
    case FOUND_USER_FILTERS:
      debug.log("reducer: user filters - found", action);

      return {
        ...state,
        user_filters: action.criteria.response.filters,
      };

    // case CREATED_USER_FILTERS:
    //   //console.log("reducer: user tool - created", action);
    //   debug.log("reducer: user filters - created",action);
    //   return {
    //     ...state,
    //     //is_user_tool: action.criteria.result === "success",
    //     user_filters: action.criteria.response.filters,
    //   };
    case UPDATED_USER_FILTERS:
      debug.log("reducer: user filters - updated", action);
      return {
        ...state,
        user_filters: action.criteria.response.filters,
      };

    case DELETED_USER_FILTERS:
      debug.log("reducer: user filters - deleted", action);
      return {
        ...state,
        //is_user_tool: !(action.criteria.result === "success"),
        user_filters: action.criteria.response.filters,
      };

    case TOGGLE_USER_FILTERS:
      //console.log("reducer: user tool - toggle", action);
      debug.log("reducer: user filters - toggle", action);
      return {
        ...state,
        is_user_tool: action.toggle,
      };

    default:
      //console.log("default reducer: stored user", user, state);
      return {
        ...state,
        //is_user_tool: (state.is_user_tool === null) ? null : state.is_user_tool,
      };

  }
};

export default userFiltersData;