//import Formsy from 'formsy-react-es6';
import { Form, RadioGroup } from 'formsy-semantic-ui-react';
import find from 'lodash/find';
import get from 'lodash/get';
import has from 'lodash/has';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import { Button, Checkbox, Dimmer, Grid, Input, Label, Loader, Radio, Divider } from 'semantic-ui-react';
import { convertMoney, debug, hasValue } from '../../common/helpers';
import { resetOrderReview, retrieveOrderReview } from '../../redux/actions/order';
import { updateSettings } from '../../redux/actions/settings';
import { cancelUser, migrateUser, reactivateUser, updateUserSubscription } from '../../redux/actions/user';
import isEmpty from 'lodash/isEmpty';
import ReactHtmlParser from 'react-html-parser';

function SubscriptionInfoForm(props) {
  const { settings, user, cancelUser, reactivateUser, migrateUser } = props;
  //console.log("subscription form", props);

  const [editSubscription, setEditSubscription] = useState(false);
  const [allowUpdateSubscription, setAllowUpdateSubscription] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [showConfirmReactivate, setShowConfirmReactivate] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelSubscription, setCancelSubscription] = useState(false);

  //const [modifiedFields, setModifiedFields] = useState({});
  const [productPrices, setProductPrices] = useState({
    init: true,
    1: { price: '', interval: '' },
    2: { price: '', interval: '' }
  });
  const [products, setProducts] = useState([]);

  //const query = Object.assign({product_id}, qs.parse(props.location.search));
  //console.log("query", query);

  const defaultProductID = 2;

  const updateProductPrices = (options = { product_ids: [defaultProductID] }) => {
    // this can be changed to accommodate multiple products by passing an array of objects or something similar

    // console.log("**** updateProductPrices - product ids", product_ids);
    const { product_ids } = options;

    //console.log("**** updateProductPrices - product_id", product_id);
    const products = get(settings, 'config.products', []);
    setProducts(products);

    if (products.length) {

      if (product_ids !== null) {
        let _productPrices = {};

        product_ids.forEach(product_id => {
          //console.log("product id", product_id);

          const product = find(settings.config.products, { id: parseInt(product_id) });
          //console.log("**** updateProductPrices - product", product);
          //console.log("**** updateProductPrices - address", address);
          if (product) {
            const price = product.pricing[has(product.pricing, user.addresses[0].country.toLowerCase()) ? user.addresses[0].country.toLowerCase() : 'default'].price;

            if (price) {
              //const productPrice = {};
              //productPrice[product_id] = {price: convertMoney({amount: price})};
              // setProductPrices({...productPrices, init: false, ...productPrice});
              // changeOrderState("review");
              _productPrices[product_id] = { price: convertMoney({ amount: price }), interval: product.interval };
            }
          }
        });

        if (!isEmpty(_productPrices)) {
          setProductPrices({ ...productPrices, init: false, ..._productPrices });
        }
      }

    }
  };

  /*
  const updateProductPrices = (options = {product_id: defaultProductID}) => {
    // this can be changed to accommodate multiple products by passing an array of objects or something similar

    //console.log("**** updateProductPrices - query", query);
    const {product_id} = options;

    //console.log("**** updateProductPrices - product_id", product_id);
    const products = get(settings, "config.products", []);

    if (products.length) {

      const product = find(settings.config.products, {id: parseInt(product_id)});
      //console.log("**** updateProductPrices - product", product);
      //console.log("**** updateProductPrices - address", address);

      if (product) {
        const price = product.pricing[has(product.pricing, user.addresses[0].country.toLowerCase()) ? user.addresses[0].country.toLowerCase() : "default"].price;

        if (price) {
          const productPrice = {};
          productPrice[product_id] = {price: convertMoney({amount: price})};
          setProductPrices({...productPrices, init: false, ...productPrice});
        }
      }
    }
  };
  */

  /*const changeSubscriptionDropdown = (e, data) => {
    //console.log(`change dropdown ${data.name}`, e, data);
    setModifiedFields({...modifiedFields, [data.name]: data.value});
    setAllowUpdateSubscription(true);
  };*/

  /*const changeSubscriptionInput = (e, data) => {
    //console.log(`change input ${data.name}`, e, data);
    setModifiedFields({...modifiedFields, [data.name]: data.value});
    setAllowUpdateSubscription(true);
  };*/

  const changeConfirmCancel = (e, data) => {
    // console.log(`change confirm cancel ${data.name}`, e, data);
    debug.log({ 'e': e, 'data': data }, `change confirm cancel ${data.name}`);

    //if (data.checked) {
    setConfirmCancel(data.checked);

    if (data.checked && (cancelReason.length || showConfirmReactivate)) {
      setAllowUpdateSubscription(true);
    } else {
      setAllowUpdateSubscription(false);
    }
  };

  const changeSubscription = (e, data) => {
    // console.log(`change subscription ${data.name}`, e, data);
    debug.log({ 'e': e, 'data': data }, `change subscription to ${data.label} tier ${data.value}`);

    setShowConfirmCancel(false);
    setShowConfirmReactivate(false);
    setAllowUpdateSubscription(false);

    //if (data.value === 'cancel') {
    if (data.value.startsWith('cancel')) {
      setShowConfirmCancel(true);

    } else if (data.value === 'reactivate') {
      setShowConfirmReactivate(true);

    } else {
      setAllowUpdateSubscription(true);
    }
  };

  const clickEditSubscription = (e) => {
    e.preventDefault();
    setEditSubscription(true);
  };

  useEffect(() => {
    //console.log("useEffect: Subscription Info Form. Order State", orderState, "Props", props);
    if (productPrices.init) {
      updateProductPrices({ product_ids: [1, 2] });
      //      updateProductPrices({product_id: defaultProductID});
    }

    if (cancelSubscription && !user) {
      return <Redirect to="/exit-survey"/>;
    }

    /*
    // we only want to do this once
    if (get(settings, "config.countries") && countriesOptions.length === 1) {
      setCountriesOptions([...countriesOptions, ...settings.config.countries.map(Country => ({
        key: Country.cid,
        value: Country.iso2,
        text: Country.name,
      }))]);
      //console.log("Country Options", countriesOptions);
    }



    if (order.valid) {
      console.log("order data", order);

      setReceipt({
        // using the price for the subtotal instead of subtotal for subtotal because price is before discount
        subtotal: order.price,
        tax: order.tax,
        discount: order.discount,
        total: order.total,
        init: false
      });

      changeOrderState("create");
    } else {
      setReceipt(initReceipt);
    }
*/
  }, [settings, user]);

  const cancelSubscriptionReason = (e, data) => {
    //debug.log({"e": e, "data": data}, `change subscription ${data.name}`);

    setAllowUpdateSubscription(false);
    setCancelReason('');

    if (data.value !== '') {
      setCancelReason(data.value);

      if (confirmCancel) {
        setAllowUpdateSubscription(true);
      }
    }
  };

  const updateAccount = (formData) => {
    debug.log({ 'formData': formData });

    if (editSubscription) {
      // only update subscription
      setEditSubscription(false);
    }

    if (formData.product_id.startsWith('cancel')) {
      // cancel subscription
      setCancelSubscription(true);

      cancelUser({ data: { mode: formData.product_id, reason: cancelReason } });

    } else if (formData.product_id === 'reactivate') {
      reactivateUser();
    } else {
      debug.log('calling migrate');
      // check subscription

      // code here to upgrade subscription
      migrateUser({ data: { product_id: formData.product_id } });
    }
  };

  const errorLabel = <Label
    color="red"
    pointing/>;

  return (
    <Grid.Column className="account__info-column">
      <Form
        size="large"
        className="account-content__form"
        onValidSubmit={updateAccount}
        loading={settings.fetchingOrder}>
        <div className="account__info-column-title">SUBSCRIPTION</div>
        {editSubscription ?
          (<div>
            <Form.Field>
              {/*{console.log(user.user_subscriptions[0].product.id)}*/}
              <label>Choose a Subscription <span className="red">*</span></label>
              <RadioGroup
                name="product_id"
                className="products"
                required
                defaultSelected={user.user_subscriptions[0].product.id.toString()}
                validationErrors={{
                  isDefaultRequiredValue: 'Subscription is Required'
                }}
                onChange={changeSubscription}
              >
                {/* we're not allowing users to downgrade. They can only upgrade, for now. The condition below only
                 shows the Lite option if they currently are a Lite subscriber so they can upgrade to Pro */}
                {user.user_subscriptions[0].product.id === 1 ?
                  <Radio
                    label={`Lite ${productPrices[1].price} ${productPrices[1].interval}`}
                    value="1"/> : <React.Fragment/>}
                {user.user_subscriptions[0].product.id === 1 && !isEmpty(products) && !isEmpty(find(products, { id: 1 }).description) ?
                  <div
                    className="description">{ReactHtmlParser(find(products, { id: 1 }).description)}</div> :
                  <React.Fragment/>}

                {(user.user_subscriptions[0].product.id < 2 && !hasValue(user.user_subscriptions[0].canceled)) || user.user_subscriptions[0].product.id === 2 ?
                  <Radio
                    label={`Pro ${productPrices[2].price} ${productPrices[2].interval}`}
                    value="2"/> : <React.Fragment/>}


                {((user.user_subscriptions[0].product.id < 2 && !hasValue(user.user_subscriptions[0].canceled)) || user.user_subscriptions[0].product.id === 2) && !isEmpty(products) && !isEmpty(find(products, { id: 2 }).description) ?
                  <div
                    className="description">{ReactHtmlParser(find(products, { id: 2 }).description)}</div> :
                  <React.Fragment/>}


                {/*{user.user_subscriptions[0].product.id === 1 ?*/}
                {/*  (<Radio label={`Lite ${productPrices[1].price} ${productPrices[1].interval}`} value="1"/>) : <div/>}*/}
                {/*{user.user_subscriptions[0].product.id === 2 ?*/}
                {/*  (<Radio label={`Pro ${productPrices[2].price} ${productPrices[2].interval}`} value="2"/>) : <div/>}*/}


                {/* if there are no user groups then we can cancel this subscription ourselves. */}
                {/* need more code to determine if this user owns the group */}
                {/*{console.log(hasValue(user.user_subscriptions[0].canceled))}*/}
                {/*{console.log('user sub', user)}*/}
                {!user.user_groups.length && !hasValue(user.user_subscriptions[0].canceled) ? (
                  [<Radio
                    label={`Cancel when my subscription ends`}
                    value="cancel_on_expiration"
                    key="cancel_on_expiration"/>,
                    <div
                      className="description"
                      key="description">Your current subscription will expire
                      on<br/><strong>{user.user_subscriptions[0].expire_date}</strong></div>]) : <React.Fragment/>}

                {!user.user_groups.length && !hasValue(user.user_subscriptions[0].canceled) ? (
                  [<Radio
                    label={`Cancel immediately`}
                    value="cancel_immediately"
                    key="cancel_immediately"/>,
                    <div
                      className="description"
                      key="description">Refund my card ending
                      in <strong>{user.user_subscriptions[0].card_last4}</strong> with the prorated balance for the
                      remainder of my subscription.</div>
                  ]
                ) : <React.Fragment/>}

                {!user.user_groups.length && hasValue(user.user_subscriptions[0].canceled) ? (
                  <Radio
                    label="Reactivate auto renew"
                    value="reactivate"/>) : <React.Fragment/>}
              </RadioGroup>

              {showConfirmCancel || showConfirmReactivate ?
                (<React.Fragment><Divider/>
                  <Form.Group
                    inline
                    className="subscription-confirmation">
                    <Checkbox
                      name="confirm_cancel"
                      onClick={changeConfirmCancel}/>&nbsp;<label>Click here to confirm subscription
                    {showConfirmCancel ? ' cancellation' : ''}
                    {showConfirmReactivate ? <React.Fragment> auto-renewal on <Moment
                      format="MM/DD/YYYY"
                      unix>{user.user_subscriptions[0].expire}</Moment>
                    </React.Fragment> : ''}
                  </label></Form.Group>
                  {showConfirmCancel ?
                    <Form.Field>
                      <label>Reason <span className="red">*</span></label>
                      <Input
                        placeholder="Reason for cancellation"
                        name="reason"
                        required
                        onChange={cancelSubscriptionReason}
                        validationErrors={{
                          isDefaultRequiredValue: 'Reason is Required'
                        }}
                        errorLabel={errorLabel}/>
                    </Form.Field> : ''}
                </React.Fragment>) : ''}


            </Form.Field>
            <div className="account__info-subscription_button">
              <Form.Button
                disabled={!allowUpdateSubscription}
                content={`${(showConfirmCancel ? 'Cancel' : showConfirmReactivate ? 'Reactivate' : 'Update')} Subscription`}
                className="account-form__update-button"/>
            </div>
          </div>) :

          (<div className="account__info-subscription">
            <Dimmer
              active={settings.updatingUserSubscription}
              inverted>
              <Loader size="large">Updating</Loader>
            </Dimmer>
            <div className="account__info-subscription_creation">
              Member since <Moment
              format="MM/DD/YYYY"
              unix>{user.user_subscriptions[0].created}</Moment></div>
            <div
              className="account__info-subscription_product">{user.user_subscriptions[0].product.title}</div>
            <div
              className="account__info-subscription_product">{hasValue(user.user_subscriptions[0].canceled) ? 'Expires on ' : 'Auto-renewing on '}
              <Moment
                format="MM/DD/YYYY"
                unix>{user.user_subscriptions[0].expire}</Moment></div>

            {hasValue(user.user_subscriptions[0].canceled) ?
              <React.Fragment>
                <br/>
                <div className="account__info-subscription_product">Auto-renew for
                  your {user.user_subscriptions[0].product.title} was canceled
                  on <Moment
                    format="MM/DD/YYYY"
                    unix>{user.user_subscriptions[0].canceled}</Moment>.
                </div>
              </React.Fragment>
              : ''}

            <div className="account__info-subscription_button">
              <Button onClick={clickEditSubscription}>Edit Subscription</Button>
            </div>
          </div>)}
      </Form></Grid.Column>
  );
}

function mapStateToProps(state) {
  //console.log("Subscription Info Form: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    order: state.orderData,
  };
}

export default injectStripe(connect(mapStateToProps, {
  updateSettings,
  retrieveOrderReview,
  resetOrderReview,
  updateUserSubscription,
  cancelUser,
  reactivateUser,
  migrateUser,
})(withRouter(SubscriptionInfoForm)));

/*


*/