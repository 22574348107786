import { CREATED_USER_FILTERS, RETRIEVED_CONFIG, RETRIEVED_RECIPES, UPDATE_SETTINGS } from "../../common/consts";
import isEmpty from "lodash/isEmpty";
import { debug } from "../../common/helpers";

const settings = (state = {}, action = {}) => {
  //console.log("Settings Reducers", state, action);
  let _return;

  switch (action.type) {
    case RETRIEVED_CONFIG:
      return {
        ...state,
        config: action.configResponse,
        ...action.initialSettings,
        sortOptions: action.sortOptions,
        thumbnailOptions: action.thumbnailOptions,
        calculatorCutTypes: action.calculatorCutTypes,
        ready: true
      };

    case UPDATE_SETTINGS:
      //console.log("update settings", state, action);
      return {
        ...state,
        ...action.criteria
      };

    case RETRIEVED_RECIPES:
      debug.log("reducer: settings - retrieved recipes", action);

      _return = {
        ...state,
        currentPage: action.criteria.response.current_page,
      };

      if (!isEmpty(action.criteria.response.selected_filters)) {
        //console.log("UPDATING ACTIVE FILTERS", action.criteria.response);
        _return.activeFilters = action.criteria.response.selected_filters;
      }
      // removed in 0.4.23 because it is sending an empty array within the filters array to the server
      // when we need to have no filters at all if we want to leverage the initial caching
      // by removing the default empty user_filters array, we are telling the server there are no filters and it's safe to use cache
      //_return.activeFilters.user_filters = [];

      return _return;

    case CREATED_USER_FILTERS:
      //console.log("reducer: user tool - created", action);
      debug.log("reducer: settings - created user filters", action);

      _return = {
        ...state,
      };
      //debug.log(_return.activeFilters, "active filters");
      if (action.criteria.response.new_user_filter !== null) {
        _return.activeFilters = {
          ..._return.activeFilters,
          user_filters: null
          //user_filters: [action.criteria.response.new_user_filter.id]
        };
      }

      return _return;

    default:
      return state;
  }
};
export default settings;