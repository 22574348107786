import classNames from "classnames";
//import filter from "lodash/filter";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Icon, Input, List, Table } from "semantic-ui-react";
import { convertUnits, debug, isNumber, userTierAccess } from "../../common/helpers";
import { retrieveParts } from "../../redux/actions/parts";
import { updateSettings } from "../../redux/actions/settings";
import { HashLink as Link } from "react-router-hash-link";

function PartListFilter (props) {
  const {
    path,
    simple,
    property = "title",
    defaultToTextField,
    showSearch = true,
    showUncheckAll = false,
    title,
    className,
    filters,
    selectedFilters,
    retrieveParts,
    updateSettings,
    settings,
    partsTime,
    minTier = "0.0.0",
    suffix = "",
    range = false,
    increment = 1,
    incrementByRange = false,
    presorted = false,
    minFilterOptionsScroll = 12,
    small = false,
    initOpen = true,
    multiSelect = true,
    overrideActiveFilters = false,
    disabled = !userTierAccess({minTier}),
    faq = "",
    onRemove = null,
    convertible,
    convertOptions = {}
  } = props;

  //console.log(`path ${filter path}`);
  //debug.log(`path ${path}`);
  const [open, setOpen] = useState(initOpen);
  const [searchString, setSearchString] = useState("");
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);
  const [allFilterOptions, setAllFilterOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [madeFirstSelection, setMadeFirstSelection] = useState(false);

  function resetFilter () {
    setMadeFirstSelection(false);
    setSearchString("");
    setSelectedFilterOptions([]);

    if (initOpen) {
      openList();
    }
  }

  function changeTextField (e) {
    setSearchString(e.currentTarget.value);
  }

  function searchTextField () {
    console.log("search text field", path, searchString);
    // updateSettings({
    //   activeFilters: set(settings.activeFilters, path, searchString)
    // });

    updateParts({value: searchString});
  }

  function changeSearch (e) {
    setSearchString(e.currentTarget.value);
    searchFilterOptions(e.currentTarget.value);
  }

  function searchFilterOptions (_searchString) {
    //console.log("searching for", searchString, "all filter options", allFilterOptions);

    let _allFilterOptions = allFilterOptions;

    const _filterOptions = _allFilterOptions.filter(function (item) {
      //console.log("item", item);
      const _value = (simple ? item.toString() : item[property].toString());
      //console.log("_value", _value);
      _searchString = _searchString.replace(/\\/g, "");
      const regex = new RegExp(_searchString, "gi");
      return _value.match(regex);
    });
    //console.log("_filterOptions", _filterOptions);
    setFilterOptions(_filterOptions);
  }

  function toggleSelectedFilterOption (e) {
    e.preventDefault();

    if (settings.fetchingParts) {
      return;
    }

    //console.log("toggle selected filter option", e.currentTarget.dataset.id);
    const _id = e.currentTarget.dataset.id;

    // open first
    if (selectedFilterOptions.length === 1) {
      openList();
    }

    // then remove so it appears unchecked in the list
    removeSelectedFilterOption(_id.toString());

    if (selectedFilterOptions.length > 1) {
    }
  }

  function selectFilterOption (e) {
    e.preventDefault();
    //console.log("selected option value", e.currentTarget.dataset.id);

    if (settings.fetchingParts) {
      return;
    }

    if (disabled) {
      return;
    }

    const _id = e.currentTarget.dataset.id;

    if (selectedFilterOptions.includes(_id.toString())) {
      //console.log("calling removing", _id.toString());
      removeSelectedFilterOption(_id.toString());
    } else {

      //console.log("does not exist, adding", _id.toString());
      addSelectedFilterOption(_id.toString());
    }

    // console.log("selectedFilterOptions", selectedFilterOptions);
    // if (selectedFilterOptions.length < 1) {
    //   closeList();
    // }
  }

  function addSelectedFilterOption (id) {

    let _selectedFilterOptions = [id];

    if (multiSelect) {
      _selectedFilterOptions = [id, ...selectedFilterOptions];
    }

    // console.log("adding item to filter", [..._selectedFilterOptions], path);
    setSelectedFilterOptions([..._selectedFilterOptions]);
    //console.log("(add) updated _selectedFilterOptions", _selectedFilterOptions);
    changeSelectedFilterOptions(_selectedFilterOptions);
  }

  function removeSelectedFilterOption (id) {
    let _selectedFilterOptions = selectedFilterOptions;
    //console.log("exists, removing", id.toString());
    const _idIndex = _selectedFilterOptions.indexOf(id.toString());
    _selectedFilterOptions.splice(_idIndex, 1);
    //console.log("(remove) updated _selectedFilterOptions", _selectedFilterOptions);
    setSelectedFilterOptions([..._selectedFilterOptions]);
    changeSelectedFilterOptions(_selectedFilterOptions);
  }

  function uncheckAll (e) {
    e.preventDefault();

    const _selectedFilterOptions = [];
    setSelectedFilterOptions([..._selectedFilterOptions]);
    changeSelectedFilterOptions(_selectedFilterOptions);
  }

  function changeSelectedFilterOptions (selectedFilterOptionIDs) {
    //console.log("change selected filter options", selectedFilterOptionIDs);
    //console.log("selectedFilterOptionIDs", selectedFilterOptionIDs, "selectedFilterOptions", selectedFilterOptions);
    if (selectedFilterOptionIDs.length === 1 && !madeFirstSelection) {
      setMadeFirstSelection(true);
      closeList();
    }

    updateParts({value: selectedFilterOptionIDs});
    // updateSettings({
    //   activeFilters: set(settings.activeFilters, path, selectedFilterOptionIDs)
    // });
    //
    // retrieveParts();
    //
    // // go to the top
    // window.scrollTo(0, 0);
  }

  function updateParts (options) {
    // debug.log("filter retrieve parts", options);

    if (overrideActiveFilters) {
      updateSettings({
        activePartFilters: set({}, path, options.value)
      });
    } else {
      // debug.log("update active part filters", settings.activePartFilters);
      updateSettings({
        activePartFilters: set(settings.activePartFilters, path, options.value)
      });
    }

    retrieveParts();

    // go to the top
    window.scrollTo(0, 0);
  }

  function toggleOpenList (e) {
    e.preventDefault();
    openList();
  }

  function toggleCloseList (e) {
    e.preventDefault();
    closeList();
  }

  function openList () {
    setOpen(true);
  }

  function closeList () {
    setOpen(false);
    setSearchString("");
    setFilterOptions(allFilterOptions);
  }

  function sortSelectedFilterOptions () {
    let sortedSelectedFilterOptions = [];

    if (simple) {
      //sortedSelectedFilterOptions = selectedFilterOptions.sort();
      sortedSelectedFilterOptions = selectedFilterOptions.sort((a, b) => a - b);

    } else {
      //console.log("all filter options", allFilterOptions);
      sortedSelectedFilterOptions = sortBy(selectedFilterOptions.map((id, index) => {
        //console.log("id", id);
        let filterOption = find(allFilterOptions, {id: parseInt(id)});
        if (filterOption === undefined) {
          filterOption = id;
        }
        //console.log("filter option", filterOption);
        return filterOption;
      }), property);
    }

    //console.log("sorted filter options", sortedSelectedFilterOptions);
    return sortedSelectedFilterOptions;
  }

  function sortFilterOptions () {
    let sortedFilterOptions = [];

    //debug.log("path", path, "presorted", presorted, filterOptions);
    if (presorted) {
      sortedFilterOptions = filterOptions;

    } else if (simple) {
      //sortedFilterOptions = filterOptions.sort();
      sortedFilterOptions = filterOptions.sort((a, b) => a - b);
    } else {
      //console.log("property", property);

      //debug.log("filter options to sort", filterOptions);
      sortedFilterOptions = sortBy(filterOptions.map((id, index) => {
        //console.log("id", id);
        let filterOption = find(allFilterOptions, {id: parseInt(id)});
        if (filterOption === undefined) {
          filterOption = id;
        }
        //console.log("filter option", filterOption);
        return filterOption;
      }), property);
    }
    // console.log("sort filter options", path, simple, sortedFilterOptions);

    //console.log("sorted filter options", sortedFilterOptions);
    return sortedFilterOptions;
  }

  let _filter = {};

  useEffect(() => {

    // console.log("selected filter options", "filters", filters, "all filter options", allFilterOptions);
    _filter = {};

    //debug.log(path, "filters", filters, "settings", settings, "parts time", partsTime);
    if (!isEmpty(filters)) {
      //console.log("filters", filters);
      //console.log("all filters", allFilterOptions);

      if (isEmpty(allFilterOptions) || settings.partsTime !== partsTime) {
        //debug.log(`updating filter ${path}`, "filters", filters);
        //debug.log("_f", _f);

        _filter = get(filters, path);
        //debug.log("_filter", _filter);

        if (!_filter) {
          debug.log(`cannot find ${path} in filters`, filters);
          setFilterOptions({});
          setAllFilterOptions({});

        } else {
          setFilterOptions(_filter);
          setAllFilterOptions(_filter);
        }
        //console.log("Filter: filter", _filter);

        if (isEmpty(settings.activePartFilters) || !isEmpty(settings.activePartFilters.user_filters) || isEmpty(get(settings.activePartFilters, path))) {
          resetFilter();

        }
        //console.log(`updated filter ${path}`);
      }
    } else {

    }

    const _selectedFilters = get(selectedFilters, path);

    if (!isEmpty(_selectedFilters)) {
      //console.log("selected filters from backend", _selectedFilters);

      if (Array.isArray(_selectedFilters)) {
        setSelectedFilterOptions(_selectedFilters);
        closeList();
      } else {
        setSearchString(_selectedFilters);
      }
    }
    //console.log("all filter options", allFilterOptions);
  }, [filters, selectedFilters]);

  //debug.log(`checking ${path} all filter options`, allFilterOptions);
  // we want to make sure we don't skip this filter if it's for the ID field which is a special case
  // since ID won't have any filter options
  if ((isEmpty(filters) || isEmpty(allFilterOptions)) && path !== "id") {
    //debug.log(`filters is empty or all filter options is empty for ${path}`);
    return (<React.Fragment></React.Fragment>);
  }

  if (isEmpty(allFilterOptions) && defaultToTextField) {
    return (<React.Fragment>
      <div className={classNames("text-filter", className)}>
        <div className="title">{title}</div>
        <Input placeholder='Search...' value={searchString} onChange={changeTextField}
               icon={{name: "search", circular: true, link: true, onClick: searchTextField}}/>
      </div>
    </React.Fragment>);
  }

  return (<React.Fragment>

    <div className={classNames("list-filter", className)}>
      <div className="title">{title} {!isEmpty(faq) ?

        <Link to={`/faq#${faq}`}><Icon name='question circle outline'
                                       size='small'/></Link> : ""}
        <span
          className={`list-filter__toggle list-filter--${open ? "hide" : "show"}`}>{open ?
          <Button onClick={toggleCloseList} className="link">Hide</Button> :
          <Button onClick={toggleOpenList} className="link">Show</Button>}</span>
      </div>

      {open && !isEmpty(allFilterOptions) ?
        <div className="list-filter--opened">
          {showSearch ?
            <Input placeholder='Search...' value={searchString} onChange={changeSearch}
                   disabled={disabled}
                   icon={{name: "search", circular: true, link: false}}/> : ""}
          <div
            className={classNames("list-filter__table", {
              scrollable: allFilterOptions.length > minFilterOptionsScroll,
              small
            })}>
            <Table>
              <Table.Body>
                {sortFilterOptions().map((value, key) => {
                  // console.log("value", value, "key", key);
                  // console.log("value", value, "new selectedFilterOptions", selectedFilterOptions);

                  //const _index = key;
                  const _nextIndex = key + 1;

                  const id = value.id !== undefined ? value.id.toString() : (simple ? value.toString() : value[property].toString());
                  //console.log("id", id, "selectedFilterOptions", selectedFilterOptions);

                  //let _value = simple ? `${value}${suffix}` : `${value[property]}${suffix}`;

                  let _value = "";
                  let _incrementalValue = 0;
                  let _increment = increment;

                  if (range) {
                    //_value = simple ? `${value}${suffix} - ${parseInt(value) + increment}${suffix}` : `${value[property]}${suffix} - ${parseInt(value[property]) + increment}${suffix}`;
                    debug.log("range: convertible", convertible, "path", path);

                    _value = simple ? parseFloat(value) : parseFloat(value[property]);
                    //debug.log("path", path, _value, "range init value", "convertible", convertible);

                    if (incrementByRange) {
                      _increment = sortFilterOptions().length === _nextIndex ? 0 : sortFilterOptions()[_nextIndex] - _value - .01;
                      debug.log("path", path, _increment, "increment");
                    }
                    // && isNumber(_value)
                    if (convertible) {
                      //console.log("is number ", value, "converted", convertUnits({...convertOptions, value}));

                      // if (incrementByRange) {
                      //   _increment = sortFilterOptions().length == _nextIndex ? 0 : sortFilterOptions()[_nextIndex];
                      //   debug.log("path", path, _increment, "increment");
                      //
                      // }

                      // convert incremental first since _value is still a number
                      _incrementalValue = convertUnits({...convertOptions, value: (_value + _increment)});
                      _value = convertUnits({...convertOptions, value: _value});
                      //debug.log("converted values", _value, _incrementalValue);
                    } else {

                      _incrementalValue = _value + _increment;
                    }

                    if (_increment === 0) {
                      _incrementalValue = "+";
                    }

                    if (!isEmpty(suffix)) {
                      _value = `${_value}${suffix} - ${_incrementalValue}${suffix}`;
                    } else {
                      _value = <React.Fragment>{_value} <span> - </span>{_incrementalValue}</React.Fragment>;
                    }
                  } else {

                    //debug.log("reg: convertible", convertible, "path", path);
                    _value = simple ? value : value[property];
                    //debug.log(_value, "init value", "convertible", convertible);

                    if (convertible && isNumber(_value)) {
                      //console.log("is number ", value, "converted", convertUnits({...convertOptions, value}));
                      _value = convertUnits({...convertOptions, value: _value});
                      //debug.log(_value, "converted value");
                    }

                    if (!isEmpty(suffix)) {
                      _value = `${_value}${suffix}`;
                    }
                  }

                  return (
                    <Table.Row className="option-row" key={key}>
                      <Table.Cell className="option-column">{selectedFilterOptions.includes(id) ? <span
                        className="checkmark">&#10003;</span> : ""}
                        {onRemove ? <Button onClick={onRemove} color="red" circular size='mini' icon="close"
                                            className="remove" data-id={id} data-title={_value}/> : ""}
                        <Button onClick={selectFilterOption}
                          //                        !userTierAccess({minTier})
                                className={classNames("link", {"no-access": disabled})}
                                data-id={id}>{_value}</Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
          {showUncheckAll ? <div className="uncheck-all"><Button onClick={uncheckAll} className="link">Uncheck
            All</Button></div> : ""}
        </div>
        :
        <div className="list-filter--closed">
          <List>
            {/*{console.log("sort selected filter options", sortSelectedFilterOptions())}*/}
            {sortSelectedFilterOptions().map((selectedFilterOption, index) => {
              // console.log("selected filter option", selectedFilterOption);
              const id = selectedFilterOption.id !== undefined ? selectedFilterOption.id.toString() : selectedFilterOption;
              let title = selectedFilterOption[property] !== undefined ? `${selectedFilterOption[property]}${suffix}` : `${selectedFilterOption}${suffix}`;

              if (range) {
                title = selectedFilterOption[property] !== undefined ? `${selectedFilterOption[property]}${suffix} - ${parseInt(selectedFilterOption[property]) + increment}${suffix}` : `${selectedFilterOption}${suffix} - ${parseInt(selectedFilterOption) + increment}${suffix}`;
              }

              return (<List.Item key={index} as='a' data-id={id}
                                 onClick={toggleSelectedFilterOption}><span
                className="checkmark">&#10003;</span>{title}</List.Item>);
            })}
          </List>
        </div>}
    </div>
  </React.Fragment>);
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);

  return {
    partsTime: state.partsData.time,
    parts: state.partsData.part,
    filters: state.partsData.filters,
    selectedFilters: state.partsData.selectedFilters,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, {retrieveParts, updateSettings})(PartListFilter);