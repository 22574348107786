import { debug, hasValue, numberFormat } from "./helpers";

export const recipeModifiers = options => {
  debug.log("recipe modifiers", options);

  let calculated = {};

  const sfpm = calcSurfaceFeetPerMinute(options);
  debug.log("sfpm", sfpm);

  const ipm = calcIPM(options);
  debug.log("ipm", ipm);
  options.recipe.ipm = ipm;

  const mrr = calcMRR(options);
  debug.log("mrr", mrr);

  options.recipe.mrr = mrr;
  const horsepower = calcHorsepower(options);
  debug.log("calc horsepower", horsepower, "orig horsepower", options.recipe.horsepower);

  calculated = {
    rpm: numberFormat({
      decimalPlaces: 0,
      value: options.modifiers.rpm
    }),
    ipm,
    mrr,
    sfpm,
    horsepower
  };
  debug.log("calculated", calculated);

  return calculated;

  // options =
  // options.recipe
  // options.modifiers

  // return an object with each modifier field and its value

  /*
  Calc fields:
  + Surface Speed = calcSurfaceFeetPerMinute()
  (diam * rpm * pi) / 12 as Feet/min

  + Feed (inches) Per Minute =
  feed per tooth * # flutes * RPMs

  + MRR = calcMRR


  + Est HP
  + if HP is set then show the Modified HP
  + if HP is set and < Est HP show error
  + RED color
  + Exceeds machine HP

   */
};

const calcSurfaceFeetPerMinute = options => {
  //debug.log("calcSurfaceFeetPerMinute", options);

  if (!hasValue(options.modifiers.rpm) || !hasValue(options.recipe.cutting_tools)) {
    return;
  }

  let surface_feet_per_minute = (Math.PI * options.modifiers.rpm * options.recipe.cutting_tools[0].cutting_diameter) / 12;
  //debug.log("surface_feet_per_minute", surface_feet_per_minute);
  surface_feet_per_minute = numberFormat({
    //returnAsString: false,
    value: surface_feet_per_minute
  });
  return surface_feet_per_minute;
};

const calcMRR = options => {
  let mrr;

  // switch on cut type
  switch (options.recipe.cut_type_tid) {

    // drilling
    case 31:
      if (!hasValue(options.recipe.inches_per_revolution) || !hasValue(options.modifiers.rpm) || !hasValue(options.recipe.cutting_tools)) {
        return;
      }

      mrr = options.recipe.inches_per_revolution * options.modifiers.rpm * Math.PI * Math.pow((options.recipe.cutting_tools[0].cutting_diameter / 2), 2);
      break;

    default:
      // if end mill, face mill, or high feed
      //  if (in_array($options['recipe']['cutting_tools'][0]['type']['tid'], [54, 55, 61]))
      if (!hasValue(options.recipe.radial_depth_of_cut_value) || !hasValue(options.recipe.axial_depth_of_cut_value) || !hasValue(options.recipe.ipm)) {
        return;
      }

      debug.log("ipm", options.recipe.ipm, "rdcv", options.recipe.radial_depth_of_cut_value, "adcv", options.recipe.axial_depth_of_cut_value);
      mrr = options.recipe.ipm * options.recipe.radial_depth_of_cut_value * options.recipe.axial_depth_of_cut_value;
      break;
  }

  mrr = numberFormat({
    //returnAsString: false,
    decimalPlaces: 4,
    value: mrr
  });
  //debug.log("post mrr", mrr);

  return mrr;
};

const calcIPM = options => {
  let ipm;

  // switch on cut type
  switch (options.recipe.cut_type_tid) {

    // drilling
    case 31:
      if (!hasValue(options.recipe.inches_per_revolution) || !hasValue(options.modifiers.rpm)) {
        return;
      }

      ipm = options.recipe.inches_per_revolution * options.modifiers.rpm;
      break;

    default:
      // if end mill, face mill, or high feed
      //      if (in_array($options['recipe']['cutting_tools'][0]['type']['tid'], [54, 55, 61, 57]))

      if (!hasValue(options.recipe.programmed_ipt) || !hasValue(options.modifiers.rpm) || !hasValue(options.recipe.cutting_tools)) {
        return;
      }

      ipm = options.recipe.programmed_ipt * options.modifiers.rpm * options.recipe.cutting_tools[0].number_of_flutes;
      break;
  }

  ipm = numberFormat({
    //returnAsString: false,
    decimalPlaces: 4,
    value: ipm
  });
  //debug.log("post ipm", ipm);

  return ipm;
};

const calcHorsepower = options => {
  if (!hasValue(options.recipe.mrr) || !hasValue(options.recipe.material.k_factor)) {
    return;
  }
  let horsepower = options.recipe.mrr / options.recipe.material.k_factor;

  horsepower = numberFormat({
    // returnAsString: false,
    value: horsepower
  });

  return horsepower;
};