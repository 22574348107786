import classNames from "classnames/bind";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { connect } from "react-redux";
import { Card, Grid, Image } from "semantic-ui-react";
import { NO_FIELD_VALUE } from "../../common/consts";
import { userTierAccess, debug } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
import PartField from "./PartField";
//import RecipeField from "./RecipeField";

// -------------------------------------------------- PART CARD COMPONENT -------------------------------------------------- //
function PartCard (props) {
  const {part, index} = props;
  debug.log("part", part, "tier", part.tier);

  const handleOpenPartPage = (e) => {
    window.open(`/part/${part.id}`, "_blank");
  };

  const free_user_access_part = userTierAccess({minTier: "0.0.0"}) && !userTierAccess({minTier: "0.9.9"}) && userTierAccess({minTier: part.tier});

  // look at https://react.semantic-ui.com/views/card/#content-header-card
  return (
    <Card
      id={`part--${part.id}`}
      key={part.id}
      className={classNames("part-card", "part", `part-card--${index}`, `part--${part.id}`,
        {
          "part-card--selected": part.selected,
          "part-card--free-access": free_user_access_part
        })}>
      {<Card.Content className="part-card__content">
        <div className="part-card__thumbnail"><Image
          src={part.thumbnail.thumb}
          as='div'
          size='medium'
          onClick={handleOpenPartPage}
        /></div>

        <Grid columns={2} className="part-bar">
          <Grid.Row>
            <Grid.Column width={15} floated='left' className="part-bar__title">{part.title}</Grid.Column>
            <Grid.Column width={1} floated='right' className=""></Grid.Column>
          </Grid.Row>
        </Grid>

        <div className="part-card__bottom" onClick={handleOpenPartPage}>
          {debug.log("part", part)}
          <Grid className="part-card__details" columns={2}>
            <PartField id={part.id}
                       name="part-type"
                       label="Part-Type"
                       value={!isEmpty(part.type) ? `${part.type.name}` : NO_FIELD_VALUE}
                       isText={true}
            />
            <PartField id={part.id}
                       name="machine"
                       label="Machine"
                       value={!isEmpty(part.machine) ? `${part.machine.name}` : NO_FIELD_VALUE}
                       isText={true}
            />
            <PartField id={part.id}
                       name="material"
                       label="Material"
                       value={!isEmpty(part.material) ? `${part.material.name}` : NO_FIELD_VALUE}
                       isText={true}
            />
          </Grid>
        </div>

        <Grid columns={2} className="part-bar-footer">
          <Grid.Row>
            <Grid.Column width={10} floated='left' className="part-bar__id">

            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>}
    </Card>);
}

// -------------------------------------------------- // PART CARD COMPONENT -------------------------------------------------- //

function mapStateToProps (state) {
  //console.log("Parts: Map State to Props", state);
  return {
    parts: state.partsData.parts,
    filters: state.partsData.filters,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, {updateSettings})(PartCard);