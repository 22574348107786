import {
  CANCELED_USER,
  CREATED_USER,
  LOGGEDIN_USER,
  LOGGEDOUT_USER,
  MIGRATED_USER,
  PASSWORD_RESET,
  PASSWORD_RESET_REQUEST,
  REACTIVATED_USER,
  RETRIEVE_USER,
  UPDATED_USER,
  STATUS_USER
} from '../../common/consts';
import { debug, generateUUID, sessionLogout } from '../../common/helpers';
import api from '../api';
import { updateSettings } from './settings';
import store from 'store';

export const createUser = userCriteria => dispatch => {
  if (!store.get('session_id')) {
    const newSessionID = generateUUID();
    store.set('session_id', newSessionID);
    userCriteria.session_id = newSessionID;
  }

  window.store.dispatch(updateSettings({ creatingUser: true }));
  api.user.create(userCriteria).then(createUserResponse => {
      //console.log("Create User: response", createUserResponse);
      debug.log('Create User: response', createUserResponse);
      window.store.dispatch(updateSettings({ creatingUser: false }));
      dispatch(createdUser(createUserResponse));
    }
  );
};

export const createdUser = userCriteria => ({
  type: CREATED_USER,
  criteria: userCriteria
});

export const cancelUser = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ cancelingUser: true }));
  api.user.cancel(userCriteria).then(cancelUserResponse => {
      //console.log("Cancel User: response", cancelUserResponse);
      debug.log('Canceled User: response', cancelUserResponse);
      window.store.dispatch(updateSettings({ cancelingUser: false }));
      dispatch(canceledUser(cancelUserResponse));
    }
  );
};

export const canceledUser = userCriteria => ({
  type: CANCELED_USER,
  criteria: userCriteria
});

export const reactivateUser = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ reactivatingUser: true }));
  api.user.reactivate(userCriteria).then(reactivateUserResponse => {
      //console.log("Reactivate User: response", reactivateUserResponse);
      debug.log('Reactivated User: response', reactivateUserResponse);
      window.store.dispatch(updateSettings({ reactivatingUser: false }));
      dispatch(reactivatedUser(reactivateUserResponse));
    }
  );
};

export const reactivatedUser = userCriteria => ({
  type: REACTIVATED_USER,
  criteria: userCriteria
});

export const migrateUser = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ updatingUserSubscription: true }));
  api.user.migrate(userCriteria).then(migrateUserResponse => {
      debug.log('Migrated User: response', migrateUserResponse);
      window.store.dispatch(updateSettings({ updatingUserSubscription: false }));
      dispatch(migratedUser(migrateUserResponse));
    }
  );
};

export const migratedUser = userCriteria => ({
  type: MIGRATED_USER,
  criteria: userCriteria
});

export const updateUserBilling = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ updatingUser: true, updatingUserBilling: true }));
  api.user.update(userCriteria).then(updateUserResponse => {
      // console.log("Update User: response", updateUserResponse);
      debug.log('Update User: response', updateUserResponse);
      window.store.dispatch(updateSettings({ updatingUser: false, updatingUserBilling: false }));
      dispatch(updatedUser(updateUserResponse));
    }
  );
};

export const updateUserPayment = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ updatingUser: true, updatingUserPayment: true }));
  api.user.update(userCriteria).then(updateUserResponse => {
      // console.log("Update User: response", updateUserResponse);
      debug.log('Update User: response', updateUserResponse);
      window.store.dispatch(updateSettings({ updatingUser: false, updatingUserPayment: false }));
      dispatch(updatedUser(updateUserResponse));
    }
  );
};

export const updateUserSubscription = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ updatingUser: true, updatingUserSubscription: true }));
  api.user.update(userCriteria).then(updateUserResponse => {
      // console.log("Update User: response", updateUserResponse);
      debug.log('Update User: response', updateUserResponse);
      window.store.dispatch(updateSettings({ updatingUser: false, updatingUserSubscription: false }));
      dispatch(updatedUser(updateUserResponse));
    }
  );
};

export const updateUser = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ updatingUser: true }));
  api.user.update(userCriteria).then(updateUserResponse => {
      // console.log("Update User: response", updateUserResponse);
      debug.log('Update User: response', updateUserResponse);
      window.store.dispatch(updateSettings({ updatingUser: false }));
      dispatch(updatedUser(updateUserResponse));
    }
  );
};

export const updatedUser = userCriteria => ({
  type: UPDATED_USER,
  criteria: userCriteria
});

export const resetPasswordRequest = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ resettingPasswordRequest: true }));
  api.user.password_reset_request(userCriteria).then(resetPasswordResponse => {
      //console.log("Reset Password Request: response", resetPasswordResponse);
      debug.log('Reset Password Request: response', resetPasswordResponse);
      window.store.dispatch(updateSettings({ resettingPasswordRequest: false }));
      dispatch(passwordResetRequest(resetPasswordResponse));
    }
  );
};

export const passwordResetRequest = userCriteria => ({
  type: PASSWORD_RESET_REQUEST,
  criteria: userCriteria
});

export const resetPassword = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ resettingPassword: true }));
  api.user.password_reset(userCriteria).then(resetPasswordResponse => {
      //console.log("Reset Password: response", resetPasswordResponse);
      debug.log('Reset Password: response', resetPasswordResponse);
      window.store.dispatch(updateSettings({ resettingPassword: false }));
      dispatch(passwordReset(resetPasswordResponse));
    }
  );
};

export const passwordReset = userCriteria => ({
  type: PASSWORD_RESET,
  criteria: userCriteria
});

export const loginUser = userCriteria => dispatch => {
  const newSessionID = generateUUID();
  store.set('session_id', newSessionID);
  userCriteria.session_id = newSessionID;
  window.store.dispatch(updateSettings({ loggingInUser: true }));
  api.user.login(userCriteria).then(loginUserResponse => {
      // console.log("Log In User: response", loginUserResponse);
      debug.log('Log In User: response', loginUserResponse);
      window.store.dispatch(updateSettings({ loggingInUser: false }));
      dispatch(loggedInUser(loginUserResponse));
    }
  );
};

export const loggedInUser = userCriteria => ({
  type: LOGGEDIN_USER,
  criteria: userCriteria
});

export const logoutUser = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ loggingOutUser: true }));
  api.user.logout(userCriteria).then(logoutUserResponse => {
      //  console.log("Log Out User: response", logoutUserResponse);
      debug.log('Log Out User: response', logoutUserResponse);
      window.store.dispatch(updateSettings({ loggingOutUser: false }));
      dispatch(loggedOutUser(logoutUserResponse));
    }
  );
  // immediately log the user out of the site
  sessionLogout();
};

export const loggedOutUser = userCriteria => ({
  type: LOGGEDOUT_USER,
  criteria: userCriteria
});

export const retrieveUser = () => ({
  type: RETRIEVE_USER
});

export const retrieveUserStatus = userCriteria => dispatch => {
  window.store.dispatch(updateSettings({ retrievingUserStatus: true }));
  api.user.status(userCriteria).then(UserStatusResponse => {
      debug.log('Status User: response', UserStatusResponse);
      window.store.dispatch(updateSettings({ retrievingUserStatus: false }));
      dispatch(retrievedUserStatus(UserStatusResponse));
    }
  );
};

export const retrievedUserStatus = userCriteria => ({
  type: STATUS_USER,
  criteria: userCriteria
});