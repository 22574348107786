import React from "react";
import { connect } from "react-redux";
import { Grid, Radio } from "semantic-ui-react";
import { updateSettings } from "../../redux/actions/settings";
import { updateUser } from "../../redux/actions/user";
import { imperialUnits } from "../../common/helpers";

function RecipeToolbar (props) {
  const {updateUser, position = "top"} = props;

  /*
  const clickCloseTab = () => {
    //console.log("close");
    window.close();
  };
*/

  // When we toggle the Imperial / Metric values we update the settings which will automatically rerender the cards
  const handleUnitChange = (e, {value}) => {
    // updateSettings({imperial: value === "true"});
    updateUser({data: {default_units: value === "true" ? "imperial" : "metric"}});
  };

  return (<Grid className="recipe-toolbar toolbar" columns={2}>
    <Grid.Row>
      {position === "top" ?
        (<React.Fragment>
          {/*<Grid.Column className="toolbar__close"*/}
          {/*width={3} floated='left'><a rel="noopener noreferrer" href="#" onClick={(e) => {*/}
          {/*e.preventDefault();*/}
          {/*clickCloseTab();*/}
          {/*}}>&#9664; Close Tab</a></Grid.Column>*/}


          <Grid.Column className="toolbar__units" width={5} floated='right'><span className="label">UNITS:</span>
            <Radio label="Imperial" name="units" value="true" checked={imperialUnits()}
                   onChange={handleUnitChange}/>
            <Radio label="Metric" name="units" value="false" checked={!imperialUnits()} onChange={handleUnitChange}/>
          </Grid.Column></React.Fragment>) : ""
        // (<><Grid.Column className="toolbar__close"
        //                                        width={3} floated='left'><a rel="noopener noreferrer" href="#"
        //                                                                    onClick={(e) => {
        //                                                                      e.preventDefault();
        //                                                                      clickCloseTab();
        //                                                                    }}>&#9664; Close Tab</a></Grid.Column></>)

      }
    </Grid.Row>
  </Grid>);
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    // totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
  };
}

export default connect(mapStateToProps, {updateSettings, updateUser})(RecipeToolbar);
