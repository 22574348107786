//import Formsy from 'formsy-react-es6';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Elements } from "react-stripe-elements";
import { Grid, Header } from "semantic-ui-react";
import { DEFAULT_ANONYMOUS_ROUTE } from "../../common/consts";
import { isAuthenticated, isCustomer, isLocked, showMessage, userTierAccess } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
import Layout from "../global/Layout";
import BillingInfoForm from "./BillingInfoForm";
import PaymentInfoForm from "./PaymentInfoForm";
import SubscriptionInfoForm from "./SubscriptionInfoForm";
import UserTools from "./UserTools";
import UserRecipeModifiers from "./UserRecipeModifiers";
import UserFilters from "./UserFilters";

function Account (props) {
  const {settings, user, updateSettings} = props;
  //console.log("account", props);

  useEffect(() => {
    //console.log("useEffect: Account", props);

    if (settings.activeMenuItem !== "account") {
      updateSettings({activeMenuItem: "account"});
    }

  }, [settings]);

  return (!isAuthenticated() ? <Redirect to={DEFAULT_ANONYMOUS_ROUTE}/> :
    <Elements>
      <Layout>
        <Grid className="account-content content">
          <Grid.Row className="account-content__heading">
            <Grid.Column className="account-content__header" width={5}><Header as="h1">Account</Header>
            </Grid.Column>
          </Grid.Row>
          {isLocked() ? <Grid.Row>{showMessage({messageType: "account_locked", isError: true})} </Grid.Row> : ""}
          {user.migrated ? <Grid.Row>{showMessage({
            messageHeader: user.migrated_message.en.title,
            messageText: user.migrated_message.en.html,
            isError: false
          })}  </Grid.Row> : ""}
          {/*{showMessage({messageHeader: "Subscription Updated", messageText: user.migrated_message, isError: false})}*/}
          <Grid.Row>
            <Grid className="account-content__form-columns" columns={3} divided>
              <Grid.Row stretched>
                <BillingInfoForm/>
                {isCustomer() ? <PaymentInfoForm/> : <div/>}
                {isCustomer() ? <SubscriptionInfoForm/> : <div/>}
              </Grid.Row>
            </Grid>
          </Grid.Row>

          {isAuthenticated() && userTierAccess({minTier: "1"}) ?
            <Grid.Row id="recipe-modifiers">
              <Grid className="account-content__form-columns" columns={1}>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <UserRecipeModifiers/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
            : ""}

          <Grid.Row>
            <Grid className="account-content__form-columns" columns={1}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <UserTools/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>

          <Grid.Row>
            <Grid className="account-content__form-columns" columns={1}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <UserFilters/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>

          <Grid.Row>
            <Grid className="account-content__form-columns" columns={1}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <a className="account-recipe-request-link" href={`/recipe-request`}
                     rel="noopener noreferrer">Click here to request a recipe</a>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>
        </Grid>
      </Layout></Elements>);
}

function mapStateToProps (state) {
  //console.log("Account: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    //order: state.orderData,
  };
}

export default connect(mapStateToProps, {updateSettings})(Account);