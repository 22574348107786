import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import { updateSettings } from "../../redux/actions/settings";
import { findGroupMembers } from "../../redux/actions/groups";
import GroupTable from "./GroupTable";

function GroupsTables (props) {
  const {user} = props;

  useEffect(() => {
  }, []);

  return (<Grid.Column className="account__info-column">
    {
      user.owned_groups.map(group => {
        //debug.log("group table", group);
        return (<GroupTable key={group.id} _group={group}/>);
      })}
  </Grid.Column>);
}

function mapStateToProps (state) {
  //console.log("User Tools: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    groups: state.groupsData,
    //userTools: state.userToolsData,
  };
}

export default connect(mapStateToProps, {
  updateSettings,
  findGroupMembers,
})(withRouter(GroupsTables));