//import isEmpty from "lodash/isEmpty";
import classnames from "classnames";
import React from "react";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Grid } from "semantic-ui-react";
import { convertUnits, imperialUnits, isNumber, userTierAccess, debug } from "../../common/helpers";
import isEmpty from "lodash/isEmpty";

function PartField (props) {
  const {name, label, labelItalics, value, valueItalics, id, roundNumber, precision = 3, isPercent, isPrice, isURL, url, urlOptions = {}, isDate, dateOptions = {format: "MM/DD/YYYY"}, convertible, convertOptions = {}, labelWidth = 7, valueWidth = 9, minTier = "0.0.0", isPro = false, part, checkProWithTier = false, isText = false} = props;
  //console.log("Props", props);

  let _value = value;
  const noAccessValue = <span className="no-access"></span>;
  let restrictField = false;
  //const noAccessValue = <span className="no-access">Members Only. <a href="/join">Join</a></span>;

  //console.log("convertible", convertible, "value", value);
  if (convertible && isNumber(value)) {
    debug.log(name, "IS CONVERTIBLE", value);

    //console.log("is number ", value, "converted", convertUnits({...convertOptions, value}));
    _value = convertUnits({...convertOptions, value});
    //console.log("convert units ", label, _value);

    // _value = <NumberFormat
    //   value={convertUnits({...convertOptions, value})}
    //   displayType={"text"}
    //   thousandSeparator={true}
    //   renderText={value => <div>{value}</div>}/>;
    //

  } else if (isNumber(value) && !isText) {
    debug.log(name, "IS NUMBER", value);
    _value = <NumberFormat
      value={roundNumber ? Math.round(value) : value}
      displayType={"text"}
      decimalScale={precision}
      thousandSeparator={true}
      renderText={value => <div>{value + (isPercent ? "%" : "")}</div>}/>;
  }

  if (isDate) {
    _value = <Moment {...dateOptions}>{value}</Moment>;
  }

  if (isURL) {
    _value = <a href={url} {...urlOptions} rel="noopener noreferrer">{value}</a>;
  }

  if (isPrice) {
    // https://www.npmjs.com/package/react-number-format
    _value = <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
      decimalScale={2}
      fixedDecimalScale={true}
      renderText={value => <div>{value}</div>}/>;
  }

  if (checkProWithTier) {
    //console.log("check pro", _value, isPro);
    //debug.log("CHECK PRO WITH TIER", name, userTierAccess({minTier: "0"}), `isPro: ${isPro}`, !userTierAccess({minTier}));
    // if the user tier is 0 and this is a Pro part OR the user tier isn't the minTier
    //if ((userTierAccess({minTier: "1"}) && isPro) && !userTierAccess({minTier})) {
    //  restrictField = true;
    // }

    // if this is not a pro part, we don't care (yet)
    if (isPro) {
      if (!userTierAccess({minTier})) {
        restrictField = true;
      }
    }
  }

  // if the user can't access the minTier
  if (!userTierAccess({minTier})) {

    //debug.log("NO MAX TIER", name);

    // ...and if the user can't access tier 1
    if (!userTierAccess({minTier: "1"})) {
      restrictField = true;
    }

    //debug.log(name, minTier, _value, RESTRICTED_ACCESS_TOKEN, `isPro: ${isPro}`);
    //console.log("check tier", userTierAccess({minTier}));
    // if the user is logged in we should have the no access token in each of the limited fields
    // or we can tell by the user's tier and the field's tier.
    // either way, if they don't have access to this field, show them a message
    // if (!userTierAccess({minTier}) || includes(_value, RESTRICTED_ACCESS_TOKEN)) {
    //   restrictField = true;
    // }
  }

  // 2/26/20 - this may be redundant, but now we're using the part's tier to do the final check
  // if the user's tier is < the part tier, the field is restricted
  if (part) {
    if (!isEmpty(part.tier)) {
      if (userTierAccess({minTier: part.tier})) {
        restrictField = false;
      }
    }
    // debug.log("restrict field", name, restrictField, "tier", part.tier, value);
  }

  if (restrictField) {
    _value = noAccessValue;
  }

  return (
    <Grid.Row className={`part__${name}`}>
      <Grid.Column width={labelWidth}
                   className={classnames("label", {
                     "no-access": restrictField,
                     "italic": labelItalics
                   })}>{label}</Grid.Column>
      {/*<Grid.Column width={valueWidth}*/}
      {/*className="value" data-tip=""*/}
      {/*data-for={`part__${id}-${name}`}>{convertible && isNumber(value) ? convertUnits({...convertOptions, value}) : value}</Grid.Column>*/}
      <Grid.Column width={valueWidth}
                   className={classnames("value", {
                     "italic": valueItalics
                   })} data-tip=""
                   data-for={`part__${id}-${name}`}>{_value}</Grid.Column>
      {/*{console.log("VALUE " + name, value)}*/}
      {convertible && !restrictField ?
        <ReactTooltip id={`part__${id}-${name}`} getContent={() => {
          // https://www.npmjs.com/package/react-tooltip
          return convertible && isNumber(value) ? convertUnits({
            imperial: !imperialUnits(),
            value,
            ...convertOptions
          }) : "";
        }}/>
        : ""}
    </Grid.Row>
  );
}

function mapStateToProps (state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //parts: state.partsData.parts,
    //filters: state.partsData.filters,
    //totalPages: state.partsData.totalPages,
    //totalParts: state.partsData.totalParts,
    settings: state.settings,
    user: state.userData,
  };
}

export default connect(mapStateToProps, {})(PartField);