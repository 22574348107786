import { CREATED_USER_TOOL, DELETED_USER_TOOL, FOUND_USER_TOOLS, TOGGLE_USER_TOOL } from "../../common/consts";
import { debug } from "../../common/helpers";
import api from "../api";
import { updateSettings } from "./settings";

export const findUserTools = userToolsCriteria => dispatch => {
  window.store.dispatch(updateSettings({fetchingUserTools: true}));
  api.user.user_tools(userToolsCriteria).then(userToolsResponse => {
      //console.log("Finding User Tools: response", userToolsResponse);
      debug.log("Finding User Tools: response",userToolsResponse);
      window.store.dispatch(updateSettings({fetchingUserTools: false}));
      dispatch(foundUserTools(userToolsResponse));
    }
  );
};

export const foundUserTools = userToolsCriteria => ({
  type: FOUND_USER_TOOLS,
  criteria: userToolsCriteria
});

export const createUserTool = userToolCriteria => dispatch => {
  window.store.dispatch(updateSettings({creatingUserTool: true}));
  api.user.create_user_tool(userToolCriteria).then(userToolResponse => {
      // console.log("Create User Tool: response", userToolResponse);
      debug.log("Create User Tool: response",userToolResponse);
      window.store.dispatch(updateSettings({creatingUserTool: false}));
      dispatch(createdUserTool(userToolResponse));
    }
  );
};

export const createdUserTool = userToolResponse => ({
  type: CREATED_USER_TOOL,
  criteria: userToolResponse
});

export const deleteUserTool = userToolCriteria => dispatch => {
  window.store.dispatch(updateSettings({deletingUserTool: true}));
  api.user.delete_user_tool(userToolCriteria).then(userToolResponse => {
      // console.log("Delete User Tool: response", userToolResponse);
      debug.log("Delete User Tool: response",userToolResponse);
      window.store.dispatch(updateSettings({deletingUserTool: false}));
      dispatch(deletedUserTool(userToolResponse));
    }
  );
};

export const deletedUserTool = userToolResponse => ({
  type: DELETED_USER_TOOL,
  criteria: userToolResponse
});

export const toggleUserTool = toggle => ({
  type: TOGGLE_USER_TOOL,
  toggle
});