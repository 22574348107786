import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Dimmer, Grid, Header, Loader, Segment } from 'semantic-ui-react';
import store from 'store';
import {
  debug,
  isAuthenticated,
  isNumber,
  previewCardIndex,
  recipeCardIndex,
  userTierAccess,
  isMobileDevice
} from '../../common/helpers';
import { retrieveRecipes } from '../../redux/actions/recipes';
import { updateSettings } from '../../redux/actions/settings';
import Layout from '../global/Layout';
import UserSurveyCTA from '../global/UserSurveyCTA';
import RecipeFilters from './RecipeFilters';
import RecipeCard from './RecipeCard';
import RecipeCardPreview from './RecipeCardPreview';
import RecipesPagination from './RecipesPagination';
import RecipesToolbar from './RecipesToolbar';
import RecipeRoadblock from './RecipeRoadblock';
import { scroller } from 'react-scroll';

function Recipes(props) {
  const { retrieveRecipes, updateSettings, recipes, totalRecipes, filters, settings } = props;
  //console.log("Recipes: Props", props);

  const [init, setInit] = useState(false);
  const [recipesForCards, setRecipesForCards] = useState(recipes);
  const [previousRecipes, setPreviousRecipes] = useState(recipes);
  const [autoShowRecipePreviewCard, setAutoShowRecipePreviewCard] = useState(false);

  const history = useHistory();

  const parsedURLParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  // console.error('load component parsed url params', parsedURLParams, 'location search', props.location.search);

  //const contextRef = createRef();

  //const parsedURLHash = queryString.parse(props.location.hash);
  //console.log("parsed url", parsedURLHash, parsedURLParams);

  useEffect(() => {
    //console.log("useEffect: Recipes", props, settings.ready);
    //updateSettings({fetchingRecipes: true});
    // ********* HERE WE'RE LOADING THE DEFAULT RECIPES ********* //
    //debug.info('settings ready', settings.ready, 'init', init);
    if (settings.ready && !init) {

      if (parsedURLParams.tool_id && isNumber(parsedURLParams.tool_id)) {
        //console.log("filtering by tool id", parsedURLParams.tool_id);
        updateSettings({
          activeRecipeFilters: set(settings.activeRecipeFilters, 'tools.ids', [parsedURLParams.tool_id])
        });
      }

      // console.error('checking parsed url params', parsedURLParams);
      // https://www.npmjs.com/package/qs
      if (!isEmpty(parsedURLParams.filters)) {
        debug.log('parsed url params', parsedURLParams);
        updateSettings({
          activeRecipeFilters: parsedURLParams.filters
        });
      }

      // check for lite user
      if (userTierAccess({ minTier: '1.0.0', maxTier: '1.9.9' })) {
        // lite user: only show recipes flagged as lite
        updateSettings({
          activeRecipeFilters: set(settings.activeRecipeFilters, 'tier', '1.0.0')
        });
      } else if (userTierAccess({ minTier: '2.0.0' })) {
        // pro user: do nothing (show all recipes)
      } else {
        // free user: only show recipes flagged as free
        updateSettings({
          activeRecipeFilters: set(settings.activeRecipeFilters, 'tier', '0.00')
        });
      }

      // console.error('active filters', settings.activeRecipeFilters);
      retrieveRecipes();

      setInit(!init);

      if (settings.activeMenuItem !== 'recipes') {
        updateSettings({ activeMenuItem: 'recipes' });

        onResize();
        onScroll();
        document.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onResize);
        window.onload = function () {
          // if (!filtersElement) {
          //   return;
          // }

          //console.log("offset", filtersContainerElementOffsetY);

        };

      }
    }

    // We only want to update the the URL filters when the page is finally ready and has already been initialized
    // This is so we don't conflict with an initial URL that already has filter params
    if (settings.ready && init) {
      if (!settings.fetchingRecipes) {
        updateURLFilters();
      }
    }

    if (!isEmpty(recipes)) {
      //console.log("updating recipes for cards");
      //console.log("new recipes", recipes, "previous recipes", previousRecipes);
      //setRecipesForCards(recipes);

      // we use previousRecipes to track an exact copy of recipes therefore when recipes changes
      // we can compare it to previousRecipes to see if we have a similar set
      // if not, update previousRecipes to match recipes and update recipesForCards
      if (recipes !== previousRecipes) {
        // console.log("update", {recipes, previousRecipes, recipesForCards});
        setPreviousRecipes(recipes);
        setRecipesForCards(recipes);

        if (settings.previewRecipeID > 0) {
          setAutoShowRecipePreviewCard(true);
          //debug.log("recipes",recipes);
          //debug.log("recipePreviewID",settings.previewRecipeID);
          const recipe = find(recipes, { id: settings.previewRecipeID });
          //console.log("auto showing preview for recipe", recipe);
          // console.log("recipes for cards", recipesForCards);

          if (!isEmpty(recipe)) {
            showRecipePreviewCard({ recipes, recipe });
          }
        } else {

        }
      }
    } else {
      //console.log("no recipes found");
      if (totalRecipes === 0) {
        // console.log("total = 0");
      }
    }

  }, [recipes, settings]);

  // close filters when recipes are updated
  useEffect(() => {
    if (settings.ready && init) {
      //if (!settings.fetchingRecipes) {
      updateSettings({
        openFilters: false
      });
      //}
    }
  }, [recipes]);

  function updateURLFilters() {
    // https://www.npmjs.com/package/qs
    let querystring = '';
    if (!isEmpty(settings.activeRecipeFilters)) {
      querystring = qs.stringify({ filters: settings.activeRecipeFilters }, { encode: false });
    }
    debug.log('RECIPE FILTERS: active filters', settings.activeRecipeFilters, 'query string', querystring);
    history.push({ search: querystring });
  }

  function resetRecipePreviewCard(options) {
    // iterate through all recipes and modify values
    //let rfc = recipesForCards.map((recipe, index) => {
    let rfc = options.recipes.map((recipe, index) => {
      recipe.selected = false;
      return recipe;
    });

    // reset cards by filtering out the one used for the preview if it exists
    const filteredRFC = rfc.filter((recipe, index) => !recipe.preview);

    //debug.log({"recipes for cards": options.recipes, "rfc": rfc, "filteredRFC": filteredRFC}, "resetRecipePreviewCard");
    return filteredRFC;
  }

  function hideRecipePreviewCard() {

    // reassign recipesForCards as recipes
    setRecipesForCards(resetRecipePreviewCard({ recipes: recipesForCards }));
    updateSettings({ previewRecipeID: 0 });
  }

  function showRecipePreviewCard(options) {
    if (isEmpty(options.recipes)) {
      options.recipes = recipesForCards;
    }
    //console.log(options);
    const recipeCardID = `recipe--${options.recipe.id}`;
    const recipeCardClassName = `.${recipeCardID}`;

    //const pcIndex = previewCardIndex({card: options.card, index: options.index});
    //const pcIndex = previewCardIndex({index: options.index, className: `.recipe--${options.recipe.id}`});
    const pcIndex = previewCardIndex({ className: recipeCardClassName });
    //console.log("pcIndex", pcIndex);
    let r = resetRecipePreviewCard({ recipes: options.recipes });

    let rcIndex = recipeCardIndex({ className: recipeCardClassName });
    //debug.log("rcindex", rcIndex);

    //r[options.index].selected = true;

    // if (rcIndex > -1) {
    //
    // } else {
    //   rcIndex = r.length - 1;
    // }

    r[rcIndex].selected = true;

    r.splice(pcIndex, 0, { ...options.recipe, preview: true });
    //debug.log("recipes",r);
    //updateSettings({previewRecipeID: options.recipe.id});
    setRecipesForCards(r);

    // scroller.scrollTo(recipeCardID, {
    //   duration: 1500,
    //   delay: 200,
    //   smooth: true,
    //   //containerId: "ContainerElementID",
    //   offset: 0, // Scrolls to element down the page
    // });
  }

  // -------------------------------------------------- RECIPE CARDS COMPONENT -------------------------------------------------- //
  const RecipeCards = React.memo(() => {
    //const {} = props;
    //const globalState = window.store.getState();
    //console.log("global state", globalState);
    if (!isEmpty(recipesForCards)) {
      debug.log('Rendering Recipe Cards', [recipesForCards]);
    }
    return (
      <Card.Group
        className="recipes-container-cards"
        itemsPerRow={3}>
        {
          (totalRecipes === 0) ? <Header
              as="h1"
              className="recipes-container__no-recipes">NO RECIPES</Header> :
            // && recipesForCards.length > 100
            ((!isEmpty(recipesForCards)) ? recipesForCards.map((recipe, index) => {
              //console.log("mapping through recipe", recipe, recipe.preview);
              //console.log(recipesForCards);
              if (recipe.preview) {

                // console.log("PREVIEW", recipe);
                // since we don't want to count the preview card as an indexable card,
                // we deincrement the index so the rest of the cards will remain in consecutive order
                index -= 1;

                const scrollerElementID = isMobileDevice() ? 'recipe-card-preview' : `recipe--${recipe.id}`;
                scroller.scrollTo(scrollerElementID, {
                  duration: 1500,
                  delay: 500,
                  smooth: true,
                  offset: isMobileDevice() ? -170 : -350, // Scrolls to element down the page
                });

                return (
                  <RecipeCardPreview
                    key={`${recipe.id}-preview`}
                    recipe={recipe}
                    autoShowRecipePreviewCard={autoShowRecipePreviewCard}
                    recipesForCards={recipesForCards}
                    setRecipesForCards={setRecipesForCards}
                    hideRecipePreviewCard={hideRecipePreviewCard}></RecipeCardPreview>
                );
              } else {

                if (recipe.id === settings.previewRecipeID && !recipe.selected) {}

                return (<RecipeCard
                  key={recipe.id}
                  index={index}
                  recipe={recipe}
                  recipesForCards={recipesForCards}
                  setRecipesForCards={setRecipesForCards}
                  showRecipePreviewCard={showRecipePreviewCard}
                  hideRecipePreviewCard={hideRecipePreviewCard}/>);
              }
            }) : <Segment
              className="recipes-container-cards__area"><Dimmer
              active
              inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer></Segment>)
        }
      </Card.Group>);
  });

  function onScroll(e) {
    const filtersContainerElement = document.querySelector('.filters-container');
    const filtersElement = document.querySelector('.filters-container .filters');
    // const filtersListElement = document.querySelector(".filters-container .filters .filters-list");

    if (!filtersContainerElement) {
      return;
    }

    const filtersContainerElementOffsetY = filtersContainerElement.offsetTop;

    //console.log("filters", filtersElementOffsetY, "current filters y", filtersElement.offsetTop, "filter containers", filtersContainerElementOffsetY, "window", window.scrollY);

    // 7/24/19 - commented out this code because based on method used by McMasters, the main body (recipes grid) is now scrollable
    // so we don't need to fix the filters. You can scroll the recipes separate from the filters.

    // 9/12/19 - uncommented this method so we can adjust the position as the user scrolls and remove the scrollbar
    // on the recipes grid
    //console.log(window.innerHeight);

    if (window.scrollY >= filtersContainerElementOffsetY) {
      //console.log("filter container is at top");
      filtersElement.style.position = 'fixed';
      filtersElement.style.top = `10px`;

    } else {
      //console.log("filter container is NOT at top");
      filtersElement.style.position = 'relative';
      filtersElement.style.top = 0;
    }
  }

  function onResize(e) {
    // don't bother doing this if the user isn't able to view the page
    if (isAuthenticated()) {
      // const filtersContainerElement = document.querySelector(".filters-container");
      // const filtersElement = document.querySelector(".filters-container .filters");
      const filtersListElement = document.querySelector('.filters-container .filters .filters-list');
      // const filtersContainerElementOffsetY = filtersContainerElement.offsetTop;

      let windowHeight = parseInt(window.innerHeight) - 100;
      debug.log('WINDOW HEIGHT', windowHeight);

      if (filtersListElement) {
        filtersListElement.style.maxHeight = `${windowHeight}px`;
      }
    }
  }

  // -------------------------------------------------- // RECIPE CARDS COMPONENT -------------------------------------------------- //
  return (
    <Layout>
      <RecipeRoadblock/>
      {/*<Responsive minWidth={768}>*/}
      <Grid className="recipes-content content">
        <Grid.Column
          className={`filters-container ${settings.openFilters ? 'open' : 'close'}`}
          width={3}>
          <RecipeFilters
            filters={filters}
            location={props.location}/>
        </Grid.Column>
        <Grid.Column
          className={`recipes-container ${settings.openFilters ? 'close' : 'open'}`}

          width={13}>
          {!isEmpty(settings) ? <RecipesToolbar/> : ''}
          <RecipeCards/>
          {/*{console.log("userInteractionEnableUserSurveyCTA", settings.userInteractionEnableUserSurveyCTA, "offered_user_survey", store.get("offered_user_survey"))}*/}
          {userTierAccess({ minTier: '1' }) && settings.userInteractionEnableUserSurveyCTA && !store.get('visited_user_survey') && !isMobileDevice() ?
            <UserSurveyCTA/> : ''}
          <RecipesPagination/>
        </Grid.Column>
      </Grid>
      {/*</Responsive>*/}
      {/*<Responsive maxWidth={767}>*/}
      {/*  <Message*/}
      {/*    info*/}
      {/*    className="optimized-message">*/}
      {/*    <Message.Header>WE'RE WORKING ON MOBILE</Message.Header>*/}
      {/*    <p>For now, ProvenCut is optimized for desktop.</p>*/}
      {/*  </Message>*/}
      {/*</Responsive>*/}
    </Layout>
  );
}

function mapStateToProps(state) {
  //console.log("Recipes: Map State to Props", state);
  return {
    totalRecipes: state.recipesData.totalRecipes,
    recipes: state.recipesData.recipes,
    filters: state.recipesData.filters,
    settings: state.settings,
    //settings: getSettings(state),
  };
}

// const settingsSelector = state => state.settings;
// const getSettings = createSelector(settingsSelector, settings => settings);

export default connect(mapStateToProps, { retrieveRecipes, updateSettings })(Recipes);