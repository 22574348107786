import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { scroller } from "react-scroll";
import { Grid, Header } from "semantic-ui-react";
//import { retrieveFAQs } from "../../redux/actions/faqs";
import { updateSettings } from "../../redux/actions/settings";
import Layout from "../global/Layout";

function FAQ (props) {
  const {settings, updateSettings} = props;

  useEffect(() => {
    //console.log("useEffect: FAQ", props);

    if (settings.activeMenuItem !== "faq") {
      //retrieveFAQs();
      updateSettings({activeMenuItem: "faq"});
    }
  }, [settings]);

  if (isEmpty(get(settings, "config.faqs"))) {
    return (<Layout/>);
  }

  const clickQuestionHandler = (faqID) => {
    //console.log("faq id", faqID);
    //e.preventDefault();

    scroller.scrollTo(faqID, {
      duration: 1500,
      delay: 100,
      smooth: true,
      //containerId: "ContainerElementID",
      offset: -5, // Scrolls to element + 50 pixels down the page
    });
  };

  return (<Layout>
    <Grid className="faq-content content">
      <Grid.Row className="faq-content__title" columns={2}>
        <Grid.Column><Header as="h1">Frequently Asked Questions</Header></Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid.Row>

      {Object.values(settings.config.faqs).map((obj, index) => {
        //console.log("obj", obj, "index", index);

        let faqsQuestions = [];

        if (!isEmpty(obj.headline)) {
          const faqHeadine = <Grid.Row key={index} className="faq-content__questions-headline">
            <Grid.Column><Header as="h3" className="faq-content__headline">{obj.headline}</Header></Grid.Column>
          </Grid.Row>;

          faqsQuestions.push(faqHeadine);
        }

        if (!isEmpty(obj.faqs)) {
          faqsQuestions.push(obj.faqs.map((faq, faqIndex) => {
            //console.log("faq", faq);

            return (<Grid.Row className="faq-content__questions" key={faqIndex}>
              <Grid.Column className="faq-content__question"
              ><a href={`#faq-${faq.id}`}
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    clickQuestionHandler(`faq-${faq.id}`);
                  }}>{faq.question}
                <span
                  className="blue">&#187;</span></a></Grid.Column>
            </Grid.Row>);

          }));
        }

        return faqsQuestions;
      })}

      {Object.values(settings.config.faqs).map((obj, index) => {
        //console.log("obj", obj, "index", index);

        let faqsGrid = [];

        if (!isEmpty(obj.headline)) {
          const faqHeadine = <Grid.Row key={index} className="faq-content__grid-headline">
            <Grid.Column><Header as="h3" className="faq-content__headline">{obj.headline}</Header></Grid.Column>
          </Grid.Row>;

          faqsGrid.push(faqHeadine);
        }

        if (!isEmpty(obj.faqs)) {
          faqsGrid.push(obj.faqs.map((faq, faqIndex) => {
            //console.log("faq", faq);

            return (<Grid.Row id={`faq-${faq.id}`} className="faq-content__faqs" columns={2} divided key={faqIndex}>
              <Grid.Column id={!isEmpty(faq.html_id) ? faq.html_id : ""} className="faq-content__faq"
                           width={5}>{faq.question}</Grid.Column>
              <Grid.Column className="faq-content__faq" width={11}>{ReactHtmlParser(faq.answer)}</Grid.Column>
            </Grid.Row>);
          }));
        }

        return faqsGrid;
      })}
    </Grid>
  </Layout>);
}

function mapStateToProps (state) {
  //console.log("FAQs: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, {updateSettings})(FAQ);