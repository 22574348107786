// import { DEFAULT_AUTHENTICATED_ROUTE } from "../../common/consts";
// import { isAuthenticated } from "../../common/helpers";
import { updateSettings } from "../../redux/actions/settings";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Elements } from "react-stripe-elements";
import { Grid, Header } from "semantic-ui-react";
import Layout from "../global/Layout";
import isEmpty from "lodash/isEmpty";

function Embedded (props) {
  const {settings, activeMenuItem, path = "", title = ""} = props;
  const articleSlug = props.match.params.articleSlug;
  const pageNumber = props.match.params.pageNumber;
  //console.log("page number", pageNumber);

  const [contentHeight, setContentHeight] = useState(1000);

  //console.log("article slug", articleSlug);
  //const parsedURLParams = queryString.parse(props.location.search);
  //console.log("params", parsedURLParams);

  const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  const eventer = window[eventMethod];
  const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

  function messageEventCallback (e) {
    var key = e.message ? "message" : "data";
    var data = e[key];

    //console.log("received message", data);
    if (!isEmpty(data) && data.contentHeight > 0 && data.contentHeight !== contentHeight) {
      window[eventMethod === "attachEvent" ? "detachEvent" : "removeEventListener"](messageEvent, messageEventCallback);
      //console.log("setting content height", parseInt(data.contentHeight), parseInt(data.contentHeight) + 100);
      const paddedContentHeight = parseInt(data.contentHeight) + 100;
      setContentHeight(paddedContentHeight);
    }
  }

  eventer(messageEvent, messageEventCallback, false);

  useEffect(() => {
    //console.log("useEffect: SignUp", props);

    if (settings.activeMenuItem !== activeMenuItem) {
      updateSettings({activeMenuItem});
    }

    // function messageEventCallback (e) {
    //   var key = e.message ? "message" : "data";
    //   var data = e[key];
    //
    //   console.log("received message", data);
    //   if (!isEmpty(data) && data.contentHeight > 0 && data.contentHeight !== contentHeight) {
    //     console.log("setting content height", parseInt(data.contentHeight), parseInt(data.contentHeight) + 100);
    //     const paddedContentHeight = parseInt(data.contentHeight) + 100;
    //     setContentHeight(paddedContentHeight);
    //   }
    // }
    //
    // eventer(messageEvent, messageEventCallback, false);
    //console.log("event method:", eventMethod, "message event:", messageEvent);

    return () => {
      // without this code we would get a warning:
      // "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function."
      //console.log("unloading message event listener");
      // window[eventMethod === "attachEvent" ? "detachEvent" : "removeEventListener"](messageEvent, messageEventCallback);
    };
  }, [settings]);

  //let formURL = `https://docs.google.com/forms/d/e/1FAIpQLScIDRypPqLLL9PHJ-II-2tR2BXKrgW4z-_ZmZI1oLZY6bHk_Q/viewform?embedded=true&usp=pp_url${!isEmpty(user.email) ? `&emailAddress=${user.email}&entry.2135090890=${user.fullname}` : ""}`;
  function loadedIFrame (e) {
    console.log("loaded iframe", e);
  }

  const baseURL = `https://posts.provencut.com/`;
  let embedURL = baseURL;
  if (pageNumber) {
    embedURL += `category/${path}/page/${pageNumber}`;
  }

  if (articleSlug) {
    embedURL += articleSlug;
  }

  if (embedURL === baseURL) {
    embedURL += `category/${path}`;
  }

  //console.log("embed url", embedURL);

  return (
    <Elements>
      <Layout>
        <Grid className="embedded-content content">
          {!isEmpty(title) ?
            <Grid.Row className="embedded-content__heading">
              <Grid.Column className="embedded-content__header" width={5}><Header as="h1">${title}</Header>
              </Grid.Column>
            </Grid.Row> : ""}

          <Grid.Row>
            <Grid className="embedded-content__columns" columns={1}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className="iframe-container">
                    <iframe
                      id="embedded-iframe"
                      title="Embedded"
                      src={embedURL}
                      height={`${contentHeight}px`}
                      width="100%"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      scrolling="no"
                      onLoad={loadedIFrame}>Loading...
                    </iframe>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>


        </Grid>
      </Layout></Elements>);
}

function mapStateToProps (state) {
  //console.log("SignUp: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    //order: state.orderData,
  };
}

export default connect(mapStateToProps, {})(withRouter(Embedded));
