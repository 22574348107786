import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Grid, Button, Icon, Loader, Popup, Radio } from 'semantic-ui-react';
import { retrieveRecipes } from '../../redux/actions/recipes';
import { updateSettings } from '../../redux/actions/settings';
import { updateUser } from '../../redux/actions/user';
import { imperialUnits } from '../../common/helpers';

function RecipesToolbar(props) {
  const { settings, user, totalRecipes, updateUser, updateSettings, retrieveRecipes } = props;
  //console.log("Toolbar props", props);
  //console.log("ToolBar", settings);

  //console.log("imperial units from helper", imperialUnits());

  //const [orderState, setOrderState] = React.useState({sort: settings.defaultSort, order: "asc"});

  // When we toggle the Imperial / Metric values we update the settings which will automatically rerender the cards
  const handleUnitChange = (e, { value }) => {
    //updateSettings({imperial: value === "true"});
    updateUser({ data: { default_units: value === 'true' ? 'imperial' : 'metric' } });
  };

  const handleSortChange = (e, { value }) => {
    // console.log("Sort change", value);// , "order", orderState.order);

    updateSettings({
      activeRecipeSort: value
    });

    retrieveRecipes();
  };

  const handleToggleFilters = (e, { value }) => {
    updateSettings({
      openFilters: !settings.openFilters
    });
  };

  const handleThumbnailChange = (e, { value }) => {
    updateSettings({
      activeRecipeThumbnail: value
    });

    //console.log("thumbnail", value);
  };

  const handleSortDirectionChange = (e, { value }) => {
    // console.log("Direction change", value);

    updateSettings({
      activeRecipeSortDirection: value
    });

    retrieveRecipes();
  };

  // reposition sticky toolbar based on container position on page since the top property will differ depending on if we have a banner
  const recipesContainerElement = document.getElementsByClassName('recipes-container');
  let recipesContainerElementTop = 0;
  if (recipesContainerElement.length) {
    recipesContainerElementTop = Math.round(recipesContainerElement[0].getBoundingClientRect().top + window.scrollY);
  }
  //debug.log("to top", recipesContainerElementTop);

  const defaultPopupText = 'Click to copy URL';
  const [sharePopUpText, setSharePopUpText] = useState(defaultPopupText);

  const handleShareRecipesURL = (e) => {
    // const defaultPopupText = "Click to copy URL";
    // const [sharePopUpText, setSharePopUpText] = useState(defaultPopupText);

    navigator.clipboard.writeText(window.location.href).then(
      () => setSharePopUpText('Copied To Clipboard'),
      //() => setCopyStatus('failed'),
    );
  };

  const handleSharePupUpClose = (e) => {
    setSharePopUpText(defaultPopupText);
  };

  return (<Grid
    className="recipes-container-toolbar toolbar"
    columns="equal"
    style={{ top: `${recipesContainerElementTop}px` }}>
    <Grid.Row>
      <Grid.Column
        className="toolbar__results"
        width={2}>{totalRecipes} RESULTS

        <Popup
          trigger={<Icon
            link
            name="share alternate"
            size="small"
            onClick={handleShareRecipesURL}
            className="filters__share-icon"/>}
          onClose={handleSharePupUpClose}
          content={sharePopUpText}
          inverted
          position="bottom center"
        />

        <Loader
          active={settings.fetchingRecipes}
          inline
          size="mini"/></Grid.Column>
      <Grid.Column
        className="toolbar__toggle-filters">
        <Button onClick={handleToggleFilters}>FILTERS & SORT</Button>
      </Grid.Column>

      <Grid.Column
        className="toolbar__sort toolbar__dropdown-wrapper"
        width={6}>
        <span className="label">SORT:{''}</span> <Dropdown
        inline
        options={settings.sortOptions}
        value={settings.activeRecipeSort}
        onChange={handleSortChange}/>
        <Radio
          label=""
          name="order"
          value="asc"
          className="sort"
          checked={settings.activeRecipeSortDirection === 'asc'}
          onChange={handleSortDirectionChange}/><Icon name="sort amount down"/>
        <Radio
          label=""
          name="order"
          value="desc"
          className="sort"
          checked={settings.activeRecipeSortDirection === 'desc'}
          onChange={handleSortDirectionChange}/><Icon name="sort amount up"/>
      </Grid.Column>

      <Grid.Column
        className="toolbar__thumbnail toolbar__dropdown-wrapper"
        width={4}>
        <span className="label">THUMBNAIL:{''}</span> <Dropdown
        inline
        options={settings.thumbnailOptions}
        value={settings.activeRecipeThumbnail}
        onChange={handleThumbnailChange}/>
      </Grid.Column>

      <Grid.Column
        className="toolbar__units"
        width={4}>
        {/*<span className="label">UNITS:</span>*/}
        <Radio
          label="Imperial"
          name="units"
          value="true"
          checked={imperialUnits(user)}
          onChange={handleUnitChange}/>
        <Radio
          label="Metric"
          name="units"
          value="false"
          checked={!imperialUnits(user)}
          onChange={handleUnitChange}/>
      </Grid.Column>
    </Grid.Row>
  </Grid>);
}

function mapStateToProps(state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    totalPages: state.recipesData.totalPages,
    totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
  };
}

export default connect(mapStateToProps, { updateSettings, updateUser, retrieveRecipes })(RecipesToolbar);
