//import isEmpty from "lodash/isEmpty";
import set from 'lodash/set';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Grid, Header, Icon, Input, Modal, Message, Transition } from 'semantic-ui-react';
import { hasValue, isAuthenticated, userTierAccess } from '../../common/helpers';
import { USER_FILTERS_TITLE_LENGTH } from '../../common/consts';
import { retrieveRecipes } from '../../redux/actions/recipes';
import { updateSettings } from '../../redux/actions/settings';
import { createUserFilters } from '../../redux/actions/userFilters';
import RecipeListFilter from './RecipeListFilter';
import { HashLink as Link } from 'react-router-hash-link';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// import isEqual from 'lodash/isEqual';

function RecipeFilters(props) {
  const { user, settings, filters, updateSettings, retrieveRecipes, createUserFilters, userTools } = props;

  // const parsedURLParams = queryString.parse(props.location.search);
  // //const parsedURLHash = queryString.parse(props.location.hash);
  // //console.log("parsed url", parsedURLHash, parsedURLParams);
  //
  // console.log("settings", settings);
  // if (parsedURLParams.tool_id && isNumber(parsedURLParams.tool_id) && !settings.fetchingRecipes && !isEmpty(settings.config)) {
  //   console.log("filtering by tool id", parsedURLParams.tool_id);
  //   // updateSettings({
  //   //   activeFilters: set(settings.activeFilters, "tools.ids", [parsedURLParams.tool_id])
  //   // });
  //
  //   //retrieveRecipes();
  // }

  /*
    const initialFiltersState = {
      "machines.brands": [],
      "machines.models": [],
      "materials.classes": [],
      "materials.types": [],
      "tools.cutting_diameters": [],
      "tools.flutes": [],
      "tools.types": [],
      "tools.brands": [],
      "tools.models": [],
      "tools.materials": [],
      "tools.stickouts": [],
    };
    */
  // const [filtersState, setFiltersState] = React.useState(initialFiltersState);
  //console.log("filtersState", filtersState);

  const [saveFiltersModalOpen, setSaveFiltersModalOpen] = React.useState(false);
  // const [removeFiltersModalOpen, setRemoveFiltersModalOpen] = React.useState(false);
  // const [removeFiltersOption, setRemoveFiltersOption] = React.useState({id: null, title: ""});

  const [showSaveFiltersButton, setShowSaveFiltersButton] = React.useState(false);
  const [saveFiltersTitle, setSaveFiltersTitle] = React.useState('');

  const resetFilters = () => {
    //setFiltersState(old => (initialFiltersState));

    updateSettings({
      activeRecipeFilters: {},
      previewRecipeID: 0,
    });

    retrieveRecipes();
  };

  useEffect(() => {
    //console.log("useEffect: Filters");

    if (!settings.fetchingRecipes) {
      //updateURLFilters();
    }

  }, [settings]);

  function toggleUserRecipes(e, data) {
    // console.log("toggle user recipes", e, data);

    // console.log("uid", user.uid);

    if (data.checked) {
      // console.log("Showing Only User Recipes");
      if (hasValue(user.uid)) {
        updateSettings({
          activeRecipeFilters: set(settings.activeRecipeFilters, 'user.recipes.uid', user.uid)
        });
      }
    } else {
      // console.log("Showing All Recipes");
      //console.log("active filter uid", settings.activeFilters.user.recipes.uid);
      if (hasValue(settings.activeRecipeFilters.user) && hasValue(settings.activeRecipeFilters.user.recipes)) {
        let activeFilters = settings.activeRecipeFilters;
        delete settings.activeRecipeFilters.user.recipes;
        //console.log("updated active filters", activeFilters);
        updateSettings({
          activeRecipeFilters: activeFilters
        });
      }
    }

    retrieveRecipes();
  }

  function toggleUserTools(e, data) {
    //  console.log("toggle user tools", e, data);

    // console.log("uid", user.uid);

    if (data.checked) {
      //  console.log("Showing Only User Tools");
      if (hasValue(user.uid)) {
        updateSettings({
          activeRecipeFilters: set(settings.activeRecipeFilters, 'user.tools.uid', user.uid)
        });
      }
    } else {
      //  console.log("Showing All Tools");
      //console.log("active filter uid", settings.activeFilters.user.recipes.uid);
      if (hasValue(settings.activeRecipeFilters.user.tools)) {
        let activeFilters = settings.activeRecipeFilters;
        delete settings.activeRecipeFilters.user.tools;
        //console.log("updated active filters", activeFilters);
        updateSettings({
          activeRecipeFilters: activeFilters
        });
      }
    }

    retrieveRecipes();
  }

  function togglePreviewPro(e, data) {
    //  console.log("toggle user tools", e, data);

    // console.log("uid", user.uid);

    if (data.checked) {
      //  console.log("Showing Only User Tools");
      if (hasValue(user.uid)) {
        updateSettings({
          activeRecipeFilters: set(settings.activeRecipeFilters, 'tier', '2.0.0')
        });
      }
    } else {
      //  console.log("Showing All Tools");
      //console.log("active filter uid", settings.activeFilters.user.recipes.uid);
      if (hasValue(settings.activeRecipeFilters.tier)) {
        //let activeFilters = settings.activeRecipeFilters;
        delete settings.activeRecipeFilters.tier;
        //console.log("updated active filters", activeFilters);
        // updateSettings({
        //   activeRecipeFilters: activeFilters
        // });

        updateSettings({
          activeRecipeFilters: set(settings.activeRecipeFilters, 'tier', '1.0.0')
        });
      }
    }

    retrieveRecipes();
  }

  function togglePreviewFree(e, data) {
    //  console.log("toggle user tools", e, data);

    // console.log("uid", user.uid);

    if (data.checked) {
      //  console.log("Showing Only User Tools");
      if (hasValue(user.uid)) {
        updateSettings({
          activeRecipeFilters: set(settings.activeRecipeFilters, 'tier', '0.0.0')
        });
      }
    } else {
      //  console.log("Showing All Tools");
      //console.log("active filter uid", settings.activeFilters.user.recipes.uid);
      if (hasValue(settings.activeRecipeFilters.tier)) {
        let activeFilters = settings.activeRecipeFilters;
        delete settings.activeRecipeFilters.tier;
        //console.log("updated active filters", activeFilters);
        updateSettings({
          activeRecipeFilters: activeFilters
        });
      }
    }

    retrieveRecipes();
  }

  function closeFilters() {
    updateSettings({
      openFilters: false
    });
  }

  function changeSaveFiltersTitle(e) {
    //console.log(e.currentTarget.value);

    if (e.currentTarget.value.length > 5 && e.currentTarget.value.length < USER_FILTERS_TITLE_LENGTH) {
      setSaveFiltersTitle(e.currentTarget.value);
      setShowSaveFiltersButton(true);
    } else {
      setSaveFiltersTitle('');
      setShowSaveFiltersButton(false);
    }
  }

  function saveMyFilters(e) {
    e.preventDefault();

    //console.log("save filters", saveFiltersTitle);
    hideSaveFiltersModal();
    createUserFilters({
      data: {
        title: saveFiltersTitle,
        filters: settings.activeRecipeFilters,
      }
    });
  }

  function showSaveFiltersModal() {
    setSaveFiltersModalOpen(true);
  }

  function hideSaveFiltersModal() {
    setSaveFiltersModalOpen(false);
  }

  return (
    <div className="filters">
      <div className="filters-top">
        <Grid
          className="filters__close"
          columns={2}>
          <Grid.Row>
            <Grid.Column
              width={10}
              className="heading">
            </Grid.Column>
            <Grid.Column
              width={6}
              align="right">
              <Button
                onClick={closeFilters}
                className="link"
                icon="close"></Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid
          className="filters__heading"
          columns={2}>
          <Grid.Row className="filters__user-recipes">
            <Grid.Column
              width={10}
              className="heading">FILTERS
            </Grid.Column>
            <Grid.Column
              width={6}
              align="right">
              {/*<a href="#" onClick={resetFilters}>Clear All</a>*/}
              <Button
                onClick={resetFilters}
                className="link">Clear All</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/*<Button negative onClick={resetFilters} className="filters__reset-button">Reset</Button>*/}

        {isAuthenticated() ?
          <Grid
            className="filters__user"
            columns={2}>
            <Grid.Row className="filters__user-recipes">
              <Grid.Column
                width={10}
                className="label">
                My Bookmarks <Link to={`/faq#my-bookmarks`}><Icon
                name="question circle outline"
                size="small"/></Link>
              </Grid.Column>
              <Grid.Column width={6}>
                <Checkbox
                  toggle
                  onChange={toggleUserRecipes}/>
              </Grid.Column>
            </Grid.Row>
            {/*{debug.log("user tools totals", userTools.total_user_tools, userTools.total_user_machines)}*/}
            {userTools.total_user_tools > 0 || userTools.total_user_machines > 0 ?
              <Grid.Row className="filters__user-tools">
                <Grid.Column
                  width={10}
                  className="label">
                  My Tools <Link to={`/faq#my-tools`}><Icon
                  name="question circle outline"
                  size="small"/></Link>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Checkbox
                    toggle
                    onChange={toggleUserTools}/>
                </Grid.Column>
              </Grid.Row> : ''}

            {userTierAccess({ minTier: '1.0.0', maxTier: '1.9.9' }) ?
              <Grid.Row className="filters__pro-recipes">
                <Grid.Column
                  width={10}
                  className="label">
                  Preview Pro
                </Grid.Column>
                <Grid.Column width={6}>
                  <Checkbox
                    toggle
                    onChange={togglePreviewPro}/>
                </Grid.Column>
              </Grid.Row> : ''}

            {userTierAccess({ minTier: '0.0.0' }) && !userTierAccess({ minTier: '0.9.9' }) ?

              <React.Fragment>
                <Transition
                  animation="tada"
                  duration={1500}
                  // visible={settings.activeSortDirection === "asc"}
                  visible={settings.hideRoadblock}
                >
                  <Grid.Row className="filters__free-recipes-cta">
                    <Grid.Column
                      width={16}
                      className="label">
                      <Message>
                        <Message.Header>Toggle to preview full ProvenCut database.</Message.Header>
                      </Message></Grid.Column>
                  </Grid.Row>
                </Transition>

                <Grid.Row className="filters__free-recipes">
                  <Grid.Column
                    width={10}
                    className="label">
                    Test Drive Preview
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Checkbox
                      toggle
                      onChange={togglePreviewFree}
                      defaultChecked/>
                  </Grid.Column>
                </Grid.Row></React.Fragment> : ''}
          </Grid>
          : ''}

        {/*<div className="filter-by">*/}
        {/*<div className="heading">FILTER BY</div>*/}
        {/*</div>*/}
      </div>
      <div className="filters-list">
        {!isEmpty(filters) && !isEmpty(filters.user_filters) && isAuthenticated() ?
          <React.Fragment><RecipeListFilter
            path="user_filters"
            property="title"
            className="user-filters"
            title="My Filters"
            minTier="1"
            minFilterOptionsScroll={5}
            small={true}
            initOpen={false}
            multiSelect={false}
            overrideActiveFilters={true}
            // onRemove={confirmRemoveMyFilters}
          /></React.Fragment> : ''}

        {/*{debug.log("user filters save selected button", settings.activeFilters)}*/}
        {/*isEmpty(get(settings.activeFilters, "user_filters")}*/}
        {!isEmpty(settings.activeRecipeFilters) && settings.activeRecipeFilters.user_filters !== null && isEmpty(get(settings.activeRecipeFilters, 'user_filters')) && userTierAccess({ minTier: '1' }) ?
          <React.Fragment><Modal
            trigger={<Button
              className="link save-filters-link"
              onClick={showSaveFiltersModal}>Save Selected Filters</Button>}
            open={saveFiltersModalOpen}
            onClose={hideSaveFiltersModal}
            basic
            size="small"
            className="save-filters-modal"
          >
            <Header
              icon="filter"
              content="Save Filters"/>
            <Modal.Content>
              <h3>Please enter a title for this group of filters.</h3>
              <Input
                type="text"
                placeholder="Title"
                maxLength={USER_FILTERS_TITLE_LENGTH}
                minLength="5"
                autoFocus
                onChange={changeSaveFiltersTitle}/>
            </Modal.Content>
            <Modal.Actions>
              {showSaveFiltersButton ? <Button
                color="green"
                onClick={saveMyFilters}
                inverted>
                <Icon name="checkmark"/> Save Filters
              </Button> : ''}
            </Modal.Actions>
          </Modal></React.Fragment> : ''}

        <RecipeListFilter
          path="id"
          property="id"
          className="recipe-id"
          title="Recipe ID"
          defaultToTextField/>

        <RecipeListFilter
          path="materials.classes"
          property="name"
          className="material"
          title="Material"
          sortOptions
          showSearch={false}
          minFilterOptionsScroll={20}/>

        <RecipeListFilter
          path="materials.types"
          property="title"
          className="material-types"
          title="Material Type"
          showSearch={false}
          initOpen={false}/>

        <RecipeListFilter
          path="tools.cutting_diameters"
          className="tool-cutting-diameter"
          title="Cutting Diameter"
          simple
          sortOptions
          convertOptions={{ type: 'inches', precision: 3 }}
          convertible
          initOpen={false}/>

        <RecipeListFilter
          path="tools.types"
          property="name"
          className="tool-types"
          title="Tool Type"
          showSearch={false}/>

        <RecipeListFilter
          path="cuts.types"
          property="name"
          className="cut-types"
          title="CAM Operation"
          showSearch={false}
          initOpen={false}/>

        <RecipeListFilter
          path="machines.types"
          property="title"
          className="machine-types"
          title="Machine Type"
          showSearch={false}
          initOpen={false}/>

        <RecipeListFilter
          path="machines.brands"
          property="title"
          className="machine-brands"
          title="Machine Brand"
          showSearch={false}
          initOpen={false}/>

        <RecipeListFilter
          path="machines.models"
          property="part_number"
          className="machine-models"
          title="Machine Model"
          initOpen={false}/>

        <RecipeListFilter
          path="machines.spindle_tapers"
          property="title"
          className="machine-spindle-tapers"
          title="Spindle Taper"
          showSearch={false}
          initOpen={false}/>

        {/*OLD<Filter path="tools.brands" property="title" className="tool-brands" title="Tool Brands"/>*/}

        <RecipeListFilter
          path="tools.brands"
          property="title"
          className="tool-brands"
          title="Tool Brand"
          initOpen={false}/>


        <RecipeListFilter
          path="tools.relieved_shank"
          property="name"
          className="tool-relieved-shank"
          title="Relieved Shank"
          showSearch={false}
          initOpen={false}
          multiSelect={false}/>

        {/*OLD<ListFilter path="tools.stickouts" className="tool-stickouts" title="Tool Stickout" simple suffix='"' minTier="2"/>*/}

        <RecipeListFilter
          path="tools.stickout_mins"
          className="tool-stickouts"
          title="Tool Stickout"
          simple
          range
          minTier="1"
          minFilterOptionsScroll={20}
          faq="stickout"
          convertOptions={{ type: 'inches', precision: 3 }}
          convertible
          initOpen={false}/>

        {/*OLD<ListFilter path="tools.gage_lengths" className="tool-gage-lengths" title="Gage Length" simple suffix='"' minTier="2"/>*/}

        <RecipeListFilter
          path="tools.gage_length_mins"
          className="tool-gage-lengths"
          title="Gauge Length"
          simple
          range
          minTier="1"
          minFilterOptionsScroll={20}
          faq="gauge-length"
          convertOptions={{ type: 'inches', precision: 3 }}
          convertible
          initOpen={false}/>

        <RecipeListFilter
          path="tools.materials"
          property="name"
          className="tool-materials"
          title="Tool Material"
          showSearch={false}
          initOpen={false}/>

        <RecipeListFilter
          path="tools.flutes"
          className="tool-flutes"
          title="# of Flutes"
          simple
          showSearch={false}
          initOpen={false}/>

        <RecipeListFilter
          path="holders.brands"
          property="title"
          className="holder-brands"
          title="Holder Brand"
          initOpen={false}/>

        <RecipeListFilter
          path="holders.styles"
          property="name"
          className="holder-styles"
          title="Holder Style"
          showSearch={false}
          initOpen={false}/>

        <RecipeListFilter
          path="coolants.styles"
          property="name"
          className="coolant-styles"
          title="Coolant Style"
          showSearch={false}
          minTier="1"
          initOpen={false}/>

        <RecipeListFilter
          path="machines.weight_ids"
          property="name"
          className="machines-weight"
          title="Machine Weight"
          minTier="1"
          minFilterOptionsScroll={20}
          presorted
          initOpen={false}/>

        <RecipeListFilter
          path="machines.horsepower_ids"
          property="name"
          className="machines-horsepower"
          title="Machine HP"
          minTier="1"
          minFilterOptionsScroll={20}
          presorted
          initOpen={false}/>

        <RecipeListFilter
          path="tools.models"
          property="part_number"
          className="tool-models"
          title="Tool Part Number"
          defaultToTextField
          initOpen={false}/>

      </div>
    </div>
  );
}

function mapStateToProps(state) {
  //console.log("Recipes: Map State to Props", state);
  return {
    recipes: state.recipesData.recipes,
    filters: state.recipesData.filters,
    settings: state.settings,
    user: state.userData,
    userTools: state.userToolsData,
  };
}

export default connect(mapStateToProps, { retrieveRecipes, updateSettings, createUserFilters })(RecipeFilters);