import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { retrieveParts } from '../../redux/actions/parts';
import { updateSettings } from '../../redux/actions/settings';
import Layout from '../global/Layout';
import { Card, Dimmer, Divider, Grid, Header, Loader, Segment } from 'semantic-ui-react';
import PartFilters from './PartFilters';
import isEmpty from 'lodash/isEmpty';
//import PartsToolbar from "./PartsToolbar";
import { debug, isAuthenticated, userTierAccess, hasValue } from '../../common/helpers';
import set from 'lodash/set';
//import find from "lodash/find";
//import { scroller } from "react-scroll";
import PartTypeFilter from './PartTypeFilter';
import PartCard from './PartCard';
import get from 'lodash/get';

function Parts(props) {
  const { retrieveParts, updateSettings, parts, totalParts, filters, selectedFilters, settings } = props;

  const [init, setInit] = useState(false);
  const [partsForCards, setPartsForCards] = useState(parts);
  const [previousParts, setPreviousParts] = useState(parts);
  //const [showPartTypeFilter, setShowPartTypeFilter] = useState(true);

  useEffect(() => {
    //console.log("useEffect: Recipes", props, settings.ready);
    //updateSettings({fetchingRecipes: true});
    // ********* HERE WE'RE LOADING THE DEFAULT RECIPES ********* //
    if (settings.ready && !init) {

      // check for lite user
      if (userTierAccess({ minTier: '1.0.0', maxTier: '1.9.9' })) {
        // lite user: only show recipes flagged as lite
        updateSettings({
          activePartFilters: set(settings.activePartFilters, 'tier', '1.0.0')
        });
      } else if (userTierAccess({ minTier: '2.0.0' })) {
        // pro user: do nothing (show all recipes)
      } else {
        // free user: only show recipes flagged as free
        updateSettings({
          activePartFilters: set(settings.activePartFilters, 'tier', '0.00')
        });
      }

      retrieveParts();

      setInit(!init);

      if (settings.activeMenuItem !== 'parts') {
        updateSettings({ activeMenuItem: 'parts' });

        onResize();
        onScroll();
        document.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onResize);
        window.onload = function () {
          // if (!filtersElement) {
          //   return;
          // }

          //console.log("offset", filtersContainerElementOffsetY);

        };

      }
    }

    if (!isEmpty(parts)) {
      //console.log("updating parts for cards");
      //console.log("new parts", parts, "previous parts", previousParts);
      //setPartsForCards(parts);

      // we use previousParts to track an exact copy of parts therefore when recipes changes
      // we can compare it to previousParts to see if we have a similar set
      // if not, update previousParts to match recipes and update partsForCards
      if (parts !== previousParts) {
        // console.log("update", {recipes, previousParts, partsForCards});
        setPreviousParts(parts);
        setPartsForCards(parts);
      }
    } else {
      //console.log("no recipes found");
      if (totalParts === 0) {
        // console.log("total = 0");
      }
    }
  }, [parts, settings]);

  // -------------------------------------------------- PART CARDS COMPONENT -------------------------------------------------- //

  const PartCards = React.memo(() => {
    //const {} = props;
    //const globalState = window.store.getState();
    //console.log("global state", globalState);
    if (!isEmpty(partsForCards)) {
      debug.log('Rendering Part Cards', [partsForCards]);
    }
    return (
      <React.Fragment>
        <Divider/>

        <Header
          as="h1"
          className="part-container-cards__select-part">SELECT A PART</Header>

        <Card.Group
          className="parts-container-cards"
          itemsPerRow={3}>
          {
            (totalParts === 0) ? <Header
                as="h1"
                className="parts-container__no-parts">NO PARTS</Header> :
              ((!isEmpty(partsForCards)) ? partsForCards.map((part, index) => {
                //console.log("mapping through recipe", recipe, recipe.preview);
                //console.log(recipesForCards);

                return (<PartCard
                  key={part.id}
                  index={index}
                  part={part}
                  partsForCards={partsForCards}
                  setPartsForCards={setPartsForCards}/>);

              }) : <Segment
                className="parts-container-cards__area"><Dimmer
                active
                inverted>
                <Loader size="large">Loading</Loader>
              </Dimmer></Segment>)
          }
        </Card.Group></React.Fragment>);
  });

  function onScroll(e) {
    const filtersContainerElement = document.querySelector('.filters-container');
    const filtersElement = document.querySelector('.filters-container .filters');

    if (!filtersContainerElement || !filtersElement) {
      return;
    }

    const filtersContainerElementOffsetY = filtersContainerElement.offsetTop;

    //console.log("filters", filtersElementOffsetY, "current filters y", filtersElement.offsetTop, "filter containers", filtersContainerElementOffsetY, "window", window.scrollY);

    // 7/24/19 - commented out this code because based on method used by McMasters, the main body (parts grid) is now scrollable
    // so we don't need to fix the filters. You can scroll the parts separate from the filters.

    // 9/12/19 - uncommented this method so we can adjust the position as the user scrolls and remove the scrollbar
    // on the recipes grid
    //console.log(window.innerHeight);

    if (window.scrollY >= filtersContainerElementOffsetY) {
      //console.log("filter container is at top");
      filtersElement.style.position = 'fixed';
      filtersElement.style.top = `10px`;

    } else {
      //console.log("filter container is NOT at top");
      filtersElement.style.position = 'relative';
      filtersElement.style.top = 0;
    }
  }

  function onResize(e) {
    // don't bother doing this if the user isn't able to view the page
    if (isAuthenticated()) {
      // const filtersContainerElement = document.querySelector(".filters-container");
      // const filtersElement = document.querySelector(".filters-container .filters");
      const filtersListElement = document.querySelector('.filters-container .filters .filters-list');
      // const filtersContainerElementOffsetY = filtersContainerElement.offsetTop;

      let windowHeight = parseInt(window.innerHeight) - 100;
      //debug.log("window height", windowHeight);

      if (filtersListElement && windowHeight) {
        filtersListElement.style.maxHeight = `${windowHeight}px`;
      }
    }
  }

  // -------------------------------------------------- // PARTS CARDS COMPONENT -------------------------------------------------- //
  return (
    <Layout>
      {/*<Responsive minWidth={768}>*/}
      <Grid className="parts-content content">
        <Grid.Column
          className="filters-container"
          width={3}>
          {filters && <PartFilters
            filters={filters}
            location={props.location}/>}
        </Grid.Column>
        <Grid.Column
          className="parts-container"
          mobile={16}
          tablet={11}
          computer={13}>
          {/*{!isEmpty(settings) && <PartsToolbar/>}*/}

          {filters && hasValue(filters.part_types.ids) && <PartTypeFilter/>}

          {partsForCards && !isEmpty(get(selectedFilters, 'part_types.ids')) && <PartCards/>}
        </Grid.Column>
      </Grid>
      {/*</Responsive>*/}
    </Layout>
  );

  /*

  show toolbar
  show filters
  show grid of parts using generic part images

  parts/search endpoint returns a filters object which includes Part Types, Materials, Machines, and Parts (which defaults to empty until Part Type is selected)
  each part is formatted from the part type taxonomy and includes a generic part image and part name

  as selections are made, the filters will be rebuilt

  after the part type filter is selected, a new filter is added called Parts. This is an array of all of the Part records filtered by Part Type, Machine, and Material

  if the Parts filter has items, hide the


   */

}

function mapStateToProps(state) {
  //console.log("Parts: Map State to Props", state);
  return {
    totalParts: state.partsData.totalParts,
    parts: state.partsData.parts,
    filters: state.partsData.filters,
    selectedFilters: state.partsData.selectedFilters,
    settings: state.settings,
    //settings: getSettings(state),
  };
}

export default connect(mapStateToProps, { retrieveParts, updateSettings })(Parts);