import {
  CREATED_USER_RECIPE_MODIFIERS,
  DELETED_USER_RECIPE_MODIFIERS,
  FOUND_USER_RECIPE_MODIFIERS,
  RETRIEVED_RECIPES,
  UPDATED_USER_RECIPE_MODIFIERS,
} from "../../common/consts";
import { checkInvalidSessionID, debug } from "../../common/helpers";

const userRecipeModifiersData = (state = {}, action = {}) => {
  //debug.log("User Recipe Modifiers Reducers", state, action);

  if (checkInvalidSessionID({action})) {
    return {
      ...state
    };
  }

  switch (action.type) {
    case FOUND_USER_RECIPE_MODIFIERS:
      debug.log("reducer: user recipe modifiers - found", action);

      return {
        ...state,
        user_recipe_modifiers: action.criteria.response.user_recipe_modifiers
      };

    case CREATED_USER_RECIPE_MODIFIERS:
      //console.log("reducer: user tool - created", action);
      debug.log("reducer: user recipe modifiers - created", action);
      return {
        ...state,
        user_recipe_modifiers: action.criteria.response.user_recipe_modifiers
      };

    case UPDATED_USER_RECIPE_MODIFIERS:
      //console.log("reducer: user tool - created", action);
      debug.log("reducer: user recipe modifiers - updated", action);
      return {
        ...state,
        user_recipe_modifiers: action.criteria.response.user_recipe_modifiers
      };

    case DELETED_USER_RECIPE_MODIFIERS:
      //console.log("reducer: user tool - deleted", action);
      debug.log("reducer: user recipe modifiers - deleted", action);
      return {
        ...state,
        user_recipe_modifiers: action.criteria.response.user_recipe_modifiers
      };

    case RETRIEVED_RECIPES:

      return {
        ...state,
        // total_user_tools: action.criteria.response.total_user_tools,
        // total_user_machines: action.criteria.response.total_user_machines,
      };

    default:
      //console.log("default reducer: stored user", user, state);
      return {
        ...state,
        // is_user_tool: (state.is_user_tool === null) ? null : state.is_user_tool,
      };

  }
};

export default userRecipeModifiersData;