import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dimmer, Grid, Loader, Table } from 'semantic-ui-react';
import { updateSettings } from '../../redux/actions/settings';
import { deleteUserTool, findUserTools } from '../../redux/actions/userTools';
import set from 'lodash/set';

function UserTools(props) {
  const { findUserTools, deleteUserTool, settings, user, userTools } = props;

  const [initUserTools, setInitUserTools] = useState(true);
  const [myTools, setMyTools] = useState([]);
  //const [myMachines, setMyMachines] = useState([]);

  useEffect(() => {
    //debug.log("useEffect: User Tools", props);

    // if (settings.activeMenuItem !== "account") {
    //   updateSettings({activeMenuItem: "account"});
    // }

    //if (isAuthenticated() && isEmpty(userTools.user_tools) && !settings.fetchingUserTools) {

    if (initUserTools) {
      setInitUserTools(false);
      findUserTools({ uid: user.uid });
      //console.log('user is authed', user)
    }

    if (!isEmpty(userTools.user_tools)) {
      buildUserToolsList();
    }

  }, [settings, userTools]);

  const buildUserToolsList = () => {
//[{key: null, value: null, text: "Select State"}, ...
    setMyTools([]);

    if (!isEmpty(userTools.user_tools.tools)) {
      setMyTools(userTools.user_tools.tools.map(user_tool => ({
        key: user_tool.tool.id,
        value: user_tool.id,
        label: user_tool.tool.aka,
      })));
    }
    if (!isEmpty(userTools.user_tools.machines)) {
      /*
      setMyMachines(userTools.user_tools.machines.map(machine => ({
        key: machine.id,
        value: machine.id,
        label: `${machine.brand.title} ${machine.part_number}`,
      })));
      */
    }
  };

  const viewRecipes = (tool_id) => {
    //console.log("view recipes for", tool_id);
    //props.history.push(`/recipes?tool_id=${tool_id}`);
    // props.history.push({
    //   pathname: '/recipes',
    //   search: `filters[tools][ids][0]=${tool_id}`
    // });

    updateSettings({
      activeRecipeFilters: set(settings.activeRecipeFilters, 'tools.ids', [tool_id])
    });

    props.history.push('/recipes');
  };

  const removeUserTool = (tool_id) => {
    deleteUserTool({ id: tool_id });
  };

  // can add && !isEmpty(myMachines) if we're ever using machines
  return (<Grid.Column className="account__info-column">
    <div className="account__info-column-title">MY TOOLS</div>
    <Dimmer
      active={settings.fetchingUserTools}
      inverted>
      <Loader size="large">Loading</Loader>
    </Dimmer>
    <div className="my-tools__table">
      <Table
        celled
        compact
        striped>
        <Table.Header fullWidth><Table.Row>
          <Table.HeaderCell/>
          <Table.HeaderCell>Tool</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>{!isEmpty(myTools) ? myTools.map((tool, index) => {
          //debug.log([tool]);
          return (<Table.Row key={tool.key}>
            <Table.Cell collapsing>{index + 1}</Table.Cell>
            <Table.Cell>{tool.label}</Table.Cell>
            <Table.Cell
              collapsing
              className="my-tools__buttons"><Button onClick={() => viewRecipes(tool.key)}>View
              Recipes</Button> <Button
              negative
              onClick={() => removeUserTool(tool.value)}>Remove
              Tool</Button></Table.Cell>
          </Table.Row>);
        }) : <Table.Row>
          <Table.Cell></Table.Cell>
          <Table.Cell>There are no tools associated with your account.</Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>}</Table.Body>
      </Table>
    </div>
  </Grid.Column>);
}

function mapStateToProps(state) {
  //console.log("User Tools: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData,
    userTools: state.userToolsData,
  };
}

export default connect(mapStateToProps, { updateSettings, findUserTools, deleteUserTool })(withRouter(UserTools));