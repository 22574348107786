import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dropdown, Grid, Header, Icon, Image, Menu } from 'semantic-ui-react';
import {
  isAuthenticated,
  showMessage,
  showSessionMessages,
  userTierAccess,
  isStagingOrDev
} from '../../common/helpers';
import { updateSettings } from '../../redux/actions/settings';
import CallToAction from './CallToAction';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import ReactHtmlParser from 'react-html-parser';

//import { updateSettings } from "../../redux/actions/settings";

function PageHeader(props) {
  //console.log("HEADER", props);

  const { settings, user, updateSettings, reversed = false, hideSessionMessages = false } = props;
  //const parsedURLParams = queryString.parse(props.location.search);
  //console.log("parsed URL params", parsedURLParams);
  const parsedURLHash = queryString.parse(props.location.hash);
  //console.log("parsed URL hash", parsedURLHash);

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    //console.log("useEffect: PageHeader", props);

  }, [settings]);

  const handleMenuItemClick = (e, { name }) => updateSettings({ activeMenuItem: name });

  const joinProvenCut = () => {
    props.history.push('/join');
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <header>
      {!isAuthenticated() || !userTierAccess({ minTier: '1' }) ? <CallToAction position="top"/> : ''}

      <Menu
        stackable
        text
        className="pc-menu--top">
        <Menu.Menu position="left">
          <Menu.Item
            className="pc-menu__item"
            name="home"
            onClick={handleMenuItemClick}
            href="/?stay=true">
            <Image
              className="pc-menu__logo"
              src={`https://d3827p95tv2r1a.cloudfront.net/cdn/logos/provencut_logo_${reversed ? 'blue_white_' : ''}280x57.png`}
              alt="Header Logo"/>
          </Menu.Item>

          <Menu.Item
            icon="bars"
            className={`hamburger ${openMenu ? 'open' : 'close'}`}
            // active={activeItem === 'logout'}
            onClick={toggleMenu}
          />
        </Menu.Menu>

        <Menu.Menu
          position="right"
          className={openMenu ? 'open' : 'close'}>
          {/*<Menu.Item className="pc-menu__item" name="recipes"*/}
          {/*active={settings.activeMenuItem === "recipes"}*/}
          {/*href="/recipes">RECIPES</Menu.Item>*/}
          {isAuthenticated() ?
            <Dropdown
              className="pc-dropdown-menu"
              item
              text="RECIPES">
              <Dropdown.Menu>
                <Dropdown.Item
                  className="pc-dropdown-menu__item"
                  text="FIND RECIPES"
                  href="/recipes"
                  active={settings.activeMenuItem === 'recipes'}/>
                {isAuthenticated() && userTierAccess({ minTier: '1' }) ? <Dropdown.Item
                  className="pc-dropdown-menu__item"
                  text="REQUEST A RECIPE"
                  href="/recipe-request"
                  active={settings.activeMenuItem === 'recipe-request'}/> : ''}
                {isAuthenticated() && userTierAccess({ minTier: '1' }) ? <Dropdown.Item
                  className="pc-dropdown-menu__item"
                  text="SUBMIT A RECIPE"
                  href="/submit-recipe"
                  active={settings.activeMenuItem === 'submit-recipe'}/> : ''}
              </Dropdown.Menu>
            </Dropdown>
            : ''}

          {isAuthenticated() && userTierAccess({ minTier: '1' }) ? <Menu.Item
            className="pc-menu__item"
            name="calculator"
            active={settings.activeMenuItem === 'calculator'}
            href="/calculator">CALCULATOR</Menu.Item> : ''}

          {isStagingOrDev() && isAuthenticated() && userTierAccess({ minTier: '1' }) ? <Menu.Item
            className="pc-menu__item"
            name="faq"
            active={settings.activeMenuItem === 'parts'}
            href="/parts">PARTS</Menu.Item> : ''}

          <Menu.Item
            className="pc-menu__item"
            name="faq"
            active={settings.activeMenuItem === 'faq'}
            href="/faq">FAQ</Menu.Item>

          <Menu.Item
            className="pc-menu__item"
            name="news"
            active={settings.activeMenuItem === 'news'}
            href="/news">NEWS</Menu.Item>

          {/*{isAuthenticated() && userTierAccess({minTier: "1"}) && isEmpty(user.owned_groups) ?*/}
          {/*{isAuthenticated() && userTierAccess({ minTier: '1' }) ?*/}
          {/*  <Menu.Item*/}
          {/*    className="pc-menu__item"*/}
          {/*    name="account"*/}
          {/*    active={settings.activeMenuItem === 'account'}*/}
          {/*    href="/account">ACCOUNT</Menu.Item> : ''}*/}

          {/*{isAuthenticated() && userTierAccess({minTier: "1"}) && !isEmpty(user.owned_groups) ?*/}
          {isAuthenticated() && userTierAccess({ minTier: '1' }) ?
            <Dropdown
              className="pc-dropdown-menu"
              item
              text="ACCOUNT">
              <Dropdown.Menu>
                <Dropdown.Item
                  className="pc-dropdown-menu__item"
                  text="MANAGE ACCOUNT"
                  href="/account"
                  active={settings.activeMenuItem === 'account'}/>

                {!isEmpty(user.owned_groups) ?
                  <Dropdown.Item
                    className="pc-dropdown-menu__item"
                    text="MANAGE GROUPS"
                    href="/manage-groups"
                    active={settings.activeMenuItem === 'manage-groups'}/> : ''}
              </Dropdown.Menu>
            </Dropdown> : ''}

          {!isAuthenticated() ? <Menu.Item
            className="pc-menu__item"
            name="login"
            active={settings.activeMenuItem === 'login'}
            href="/login">LOG
            IN</Menu.Item> : <Menu.Item
            className="pc-menu__item"
            name="logout"
            href="/logout">LOG
            OUT</Menu.Item>}
        </Menu.Menu>

      </Menu>

      {/*// Top banner for Test Drive users*/}
      {isAuthenticated() && !userTierAccess({ minTier: '1' }) ?
        <Grid className="top-banner-ad test-drive-top-banner-ad">
          <Grid.Row columns={2}>
            <Grid.Column
              width={6}
              className="test-drive-top-banner-ad__image"><Header as="h1">Don't Miss
              Out</Header></Grid.Column>
            <Grid.Column
              width={10}
              className="test-drive-top-banner-ad__cta">
              <Grid verticalAlign="middle">
                <Grid.Row columns={2}>
                  <Grid.Column width={11}>
                    <Header as="h1">{get(settings, 'config.htmls.test_drive_top_banner.title')}</Header>
                    {ReactHtmlParser(get(settings, 'config.htmls.test_drive_top_banner.html'))}
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Button
                      animated
                      secondary
                      className="test-drive-top-banner-ad__cta-button"
                      floated="right"
                      onClick={joinProvenCut}>
                      <Button.Content visible>Join ProvenCut</Button.Content>
                      <Button.Content hidden><Icon name="arrow right"/></Button.Content>
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row></Grid> : <React.Fragment/>}

      {/*    to show a message to free users put back the /recipes?referrer=signup#message=new_testdrive_signup code */}
      {/*     in the redirect for new users in the FreeSignUp.js doc*/}
      {parsedURLHash.message ? showMessage({ messageType: parsedURLHash.message }) : ''}

      {!hideSessionMessages ? showSessionMessages() : ''}
    </header>);
}

function mapStateToProps(state) {
  //console.log("Toolbar: Map State to Props", state);
  return {
    //recipes: state.recipesData.recipes,
    //filters: state.recipesData.filters,
    //totalPages: state.recipesData.totalPages,
    //totalRecipes: state.recipesData.totalRecipes,
    settings: state.settings,
    user: state.userData
  };
}

export default connect(mapStateToProps, { updateSettings })(withRouter(PageHeader));