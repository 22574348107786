import { RETRIEVED_ORDER_REVIEW } from "../../common/consts";
import { debug } from "../../common/helpers";
import api from "../api";
import { updateSettings } from "./settings";

export const retrieveOrderReview = orderCriteria => dispatch => {
  window.store.dispatch(updateSettings({fetchingOrder: true}));
  api.order.subscription_review(orderCriteria).then(orderReviewResponse => {
      debug.log("Retrieve Order Review: response",orderReviewResponse);
      window.store.dispatch(updateSettings({fetchingOrder: false}));
      dispatch(retrievedOrderReview(orderReviewResponse));
    }
  );
};

export const retrievedOrderReview = orderCriteria => ({
  type: RETRIEVED_ORDER_REVIEW,
  criteria: orderCriteria
});

export const resetOrderReview = () => dispatch => {
  dispatch(retrievedOrderReview({result: "reset", response: {}}));
};